import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Spin,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { dayjs } from "../../../../Utilities/dayjs";
import TdsTcsServices from "../../../Services/TdsTcsServices";
import {
  notificationContext,
  NotificationType,
} from "../../../Common/PageRoute";

interface IPropsTcs {
  open: boolean;
  setOpen: (value: boolean) => void;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  editData?: any;
}

const AddTcsTaxRate = (props: IPropsTcs) => {
  console.log("props: ", props.editData?.id);

  const [form] = Form.useForm();
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type: NotificationType, message: string) => {
    api[type]({
      message: "",
      description: message,
      duration: 2,
      closeIcon: false,
      style: {
        width: 350,
      },
    });
  };

  useEffect(() => {
    if (props.editData?.id) {
      form.setFieldsValue({
        section: props.editData?.section,
        natureOfCollection: props.editData?.natureOfCollection,
        rate: props.editData?.rate,
        // effectiveDate: dayjs(
        //   dayjs(props.editData?.effectiveDate).format("DD/MM/YYYY"),
        //   "DD/MM/YYYY"
        // ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editData?.id]);

  const saveTCS = () => {
    form.validateFields().then(async (value) => {
      try {
        setSaveButtonLoading(true);
        const postData = {
          ...value,
          // effectiveDate: dayjs(
          //   dayjs(value?.effectiveDate).format("DD/MM/YYYY"),
          //   "DD/MM/YYYY"
          // ),
        };

        const res = await TdsTcsServices.addTCS(postData, props.editData?.id);
        console.log("result", res);

        if (res.status) {
          // debugger;
          openNotification(
            "success",
            `${props.editData?.id ? "updated TCS rate." : "added TCS rate."}`
          );
          setSaveButtonLoading(false);
          props.setOpen(false);
          props.setTrigger((x: boolean) => !x);
        } else {
          setSaveButtonLoading(false);
          console.error("error", res?.message);
          res?.message && openNotification("error", res.message);
        }
      } catch (err: any) {
        // openNotification("error", err);
        console.error("error", err);
        err && openNotification("error", err);
        setSaveButtonLoading(false);
      }
    });
  };

  return (
    <>
      <Modal
        maskClosable={false}
        title={props.editData?.id ? "Update TCS Rate" : "Add TCS Rate"}
        open={props.open}
        width={500}
        // centered
        // onOk={handleOk}
        onCancel={() => props.setOpen(false)}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => saveTCS()}
              style={{ width: "80px" }}
              loading={saveButtonLoading}
              className="modelSaveButtonMargin"
            >
              {props.editData?.id ? "Update" : "Save"}
            </Button>
          </div>
        }
      >
        {/* <Spin */}
        {/* // indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} */}
        {/* // spinning={loading} */}
        {/* > */}
        <Form
          form={form}
          // layout="vertical"
          labelWrap={true}
          labelCol={{ xs: 7, sm: 10 }}
          wrapperCol={{ xs: 16, sm: 14 }}
          autoComplete="off"
          colon={false}
          labelAlign="left"
          name="tscDetail"
          requiredMark={false}
          // initialValues={intialValue}
        >
          <Divider className="ca-model-css" />

          <Form.Item
            className="mb-14"
            // className="inputBoxMb8"
            name="section"
            label="Section"
            rules={[{ required: true, message: "Section is required" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            className="mb-14"
            name="natureOfCollection"
            label="Nature Of Collection"
            rules={[
              { required: true, message: "Nature of collection is required" },
            ]}
            // className="inputBoxMb8"
          >
            <Input />
          </Form.Item>

          <Form.Item
            className="mb-14"
            label="Rate (%)"
            name="rate"
            rules={[
              {
                required: true,
                message: "Rate is required",
              },
            ]}
          >
            <InputNumber
              min={0}
              max={100}
              style={{ width: "100%" }}
              controls={false}
            />
          </Form.Item>

          {/* <Form.Item
            className="mb-14"
            label="EffectiveDate"
            name="effectiveDate"
          >
            <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
          </Form.Item> */}
        </Form>
        {/* </Spin> */}
        <Divider />
      </Modal>
      {contextHolder}
    </>
  );
};

export default AddTcsTaxRate;
