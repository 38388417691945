import React from "react";
import logoIcon from "../../Images/preview_company_logo.png";
import qrCode from "../../Images/172x170.svg";
import SignatureImg from "../../Images/preview_signature.png";
import "./Template3.css";
const Template3 = ({ currentClient, isChanged }: any) => {
  return (
    <>
      <div className={`Template` + isChanged?.font + ` ` + `main-invoice`}>
        <div className="main-invoice-space">
          <div className="pre-printed-top-space pre-printed-bottom-space">
            <div>
              <div className="main-tax-invoice">
                <h2 className="" style={{ margin: 0 }}>
                  Tax Invoice
                </h2>
              </div>
            </div>
            <div className="invoice-table-setion">
              {/* pre printed format start end */}
              <table
                style={{ borderCollapse: "collapse" }}
                className="temp3table"
              >
                <tbody>
                  <tr style={{ verticalAlign: "top" }}>
                    <td
                      className="invoice-padd-5 w-50"
                      style={{
                        border: "1px solid #000000",
                        verticalAlign: "top",
                      }}
                      rowSpan={8}
                    >
                      <div
                        className="invoice-logo-content"
                        style={{ alignItems: "center" }}
                      >
                        <div
                          className="invoice-logo"
                          style={{
                            marginRight: 5,
                            display: isChanged?.showLogo ? "" : "none",
                          }}
                        >
                          <img
                            src={
                              "https://placehold.co/100x100/EEE/31343C?font=montserrat&text=Logo"
                            }
                            alt="logo"
                          />
                        </div>
                        <div style={{ width: "72%" }}>
                          <h3
                            className=""
                            style={{
                              fontSize: 14,
                              marginBottom: 0,
                              marginTop: 0,
                            }}
                          >
                            <b> {currentClient?.businessName}</b>
                          </h3>
                          <p
                            className="invoice-fnt-11"
                            style={{
                              // margin: "0px",
                              display: isChanged?.showCompanyAddress
                                ? ""
                                : "none",
                            }}
                          >
                            Address: sample address, sample address2, Jodhpur,
                            Rajasthan, 342012
                          </p>
                          <p
                            className="invoice-fnt-11"
                            style={{
                              display: isChanged?.showEmail ? "" : "none",
                            }}
                          >
                            Email: demo.name@example.com
                          </p>
                          <p
                            className="invoice-fnt-11"
                            style={{
                              display: isChanged?.showWebsite ? "" : "none",
                            }}
                          >
                            Website: www.test.com
                          </p>
                          <p
                            className="invoice-fnt-11"
                            style={{
                              display: isChanged?.showPhone ? "" : "none",
                            }}
                          >
                            Phone no.: +919898989898
                          </p>
                          <b
                            className="invoice-fnt-11"
                            style={{
                              display: isChanged?.showGSTIN ? "" : "none",
                            }}
                          >
                            GSTIN: 08ABCDE9999F1Z8
                          </b>
                          <br
                            style={{
                              display: isChanged?.showGSTIN ? "" : "none",
                            }}
                          />
                          <b
                            className="invoice-fnt-11"
                            style={{
                              display: isChanged.showPan ? "" : "none",
                            }}
                          >
                            PAN: ABCCA4785G
                          </b>
                          <p className="invoice-fnt-11"></p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="invoice-padd-5 invoice-fnt-11"
                      style={{ verticalAlign: "middle" }}
                    >
                      <b>Invoice Number:</b> INV0001
                    </td>
                    <td
                      className="invoice-padd-5 invoice-fnt-11"
                      style={{ verticalAlign: "middle" }}
                    >
                      <b>Invoice Date:</b> DD-MM-YYYY
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="invoice-padd-5 invoice-fnt-11"
                      style={{ verticalAlign: "middle" }}
                    >
                      <b>PO No.:</b> POO1
                    </td>
                    <td
                      className="invoice-padd-5 invoice-fnt-11"
                      style={{ verticalAlign: "middle" }}
                    >
                      <b>PO Date:</b> DD-MM-YYYY
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="invoice-padd-5 invoice-fnt-11"
                      style={{
                        verticalAlign: "middle",
                        display: isChanged.showEBillNo ? "" : "none",
                      }}
                    >
                      <b>E-way Bill No:</b> 1Demo74
                    </td>
                    <td
                      className="invoice-padd-5 invoice-fnt-11"
                      style={{
                        verticalAlign: "middle",
                        display: isChanged.showEBillNo ? "" : "none",
                      }}
                    >
                      <b>E-way Bill Date:</b> DD-MM-YYYY
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: isChanged.showDispatchDetail ? "" : "none",
                    }}
                  >
                    <td
                      className="invoice-padd-5 invoice-fnt-11"
                      style={{ verticalAlign: "middle" }}
                    >
                      <b>LR No.:</b> 1Demo45
                    </td>
                    <td
                      className="invoice-padd-5 invoice-fnt-11"
                      style={{ verticalAlign: "middle" }}
                    >
                      <b>LR Date:</b> DD-MM-YYYY
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: isChanged.showDispatchDetail ? "" : "none",
                    }}
                  >
                    <td className="invoice-padd-5 invoice-fnt-11" colSpan={2}>
                      <b>Vehicle No:</b> RJ-0X-LK-2XX4
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: isChanged.showDispatchDetail ? "" : "none",
                    }}
                  >
                    <td className="invoice-padd-5 invoice-fnt-11" colSpan={2}>
                      <b>Transporter:</b>
                      Transport Pvt Ltd.
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                style={{ borderCollapse: "collapse" }}
                className="temp3table"
              >
                <tbody>
                  <tr>
                    <td style={{ padding: 0, borderTop: 0 }} colSpan={3}>
                      <div style={{ display: "flex" }}>
                        <div className="invoice-padd-5" style={{ flex: 1 }}>
                          <p className="invoice-fnt-11">
                            <b>Buyer (Bill to): </b>
                          </p>
                          <h5 style={{ fontSize: 11, margin: 0 }}>
                            <b>Billing Party Name</b>
                          </h5>
                          <p />
                          <p className="invoice-fnt-11">
                            GSTIN: 24XXXXXXXXXXXXX
                          </p>
                          <p className="invoice-fnt-11">
                            Address Line 1, Address Line 2, Selected with
                            Account City, State Name, Pincode
                          </p>
                          <p className="invoice-fnt-11">
                            Phone no.: 9500000000
                          </p>
                          <p className="invoice-fnt-11">Email: xyz@gmail.com</p>
                          <div style={{ display: "flex" }}>
                            <p className="invoice-fnt-11">
                              <b>Country:</b> Country Name
                            </p>
                          </div>
                          <p className="invoice-fnt-11"></p>
                        </div>
                        <div
                          className="invoice-padd-5"
                          style={{
                            flex: 1,
                            borderLeft: "1px solid #000000",
                            display: isChanged.showShipping ? "" : "none",
                          }}
                        >
                          <p className="invoice-fnt-11">
                            <b>Consignee (Ship to): </b>
                          </p>
                          <h5 style={{ fontSize: 11, margin: 0 }}>
                            <b>Shipping Party Name</b>
                          </h5>
                          <p />
                          <p className="invoice-fnt-11">
                            GSTIN: 24XXXXXXXXXXXXX
                          </p>
                          <p className="invoice-fnt-11">
                            Address Line 1, Address Line 2, Shipping City, State
                            Name, Shipping Pincode
                          </p>
                          <p className="invoice-fnt-11">
                            Phone no.: 9500000000
                          </p>
                          <p className="invoice-fnt-11">Email: xyz@gmail.com</p>
                          <div style={{ display: "flex" }}>
                            <p
                              className="invoice-fnt-11"
                              style={{ marginRight: 5 }}
                            >
                              <b>Place Of Supply:</b>
                              State Name
                            </p>
                            <p className="invoice-fnt-11">
                              <b>Country:</b> Country Name
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                style={{ borderCollapse: "collapse" }}
                className="temp3table"
              >
                <thead>
                  <tr
                    style={{
                      borderCollapse: "collapse",
                      pageBreakInside: "avoid",
                    }}
                  >
                    <th
                      className="invoice-fnt-11 break-all"
                      style={{
                        whiteSpace: "nowrap",
                        textAlign: "center",
                        padding: 5,
                      }}
                    >
                      <b>Sr no.</b>
                    </th>
                    <th
                      className="invoice-fnt-11 break-all"
                      style={{ textAlign: "center", padding: 5 }}
                    >
                      <b>Product/Service</b>
                    </th>
                    <th
                      className="invoice-fnt-11 break-all"
                      style={{
                        textAlign: "center",
                        padding: 5,
                        display: isChanged.showHSN ? "" : "none",
                      }}
                    >
                      <b>HSN/SAC</b>
                    </th>
                    <th
                      className="invoice-fnt-11 break-all"
                      style={{ textAlign: "center", padding: 5 }}
                    >
                      <b>Qty</b>
                    </th>
                    <th
                      className="invoice-fnt-11 break-all"
                      style={{ textAlign: "center", padding: 5 }}
                    >
                      <b>Rate</b>
                    </th>
                    <th
                      className="invoice-fnt-11"
                      style={{ textAlign: "center", padding: 5 }}
                    >
                      <b>Discount</b>
                    </th>
                    <th
                      className="invoice-fnt-11 break-all"
                      style={{ textAlign: "center", padding: 5 }}
                    >
                      <b>Taxable Amt</b>
                    </th>
                    <th
                      className="invoice-fnt-11 break-all"
                      style={{ textAlign: "center", padding: 5 }}
                    >
                      <b>Tax</b>
                    </th>
                    <th
                      className="invoice-fnt-11 break-all"
                      style={{ textAlign: "center", padding: 5 }}
                    >
                      <b>Total (₹)</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ borderCollapse: "collapse" }}>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{
                        verticalAlign: "top",
                        textAlign: "center",
                        padding: "3px 5px",
                      }}
                    >
                      1
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{ verticalAlign: "top", padding: "3px 5px" }}
                    >
                      <b>Product 1</b>
                      <p className="invoice-lh-inherit">best item</p>
                      <p className="invoice-lh-inherit">Challan no.: C0001</p>
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{
                        textAlign: "center",
                        verticalAlign: "top",
                        padding: "3px 5px",
                        display: isChanged.showHSN ? "" : "none",
                      }}
                    >
                      415278
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{
                        textAlign: "right",
                        verticalAlign: "top",
                        padding: "3px 5px",
                      }}
                    >
                      1.000
                      <div
                        style={{
                          color: "#6f6f6f",
                          fontSize: 9,
                          display: isChanged.showUnit ? "" : "none",
                        }}
                      >
                        PCS
                      </div>
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{
                        textAlign: "right",
                        verticalAlign: "top",
                        padding: "3px 5px",
                      }}
                    >
                      75000.00
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{
                        textAlign: "right",
                        verticalAlign: "top",
                        padding: "3px 5px",
                      }}
                    >
                      0.00
                      <div style={{ color: "#6f6f6f", fontSize: 9 }}>
                        (0.00%)
                      </div>
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{
                        textAlign: "right",
                        verticalAlign: "top",
                        padding: "3px 5px",
                      }}
                    >
                      75000.00
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{
                        textAlign: "right",
                        verticalAlign: "top",
                        padding: "3px 5px",
                      }}
                    >
                      2250.00
                      <div style={{ color: "#6f6f6f", fontSize: 9 }}>(3%)</div>
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{
                        textAlign: "right",
                        verticalAlign: "top",
                        padding: "3px 5px",
                      }}
                    >
                      77600.00
                    </td>
                  </tr>
                  <tr style={{}}>
                    <td className="inv-border-block-0" />
                    <td className="inv-border-block-0" />
                    <td className="inv-border-block-0" />
                    <td className="inv-border-block-0" />
                    <td className="inv-border-block-0" />
                    <td className="inv-border-block-0" />
                    <td className="inv-border-block-0" />
                    <td className="inv-border-block-0" />
                    <td className="inv-border-block-0" />
                  </tr>
                  <tr
                    style={{
                      borderCollapse: "collapse",
                      pageBreakInside: "avoid",
                      borderTop: "1px solid #000000",
                      height: 25,
                    }}
                  >
                    <td />
                    <td
                      className="invoice-fnt-11 break-all"
                      style={{ padding: 5 }}
                    >
                      <b>Sub Total</b>
                    </td>
                    <td
                      style={{
                        display: isChanged?.showHSN ? "" : "none",
                      }}
                    />
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      1.000
                    </td>
                    <td />
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      0.00
                    </td>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      75000.00
                    </td>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      2250.00
                    </td>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      <b>77600.00</b>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr />
                </tfoot>
              </table>
              <table
                className="temp3table"
                style={{
                  border: "1px solid #000000",
                  borderCollapse: "collapse",
                  borderBottom: 0,
                }}
              >
                {/* <thead>
                                      <tr>
                                          <th class="invoice-fnt-11 invoice-padd-5"
                                              style="text-align: center; border-top: 0px;">GST
                                              Rate</th>
                                          <th class="invoice-fnt-11 invoice-padd-5"
                                              style="text-align: right; border-top: 0px;">Taxable
                                              Value</th>
                                          <th class="invoice-fnt-11 invoice-padd-5"
                                              style="text-align: right; border-top: 0px;">SGST</th>
                                          <th class="invoice-fnt-11 invoice-padd-5"
                                              style="text-align: right; border-top: 0px;">CGST</th>
                                      </tr>
                                  </thead> */}
                <tbody>
                  {/* 
                                      <tr>
                                          <td class="invoice-fnt-11 invoice-padd-5" style="text-align: center;">
                                              3%</td>
                                          <td class="invoice-fnt-11 invoice-padd-5" style="text-align: right;">
                                              75000.00</td>
                                          <td class="invoice-fnt-11 invoice-padd-5" style="text-align: right;">
                                              1125.00</td>
                                          <td class="invoice-fnt-11 invoice-padd-5" style="text-align: right;">
                                              1125.00</td>
                                      </tr> */}
                  <tr style={{ padding: 0 }}>
                    <td
                      className="invoice-fnt-11 invoice-padd-5 inv-border-block-0"
                      style={{ textAlign: "right", paddingBottom: 0 }}
                      colSpan={3}
                    >
                      <p>Taxable Amt</p>
                    </td>
                    <td
                      className="invoice-fnt-11 invoice-padd-5 inv-border-block-0"
                      style={{ textAlign: "right", paddingBottom: 0 }}
                    >
                      <p>75000.00</p>
                    </td>
                  </tr>
                  {/* <tr style={{ padding: 0 }}>
                    <td
                      className="invoice-fnt-11 invoice-padd-5 inv-border-block-0"
                      style={{ textAlign: "right", paddingTop: 0 }}
                      colSpan={3}
                    ></td>
                    <td
                      className="invoice-fnt-11 invoice-padd-5 inv-border-block-0"
                      style={{ textAlign: "right", paddingTop: 0 }}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      className="invoice-fnt-11 invoice-padd-5"
                      style={{ textAlign: "right" }}
                      colSpan={3}
                    >
                      <b>CESS Amount(₹)</b>
                    </td>
                    <td
                      className="invoice-fnt-11 invoice-padd-5"
                      style={{ textAlign: "right" }}
                    >
                      <b>345.00</b>
                    </td>
                  </tr> */}
                  <tr>
                    <td
                      className="invoice-fnt-11 invoice-padd-5"
                      style={{ textAlign: "right" }}
                      colSpan={3}
                    >
                      <b>TOTAL GST(₹)</b>
                    </td>
                    <td
                      className="invoice-fnt-11 invoice-padd-5"
                      style={{ textAlign: "right" }}
                    >
                      <b>2250.00</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="invoice-fnt-11 invoice-padd-5"
                      style={{ textAlign: "right" }}
                      colSpan={3}
                    >
                      <p>Discount Amount(11.00%)</p>
                    </td>
                    <td
                      className="invoice-fnt-11 invoice-padd-5"
                      style={{ textAlign: "right" }}
                    >
                      <p>110.39</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="invoice-fnt-11 invoice-padd-5"
                      style={{ textAlign: "right" }}
                      colSpan={3}
                    >
                      <b>Total Amount(₹)</b>
                    </td>
                    <td
                      className="invoice-fnt-11 invoice-padd-5"
                      style={{ textAlign: "right" }}
                    >
                      <b>77250.00</b>
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="invoice-fnt-11 invoice-padd-5"
                      style={{ textAlign: "right" }}
                      colSpan={3}
                    >
                      <b> Received Amount(₹)</b>
                    </td>
                    <td
                      className="invoice-fnt-11 invoice-padd-5"
                      style={{ textAlign: "right" }}
                    >
                      <b>0.00</b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                style={{ borderCollapse: "collapse", border: "1px solid" }}
                // className="temp3table"
              >
                <tbody>
                  <tr style={{ border: "1px solid" }}>
                    <td style={{ borderBottom: 0, padding: 0 }}>
                      <div style={{ display: "flex" }}>
                        <div
                          className="invoice-fnt-11 invoice-padd-5 conditions"
                          style={{
                            flex: 1,
                            display: isChanged.showBankDetail ? "" : "none",
                          }}
                        >
                          <table style={{ borderCollapse: "collapse" }}>
                            <tbody>
                              <tr>
                                <td colSpan={4}>
                                  <b className="">Bank Details</b>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ whiteSpace: "nowrap" }}>
                                  A/C Holder Name:
                                </td>
                                <td colSpan={3}>Account Holder Name</td>
                              </tr>
                              <tr>
                                <td>Bank Name:</td>
                                <td colSpan={3}>Your Bank Name</td>
                              </tr>
                              <tr>
                                <td>A/C No:</td>
                                <td>17550XXXXXX1008</td>
                                <td
                                  style={{
                                    paddingLeft: 2,
                                    whiteSpace: "nowrap",
                                    width: 57,
                                  }}
                                >
                                  IFSC Code:
                                </td>
                                <td>IFSCOCODES</td>
                              </tr>
                            </tbody>
                          </table>
                          <div>
                            <table>
                              <tbody>
                                <tr>
                                  <td>UPI Id:</td>
                                  <td>john.doe@sbi</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div style={{ flex: 1, borderLeft: "1px solid " }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className="invoice-padd-5"
                          >
                            <p className="invoice-fnt-11">
                              <b>Outstanding Amt:</b>
                            </p>
                            <p className="invoice-fnt-11">₹XXX0.00 Cr</p>
                          </div>
                          <div
                            className="invoice-fnt-11 invoice-padd-5"
                            style={{ borderTop: "1px solid " }}
                          >
                            <p
                              className="invoice-lh-normal"
                              style={{ whiteSpace: "nowrap", marginRight: 2 }}
                            >
                              <b>Special Notes:</b>
                            </p>
                            <p
                              className="invoice-lh-normal"
                              style={{ width: "100%", flex: 1 }}
                            >
                              Special notes display over which is entered on
                              transaction.
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr style={{ pageBreakInside: "avoid" }}>
                    <td
                      className="invoice-padd-5"
                      style={{ borderTop: "1px solid #000000" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="invoice-fnt-11">Amount in Words:</p>
                        <p className="invoice-fnt-11">E. &amp; O.E</p>
                      </div>
                      <p className="invoice-fnt-11">
                        <b>
                          seventy seven thousand two hundred fifty Rupees Only
                        </b>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* <table
                style={{ borderCollapse: "collapse" }}
                className="temp3table"
              >
^                <tbody>
                  <tr>
                    <td style={{ borderBottom: 0, padding: 0 }}>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{ flex: 1, borderLeft: "1px solid #000000" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            className="invoice-padd-5"
                          >
                            <p className="invoice-fnt-11">
                              <b>Outstanding Amt:</b>
                            </p>
                            <p className="invoice-fnt-11">₹XXX0.00 Cr</p>
                          </div>
                          <div
                            className="invoice-fnt-11 invoice-padd-5"
                            style={{ borderTop: "1px solid #000000" }}
                          >
                            <p
                              className="invoice-lh-normal"
                              style={{ whiteSpace: "nowrap", marginRight: 2 }}
                            >
                              <b>Special Notes:</b>
                            </p>
                            <p
                              className="invoice-lh-normal"
                              style={{ width: "100%", flex: 1 }}
                            >
                              Special notes display over which is entered on
                              transaction.
                            </p>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr style={{ pageBreakInside: "avoid" }}>
                    <td
                      className="invoice-padd-5"
                      style={{ borderBottom: "1px solid #000000" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p className="invoice-fnt-11">Amount in Words:</p>
                        <p className="invoice-fnt-11">E. &amp; O.E</p>
                      </div>
                      <p className="invoice-fnt-11">
                        <b>
                          seventy seven thousand two hundred fifty Rupees Only
                        </b>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table> */}
              <table
                style={{
                  border: "1px solid #000000",
                  borderCollapse: "collapse",
                  display: isChanged.showTaxableValue ? "" : "none",
                }}
              >
                <thead className="">
                  <tr>
                    <th
                      className="invoice-fnt-11"
                      style={{
                        textAlign: "center",
                        borderTop: 0,
                        padding: 5,
                        wordBreak: "break-word",
                      }}
                    >
                      <b>HSN/SAC</b>
                    </th>
                    <th
                      className="invoice-fnt-11"
                      style={{
                        textAlign: "center",
                        borderTop: 0,
                        padding: 5,
                        wordBreak: "break-word",
                      }}
                    >
                      <b>Taxable Amt</b>
                    </th>
                    <th
                      className="invoice-fnt-11"
                      style={{
                        textAlign: "center",
                        borderTop: 0,
                        padding: 5,
                        wordBreak: "break-word",
                      }}
                    >
                      <b>IGST</b>
                    </th>
                    <th
                      className="invoice-fnt-11"
                      style={{
                        textAlign: "center",
                        borderTop: 0,
                        padding: 5,
                        wordBreak: "break-word",
                      }}
                    >
                      <b>Tax Amount(₹)</b>
                    </th>
                    <th
                      className="invoice-fnt-11"
                      style={{
                        textAlign: "center",
                        borderTop: 0,
                        padding: 5,
                        wordBreak: "break-word",
                      }}
                    >
                      <b>CGST</b>
                    </th>
                    <th
                      className="invoice-fnt-11"
                      style={{
                        textAlign: "center",
                        borderTop: 0,
                        padding: 5,
                        wordBreak: "break-word",
                      }}
                    >
                      <b>Tax Amount(₹)</b>
                    </th>
                    <th
                      className="invoice-fnt-11"
                      style={{
                        textAlign: "center",
                        borderTop: 0,
                        padding: 5,
                        wordBreak: "break-word",
                      }}
                    >
                      <b>SGST</b>
                    </th>
                    <th
                      className="invoice-fnt-11"
                      style={{
                        textAlign: "center",
                        borderTop: 0,
                        padding: 5,
                        wordBreak: "break-word",
                      }}
                    >
                      <b>Tax Amount(₹)</b>
                    </th>

                    <th
                      className="invoice-fnt-11"
                      style={{
                        textAlign: "center",
                        borderTop: 0,
                        padding: 5,
                        wordBreak: "break-word",
                      }}
                    >
                      <b>Tax Amount(₹)</b>
                    </th>
                    <th
                      className="invoice-fnt-11"
                      style={{
                        textAlign: "center",
                        borderTop: 0,
                        padding: 5,
                        wordBreak: "break-word",
                      }}
                    >
                      <b>Total Tax Amt(₹)</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style={{
                      pageBreakInside: "avoid",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{ textAlign: "center", padding: 5 }}
                    >
                      415278
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      75000.00
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{ textAlign: "center", padding: 5 }}
                    >
                      0%
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      0.00
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{ textAlign: "center", padding: 5 }}
                    >
                      1.5%
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      1125.00
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{ textAlign: "center", padding: 5 }}
                    >
                      1.5%
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      1125.00
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{ textAlign: "center", padding: 5 }}
                    >
                      10%
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      345.00
                    </td>
                    <td
                      className="invoice-fnt-11 inv-border-block-0"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      2595.00
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "center", padding: 5 }}
                    >
                      Total(₹)
                    </td>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      75000.00
                    </td>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "center" }}
                    ></td>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      0.00
                    </td>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "center" }}
                    ></td>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      1125.00
                    </td>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "center" }}
                    ></td>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      1125.00
                    </td>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "center" }}
                    ></td>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      345.00
                    </td>
                    <td
                      className="invoice-fnt-11"
                      style={{ textAlign: "right", padding: 5 }}
                    >
                      2595.00
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                className="qr-section invoice-fnt-11"
                style={{
                  pageBreakInside: "avoid",
                  justifyContent: "space-between",
                }}
              >
                <div className="qr-section-tax" style={{ width: "75%" }}>
                  <div className="qr-section-tax-inner" style={{ height: 180 }}>
                    <div className="invoice-padd-5">
                      <p>
                        <span>
                          <b className="">E-invoice Details</b>
                        </span>
                      </p>
                      <p>
                        <span>IRN</span>
                        <span>: 1Dummy3</span>
                      </p>
                      <p>
                        <span>ACK No</span>
                        <span>: 1XXXX5</span>
                      </p>
                      <p>
                        <span>ACK DATE</span>
                        <span>: DD-MM-YYYY</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="invoice-padd-5" style={{ margin: "auto" }}>
                  <div style={{ width: 172, height: 170 }}>
                    <img
                      src="https://placehold.co/172x170"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <table style={{ borderCollapse: "collapse" }}>
                <tbody></tbody>
              </table>
              <table
                style={{
                  borderCollapse: "collapse",
                  pageBreakInside: "avoid",
                  borderTop: "1px solid #000000",
                }}
                className="print-border-top"
              >
                <tbody>
                  <tr>
                    <td
                      className="invoice-padd-5 invoice-fnt-11"
                      style={{
                        verticalAlign: "top",
                        borderTop: 0,
                        width: "50%",
                      }}
                    >
                      Customer's seal &amp; signature:
                    </td>
                    <td
                      className="invoice-padd-5"
                      style={{ textAlign: "right", borderTop: 0 }}
                    >
                      <p className="invoice-fnt-11">For, Demo Company</p>
                      <div
                        style={{ marginLeft: "auto", width: 120, height: 66 }}
                      >
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={qrCode}
                        />
                      </div>
                      <p className="invoice-fnt-11">Signature</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template3;
