import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Popover,
  Row,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useContext } from "react";
import { Utils } from "../../../Utilities/Utils";
import {
  DownloadOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { dayjs } from "../../../Utilities/dayjs";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";
import { useTheme } from "../../Context/ThemeContext";
import FixedAssetsService, {
  AssetStatus,
} from "../../Services/FixedAssetsServiceNew";
import Search from "antd/es/transfer/search";
import FixedAssetsServiceNew from "../../Services/FixedAssetsServiceNew";
import InvoiceExportService from "../../Services/InvoiceExportDetails";
const { RangePicker } = DatePicker;

interface IDisposedListDataType {
  key: string;
  id: string;
  number: string;
  name: string;
  sno: number;
  date: string;
  disposalValue: number;
  disposedate: string;
  depreciation: number;
  price: string;
  gainloss: number;
}
const FAR = () => {
  const { currentClient, companySettings } = useClient();
  const { themeData } = useTheme();
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [disposeList, setDisposeList] = React.useState<any[]>([]);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [searchDispose, setSearchDispose] = React.useState<any>();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [reRenderData, setReRenderData] = React.useState<boolean>(false);
  const { openNotification } = useContext(notificationContext);
  const [exportAssetData, setExportAssetData] = React.useState<any[]>([]);
  const [exportType, setExportType] = React.useState<number>(-1);
  const [filterDate, setFilterDate] = React.useState<any>(
    JSON.parse(localStorage.getItem("LockPeriod")!)
      ? {
          from: JSON.parse(localStorage.getItem("LockPeriod")!).from,
          to: JSON.parse(localStorage.getItem("LockPeriod")!).to,
        }
      : {
          from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
          to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
        }
  );

  const [selectedColumns, setSelectedColumns] = React?.useState([
    "sno",
    "number",
    "name",
    "date",
    "disposedate",
    "price",
    "disposalValue",
    "depreciation",
    "gainloss",
  ]);

  const allColumns = [
    // { title: "S.No", dataIndex: "sno", key: "sno" },
    { title: "Asset Reference", dataIndex: "number", key: "number" },
    { title: "Asset Name", dataIndex: "name", key: "name" },
    { title: "Purchase Date", dataIndex: "date", key: "date" },
    { title: "Dispose Date", dataIndex: "disposedate", key: "disposedate" },
    { title: "Purchase Price", dataIndex: "price", key: "price" },
    {
      title: "Disposal Value",
      dataIndex: "disposalValue",
      key: "disposalValue",
    },
    { title: "Depreciation", dataIndex: "depreciation", key: "depreciation" },
    { title: "Gain/Loss", dataIndex: "gainloss", key: "gainloss" },
    // { title: "Payment Date", dataIndex: "payDate", key: "payDate" },
    {
      title: "Depreciation Start Date",
      dataIndex: "depreciationStartDate",
      key: "depreciationStartDate",
    },
    { title: "Location", dataIndex: "location", key: "location" },
    {
      title: "Disposal Payment Ref",
      dataIndex: "disposalPaymentRef",
      key: "disposalPaymentRef",
    },
    {
      title: "Purchase Pay Reference",
      dataIndex: "purPayRef",
      key: "purPayRef",
    },
    {
      title: "Warranty Date",
      dataIndex: "warrantyExpDate",
      key: "warrantyExpDate",
    },
  ];
  const columns: ColumnsType<IDisposedListDataType> = [
    {
      title: "S.No",
      dataIndex: "sno",
    },
    {
      title: "Asset Reference",
      dataIndex: "number",
    },
    {
      title: "Asset Name",
      dataIndex: "name",
    },
    {
      title: "Purchase Date",
      dataIndex: "date",
    },
    {
      title: "Dispose Date",
      dataIndex: "disposedate",
    },
    {
      title: "Purchase Price",
      dataIndex: "price",
      align: "right",
      width: 150,
    },
    {
      title: "Disposal Value",
      dataIndex: "disposalValue",
      align: "right",
    },
    {
      title: "Depreciation",
      dataIndex: "depreciation",
      align: "right",
    },
    {
      title: (
        <>
          {"Gain/Loss "}
          <Tooltip
            title="Disposal Value - (Asset Value - Accumulated Depreciation)"
            placement="top"
            color="#4b5b95"
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "gainloss",
      align: "right",
    },
    // {
    //   title: "Payment Date",
    //   dataIndex: "payDate",
    //   align: "right",
    // },
    {
      title: "Depreciation Start Date",
      dataIndex: "depreciationStartDate",
      align: "right",
    },
    {
      title: "Location",
      dataIndex: "location",
      align: "right",
    },
    {
      title: "Disposal Payment Ref",
      dataIndex: "disposalPaymentRef",
      align: "right",
    },
    {
      title: "Purchase Pay Reference",
      dataIndex: "purPayRef",
      align: "right",
    },

    {
      title: "Warranty Date",
      dataIndex: "warrantyExpDate",
      align: "right",
    },
  ];

  console.log("search", searchDispose);

  const getList = async (
    clientId: string,
    fromDate: string,
    toDate: string,
    start: number,
    length: number,
    sortCol: any,
    sortDir: any,
    status: any
  ) => {
    if (exportType < 1) {
      setLoading(true);
    }
    await FixedAssetsServiceNew.getChildAssetList(
      clientId,
      fromDate,
      toDate,
      start,
      length,
      sortCol,
      sortDir,
      searchDispose
    )
      .then((res) => {
        console.log("first", res);
        if (res != null || res?.items?.length > 0) {
          let assetData = // res?.items
            res?.items?.map((e: any, index: number) => ({
              sno: index + 1,
              number: e?.number,
              name: e?.assetName,
              date: dayjs(e?.invoiceDate).format("DD/MM/YYYY"),
              price: Utils.getFormattedNumber(e.assetPrice),
              disposedate:
                e?.assetDisposalDate !== undefined &&
                dayjs(e?.assetDisposalDate).unix() > 1
                  ? dayjs(e?.assetDisposalDate).format("DD/MM/YYYY")
                  : undefined,
              disposalValue: e?.assetDisposalValue,
              depreciation: e?.accumulatedDepreciation === 1 ? "Yes" : "No",
              gainloss: Utils?.getFormattedNumber(e?.depAmount),
              depreciationStartDate: dayjs(e?.assetDepStartDate).format(
                "DD/MM/YYYY"
              ),
              location: e?.assetLocation,
            }));
          if (exportType >= 1) {
            setExportAssetData(assetData);
            if (exportType === 1) {
              exportCsv(assetData);
            } else if (exportType === 2) {
              exportPDF(assetData);
            } else if (exportType === 3) {
              exportExcel(assetData);
            }
          } else {
            setTotalRecords(res?.totalRecords);
            setDisposeList(assetData);
          }
        } else if (res.items.length === 0) {
          setDisposeList([]);
          setTotalRecords(res?.totalRecords);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    setLoading(false);
  };

  console.log("displist", disposeList);

  React.useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      // setLoading(true);
      getList(
        currentClient?.id!,
        dayjs(filterDate?.from, "DD/MM/YYYY").toISOString(),
        dayjs(filterDate?.to, "DD/MM/YYYY").endOf("day").toISOString(),
        (page - 1) * pageSize,
        pageSize,
        undefined,
        undefined,
        searchDispose
        // "", // tyep
        // "", // lastAccDate
        // true
      );
    }
  }, [
    currentClient?.id,
    searchDispose,
    page,
    pageSize,
    reRenderData,
    filterDate,
    exportType,
  ]);

  const handleCheckboxChange = (value: any) => {
    setSelectedColumns((prev) =>
      prev.includes(value)
        ? prev.filter((col) => col !== value)
        : [...prev, value]
    );
  };

  const columnOptions = allColumns.map((col: any) => ({
    label: col.title,
    value: col.key,
  }));

  const content = (
    <Space direction="vertical">
      {columnOptions.map((option) => (
        <Checkbox
          key={option.value}
          checked={selectedColumns.includes(option.value)}
          onChange={() => handleCheckboxChange(option.value)}
        >
          {option.label}
        </Checkbox>
      ))}
    </Space>
  );
  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };
  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };
  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };

  const getLast15Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(15, "day").startOf("day"); // Start 15 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };
  const getLast7Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(7, "day").startOf("day"); // Start 7 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };

  const getThisMonth = () => {
    const now = dayjs();
    return [now.startOf("month"), now.endOf("month")];
  };
  const getLastMonth = () => {
    const now = dayjs();
    const lastMonth = now.subtract(1, "month");
    return [lastMonth.startOf("month"), lastMonth.endOf("month")];
  };

  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {});
  };

  let path = "Fixed Assets Report";
  // const columnsA = ["Asset Reference", "Ref No", "Account Name", "Amount"];
  const getSelectedColumnTitles = () => {
    return allColumns
      .filter((column) => selectedColumns.includes(column.dataIndex))
      .map((column) => column.title);
  };

  const selectedColumnTitles = getSelectedColumnTitles();
  const fieldMapping = {
    "S.No": (item: any, index: number) => item + 1,
    "Asset Reference": (item: any) => item?.number,
    "Asset Name": (item: any) => item?.name,
    "Purchase Date": (item: any) => item?.date,
    "Dispose Date": (item: any) => item?.disposedate,
    "Purchase Price": (item: any) => item?.purchasePrice,
    "Disposal Value": (item: any) => item?.disposalValue,
    Depreciation: (item: any) => item?.depreciation,
    "Gain/Loss": (item: any) => item?.gainloss,
    "Depreciation Start Date": (item: any) => item?.depreciationStartDate,
    Location: (item: any) => item?.location,
    "Disposal Payment Re": (item: any) => item?.disposalPaymentRe,
    "Purchase Pay Reference": (item: any) => item?.purchasePayReference,
    "Warranty Date": (item: any) => item?.warrantyDate,
  };

  const exportCsv = (exportData: any[]) => {
    InvoiceExportService.generateCSV(
      exportData,
      selectedColumnTitles,
      fieldMapping,
      generateFooterRow,
      "",
      filterDate,
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (exportData: any[]) => {
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      selectedColumnTitles,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportExcel = async (exportData: any[]) => {
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      selectedColumnTitles,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportContent = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportAssetData.length > 0) {
              exportCsv(exportAssetData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportAssetData.length > 0) {
              exportPDF(exportAssetData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSX"
          onClick={() => {
            if (exportAssetData.length > 0) {
              exportExcel(exportAssetData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSX
        </Button>
      </div>
    </Space>
  );

  return (
    <>
      <div>
        <Card
          className="ca-cashInHand-card-body"
          style={{
            marginTop: "6px",
            padding: "48px 0px 0px 0px",
            boxShadow: "0px 0px 10px 0px #96A1C84D",
          }}
        >
          <Row
            gutter={10}
            justify={"space-between"}
            style={{ margin: "20px 0px" }}
          >
            <Col>
              <Row>
                <Col>
                  <h3 style={{ color: "rgb(57, 92, 210) " }}>
                    Fixed Assets Register
                  </h3>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col style={{ marginRight: "10px" }}>
                  {disposeList?.length === 0 ? (
                    <>
                      <Button
                        type="default"
                        icon={<DownloadOutlined />}
                        disabled
                      >
                        Export
                      </Button>
                    </>
                  ) : (
                    <>
                      <Popover
                        content={content}
                        placement="rightTop"
                        trigger="hover"
                      >
                        <Button
                          type="default"
                          icon={<DownloadOutlined />}
                          // onClick={() => exportCsv()}
                        >
                          Export
                        </Button>
                      </Popover>
                    </>
                  )}
                </Col>
                <Col>
                  <span>Colums </span>
                  <Popover content={content} trigger="click">
                    <Button
                      size="small"
                      type="primary"
                      icon={<PlusOutlined />}
                      style={{
                        padding: "1px",
                        margin: "3px 10px 0px 0px",
                      }}
                    />
                  </Popover>
                </Col>
                <Col>
                  <Search
                    placeholder="Search..."
                    onChange={(e: any) => {
                      setSearchDispose(e.target.value);
                      setExportAssetData([]);
                      setExportType(-1);
                    }}
                  />
                </Col>
                <Col>
                  <span style={{ padding: "0px 10px" }}>Date</span>
                  <RangePicker
                    defaultValue={[
                      dayjs(filterDate.from, "DD/MM/YYYY"),
                      dayjs(),
                      // dayjs(filterDate.to, "DD/MM/YYYY"),
                    ]}
                    allowClear={false}
                    format="DD/MM/YYYY"
                    style={{
                      width:
                        width <= 464
                          ? "210px"
                          : width == 375
                          ? "200px"
                          : "250px",
                      border: "1px solid #f1f3ff",
                    }}
                    onChange={(e: any) => {
                      if (e === null) {
                        setFilterDate({});
                      } else {
                        setFilterDate({
                          ...filterDate,
                          from: dayjs(e[0]).format("DD/MM/YYYY"),
                          to: dayjs(e[1]).format("DD/MM/YYYY"),
                        });
                        localStorage.setItem(
                          "LockPeriod",
                          JSON.stringify({
                            ...filterDate,
                            from: dayjs(e[0]).format("DD/MM/YYYY"),
                            to: dayjs(e[1]).format("DD/MM/YYYY"),
                          })
                        );
                        openNotification("success", `Period locked.`);
                      }

                      //console.log(e, "dateCheck");
                      setDisposeList([]);
                      setExportType(-1);
                    }}
                    presets={[
                      {
                        label: "Today",
                        value: [dayjs().add(0, "d"), dayjs()],
                      },
                      {
                        label: "Last 7 Days",
                        value: getLast7Days(),
                      },
                      {
                        label: "Last 15 Days",
                        value: getLast15Days(),
                      },
                      {
                        label: "This Month",
                        value: getThisMonth(),
                      },
                      {
                        label: "Last Month",
                        value: getLastMonth(),
                      },
                      {
                        label: "Quarter 1",
                        value: getQuarterPreset(0),
                      },
                      {
                        label: "Quarter 2",
                        value: getQuarterPreset(3),
                      },
                      {
                        label: "Quarter 3",
                        value: getQuarterPreset(6),
                      },
                      {
                        label: "Quarter 4",
                        value: getQuarterPreset(9),
                      },
                      {
                        label: "This Year",
                        value: thisYear(),
                      },
                      {
                        label: "Last Year",
                        value: lastYear(),
                      },
                    ]}
                  />{" "}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={24} md={24}>
              <Table
                className={`ca-viewmodel-table Tabel-style table-${
                  themeData?.componentSize ?? "middle"
                }`}
                columns={columns.filter((cl: any) =>
                  selectedColumns.includes(cl?.dataIndex)
                )} // dataSource={disposeList}
                dataSource={
                  loading
                    ? (Array.from({ length: pageSize }, (_, index) => ({
                        key: `loading-${index}`,
                      })) as IDisposedListDataType[])
                    : disposeList
                }
                size="small"
                scroll={
                  pageSize > 15
                    ? { x: 1500, y: window.innerHeight - 300 }
                    : undefined
                }
              />
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};

export default FAR;
