import React from "react";
import { Col, Input, Row, Select, Skeleton, Space, Table, Tooltip } from "antd";
// import { useTheme } from "../../Context/ThemeContext";
import type { TableColumnsType } from "antd";
import { useTheme } from "../../../Context/ThemeContext";
import { useClient } from "../../../Context/ClientContext";
import CashAndBankAccountServices, {
  ChequeBookStatus,
} from "../../../Services/CashAndBankAccountServices";
import { ContainerOutlined, EditOutlined } from "@ant-design/icons";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";
import { AdjustBankType } from "./AdjustBankBalanceModel";
// import {
//   ReconcileTypes,
//   VoucherFilterStatus,

// } from "../../Services/VoucherServices";
// import { useClient } from "../../Context/ClientContext";

// import { Utils } from "../../../Utilities/Utils";
export interface VoucherDetailsData {
  key: React.Key;
  id: string;
  chequeBookName: string;
  fromPage: number;
  toPage: number;
  totalPages: number;
  issuedNo: number;
  pendingNo: number;
  status: number;
}
interface ChequeBooksTableProps {
  ledgerId: string;
  setOpenModel: any;
  refresh: boolean;
  setRefresh: any;
}

const ChequeBooksTable = (props: ChequeBooksTableProps) => {
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const [loading, setLoading] = React.useState<boolean>(false);
  // const [voucherDetailsData, setVoucherDetailsData] = React.useState([]);
  const [booksData, setBooksData] = React.useState<VoucherDetailsData[]>([]);
  const [bookType, setBookType] = React.useState<number>(ChequeBookStatus.All);
  const [selectedYear, setSelectedYear] = React.useState<number>();
  const [search, setSearch] = React.useState<string>();

  const [confirmation, setConfirmation] = React.useState<{
    isOpen: boolean;
    isArchived: boolean;
    message: string;
    id: string;
  }>({
    isOpen: false,
    isArchived: false,
    message: "",
    id: "",
  });

  const [width, setWidth] = React.useState<any>(window.innerWidth);

  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });

  // const [openModel, setOpenModel] = React.useState<{
  //   type: string;
  //   param: any;
  // }>({
  //   type: "",
  //   param: {},
  // });

  const columns: TableColumnsType<VoucherDetailsData> = [
    {
      title: "Cheque Book Name",
      key: "chequeBookName",
      dataIndex: "chequeBookName",
      sorter: true,
      ellipsis: true,
      width: "15%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
          }}
          title={false}
        >
          {record?.chequeBookName}
        </Skeleton>
      ),
    },
    {
      title: "From Page",
      key: "fromPage",
      dataIndex: "fromPage",
      width: "10%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.fromPage}
        </Skeleton>
      ),
    },
    {
      title: "To Page",
      key: "toPage",
      dataIndex: "toPage",
      width: "10%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.toPage}
        </Skeleton>
      ),
    },
    {
      title: "Total Pages",
      key: "totalPages",
      dataIndex: "totalPages",
      width: "10%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.totalPages}
        </Skeleton>
      ),
    },
    {
      title: "Issued No",
      key: "issuedNo",
      dataIndex: "issuedNo",
      width: "10%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.issuedNo}
        </Skeleton>
      ),
    },
    {
      title: "Pending No",
      key: "pendingNo",
      dataIndex: "pendingNo",
      width: "10%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.pendingNo}
        </Skeleton>
      ),
    },
    {
      title: "Status",
      key: "status",
      sorter: true,
      dataIndex: "status",
      width: "10%",
      align: "center",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {(() => {
            switch (record.status) {
              case ChequeBookStatus.All:
                return "All";
              case ChequeBookStatus.YetToBeUsed:
                return "Yet to Be Used";
              case ChequeBookStatus.Used:
                return "Used";
              case ChequeBookStatus.Using:
                return "Using";
              case ChequeBookStatus.Archived:
                return "Archived";
            }
          })()}
        </Skeleton>
      ),
    },
    {
      title: " ",
      key: "id",
      dataIndex: "icon2",
      width: "5%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {(() => {
            const isArchived = record.status === ChequeBookStatus.Archived;
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Edit" color="#444653" placement="top">
                  <EditOutlined
                    twoToneColor="#444653"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      props.setOpenModel({
                        type: "addChequeBook",
                        param: {
                          id: record.id,
                          type: AdjustBankType.undefined,
                        },
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip
                  title={isArchived ? "Unarchive" : "Archive"}
                  color={isArchived ? "#f57864" : "blue"}
                  placement="top"
                >
                  <ContainerOutlined
                    className="cursor"
                    twoToneColor="#ff879d"
                    onClick={() => {
                      setConfirmation({
                        isOpen: true,
                        isArchived: isArchived,
                        message: isArchived
                          ? "Are you sure you want to unarchive this cheque book?"
                          : "Are you sure you want to archive this cheque book?",
                        id: record.id,
                      });
                    }}
                  />
                </Tooltip>
              </div>
            );
          })()}
        </Skeleton>
      ),
    },
  ];

  React.useEffect(() => {
    // console.log(width);
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const getChequeBookList = async (
    currentClientId: string,
    ledgerId: string
  ) => {
    try {
      setLoading(true);
      const chequeBooks = await CashAndBankAccountServices.getChequeBooks(
        currentClientId,
        ledgerId,
        {
          status: bookType,
          search,
        }
      );
      setBooksData(
        chequeBooks.result.items.map((c: any) => {
          return {
            key: c.id,
            id: c.id,
            chequeBookName: c.chequeBook_Name,
            fromPage: c.fromPage,
            toPage: c.toPage,
            totalPages: c.totalNo,
            issuedNo: c.issuedNo,
            pendingNo: c.pendingNo,
            status: c.status,
          };
        })
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (currentClient?.id && props?.ledgerId) {
      getChequeBookList(
        currentClient?.id,
        props.ledgerId
        // (page - 1) * pageSize,
        // pageSize,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, props.ledgerId, bookType, search, props.refresh]);

  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "AutoNumber",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
  };

  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 5 }, (_, i) => {
    const year = currentYear - i;
    return { value: year, label: year };
  });

  const handleArchiveClick = async () => {
    try {
      setLoading(true);
      const res = await CashAndBankAccountServices.makeArchive(
        currentClient?.id,
        props.ledgerId,
        {
          chequeBookId: confirmation.id,
          archive: !confirmation.isArchived,
        }
      );
      console.log("res", res);
      props.setRefresh(!props.refresh);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row
        justify="space-between"
        style={{
          // paddingTop: props?.partyId ? "10px" : "0px"
          // marginTop: 10,
          padding: "4px 0px 0px",
        }}
      >
        <Col>
          <Space size={width <= 768 ? 440 : 5}>
            <Input
              placeholder="Search cheque book"
              allowClear
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Select
              defaultValue={yearOptions[0].value}
              value={selectedYear}
              style={{ width: 120 }}
              options={yearOptions}
              onChange={(e) => setSelectedYear(e)}
            />
          </Space>
        </Col>

        <Col
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          <Row gutter={width <= 768 ? 60 : 8}>
            <Col>
              <Space size={width <= 768 ? 440 : 5}>
                <Select
                  clearIcon={true}
                  value={bookType}
                  style={{ width: 120 }}
                  allowClear
                  options={[
                    { value: ChequeBookStatus.All, label: "All" },
                    {
                      value: ChequeBookStatus.YetToBeUsed,
                      label: "Yet To Be Used",
                    },
                    { value: ChequeBookStatus.Using, label: "Using" },
                    { value: ChequeBookStatus.Used, label: "Used" },
                    { value: ChequeBookStatus.Archived, label: "Archived" },
                  ]}
                  onChange={(val: ChequeBookStatus) => {
                    setBookType(val);
                  }}
                />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Row gutter={10} justify="space-between" style={{ marginTop: "10px" }}>
        <Col
          span={12}
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          <Row></Row>
        </Col>
      </Row> */}

      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        size="small"
        columns={columns}
        // onRow={(record, index) => ({
        //   style: openRowStyle(record, index),
        // })}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        dataSource={
          loading
            ? (Array.from({ length: 15 }, (_, index) => ({
                key: `loading-${index}`,
              })) as VoucherDetailsData[])
            : booksData
        }
        scroll={booksData.length > 15 ? { x: 700, y: 620 } : { x: 600 }}
        onChange={tableSort}
        pagination={{
          showSizeChanger: true,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
      {confirmation.isOpen && (
        <ConfirmationModal
          open={confirmation.isOpen}
          onNo={() =>
            setConfirmation({
              isOpen: false,
              isArchived: false,
              message: "",
              id: "",
            })
          }
          onYes={async () => {
            await handleArchiveClick();
            setConfirmation({
              isOpen: false,
              isArchived: false,
              message: "",
              id: "",
            });
          }}
          text={confirmation.message}
        />
      )}
    </>
  );
};

export default ChequeBooksTable;
