import React, { useCallback } from "react";
import { Card, Col, Row, Table, Tooltip, Skeleton, Input } from "antd";
import { NavLink, useLocation, useParams } from "react-router-dom";
import {
  ArrowLeftOutlined,
  FormOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useClient } from "../../../../Context/ClientContext";
import "../../../Parties/Party.css";
import "../../../../Common/Acc_Common.css";
import "../../../../Common/Dashboardlayout.css";
import { useTheme } from "../../../../Context/ThemeContext";
import { ColumnsType } from "antd/es/table";
import { useKeyboardShortcuts } from "../../../../Common/KeyboardShortcuts ";
import AddGroup from "../AddGroup";
import GroupDetailsTree from "././GroupDetailsTree";
import ChartofAccountServicesNew, {
  GroupTypes,
} from "../../../../Services/ChartofAccountServicesNew";
import { SearchLedgerByGroupsTypes } from "../../../../Services/LedgerService";

interface DataType {
  id: any;
  key: React.Key;
  group_Name: string;
  email: string;
  phone: number;
  groupType: number;
}

const GroupDetails: React.FC<{
  searchLedgerByGroupsTypes?: SearchLedgerByGroupsTypes;
  showName?: string;
}> = (props) => {
  const location = useLocation();
  // console.log("location..........", location.state);

  const { groupId } = useParams();
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [searchGroupName, setSearchGroupName] = React.useState<
    string | undefined
  >("");
  const [groupListData, setGroupDataList] = React.useState<any[]>([]);
  const [pageSize, setPageSize] = React.useState<number>(50);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [openModel, setOpenModel] = React.useState<{
    open: boolean;
    param: any;
  }>({
    open: false,
    param: "",
  });
  const [groupDetailsData, setGroupDetailsData] = React.useState<{
    editable: boolean;
    group_Name: string;
    parent: string;
    parent_Name: string;
    id: string;
    group_Type: number;
    aliasName: string;
    parent_Type: GroupTypes;
  }>({
    editable: false,
    group_Name: "",
    parent: "",
    parent_Name: "",
    id: "",
    group_Type: 0,
    aliasName: "",
    parent_Type: GroupTypes.Secondary,
  });

  React.useEffect(() => {
    ledgerDetails(groupId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const ledgerDetails = (id?: string) => {
    console.log("callBack x...........................", id);
    setGroupDetailsData(groupListData.find((x: any, i: any) => x?.id === id));
  };

  // --------- Table Columns  ---------
  const columns: ColumnsType<DataType> = [
    {
      title: "",
      dataIndex: "group_Name",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <NavLink
            to={`/accounts/clients/${currentClient?.id}/master/group/${record?.id}`}
          >
            <div
              onClick={() => {
                ledgerDetails(record?.id);
                console.log("ledgerDetails", ledgerDetails);
              }}
              style={{
                color: "black",
              }}
            >
              {record?.group_Name}
            </div>
          </NavLink>
        </Skeleton>
      ),
    },
  ];

  // --------- Group Table list data ---------
  React.useEffect(() => {
    setLoading(true);
    const getListData = async (
      search: string | undefined,
      GroupType?: GroupTypes,
      groupName?: string,
      aliasName?: string
      // order?: string,
      // groupId?: string
    ) => {
      await ChartofAccountServicesNew.getGroupList(
        currentClient?.id!,
        currentClient?.productModule,
        search,
        "",
        ""
        // GroupType,
        // groupName,
        // aliasName,
        // order,
        // groupId
      )
        .then((res: any) => {
          if (res.items.length > 1) {
            setGroupDataList(res.items);
            if (!search) {
              setGroupDetailsData(
                res.items.find((x: any, i: any) => x?.id === groupId)
              );
            }
          } else if (res?.items.length === 1) {
            const x = [...res?.items[0], ...res?.items[0].children];
            // console.log("...........", x);
            setGroupDataList(res?.items[0].children);
          } else {
            setGroupDataList([]);
            // console.log("Error", res);
          }
          setLoading(false);
          //  setTotalRecords(res?.totalRecords);
        })
        .catch((err: any) => {
          setLoading(false);
          // console.log("Error", err);
        });
    };
    getListData(searchGroupName);

    // set ledgerDeails
    // else if (searchGroupName === null) {
    //   ledgerDetails(groupId);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, searchGroupName]);

  //----- Short-Cut for Item -----
  useKeyboardShortcuts("N", ["shiftKey"], () => {
    setOpenModel({ open: true, param: "" });
  });

  return (
    <>
      <Row>
        <Col lg={5} style={{ paddingRight: "5px", display: "flex" }}>
          <Card
            style={{ border: "1.5px solid #eef2fe" }}
            className="ca-card-body"
          >
            <div>
              <Row
                align={"middle"}
                style={{ width: "292px", paddingTop: "5px" }}
              >
                <Col lg={1} style={{ margin: "0px 8px" }}>
                  <NavLink
                    to={`/accounts/clients/${currentClient?.id}/master/group`}
                  >
                    <ArrowLeftOutlined />
                  </NavLink>
                </Col>
                <Col lg={19}>
                  <Input
                    allowClear
                    // width: "220px",
                    style={{ margin: "5px 0px 5px 0px" }}
                    placeholder="Search group.."
                    onChange={(e: any) => {
                      setLoading(true);
                      if (!e.cancelable && e.target.value) {
                        console.log("Hello");
                        const searchGroupName = setTimeout(() => {
                          setSearchGroupName(e.target.value);
                          // setGroupDataList([]);
                        }, 1000);
                        return () => clearTimeout(searchGroupName);
                      } else {
                        // setGroupDataList(location?.state?.groupList);
                        setSearchGroupName(e.target.value);
                      }
                    }}
                    // suffix={
                    //   loading || searchGroupName ? (
                    //     ""
                    //   ) : (
                    //     <img alt="Search.." src={search} />
                    //   )
                    // }
                  />
                </Col>
                <Col lg={2} style={{ marginLeft: "8px" }}>
                  <PlusOutlined
                    onClick={() => {
                      setOpenModel({ open: true, param: "" });
                    }}
                  />
                </Col>
                {/* <Col lg={2}>
                  <Popover
                    trigger="click"
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                    content={
                      <Row style={{ maxWidth: "230px" }}>
                        <Col lg={24}>
                          <Select
                            style={{ width: "100%" }}
                            options={[
                              {
                                label: "All",
                                value: 0,
                              },
                              { label: "Customer", value: 1 },
                              { label: "Suppliers", value: 2 },
                            ]}
                          />
                        </Col>
                        <Col lg={24} style={{ marginTop: "6px" }}>
                          <Select
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="GST Type"
                            // value={party}
                            options={[
                              {
                                label: "Unregistered",
                                value: 0,
                              },
                              { label: "Regular", value: 1 },
                              { label: "Composition", value: 2 },
                            ]}
                          />
                        </Col>
                        <Col lg={24} style={{ marginTop: "6px" }}>
                          <Select
                            allowClear
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="State"
                            optionFilterProp="children"
                          />
                        </Col>
                        <Col lg={24} style={{ marginTop: "6px" }}>
                          <Row justify="space-around">
                            <Button type="primary">Apply</Button>
                            <Button danger>Reset</Button>
                            <Button>Close</Button>
                          </Row>
                        </Col>
                      </Row>
                    }
                  >
                    <FilterFilled
                      style={{ color: "#9AAEC1", fontSize: "16px" }}
                    />
                  </Popover>
                </Col>
                <Col lg={1}>
                  <DownOutlined />
                </Col> */}
              </Row>

              <div
                className={"withoutanimation"}
                style={{
                  maxHeight: "100%",
                  overflowY: "auto",
                  position: "relative",
                }}
              >
                <Table
                  rowClassName={(reocord) => {
                    return reocord?.id === groupId ? "ca-active-table" : "";
                  }}
                  className={`Tabel-style-none table-${
                    themeData?.componentSize ?? "middle"
                  }`}
                  showHeader={false}
                  // rowSelection={rowSelection}
                  dataSource={
                    loading
                      ? (Array.from({ length: pageSize }, (_, index) => ({
                          key: `loading-${index}`,
                        })) as DataType[])
                      : groupListData
                  }
                  columns={columns}
                  pagination={false}
                />
              </div>
              <>
                <div
                  style={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: "#F5F8FF",
                    height: "40px",
                  }}
                >
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <th
                          style={{ paddingTop: "10px" }}
                          className="textStart"
                        >
                          Total
                        </th>
                        <th style={{ textAlign: "end" }}>
                          {groupListData?.length}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            </div>
          </Card>
        </Col>
        <Col
          lg={19}
          // style={{ paddingLeft: "22px" }}
          className="ca-card-body-padding"
        >
          <Card
            style={{
              border: "1.5px solid #eef2fe",
              marginBottom: "10px",
            }}
          >
            <Row>
              <Col span={8}>
                {groupDetailsData?.group_Name?.length > 30 ? (
                  <Tooltip
                    title={groupDetailsData?.group_Name}
                    placement="top"
                    color="#3D4998"
                  >
                    {"Name : " +
                      groupDetailsData?.group_Name.slice(0, 30) +
                      "..."}
                  </Tooltip>
                ) : (
                  <>
                    <span
                      style={{
                        color: "rgb(139, 143, 165)",
                      }}
                    >
                      Name :{" "}
                    </span>{" "}
                    {groupDetailsData?.group_Name}
                  </>
                )}
              </Col>
              <Col span={8}>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Parent Group:
                  </span>
                  {groupDetailsData?.parent_Type === GroupTypes.Secondary ? (
                    <NavLink
                      to={`/accounts/clients/${currentClient?.id}/master/group/${groupDetailsData?.parent}`}
                      onClick={() => {
                        ledgerDetails(groupDetailsData?.parent);
                      }}
                    >
                      {groupDetailsData?.parent_Name}
                    </NavLink>
                  ) : (
                    <span>{groupDetailsData?.parent_Name}</span>
                  )}
                </div>
              </Col>
              {/* <Col span={6}>
                <div>
                  <span
                    style={{
                      paddingRight: "14px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Group Type:
                  </span>
                  {groupDetailsData?.group_Type === 1 ? "Primary" : "Secondary"}
                </div>
              </Col> */}
              <Col span={7}>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Short Name:
                  </span>
                  {groupDetailsData?.aliasName}
                </div>
              </Col>

              <Col span={1} style={{ textAlign: "center" }}>
                <Tooltip
                  title={
                    groupDetailsData?.editable
                      ? "Edit"
                      : "Default groups are not editable"
                  }
                  placement="top"
                  color={
                    groupDetailsData?.editable ? "#444653" : "rgb(216 207 207)"
                  }
                >
                  <FormOutlined
                    // className="ca-edit-btn"
                    style={{
                      color: groupDetailsData?.editable
                        ? "#444653"
                        : "rgb(216 207 207)",
                    }}
                    onClick={async () => {
                      groupDetailsData?.editable &&
                        setOpenModel({
                          open: true,
                          param: groupDetailsData,
                        });
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Card>
          <div>
            <Card className="ca-card-body">
              <div
                style={{
                  height: `calc(100vh - 168px)`,
                  // backgroundColor: "azure",
                  overflowY: "auto",
                }}
              >
                <GroupDetailsTree
                  groupId={groupId}
                  groupName={groupDetailsData?.group_Name}
                />
              </div>
            </Card>
          </div>
        </Col>
      </Row>
      {openModel.open && (
        <AddGroup
          open={openModel.open}
          editData={openModel?.param}
          onCancel={() => setOpenModel({ open: false, param: "" })}
          setTrigger={() => setTrigger(!trigger)}
          groupData={location?.state?.groupList}
          type={props?.searchLedgerByGroupsTypes}
          showName={props?.showName}
        />
      )}
    </>
  );
};

export default GroupDetails;
