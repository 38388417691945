import React from "react";
import { useClient } from "../../../Context/ClientContext";
import SalesService, {
  DBRecordStatus,
  InvoiceTradeType,
  InvoiceTypes,
} from "../../../Services/SalesService";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { dayjs } from "../../../../Utilities/dayjs";
import { useTheme } from "../../../Context/ThemeContext";
import AddItems from "../../Purchase/AddItems";
import { ColumnsType } from "antd/es/table";
import { Utils } from "../../../../Utilities/Utils";
import { NavLink } from "react-router-dom";

interface IPartyAllItemListData {
  key: string;
  allInvoice: [];
  firstInvoiceDate: string;
  id: string;
  lastInvoiceDate: string;
  name: string;
  itemType: boolean;
  qty: number;
  totalAmount: number;
  avgPrice: string;
  sno: number;
  // gstRate: number;
  // vat: string;
}

const PartyWiseItemsDetails: React.FC<{
  partyId?: string;
  InvoiceTradeType: InvoiceTradeType;
}> = (props) => {
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const { RangePicker } = DatePicker;
  const [filterType, setFilterType] = React.useState<InvoiceTypes>(
    InvoiceTypes.Invoice
  );
  const [page, setPage] = React.useState<number>(1);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [searchItem, setSearchItem] = React.useState<string>("");
  // const [gstRate, setGstRate] = React.useState<any[]>([]);
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });

  const [filter, setfilter] = React.useState<{
    sortCol: string;
    sortDir: string;
  }>({
    sortCol: "",
    sortDir: "",
  });

  const [filterDate, setFilterDate] = React.useState<{
    from: string;
    to: string;
  }>({ from: "", to: "" });

  const [allItemsList, setAllItemsList] = React.useState<
    IPartyAllItemListData[]
  >([]);

  React.useEffect(() => {
    if (currentClient?.id! && props?.partyId) {
      setLoading(true);
      getItemDetils(
        currentClient?.id,
        props.partyId,
        InvoiceTypes.Invoice,
        DBRecordStatus.Active,
        props.InvoiceTradeType,
        (page - 1) * pageSize,
        pageSize,
        searchItem,
        filterDate.from,
        filterDate.to,
        filter.sortCol,
        filter.sortDir
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    props?.partyId,
    page,
    pageSize,
    searchItem,
    filterDate,
    filterType,
    filter,
  ]);

  const getItemDetils = async (
    clientId: string,
    partyId: string | undefined,
    filterType: InvoiceTypes,
    status: DBRecordStatus,
    tradeType: InvoiceTradeType,
    start: number,
    length: number,
    search: string,
    from: string,
    to: string,
    sortCol: string,
    sortDir: string
  ) => {
    await SalesService.getPartyWiseItemDetails(
      clientId,
      partyId,
      filterType,
      status,
      tradeType,
      start,
      length,
      search,
      from,
      to,
      filter.sortCol,
      filter.sortDir
    )
      .then((result: any) => {
        if (result.items.length > 0) {
          setAllItemsList(
            result.items.map((v: any, i: number) => {
              return {
                allInvoice: v?.allInvoice,
                firstInvoiceDate: v?.firstInvoiceDate,
                id: v?.id,
                lastInvoiceDate: v?.lastInvoiceDate,
                name: v?.name,
                itemType: v?.isService,
                qty: v?.qty,
                totalAmount: v?.totalAmount,
                avgPrice: v?.avgPrice,
                sno: v?.sno,
              };
            })
          );
          setLoading(false);
          setTotalRecords(result?.totalRecords);
        } else {
          setLoading(false);
          setAllItemsList([]);
        }
      })
      .catch((err: any) => {
        setLoading(false);
        console.error(err.message);
        setAllItemsList([]);
      });
  };

  // --------- Table Columns  ---------
  const columns: ColumnsType<IPartyAllItemListData> = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      width: "8%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.sno}
        </Skeleton>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: width <= 1440 ? "8%" : "35%",
      render: (_, record) =>
        props.InvoiceTradeType === InvoiceTradeType.Sales ? (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <NavLink
              to={`/accounts/clients/${currentClient?.id}/sales/inventory`}
              state={{
                id: record.id,
                type: "sales",
                itemType: record.itemType ? "salesService" : "salesGoods",
              }}
            >
              {record?.name}
            </NavLink>
          </Skeleton>
        ) : (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <NavLink
              to={`/accounts/clients/${currentClient?.id}/purchase/inventory`}
              state={{
                id: record.id,
                type: "purchase",
                itemType: record.itemType ? "purchaseService" : "purchaseGoods",
              }}
            >
              {record?.name}
            </NavLink>
          </Skeleton>
        ),
    },
    {
      title: "Date Range",
      dataIndex: "firstInvoiceDate",
      key: "firstInvoiceDate",
      width: "18%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {Utils.getFormattedDate(record?.firstInvoiceDate) +
            " - " +
            Utils.getFormattedDate(record?.lastInvoiceDate)}
        </Skeleton>
      ),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: "8%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {Utils.getFormattedNumber(record?.qty)}
        </Skeleton>
      ),
    },
    {
      title: "Avg. Price",
      dataIndex: "AvgPrice",
      key: "avgPrice",
      width: "12%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.avgPrice}
        </Skeleton>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: "12%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {Utils.getFormattedNumber(record?.totalAmount)}
        </Skeleton>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div style={{ display: "flex", gap: "15px" }}>
            <Tooltip title="Edit" placement="top" color="#3D4998">
              <EditOutlined
                // className="ca-edit-btn"
                style={{ color: "#444653" }}
                onClick={async () => {
                  setOpenModel({
                    type: "addItem",
                    param: {
                      open: true,
                      id: record.id,
                    },
                  });
                }}
              />
            </Tooltip>
          </div>
        </Skeleton>
      ),
    },
  ];

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "AutoNumber",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("sort", filter, filter.column);
  };

  return (
    <>
      <Row
        justify="space-between"
        style={{
          // paddingTop: props?.partyId ? "10px" : "0px"
          marginTop: 10,
          padding: "10px 18px 3px 18px",
        }}
        // style={{ position: "sticky",zIndex:1, top: 0, padding: "5px" }}
      >
        <Col>
          <Space size={width <= 768 ? 465 : 5}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setOpenModel({
                  type: "addItem",
                  param: { open: true },
                });
              }}
            >
              Item
            </Button>
          </Space>
        </Col>
        <Col
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          <Row gutter={width <= 768 ? 98 : 8}>
            <Col>
              <Input
                allowClear
                style={{ width: "200px", border: "1px solid #f1f3ff" }}
                placeholder="Search Name"
                onChange={(e: any) => {
                  setLoading(true);
                  if (!e.cancelable) {
                    const searchItem = setTimeout(() => {
                      setPage(1);
                      setSearchItem(e.target.value);
                    }, 1000);
                    return () => clearTimeout(searchItem);
                  } else {
                    setSearchItem(e.target.value);
                  }
                }}
                suffix={
                  loading || searchItem ? (
                    ""
                  ) : (
                    <></>
                    // <img alt="Search.." src={search} />
                  )
                }
              />
            </Col>
            <Col>
              <RangePicker
                allowClear={false}
                format="DD/MM/YYYY"
                style={{
                  width:
                    width === 425 ? "330px" : width === 375 ? "280px" : "225px",
                  border: "1px solid #f1f3ff",
                }}
                onChange={(e: any) => {
                  e === null
                    ? setFilterDate({ from: "", to: "" })
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            {/* <Col>
              <Select
                mode="multiple"
                maxTagCount={"responsive"}
                style={{ minWidth: "158px" }}
                allowClear
                onChange={(e: any) => {
                  console.log(e);
                  setGstRate(e);
                  setPage(1);
                }}
                placeholder="GST"
                options={[
                  // { value: 0, label: "All" },
                  { value: 0, label: "0%" },
                  { value: 0.25, label: "0.25%" },
                  { value: 3, label: "3%" },
                  { value: 5, label: "5%" },
                  { value: 12, label: "12%" },
                  { value: 18, label: "18%" },
                  { value: 28, label: "28%" },
                ]}
              />
            </Col> */}
            <Col>
              <Select
                defaultValue="Sell Items"
                style={{ width: "120px" }}
                onSelect={(e: any) => {
                  console.log(e);
                  setPage(1);
                  setFilterType(e);
                }}
                options={[
                  { value: InvoiceTypes.Invoice, label: "Sell Items" },
                  { value: InvoiceTypes.CreditNote, label: "Return Items" },
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as IPartyAllItemListData[])
            : allItemsList
        }
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        onChange={tableSort}
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        // rowSelection={rowSelection}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
        scroll={
          pageSize > 10
            ? {
                x: width <= 768 ? 1500 : width <= 1024 ? 1150 : 700,
                y: 370,
              }
            : { x: width <= 768 ? 1500 : width <= 1024 ? 1150 : 600 }
        }
      />

      {/* --------- AddItem --------- */}
      {openModel.type === "addItem" && (
        <AddItems
          open={openModel?.param?.open}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          id={openModel?.param?.id}
          setTrigger={setTrigger}
        />
      )}
      {/* {openModel.type === "showAllItemsDetails"&&(

      )} */}
    </>
  );
};

export default PartyWiseItemsDetails;
