import {
  CloseOutlined,
  EditOutlined,
  FormOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  CollapseProps,
  DatePicker,
  Divider,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import React, { useContext, useEffect } from "react";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";
import { BusinessTypeOptions, GSTTypeOptions } from "../Clients/Clients";
import { dayjs } from "../../../Utilities/dayjs";
import {
  ICompanyInfo,
  ICompanySettings,
  SettingsSections,
} from "../../Services/SettingService";
import { CompanyGSTTypes } from "../../../Types/CommonConstants";
import { useUser } from "../../../Context/UserContext";
import Search from "antd/es/transfer/search";
const SettingsCompanyInfo = () => {
  const { companySettings, updateCompanySettings } = useClient();
  const [companyInfoform] = Form.useForm();
  const [contactDetailsForm] = Form.useForm();
  const [paymentTermForm] = Form.useForm();
  const [gstForm] = Form.useForm();
  const [generalForm] = Form.useForm();
  const [gSTTypeValue, setGSTTypeValue] = React.useState<CompanyGSTTypes>(
    companySettings?.setting?.gstDetails?.scheme
      ? companySettings?.setting?.gstDetails?.scheme
      : CompanyGSTTypes.Unregistered
  );
  const [disable, setDisable] = React.useState<boolean>(true);
  const [acc, setAcc] = React.useState<boolean>(false);
  const [gstDisable, setGSTDisable] = React.useState<boolean>(true);
  const [generalDisable, setGeneralDisable] = React.useState<boolean>(true);
  const [savebuttonloading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [checkbox, setCheckbox] = React.useState<number>(1);
  const { openNotification } = useContext(notificationContext);
  const [infoDisable, setInfoDisable] = React.useState<boolean>(true);
  const [detailsDisable, setDetailsDisable] = React.useState<boolean>(true);
  const [fydate, SetFYDate] = React.useState<string | undefined>("");
  const { getAllIndStates } = useUser();
  const stateOptions: any = getAllIndStates();
  const [saveButtonLoading, setSaveButtonLoadingg] =
    React.useState<boolean>(false);
  const [activeKey, setActiveKey] = React.useState<string[]>([]);
  const [lastClickedPanel, setLastClickedPanel] = React.useState<string | null>(
    null
  );
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [activePopover, setActivePopover] = React.useState<any>();

  const handleIconClick = (e: React.MouseEvent, panelKey: string) => {
    e.stopPropagation();
    if (!activeKey.includes(panelKey)) {
      setActiveKey([panelKey]);
      setLastClickedPanel(panelKey);
    } else {
    }
  };

  const handleCollapseChange = (key: string | string[]) => {
    if (Array.isArray(key) && key.length > 0 && key[0] === lastClickedPanel) {
      setActiveKey(key as string[]);
    } else {
      setActiveKey(key as string[]);
    }
  };
  console.log("companySettings", companySettings?.setting?.dueOn);

  const DueOnSubmit = () => {
    paymentTermForm.validateFields().then(async (val: any) => {
      setSaveButtonLoading(true);
      setDisable(true);
      const data = {
        dueOn: {
          ...companySettings?.setting?.dueOn,
          ...val?.dueOn,
        },
      };
      await updateCompanySettings!(
        SettingsSections.DueOn,
        {
          ...companySettings,
          setting: {
            ...companySettings?.setting!,
            dueOn: data?.dueOn,
          },
        },
        data
      ).then((result: any) => {
        if (result?.status) {
          openNotification("success", "Setting updated successfully.");
          setSaveButtonLoading(false);
        } else {
          setSaveButtonLoading(false);
          openNotification("error", "There is some error.");
        }
      });
    });
  };

  useEffect(() => {
    const set = companySettings?.setting;
    SetFYDate(set?.companyInfo?.fydate);
    const gst = set?.gstDetails?.gst?.map((itm: any) => {
      return {
        title: itm?.rate,
        label: itm?.name,
        value: itm?.id,
      };
    });

    const formData = {
      ...set,
      companyInfo: {
        ...set?.companyInfo,
        financialYearBegin: dayjs(set?.companyInfo?.financialYearBegin),
      },
      companyContactDetails: {
        ...set?.companyContactDetails,
      },
      dueOn: {
        ...set?.dueOn,
      },
      gstDetails: {
        ...set?.gstDetails,
        registrationDate: set?.gstDetails?.registrationDate
          ? dayjs(set?.gstDetails?.registrationDate)
          : "",
        gst: gst,
      },
    };
    companyInfoform.setFieldsValue(formData);
    contactDetailsForm.setFieldsValue(formData);
    paymentTermForm?.setFieldsValue(formData);
    gstForm.setFieldsValue(formData);
  }, [companySettings, gstDisable, infoDisable, disable, detailsDisable]);

  const formFields = [
    { label: "Name", name: "companyInfo.name", key: "1" },
    {
      label: "Financial Year start",
      name: "companyInfo.financialYearBegin",
      key: "1",
    },
    { label: "Business Type", name: "companyInfo.businessType", key: "1" },
    { label: "PAN", name: "companyInfo.panNo", key: "1" },
    { label: "GST Number", name: "companyInfo.gstNumber", key: "1" },
    { label: "Building", name: "companyInfo.regAddress.building", key: "1" },
    { label: "District", name: "companyInfo.regAddress.district", key: "1" },
    { label: "City", name: "companyInfo.regAddress.city", key: "1" },
    { label: "Reg. Office", name: "companyInfo.regAddress", key: "1" },

    { label: "Pincode", name: "companyInfo.regAddress.pincode", key: "1" },
    { label: "State", name: "companyInfo.regAddress.state", key: "1" },
    { label: "Phone", key: "2" },
    { label: "Mobile", key: "2" },
    { label: "email", key: "2" },
    { label: "Credit Period", key: "3" },
    { label: "Credit Limit", key: "3" },
    { label: "GST Type", key: "4" },
    { label: "Registration Date", key: "4" },
    { label: "GSTIN", key: "4" },
    { label: "Sales GST Options", key: "4" },
    { label: "Enable Export", key: "5" },
    { label: "Enable RCM (Reverse Charge Mechanism)", key: "5" },
    { label: "Enable Inventory", key: "5" },
  ];

  const findMatchingFieldLabels = (searchQuery: string) => {
    return formFields.filter((field) =>
      field.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const highlightLabel = (label: string) => {
    if (!searchQuery) return label;

    const parts = label.split(new RegExp(`(${searchQuery})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === searchQuery.toLowerCase() ? (
        <span
          key={index}
          style={{
            // fontWeight: 800,
            color: "white",
            backgroundColor: "rgb(106 119 227)",
          }}
        >
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Company Information",
      collapsible: "header",
      extra: (
        <>
          {infoDisable && (
            <Tooltip title="Edit">
              <FormOutlined
                className="ca-edit-btn"
                style={{ color: "rgb(106 119 227)" }}
                onClick={(e) => {
                  handleIconClick(e, "1");
                  setInfoDisable(false);
                  setGSTDisable(true);
                  setDisable(true);
                  setDetailsDisable(true);
                }}
              />
            </Tooltip>
          )}
        </>
      ),
      children: (
        <>
          <Form
            //labelCol={{ span: 7 }}
            layout="vertical"
            requiredMark={false}
            form={companyInfoform}
            disabled={infoDisable}
            labelAlign="left"
            autoComplete="off"
            name="companyInfo"
            onFinish={(val) => {
              console.log("valll", val.companyInfo);
              setSaveButtonLoading(true);
              // financialYearBegin: val.date.toISOString(),
              // const data: ICompanyInfo = {
              //   ...val?.companyInfo,
              //   financialYearBegin: val?.companyInfo.financialYearBegin.toISOString(),
              // };
              updateCompanySettings!(
                SettingsSections.CompanyInfo,
                {
                  ...companySettings,
                  setting: {
                    ...companySettings?.setting!,
                    companyInfo: val?.companyInfo,
                  },
                },
                (val = {
                  ...val,
                  companyInfo: {
                    ...val?.companyInfo,
                    fydate: fydate,
                  },
                })
              ).then((result: any) => {
                if (result?.status) {
                  setInfoDisable(true);
                  openNotification("success", "Setting updated successfully.");
                  setSaveButtonLoading(false);
                } else {
                  setSaveButtonLoading(false);
                  openNotification("error", "There is some error.");
                }
              });
            }}
          >
            <Form.Item
              className="mb-10"
              name={["companyInfo", "name"]}
              rules={[{ required: true, message: "Name is required" }]}
              label={highlightLabel("Name")}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className="mb-10"
              name={["companyInfo", "financialYearBegin"]}
              // label="Financial Year start"
              label={highlightLabel("Financial Year start")}
            >
              <DatePicker
                style={{ width: "100%" }}
                format="DD/MM/YYYY"
                onChange={(date: any, dateString: any) => {
                  SetFYDate(dateString);
                }}
              />
            </Form.Item>
            <Form.Item
              className="mb-10"
              name={["companyInfo", "businessType"]}
              label={highlightLabel("Business Type")}
            >
              <Select options={BusinessTypeOptions} />
            </Form.Item>
            <Form.Item
              className="mb-10"
              name={["companyInfo", "panNo"]}
              // label="PAN"
              label={highlightLabel("PAN")}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={highlightLabel("GST Number")}
              className="mb-10"
              name={["companyInfo", "gstNumber"]}
              // labelCol={{ span: 8 }}
            >
              <Input />
            </Form.Item>
            {highlightLabel("Reg. Office")}
            <Row gutter={18}>
              <Col xl={8} xxl={8} lg={8}>
                <Form.Item
                  name={["companyInfo", "regAddress", "building"]}
                  className="mb-10"
                >
                  <Input placeholder="Building" />
                </Form.Item>
              </Col>
              <Col xl={8} xxl={8} lg={8}>
                <Form.Item
                  name={["companyInfo", "regAddress", "district"]}
                  className="mb-10"
                >
                  <Input placeholder="District" />
                </Form.Item>
              </Col>
              <Col xl={8} xxl={8} lg={8}>
                <Form.Item
                  name={["companyInfo", "regAddress", "city"]}
                  className="mb-10"
                >
                  <Input placeholder="City" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col xl={8} xxl={8} lg={8}>
                <Form.Item
                  name={["companyInfo", "regAddress", "pincode"]}
                  className="mb-10"
                >
                  <Input placeholder="Pincode" />
                </Form.Item>
              </Col>
              <Col xl={16} xxl={16} lg={16}>
                <Form.Item
                  name={["companyInfo", "regAddress", "state"]}
                  className="mb-10"
                >
                  <Select
                    showSearch
                    placeholder="State"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={Object.entries(stateOptions)?.map(
                      ([key, x]: any) => ({
                        value: x?.id,
                        label: x?.name,
                      })
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col xl={8} xxl={8} lg={8}>
                <Form.Item
                  name={["companyInfo", "regAddress", "landmark"]}
                  className="mb-10"
                >
                  <Input placeholder="Landmark" />
                </Form.Item>
              </Col>
              <Col xl={8} xxl={8} lg={8}>
                <Form.Item
                  name={["companyInfo", "regAddress", "street"]}
                  className="mb-10"
                >
                  <Input placeholder="Street" />
                </Form.Item>
              </Col>
              <Col xl={8} xxl={8} lg={8}>
                <Form.Item
                  name={["companyInfo", "regAddress", "country"]}
                  className="mb-10"
                >
                  <Input placeholder="Country" />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row></Row> */}
            <Row justify="end" style={{ height: 32 }}>
              {!infoDisable && (
                <Space>
                  <Button
                    icon={<CloseOutlined />}
                    style={{ backgroundColor: "aliceblue" }}
                    onClick={() => setInfoDisable(true)}
                  >
                    Cancel
                  </Button>
                  <Form.Item className="m-0">
                    <Button
                      icon={<SaveOutlined />}
                      type="primary"
                      htmlType="submit"
                      loading={saveButtonLoading}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Space>
              )}
            </Row>
          </Form>
        </>
      ),
    },
    {
      key: "2",
      label: "Contact Details",
      collapsible: "header",
      extra: (
        <>
          <Row>
            {detailsDisable && (
              <Tooltip title="Edit">
                <FormOutlined
                  className="ca-edit-btn"
                  style={{ color: "rgb(106 119 227)" }}
                  onClick={(e) => {
                    handleIconClick(e, "2");
                    setDetailsDisable(false);
                    setGSTDisable(true);
                    setInfoDisable(true);
                    setDisable(true);
                  }}
                />
              </Tooltip>
            )}
          </Row>
        </>
      ),
      children: (
        <>
          <Form
            layout="vertical"
            requiredMark={false}
            form={contactDetailsForm}
            disabled={detailsDisable}
            autoComplete="off"
            name="companyContactDetails"
            onFinish={(val) => {
              setSaveButtonLoading(true);
              updateCompanySettings!(
                SettingsSections.ContactDetails,
                {
                  ...companySettings,
                  setting: {
                    ...companySettings?.setting!,
                    companyContactDetails: val?.companyContactDetails,
                  },
                },
                val
              )
                .then((result: any) => {
                  if (result?.status) {
                    setDetailsDisable(true);
                    openNotification(
                      "success",
                      "Setting updated successfully."
                    );
                    setSaveButtonLoading(false);
                  } else {
                    setSaveButtonLoading(false);
                    openNotification("error", "There is some error.");
                  }
                })
                .catch((ex: any) => {
                  setSaveButtonLoading(false);
                  openNotification("error", ex.message);
                });
            }}
          >
            {/* <span
              style={{
                fontWeight:
                  searchQuery && searchQuery.toLowerCase() === "phone"
                    ? 800
                    : "",
              }}
            >
              Phone
            </span> */}
            <Form.Item
              className="mb-10"
              label={highlightLabel("Phone")}
              name={["companyContactDetails", "phone"]}
              labelAlign="left"
              rules={[{ min: 10, message: "Phone No. must be 10 digits long" }]}
            >
              <Input
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                maxLength={10}
              />
            </Form.Item>
            <Form.Item
              label={highlightLabel("Mobile")}
              className="mb-10"
              name={["companyContactDetails", "mobile"]}
              rules={[
                {
                  required: true,
                  message: "Please input mobile number",
                },
                { min: 10, message: "Mobile No. must be of 10 digits long" },
              ]}
            >
              <Input
                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                maxLength={10}
              />
            </Form.Item>
            <Form.Item
              label={highlightLabel("Email")}
              className="mb-10"
              name={["companyContactDetails", "email"]}
              rules={[
                {
                  required: true,
                  message: "Please input valid email",
                },
                {
                  pattern: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Row justify="end" style={{ height: 32 }}>
              {!detailsDisable && (
                <Space>
                  <Button
                    icon={<CloseOutlined />}
                    style={{ backgroundColor: "aliceblue" }}
                    onClick={() => setDetailsDisable(true)}
                  >
                    Cancel
                  </Button>

                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    htmlType="submit"
                    loading={saveButtonLoading}
                  >
                    Save
                  </Button>
                </Space>
              )}
            </Row>
          </Form>
        </>
      ),
    },
    {
      key: "3",
      label: "Payment Term",
      collapsible: "header",
      extra: (
        <>
          {disable && (
            <Tooltip title="Edit">
              <FormOutlined
                style={{ color: "rgb(106 119 227)" }}
                className="ca-edit-btn"
                onClick={(e) => {
                  handleIconClick(e, "3");
                  setGSTDisable(true);
                  setInfoDisable(true);
                  setDisable(false);
                  setDetailsDisable(true);
                }}
              />
            </Tooltip>
          )}
        </>
      ),
      children: (
        <>
          <Form
            requiredMark={false}
            labelAlign="left"
            form={paymentTermForm}
            name="dueOn"
            colon={false}
            disabled={disable}
            layout="vertical"
          >
            <Form.Item className="input-setting-paymentTerm">
              {/* <Checkbox
              style={{ paddingRight: "20px" }}
              checked={checkbox === 1}
              onChange={() => {
                if (checkbox !== 1) {
                  setCheckbox(1);
                }
              }}
            /> */}

              <Form.Item
                label={highlightLabel("Credit Period (days)")}
                name={["dueOn", "duration"]}
                className="input-setting-paymentTerm"
                // labelCol={{ span: 7 }}
                // wrapperCol={{ span: 13 }}
                rules={[
                  {
                    required: true,
                    message: "Enter days in number",
                  },
                ]}
              >
                <Input
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                />
              </Form.Item>

              {/* <Col span={7}>
                  <Form.Item
                    name="durationUnit"
                    className="input-setting-paymentTerm"
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 13 }}
                  >
                    <Select
                      // disabled={checkbox !== 1}
                      defaultValue="Days"
                      options={[
                        { label: "Days", value: 0 },
                        { label: "Weeks", value: 1 },
                        { label: "Months", value: 2 },
                        { label: "Years", value: 3 },
                      ]}
                    />
                  </Form.Item>
                </Col> */}
              <Form.Item
                label={highlightLabel("Credit Limit ( ₹ )")}
                name={["dueOn", "creditLimit"]}
                // labelCol={{ span: 7 }}
                // wrapperCol={{ span: 13 }}
              >
                <Input placeholder="Enter amount" />
              </Form.Item>
            </Form.Item>
            {/* <Row>
          <Checkbox
            style={{ paddingRight: "20px" }}
            checked={checkbox === 2}
            onChange={() => {
              if (checkbox !== 2) {
                setCheckbox(2);
              }
            }}
          />

           <Col span={2} style={{ paddingRight: "10px" }}>
            <Form.Item name="ordinal" className="input-setting-paymentTerm">
              <Select
                disabled={checkbox !== 2}
                defaultValue="Last"
                options={[
                  { label: "Last", value: 0 },
                  { label: "1st", value: 1 },
                  { label: "2nd", value: 2 },
                  { label: "3rd", value: 3 },
                  { label: "4th", value: 4 },
                  { label: "5th", value: 5 },
                  { label: "6th", value: 6 },
                  { label: "7th", value: 7 },
                  { label: "8th", value: 8 },
                  { label: "9th", value: 9 },
                  { label: "10th", value: 10 },
                  { label: "11th", value: 11 },
                  { label: "12th", value: 12 },
                  { label: "13th", value: 13 },
                  { label: "14th", value: 14 },
                  { label: "15th", value: 15 },
                  { label: "16th", value: 16 },
                  { label: "17th", value: 17 },
                  { label: "18th", value: 18 },
                  { label: "19th", value: 19 },
                  { label: "20th", value: 20 },
                  { label: "21th", value: 21 },
                  { label: "22th", value: 22 },
                  { label: "23th", value: 23 },
                  { label: "24th", value: 24 },
                  { label: "25th", value: 25 },
                  { label: "26th", value: 26 },
                  { label: "27th", value: 27 },
                  { label: "28th", value: 28 },
                  { label: "29th", value: 29 },
                  { label: "30th", value: 30 },
                  { label: "31th", value: 31 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={2} style={{ paddingRight: "10px" }}>
            <Form.Item name="ordinalUnit" className="input-setting-paymentTerm">
              <Select
                disabled={checkbox !== 2}
                defaultValue="Monday"
                options={[
                  { label: "Sunday", value: 0 },
                  { label: "Monday", value: 1 },
                  { label: "Thesday", value: 2 },
                  { label: "Wednesday", value: 3 },
                  { label: "Thursday", value: 4 },
                  { label: "Friday", value: 5 },
                  { label: "Saturday", value: 6 },
                  { label: "Day", value: 1 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={3} style={{ paddingRight: "10px" }}>
            <Form.Item
              label="of the"
              name="ordinalTarget"
              className="input-setting-paymentTerm"
            >
              <Select
                disabled={checkbox !== 2}
                defaultValue="Next"
                options={[
                  { label: "Current", value: 0 },
                  { label: "Next", value: 1 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item
              name="durationUnit"
              className="input-setting-paymentTerm"
            >
              <Select
                disabled={checkbox !== 2}
                defaultValue="Week"
                options={[
                  { label: "Week", value: 1 },
                  { label: "Month", value: 2 },
                  { label: "Years", value: 3 },
                ]}
              />
            </Form.Item>
          </Col> 
        </Row> */}

            {/* <Checkbox
          style={{ gap: "20px", paddingBottom: "15px" }}
          checked={checkbox === 3}
          onChange={() => {
            if (checkbox !== 3) {
              setCheckbox(3);
            }
          }}
        />
        <span>Due on receipt</span> */}
          </Form>
          {!disable && (
            <Space style={{ justifyContent: "end", display: "flex" }}>
              <Button icon={<CloseOutlined />} onClick={() => setDisable(true)}>
                Cancel
              </Button>

              <Button
                type="primary"
                loading={savebuttonloading}
                onClick={() => {
                  DueOnSubmit();
                }}
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Space>
          )}
        </>
      ),
    },
    {
      key: "4",
      label: "GST",
      collapsible: "header",
      extra: (
        <>
          {gstDisable && (
            <Tooltip title="Edit">
              <FormOutlined
                className="ca-edit-btn"
                style={{ color: "rgb(106 119 227)" }}
                onClick={(e) => {
                  handleIconClick(e, "4");
                  setGSTDisable(false);
                  setInfoDisable(true);
                  setDisable(true);
                  setDetailsDisable(true);
                }}
              />
            </Tooltip>
          )}
        </>
      ),
      children: (
        <>
          <Form
            requiredMark={false}
            form={gstForm}
            labelAlign="left"
            disabled={gstDisable}
            autoComplete="off"
            layout="vertical"
            name="Gst"
            onFinish={(val) => {
              const gst = val?.gstDetails?.gst?.map((itm: any) => {
                return {
                  id: itm?.value,
                  name: itm?.label,
                  rate: itm?.title,
                };
              });

              const data = {
                gstDetails: {
                  ...val?.gstDetails,
                  registrationDate: dayjs(
                    val?.gstDetails?.registrationDate
                  ).toISOString(),
                  gst: gst,
                  isGSTEnabled:
                    gst?.length > 0 &&
                    (val?.registrationNo !== "" || val?.registrationNo !== null)
                      ? true
                      : false,
                },
              };

              setSaveButtonLoading(true);
              updateCompanySettings!(
                SettingsSections.GstDetails,
                {
                  ...companySettings,
                  setting: {
                    ...companySettings?.setting!,
                    gstDetails: data?.gstDetails,
                  },
                },
                data
              )
                .then((result: any) => {
                  if (result?.status) {
                    setGSTDisable(true);
                    openNotification(
                      "success",
                      "Setting updated successfully."
                    );
                    setSaveButtonLoading(false);
                  } else {
                    openNotification("error", "There is some error.");
                    setSaveButtonLoading(false);
                  }
                })
                .catch((ex: any) => {
                  setSaveButtonLoading(false);
                  openNotification("error", ex.message);
                });
            }}
          >
            <Form.Item
              label={highlightLabel("GST Type")}
              name={["gstDetails", "scheme"]}
              className="inputBoxMb8 inputLabel33"
              rules={[
                {
                  required: true,
                  message: "Please select the appropriate GST type",
                },
              ]}
            >
              <Select
                onChange={(val) => {
                  setGSTTypeValue(val);
                }}
                options={GSTTypeOptions}
              />
            </Form.Item>
            {gSTTypeValue === CompanyGSTTypes.Regular && (
              <>
                <Form.Item
                  label={highlightLabel("Registration Date")}
                  className="mb-10"
                  name={["gstDetails", "registrationDate"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input date",
                    },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                </Form.Item>

                <Form.Item
                  label={highlightLabel("GSTIN")}
                  name={["gstDetails", "registrationNo"]}
                  className="inputBoxMb8 inputLabel33"
                  rules={[
                    {
                      required: true,
                      pattern:
                        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                      message: "Enter valid GST number",
                    },
                  ]}
                >
                  <Input
                    placeholder="00AABCC1234D1ZZ"
                    allowClear={true}
                    onChange={(e: any) => {
                      const capitalvalue = e.target.value.toUpperCase();
                      gstForm.setFieldValue("gstin", capitalvalue);
                    }}
                    // onBlur={handlePANNo}
                    // disabled={
                    //   gSTTypeValue === CompanyGSTTypes.Unregistered
                    //     ? true
                    //     : false
                    // }
                    // onChange={(e: any) => {
                    //   if (e.target.value) setTouched(true);
                    //   else setTouched(false);
                    // }}
                  />
                </Form.Item>

                <Form.Item
                  label={highlightLabel("Sales GST Options")}
                  className="mb-10"
                  name={["gstDetails", "gst"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input GST options",
                    },
                  ]}
                >
                  <Select
                    labelInValue
                    mode="multiple"
                    showSearch={false}
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Please select GSTs"
                    onChange={(val) => console.log(val)}
                    options={companySettings?.gsts?.map((gst: any) => ({
                      title: gst?.rate,
                      value: gst?.id,
                      label: gst?.name,
                    }))}
                  />
                </Form.Item>
              </>
            )}
            <Row justify="end" style={{ height: 32 }}>
              {!gstDisable && (
                <Space>
                  <Button
                    icon={<CloseOutlined />}
                    style={{ backgroundColor: "aliceblue" }}
                    onClick={() => {
                      setGSTDisable(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    icon={<SaveOutlined />}
                    type="primary"
                    htmlType="submit"
                    loading={saveButtonLoading}
                  >
                    Save
                  </Button>
                </Space>
              )}
            </Row>
          </Form>
        </>
      ),
    },
    // {
    //   key: "5",
    //   label: "General Settings",
    //   collapsible: "header",
    //   extra: (
    //     <>
    //       {generalDisable && (
    //         <Tooltip title="Edit">
    //           <FormOutlined
    //             className="ca-edit-btn"
    //             style={{ color: "rgb(106 119 227)" }}
    //             onClick={(e) => {
    //               handleIconClick(e, "5");
    //               setGeneralDisable(false);
    //               setGSTDisable(true);
    //               setInfoDisable(true);
    //               setDisable(true);
    //               setDetailsDisable(true);
    //             }}
    //           />
    //         </Tooltip>
    //       )}
    //     </>
    //   ),
    //   children: (
    //     <>
    //       <Form
    //         requiredMark={false}
    //         form={generalForm}
    //         labelAlign="left"
    //         disabled={generalDisable}
    //         autoComplete="off"
    //         name="general"
    //         onFinish={(val) => {
    //           const data = {};
    //           console.log("data", val);

    //           setSaveButtonLoading(true);
    //           updateCompanySettings!(
    //             SettingsSections.GstDetails,
    //             {
    //               ...companySettings,
    //               // setting: {
    //               //   ...companySettings?.setting!,
    //               //   gstDetails: data?.gstDetails,
    //               // },
    //             },
    //             data
    //           )
    //             .then((result: any) => {
    //               if (result?.status) {
    //                 setGeneralDisable(true);
    //                 openNotification("success", "Setting updated successfully.");
    //                 setSaveButtonLoading(false);
    //               } else {
    //                 openNotification("error", "There is some error.");
    //                 setSaveButtonLoading(false);
    //               }
    //             })
    //             .catch((ex: any) => {
    //               setSaveButtonLoading(false);
    //               openNotification("error", ex.message);
    //             });
    //         }}
    //       >
    //         <Row>
    //           <Col xxl={6} xl={6} lg={12} md={12}>
    //             <Form.Item
    //               label="Enable Export"
    //               name={["generalSettings", "enableExport"]}
    //               className="inputBoxMb8 inputLabel33"
    //               layout="horizontal"
    //             >
    //               <Checkbox />
    //             </Form.Item>
    //           </Col>
    //           <Col xxl={6} xl={6} lg={12} md={12}>
    //             <Form.Item
    //               label="Enable Inventory"
    //               name={["generalSettings", "enableInventory"]}
    //               layout="horizontal"
    //               className="inputBoxMb8 inputLabel33"
    //             >
    //               <Checkbox />
    //             </Form.Item>
    //           </Col>
    //         </Row>
    //         <Row justify="end" style={{ height: 32 }}>
    //           {!generalDisable && (
    //             <Space>
    //               <Button
    //                 icon={<CloseOutlined />}
    //                 style={{ backgroundColor: "aliceblue" }}
    //                 onClick={() => {
    //                   setGeneralDisable(true);
    //                 }}
    //               >
    //                 Cancel
    //               </Button>
    //               <Button
    //                 icon={<SaveOutlined />}
    //                 type="primary"
    //                 htmlType="submit"
    //                 loading={saveButtonLoading}
    //               >
    //                 Save
    //               </Button>
    //             </Space>
    //           )}
    //         </Row>
    //       </Form>
    //     </>
    //   ),
    // },
  ];

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    const matchingFields = findMatchingFieldLabels(query);

    if (matchingFields.length > 0) {
      setActivePopover(matchingFields[0].key);
    } else {
      setActivePopover(null);
    }
  };

  const filteredItems = items
    .filter((item) => {
      const matchingFields = findMatchingFieldLabels(searchQuery);
      return matchingFields.some((field) => field.key === item.key);
    })
    .map((item) => {
      const matchingFields = findMatchingFieldLabels(searchQuery);
      if (matchingFields?.length < 19) {
        const matchedField = matchingFields.find(
          (field) => field.key === item.key
        );
        console.log("matchedField", matchedField);

        // if (matchedField) {
        //   return {
        //     ...item,
        //     label: (
        //       <Popover
        //         placement="right"
        //         content={
        //           <div>
        //             {matchingFields.map((field, index) => (
        //               <p key={index}>{field.label}</p>
        //             ))}
        //             <div style={{ textAlign: "right", marginTop: "10px" }}>
        //               <Button
        //                 size="small"
        //                 onClick={(e) => {
        //                   e.stopPropagation();
        //                   setActivePopover(null);
        //                 }}
        //               >
        //                 Cancel
        //               </Button>
        //             </div>
        //           </div>
        //         }
        //         title="Matched Field Name"
        //         visible={activePopover === item.key}
        //         trigger="focus"
        //       >
        //         <span
        //           style={{
        //             cursor: "pointer",
        //           }}
        //         >
        //           {item.label}
        //         </span>
        //       </Popover>
        //     ),
        //   };
        // }
      }

      return item;
    });

  console.log("searchQuery", searchQuery);

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        // marginTop: "6px",
        marginTop: 35,
        // padding: "48px 0px 0px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <div style={{ padding: "0px 0px 3px 0px", height: "80vh" }}>
        <div style={{ margin: "20px" }}>
          <Row justify={"center"}>
            <Col span={11}>
              <Input
                placeholder="Search settings..."
                value={searchQuery}
                onChange={handleSearchChange}
                prefix={
                  <SearchOutlined style={{ color: "#aaa", fontSize: "18px" }} />
                }
                allowClear
                style={{
                  // borderRadius: "24px",
                  padding: "10px 16px",
                  // boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                  // borderBottom: "1px solid #d9d9d9",
                  fontSize: "16px",
                }}
              />
            </Col>
          </Row>
        </div>
        <div
          style={{
            maxWidth: "724px",
            margin: "0 auto",
            maxHeight: "70vh",
            overflowY: "scroll",
          }}
        >
          <Card>
            <Collapse
              activeKey={activeKey}
              onChange={handleCollapseChange}
              items={filteredItems}
              // defaultActiveKey={["1"]}
            />
          </Card>
        </div>
      </div>
    </Card>
  );
};

export default SettingsCompanyInfo;
