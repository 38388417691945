import { ApiUtility } from "../../Utilities/ApiUtility.axios";

class LocationService {
  private route = "/v1/API/Acc_FALocation";

  getLocationList = (
    companyId: string | undefined,
    start?: number,
    length?: number,
    search?: string
  ) =>
    ApiUtility.get(
      `${this.route}/${companyId}/GetFALocationList?start=${start}&length=${length}&search=${search}`
    );

  getLocationDatabyId = (companyId: string | undefined, FALocationId: string) =>
    ApiUtility.get(`${this.route}/${companyId}/${FALocationId}`);

  deleteLocationDatabyId = (
    companyId: string | undefined,
    FALocationId: string
  ) => ApiUtility.delete(`${this.route}/${companyId}/${FALocationId}`);

  postLocationData = (
    companyId: string | undefined,
    modal: any,
    FALocationId?: string
  ) =>
    ApiUtility.post(
      `${this.route}/${companyId}/${FALocationId ? FALocationId : ""}`,
      modal
    );
}
export default new LocationService();
