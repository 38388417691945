import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import React from "react";
import { Utils } from "../../../Utilities/Utils";
import { dayjs } from "../../../Utilities/dayjs";
import ItemsService, { InventoryType } from "../../Services/ItemsService";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";

const InventoryAdjustModel: React.FC<{
  trigger: any;
  open: boolean;
  type: InventoryType;
  id: string;
  onCancel: any;
  item: any;
  allItems: any[];
}> = (props) => {
  console.log("props............", props);

  const [form] = Form.useForm();
  const { currentClient } = useClient();
  const { openNotification } = React.useContext(notificationContext);
  const [adjust, setAdjust] = React.useState<boolean>(true);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);

  // const [type, setType] = React.useState<InventoryType>(
  //   props.id ? InventoryType.Update : InventoryType.Duplicate
  // );
  console.log(currentClient?.clientId!);

  const stockOnChange = () => {
    form.setFieldValue(
      "tAmount",
      Utils.getFormattedNumber(
        form.getFieldValue("qty") * form.getFieldValue("price")
      )
    );
  };

  const totalAmountStock = () => {
    form.setFieldValue(
      "price",
      Utils.getFormattedNumber(
        form.getFieldValue("tAmount") / form.getFieldValue("qty")
      )
    );
  };

  // Post Api
  const postItem = () => {
    form.validateFields().then(async (value: any) => {
      setSaveButtonLoading(true);
      let data = {
        ...value,
        item: {
          name: value.item?.label,
          id: value.item?.value,
        },
        date: dayjs(value?.date).format("DD/MM/YYYY"),
        adjust: adjust ? 1 : 2,
      };

      await ItemsService.addItemInventory(
        data,
        currentClient?.id,
        props.type,
        props?.id
      )
        .then((res: any) => {
          if (res.result) {
            setSaveButtonLoading(false);
            props.onCancel();
            openNotification(
              "success",
              `${res.result.name}  ${
                props?.id
                  ? "updated successfully."
                  : adjust
                  ? "added quantity successfully."
                  : "reduced quantity successfully."
              }`
            );
            props.item.id === data.item.id && props.trigger();
          }
        })
        .catch((err: any) => {
          openNotification("error", err.message);
          console.error(err);
        });
    });
  };

  // Get Api
  React.useEffect(() => {
    if (props?.id) {
      const get = async (id: string) => {
        await ItemsService.getItemInventory(currentClient?.id, id)
          .then((res: any) => {
            if (res.result) {
              form.setFieldsValue({
                ...res.result,
                date: dayjs(
                  dayjs(res.result.date).format("DD/MM/YYYY"),
                  "DD/MM/YYYY"
                ),
              });
            }
            setAdjust(res.result.adjust === 1 ? true : false);
            console.log(res);
          })
          .catch((ex) => {
            console.error(ex);
          });
      };
      get(props.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id]);
  // getItemInventory;

  // --------- InitialValues in Add Item Model ---------
  const initialValues = {
    taxRate: {
      value: 4,
      key: "Gst 5%",
      title: 5,
    },
    item: {
      label: props.item.name,
      value: props.item.id,
    },
    date: dayjs(),
  };

  return (
    <div>
      <Modal
        open={props.open}
        title={
          <Row>
            <Col sm={5} xs={24} style={{ fontSize: "17px" }}>
              Stock Adjustment
            </Col>
            <Col sm={11} xs={24} span={4}>
              <Row>
                <Col style={{ paddingTop: "2px" }}>Add Stock</Col>
                <Col style={{ padding: "0px 8px" }}>
                  <Switch checked={adjust} onChange={(x) => setAdjust(x)} />
                </Col>
                <Col style={{ paddingTop: "2px" }}>Reduce Stock</Col>
              </Row>
            </Col>
          </Row>
        }
        width={1000}
        onCancel={() => props.onCancel()}
        style={{ maxHeight: `calc(100% - 100px)`, overflowY: "auto", top: 180 }}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => postItem()}
              loading={saveButtonLoading}
            >
              {!props?.id || props.type === 0 ? "Save" : "Update"}
            </Button>
          </div>
        }
      >
        <Divider className="ca-model-css" />
        {/* <Spin indicator={antIcon} spinning={modelLoading}> */}
        <Form
          form={form}
          name="item"
          autoCapitalize="false"
          requiredMark={false}
          initialValues={initialValues}
          colon={false}
          labelAlign="left"
          labelCol={{ xs: 8, sm: 7 }}
          wrapperCol={{ xs: 16, sm: 16 }}
          labelWrap={true}
        >
          <Row style={{ justifyContent: "space-between", paddingTop: "10px" }}>
            <Col sm={8} xs={24}>
              <Form.Item
                name="item"
                label="Name"
                rules={[{ required: true, message: "Item's name is required" }]}
                className="input-item"
              >
                <Select
                  labelInValue
                  showSearch
                  optionFilterProp="children"
                  placeholder="Item name"
                  filterOption={false}
                  notFoundContent={null}
                  options={props?.allItems.map((value: any) => ({
                    value: value.id,
                    label: value.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col sm={8} xs={24}>
              <Form.Item
                label="Date"
                name="date"
                // valuePropName="checked"
                className="input-item"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  disabledDate={(current) =>
                    current && current > dayjs().endOf("day")
                  }
                  style={{ width: "100%" }}
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <Col sm={8} xs={24}>
              <Form.Item
                label="Location"
                name="location"
                className="input-item"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={8} xs={24}>
              <Form.Item
                label="Quantity"
                name="qty"
                className="input-item"
                rules={[
                  { required: true, message: "Quantity is required" },
                  {
                    pattern: /^(?=.*[1-9])(?:[1-9]\d*\.?|0?\.)\d*$/,
                    message: "0 Not Valid!",
                  },
                ]}
              >
                <InputNumber
                  // controls={false}
                  style={{ width: "100%" }}
                  onChange={() => {
                    stockOnChange();
                  }}
                  onKeyPress={(e: any) => {
                    if (!/\d|\./.test(e.key)) e.preventDefault();
                    if (e.key === "." && e.target.value.includes("."))
                      e.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={8} xs={24}>
              <Form.Item
                name="price"
                label="Price"
                className="input-item"
                rules={[
                  { required: true, message: "Price is required" },
                  {
                    pattern: /^(?=.*[1-9])(?:[1-9]\d*\.?|0?\.)\d*$/,
                    message: "0 Not Valid!",
                  },
                ]}
              >
                <InputNumber
                  addonAfter="INR"
                  // controls={false}
                  style={{ width: "100%" }}
                  onChange={() => {
                    stockOnChange();
                  }}
                  onKeyPress={(e: any) => {
                    if (!/\d|\./.test(e.key)) e.preventDefault();
                    if (e.key === "." && e.target.value.includes("."))
                      e.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>
            <Col sm={8} xs={24}>
              <Form.Item
                label="Net Amount"
                name="tAmount"
                className="input-item"
              >
                <InputNumber
                  addonAfter="INR"
                  // controls={false}
                  style={{ width: "100%" }}
                  onChange={() => {
                    totalAmountStock();
                  }}
                  onKeyPress={(e: any) => {
                    if (!/\d|\./.test(e.key)) e.preventDefault();
                    if (e.key === "." && e.target.value.includes("."))
                      e.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {/* </Spin> */}
      </Modal>
    </div>
  );
};

export default InventoryAdjustModel;
