import { CloseOutlined, FormOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import "suneditor/dist/css/suneditor.min.css";
import SunEditor from "suneditor-react";
import React, { FC, useContext } from "react";
import { IUpdateCompanySettings, useClient } from "../../Context/ClientContext";
import {
  Discount_Policy,
  DuplicateCopy_Type,
  EntitySettings,
  ItemRate_Type,
  RoundOff_Type,
  SettingsSections,
} from "../../Services/SettingService";
import { notificationContext } from "../../Common/PageRoute";
import AdditionalNotes from "../../Common/AdditionalNotes";
import TextArea from "antd/es/input/TextArea";
import { Voucher_Type } from "../../Services/VoucherServices";
import TermsAndConditionModal from "../Sales/Terms&ConditionModal";
import { CompanyGSTTypes } from "../../../Types/CommonConstants";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { Product_Module } from "../../Services/ProductService";

const SettingSecCustomization: FC<{
  isDrawer?: boolean;
  settingsSections: SettingsSections;
  settingLabelName: string;
  form: FormInstance<any>;
  onTemplateId?: any;
}> = (props) => {
  const { companySettings, updateCompanySettings, currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [duplicateCopy, setDuplicateCopy] = React.useState<any>();
  const [tnCTemplateID, setTnCTemplateID] = React.useState<any>();

  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });
  const [select, setSelect] = React?.useState<{
    value: SettingsSections;
    label: string;
    // key: string;
    postNewName?: string;
  }>({
    value: props.settingsSections,
    label: props.settingLabelName,
    // key: "invoice",
    postNewName: "",
  });
  const [previousSelect, setPreviousSelect] = React?.useState<{
    value: SettingsSections;
    label: string;
    key: string;
    postNewName?: string;
  }>({
    value: SettingsSections.Invoice,
    label: "TAX INVOICE",
    key: "invoice",
    postNewName: "",
  });
  const [openNotes, setOpenNotes] = React.useState<boolean>(false);
  const [note, setNote] = React.useState<string>();
  const [notesInitial, setNotesInitial] = React.useState<string>();
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(
    props.isDrawer ? true : false
  );
  const [editorContent, setEditorContent] = React?.useState("");
  const [fromData, setFromData] = React?.useState<{
    allFiled: EntitySettings | undefined;
    postObjName: string;
    indexEditData: number | undefined;
  }>({ allFiled: undefined, postObjName: "invoiceSettings", indexEditData: 0 });
  const [addNewVoucherTypeChechbox, setAddNewVoucherTypeChechbox] =
    React.useState<boolean>(false);
  const [isSystem, setIsSystem] = React.useState<boolean>(true);
  const [deleteTempleteComformation, setDeleteTempleteComformation] =
    React.useState<boolean>(false);
  const [onCheckBoxTrigger, setOnCheckBoxTrigger] =
    React.useState<boolean>(false);

  const handleSelect = (value: any) => {
    setIsEdit(false);
  };

  const handleChange = (content: any) => {
    setEditorContent(content);
  };
  const filterOption = (
    input: string,
    option?: { label: string; value: SettingsSections }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  React?.useEffect(() => {
    const find = companySettings?.setting?.vouchersSettings?.find(
      (x) => x?.title === select.label
    );

    const indexCurrentType =
      companySettings?.setting?.vouchersSettings?.findIndex(
        (x) => x?.title === select.label
      );
    console.log("Invoice", find, indexCurrentType);

    let getData: EntitySettings | undefined;
    let postObjName;

    if (select.value === SettingsSections.Invoice) {
      getData = find?.settings?.invoiceSettings;
      postObjName = "invoiceSettings";
      setDuplicateCopy(
        find?.settings?.invoiceSettings?.invoiceCopyLabelling?.duplicateCopyType
      );
    } else if (
      select.value === SettingsSections.PInvoice ||
      select.value === SettingsSections.RCMBill ||
      select.value === SettingsSections.ExpenseJV
    ) {
      getData = find?.settings?.billSettings;
      postObjName = "billSettings";
    } else if (
      select.value === SettingsSections.CrNote ||
      select.value === SettingsSections.DrNote
    ) {
      getData = find?.settings?.returnNoteSettings;
      postObjName = "returnNoteSettings";
    } else if (
      select.value === SettingsSections.POrder ||
      select.value === SettingsSections.SOrder ||
      select.value === SettingsSections.Quotation
    ) {
      getData = find?.settings?.orderSettings;
      postObjName = "orderSettings";
    } else {
      getData = find?.settings?.basicSettings;
      postObjName = "basicSettings";
    }

    props.form.setFieldsValue(getData ?? { preSuf: "", title: "" });
    setFromData({
      allFiled: getData,
      postObjName: postObjName,
      indexEditData: indexCurrentType,
    });

    console.log("getData?.tnCTemplateId", getData?.tnCTemplateId);

    // if (select.label) {
    setTnCTemplateID({
      templateId: getData?.tnCTemplateId,
    });
    if (props.isDrawer) {
      getData?.tnCTemplateId && props?.onTemplateId(getData?.tnCTemplateId);
    }
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySettings, select?.value, onCheckBoxTrigger]);

  // console.log("val", companySettings?.setting?.invoice);

  // console.log("compSettingType", compSettingType);

  // React.useEffect(() => {
  //   if (props.isDrawer && false) {
  //     saveSettingCutomization();
  //   }
  // }, [props.isDrawer]);

  const saveSettingCutomization = () => {
    if (isEdit && select.value) {
      if (
        addNewVoucherTypeChechbox
          ? select.postNewName &&
            !companySettings?.setting?.vouchersSettings?.find(
              (x) => x.title === select.postNewName
            )
          : true
      ) {
        props.form.validateFields().then((val: any) => {
          setSaveButtonLoading(true);
          /** Post
           * to add state values in Form Values
           */

          const currentPostData = {
            ...val,
            tnCTemplateId: tnCTemplateID?.templateId,
          };

          let newVal = {
            title: !addNewVoucherTypeChechbox
              ? select.label
              : select.postNewName!,
            settingType: select.value,
            settings: {
              [fromData?.postObjName]: currentPostData,
            },
          };

          console.log("New val", val, newVal);

          if (addNewVoucherTypeChechbox) {
            updateCompanySettings!(
              select.value!,
              {
                ...companySettings,
                setting: {
                  ...companySettings?.setting!,
                  vouchersSettings: [
                    ...companySettings?.setting?.vouchersSettings!,
                    newVal,
                  ],
                },
              },
              newVal,
              select.postNewName,
              IUpdateCompanySettings.addNew
            ).then((result: any) => {
              if (result?.status) {
                setIsEdit(false);
                setAddNewVoucherTypeChechbox(false);
                setSaveButtonLoading(false);
                openNotification("success", "Setting added successfully.");
                setSelect({
                  value: select.value!,
                  label: select.postNewName!,
                });
              } else {
                setSaveButtonLoading(false);
                openNotification(
                  "error",
                  result.message ?? "There is some error."
                );
              }
            });
          } else {
            const newValpostData = {
              vouchersSettings: [newVal],
            };

            const allCustomization = companySettings?.setting?.vouchersSettings;

            allCustomization?.splice(fromData.indexEditData!, 1, newVal)!;

            updateCompanySettings!(
              select.value!,
              {
                ...companySettings,
                setting: {
                  ...companySettings?.setting!,
                  vouchersSettings: allCustomization!,
                },
              },
              newValpostData,
              select.label
            ).then((result: any) => {
              if (result?.status) {
                setIsEdit(false);
                setAddNewVoucherTypeChechbox(false);
                setSaveButtonLoading(false);
                openNotification("success", "Setting updated successfully.");
              } else {
                debugger;
                setSaveButtonLoading(false);
                openNotification(
                  "error",
                  result.message ?? "There is some error."
                );
              }
            });
          }
        });
      } else {
        openNotification("error", "Enter new template name.");
      }
    }
  };

  return (
    <>
      <div
        style={{
          padding: "5px 5px 5px 5px",
          height: "100%",
        }}
      >
        {!props.isDrawer && (
          <Row>
            <Col span={17}>
              <Row gutter={10}>
                <Col xl={8} lg={6}>
                  <Row gutter={5}>
                    <Col lg={8} style={{ alignContent: "center" }}>
                      <span>Voucher Template</span>
                    </Col>
                    <Col lg={16}>
                      <Select
                        style={{ width: "100%" }}
                        disabled={addNewVoucherTypeChechbox}
                        labelInValue
                        showSearch
                        placeholder="Select..."
                        optionFilterProp="children"
                        filterOption={filterOption}
                        defaultValue={{
                          value: SettingsSections.Invoice,
                          label: "TAX INVOICE",
                        }}
                        value={{
                          value: select.label,
                          label: select.label,
                          title: select.value,
                        }}
                        options={companySettings?.setting?.vouchersSettings?.map(
                          (x: any, index: number) => ({
                            value: x?.title,
                            label: x?.title,
                            title: `${x?.settingType}`,
                            key: index,
                          })
                        )}
                        onChange={(e: any) => {
                          const findCurrentData =
                            companySettings?.setting?.vouchersSettings?.find(
                              (x) => x.title === e.value
                            );

                          console.log(
                            "onClick",
                            findCurrentData?.system!,
                            findCurrentData?.system
                          );

                          setIsSystem(findCurrentData?.system!);

                          setSelect({
                            value: Number(e.title),
                            label: e.label,
                          });
                          setPreviousSelect({
                            value: Number(e.title),
                            label: e.label,
                            key: e.key,
                          });
                          handleSelect(e);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                {addNewVoucherTypeChechbox && (
                  <>
                    <Col lg={4}>
                      <Select
                        style={{ width: "100%" }}
                        labelInValue
                        showSearch
                        placeholder="Select..."
                        optionFilterProp="children"
                        filterOption={filterOption}
                        defaultValue={SettingsSections.Invoice}
                        options={[
                          {
                            value: SettingsSections.Invoice,
                            label: "Sales Invoice",
                            // key: "invoice1",
                          },
                          // {
                          //   value: SettingsSections.CrNote,
                          //   label: "Credit Note",
                          //   // key: "crNote1",
                          // },
                          // {
                          //   value: SettingsSections.Receipt,
                          //   label: "Receipt",
                          //   // key: "receipt1",
                          // },
                          // {
                          //   value: SettingsSections.Quotation,
                          //   label: "Estimate",
                          //   // key: "estimate",
                          // },
                          // {
                          //   value: SettingsSections.SOrder,
                          //   label: "Sales Order",
                          //   // key: "sOrder",
                          // },
                          // {
                          //   value: SettingsSections.DChallan,
                          //   label: "Delivery Challan",
                          //   // key: "dChallan",
                          // },
                          {
                            value: SettingsSections.PInvoice,
                            label: "Purchase Invoice",
                            // key: "pInvoice",
                          },
                          // {
                          //   value: SettingsSections.DrNote,
                          //   label: "Debit Note",
                          //   // key: "drNote",
                          // },
                          // {
                          //   value: SettingsSections.Payment,
                          //   label: "Payment",
                          //   // key: "payment",
                          // },
                          // {
                          //   value: SettingsSections.POrder,
                          //   label: "Purchase Order",
                          //   // key: "pOrder",
                          // },
                          // {
                          //   value: SettingsSections.Item,
                          //   label: "Items",
                          //   // key: "item",
                          // },
                          // {
                          //   value: SettingsSections.Asset,
                          //   label: "Fixed Assets",
                          //   // key: "asset",
                          // },
                          // {
                          //   value: SettingsSections.Journal,
                          //   label: "Journal",
                          //   key: "journal",
                          // },
                          // {
                          //   value: SettingsSections.Contra,
                          //   label: "Contra",
                          //   // key: "contra",
                          // },
                          // {
                          //   value: SettingsSections.Dividend,
                          //   label: "Dividend",
                          //   // key: "dividend",
                          // },
                        ]}
                        onChange={(e: any) => {
                          setSelect({
                            ...select,
                            value: e.value,
                            label: "",
                          });
                        }}
                      />
                    </Col>

                    <Col lg={4} sm={4}>
                      <Form.Item
                        name=""
                        className="m-0"
                        rules={[
                          { required: true, message: "Enter a template name" },
                        ]}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          placeholder="Templete Name"
                          onChange={(e: any) => {
                            setSelect({
                              ...select,
                              postNewName: e.target?.value,
                            });
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
                {!addNewVoucherTypeChechbox && !isSystem && (
                  <Button onClick={() => setDeleteTempleteComformation(true)}>
                    Delete
                  </Button>
                )}
              </Row>
            </Col>

            <Col
              span={3}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {isEdit ? (
                <></>
              ) : (
                <>
                  <Tooltip title="Edit">
                    <Button
                      style={{
                        marginRight: "10px",
                        padding: "13px 15px",
                        marginTop: "3px",
                      }}
                      type="primary"
                      size="small"
                      icon={<FormOutlined />}
                      onClick={() => {
                        setNote(undefined);

                        companySettings?.setting?.vouchersSettings.find(
                          (x) => x.title === select.label
                        );
                        setNotesInitial(
                          // companySettings?.setting?.invoice?.disclaimer
                          companySettings?.setting?.vouchersSettings?.find(
                            (x: any) => x.title === select.label
                          )?.settings?.invoiceSettings?.disclaimer
                        );
                        setIsEdit(true);
                      }}
                    >
                      Edit
                    </Button>

                    {/* <Button
                        className="ms-40"
                        type="default"
                        icon={<EditOutlined />}
                        onClick={() => {
                          setNote(undefined);
                          setNotesInitial(
                            companySettings?.setting?.invoice?.notes
                          );
                          setIsEdit({
                            edit: true,
                            key:
                              isEdit.key !== "salesInv"
                                ? "salesInv"
                                : undefined,
                          });
                        }}
                      /> */}
                  </Tooltip>
                </>
              )}
            </Col>
            <Col lg={3}>
              <Checkbox
                disabled={false}
                className="custom-select"
                checked={addNewVoucherTypeChechbox}
                style={{ marginTop: "6px" }}
                onClick={(e: any) => {
                  console.log(
                    "E",
                    e.target.checked,
                    select.value,
                    select.label
                  );
                  // if (e.target.checked) {
                  //   setSelect({
                  //     value: SettingsSections.Invoice,
                  //     label: "",
                  //     key: "",
                  //   });
                  // }
                  setOnCheckBoxTrigger(e.target.checked);
                  setAddNewVoucherTypeChechbox(e.target?.checked);
                  setIsEdit(e.target?.checked);
                  if (e.target.checked) {
                    setSelect({
                      value: SettingsSections.Invoice,
                      label: "",
                    });
                  } else {
                    setSelect({
                      value: previousSelect.value,
                      label: previousSelect.label,
                    });
                  }
                }}
              >
                <span style={{ marginLeft: "5px" }}>Create New Template</span>
              </Checkbox>
            </Col>

            <Divider className="my-7" />
          </Row>
        )}

        <Form
          form={props.form}
          colon={false}
          name="CustomizeSalesInvoice"
          disabled={!isEdit}
          requiredMark={false}
          // labelAlign="left"
          // labelCol={{ span: 6 }}
          // wrapperCol={{ span: 12 }}
          layout="vertical"
        >
          <>
            {/* --------- Sales Invoice --------- */}
            <Row gutter={10}>
              <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                <Form.Item
                  name="isSuffix"
                  className="m-0"
                  label="Prefix / Suffix"
                >
                  <Select
                    // style={{ width: "100%" }}
                    className="custom-select small-font-select"
                    placeholder="Select"
                    options={[
                      { value: false, label: "Prefix" },
                      { value: true, label: "Suffix" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                <Form.Item
                  name="preSuf"
                  className="m-0"
                  label="Prefix / Suffix Text"
                >
                  <Input className="custom-input small-font-input" />
                </Form.Item>
              </Col>

              <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                <Form.Item
                  // name={[select.key, "start"]}
                  name="start"
                  className="m-0 textCenter"
                  label="Start from"
                  // labelCol={{ span: 6 }}
                  // wrapperCol={{ span: 12 }}
                >
                  <Input
                    className="custom-input small-font-input"
                    onKeyPress={(e) =>
                      !/[0-9]/.test(e.key) && e.preventDefault()
                    }
                  />
                </Form.Item>
              </Col>
              <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                <Form.Item
                  name="title"
                  className="m-0"
                  label="Title"
                  rules={[{ required: true, message: "Title is required" }]}
                >
                  <Input className="custom-input small-font-input" />
                </Form.Item>
              </Col>

              <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item
                      label="Year Include"
                      valuePropName="checked"
                      // name={[select.key, "yearInclude"]}
                      name="yearInclude"
                      className="m-0"
                    >
                      <Checkbox
                        // className="custom-select"
                        style={{ marginTop: "6px", width: "40px" }}
                      >
                        {" "}
                        {/* Year Include */}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    {" "}
                    <Form.Item
                      label="Month Include"
                      valuePropName="checked"
                      // name={[select.key, "monthInclude"]}
                      name="monthInclude"
                      className="m-0"
                    >
                      <Checkbox
                        // className="custom-select"
                        style={{ marginTop: "6px" }}
                      >
                        {" "}
                        {/* Month Include */}
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                <Form.Item
                  label="Month Format"
                  // name={[select.key, "monthNumFormat"]}
                  name="monthNumFormat"
                  className="m-0"
                >
                  <Select
                    className="custom-select small-font-select"
                    placeholder="Select format"
                    options={[
                      { value: 0, label: "Number" },
                      { value: 1, label: "Text" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                <Form.Item
                  label="Frequency of No."
                  // name={[select.key, "startNumberFrequency"]}
                  name="startNumberFrequency"
                  className="m-0"
                >
                  <Select
                    className="custom-select small-font-select"
                    placeholder="Select frequency"
                    options={[
                      { value: 0, label: "Yearly" },
                      { value: 1, label: "Monthly" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                <Form.Item
                  label="Separator"
                  // name={[select.key, "separator"]}
                  name="separator"
                  className="m-0"
                >
                  <Select
                    className="custom-select small-font-select"
                    placeholder="Separator"
                    options={[
                      { value: "-", label: "-" },
                      { value: "#", label: "#" },
                      { value: "/", label: "/" },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: "10px" }} gutter={10}>
              <Col lg={24} xs={24}>
                {!isEdit ? (
                  <Form.Item
                    className="m-0"
                    label="Disclaimer"
                    // labelCol={{ span: 3 }}
                    // wrapperCol={{ span: 18 }}
                    // name={[select.key, "disclaimer"]}
                    name="disclaimer"
                  >
                    <div
                      style={{
                        background: "rgba(0, 0, 0, 0.04)",
                        padding: 10,
                        height: "130px",
                        overflowX: "scroll",
                        border: "1px solid #d9d9d9",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: fromData?.allFiled?.disclaimer!,
                      }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    className="m-0"
                    label="Disclaimer"
                    // labelCol={{ span: 3 }}
                    // wrapperCol={{ span: 18 }}
                    // name={[select.key, "disclaimer"]}
                    name="disclaimer"
                  >
                    <SunEditor
                      setContents={fromData?.allFiled?.disclaimer}
                      setOptions={{
                        buttonList: [
                          ["undo", "redo"],
                          ["font", "fontSize"],
                          ["bold", "underline", "italic", "strike"],
                          ["fontColor", "hiliteColor"],
                          ["align", "list", "lineHeight"],
                          ["outdent", "indent"],
                          ["removeFormat"],
                        ],
                        defaultTag: "div",
                        showPathLabel: false,
                      }}
                      /*  ["table", "horizontalRule", "link", "image", "video"], */
                      height="150px"
                      onChange={handleChange}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>

            {(select.value === SettingsSections.Invoice ||
              select.value === SettingsSections.PInvoice ||
              select.value === SettingsSections.ExpenseJV ||
              select.value === SettingsSections.RCMBill ||
              select.value === SettingsSections.CrNote ||
              select.value === SettingsSections.DrNote ||
              select.value === SettingsSections.Quotation ||
              select.value === SettingsSections.SOrder ||
              select.value === SettingsSections.POrder) && (
              <Row style={{ marginTop: "10px" }} gutter={10}>
                <Col lg={24} xs={24}>
                  {!isEdit ? (
                    <Form.Item
                      className="m-0"
                      label="Terms & Conditions"
                      // labelCol={{ span: 3 }}
                      // wrapperCol={{ span: 18 }}
                      // name={[select.key, "termsnConditions"]}
                      name="termsnConditions"
                    >
                      <div
                        style={{
                          background: "rgba(0, 0, 0, 0.04)",
                          padding: 10,
                          height: "130px",
                          overflowX: "scroll",
                          border: "1px solid #d9d9d9",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: fromData?.allFiled?.termsnConditions!,
                        }}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      className="m-0"
                      label={
                        <Row
                          style={{
                            alignContent: "center",
                            marginBottom: "10px",
                          }}
                          gutter={10}
                        >
                          <Col>
                            <span>Terms & Conditions</span>
                          </Col>
                          <Col>
                            <Button
                              size="small"
                              style={{ backgroundColor: "ButtonFace" }}
                              onClick={() => {
                                setOpenModel({
                                  type: "termsAndConditionsModal",
                                  param: { open: true },
                                });
                              }}
                            >
                              Select Term & Conditions
                            </Button>
                          </Col>
                        </Row>
                      }
                      // labelCol={{ span: 3 }}
                      // wrapperCol={{ span: 18 }}
                      // name={[select.key, "termsnConditions"]}
                      name="termsnConditions"
                    >
                      <SunEditor
                        setContents={
                          tnCTemplateID?.tnCText === undefined
                            ? fromData?.allFiled?.termsnConditions
                            : tnCTemplateID?.tnCText
                        }
                        setOptions={{
                          buttonList: [
                            ["undo", "redo"],
                            ["font", "fontSize"],
                            ["bold", "underline", "italic", "strike"],
                            ["fontColor", "hiliteColor"],
                            ["align", "list", "lineHeight"],
                            ["outdent", "indent"],
                            ["removeFormat"],
                          ],
                          defaultTag: "div",
                          showPathLabel: false,
                        }}
                        /*  ["table", "horizontalRule", "link", "image", "video"], */
                        height="150px"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            )}
            <Divider className="my-7" />
            <Row style={{ marginTop: "20px" }} gutter={10}>
              {currentClient?.productModule !== Product_Module.Billing &&
                select.value === SettingsSections.Invoice && (
                  <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                    <Form.Item
                      name="showContactDetail"
                      className="m-0"
                      valuePropName="checked"
                    >
                      <Checkbox>Show Contact Detail</Checkbox>
                    </Form.Item>
                  </Col>
                )}
              {(select.value === SettingsSections.Invoice ||
                select.value === SettingsSections.PInvoice ||
                select.value === SettingsSections.ExpenseJV ||
                select.value === SettingsSections.RCMBill) && (
                <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                  <Form.Item
                    label=""
                    // name={[select.key, "showReceipt"]}
                    name="showReceipt"
                    className="m-0"
                    // labelCol={{ span: 10 }}
                    valuePropName="checked"
                  >
                    <Checkbox style={{ marginTop: "5px" }}>
                      {select.value === SettingsSections.Invoice
                        ? "Show Receipt"
                        : "Show Payment"}
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
              {(select.value === SettingsSections.Invoice ||
                select.value === SettingsSections.PInvoice ||
                select.value === SettingsSections.ExpenseJV ||
                select.value === SettingsSections.RCMBill ||
                select.value === SettingsSections.CrNote ||
                (select.value as SettingsSections) ===
                  SettingsSections.DrNote ||
                select.value === SettingsSections.Quotation ||
                select.value === SettingsSections.SOrder ||
                (select.value as SettingsSections) ===
                  SettingsSections.POrder) && (
                <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                  <Form.Item
                    label=""
                    // name={[select.key, "showShippingAddress"]}
                    name="showShippingAddress"
                    className="m-0"
                    // labelCol={{ span: 10 }}
                    valuePropName="checked"
                  >
                    <Checkbox>Show Shipping Address</Checkbox>
                  </Form.Item>
                </Col>
              )}

              {(select.value === SettingsSections.Invoice ||
                select.value === SettingsSections.PInvoice ||
                select.value === SettingsSections.ExpenseJV ||
                select.value === SettingsSections.RCMBill ||
                // select.value === SettingsSections.CrNote ||
                // (select.value as SettingsSections) ===
                //   SettingsSections.DrNote ||
                select.value === SettingsSections.Quotation ||
                select.value === SettingsSections.SOrder ||
                (select.value as SettingsSections) ===
                  SettingsSections.POrder) && (
                <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                  <Form.Item
                    // name={[select.key, "showPriceHistory"]}
                    name="showPriceHistory"
                    className="m-0"
                    // labelCol={{ span: 10 }}
                    valuePropName="checked"
                  >
                    <Checkbox style={{ marginTop: "5px" }}>
                      Show Price History
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
              {(select.value === SettingsSections.Invoice ||
                select.value === SettingsSections.PInvoice ||
                select.value === SettingsSections.Quotation ||
                select.value === SettingsSections.SOrder ||
                select.value === SettingsSections.CrNote ||
                select.value === SettingsSections.DrNote ||
                select.value === SettingsSections.POrder) && (
                <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                  <Form.Item
                    // label="Show Other Charges"
                    // name={[select.key, "showOtherCharges"]}
                    name="showOtherCharges"
                    className="m-0"
                    // labelCol={{ span: 10 }}
                    valuePropName="checked"
                  >
                    <Checkbox>Show Other Charges</Checkbox>
                  </Form.Item>
                </Col>
              )}
              {select.value === SettingsSections.Invoice && (
                <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                  <Form.Item
                    // label="E-Invoicing"
                    // name={[select.key, "eInvoicing"]}
                    name="eInvoicing"
                    className="m-0"
                    // labelCol={{ span: 10 }}
                    valuePropName="checked"
                  >
                    <Checkbox style={{ marginTop: "5px" }}>
                      E-Invoicing
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
              {select.value === SettingsSections.Invoice && (
                <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                  <Form.Item
                    // label="E-Way billing"
                    // name={[select.key, "eWayBilling"]}
                    name="eWayBilling"
                    className="m-0"
                    // labelCol={{ span: 10 }}
                    valuePropName="checked"
                  >
                    <Checkbox>E-Way billing</Checkbox>
                  </Form.Item>
                </Col>
              )}
              {(select.value === SettingsSections.Invoice ||
                select.value === SettingsSections.PInvoice ||
                select.value === SettingsSections.ExpenseJV ||
                select.value === SettingsSections.RCMBill ||
                select.value === SettingsSections.CrNote ||
                select.value === SettingsSections.DrNote) && (
                <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                  <Form.Item
                    // label="Show Item Description"
                    // name={[select.key, "showItemDescription"]}
                    name="showItemDescription"
                    className="m-0"
                    // labelCol={{ span: 10 }}
                    valuePropName="checked"
                  >
                    <Checkbox>Show Item Description</Checkbox>
                  </Form.Item>
                </Col>
              )}
              {(select.value === SettingsSections.PInvoice ||
                select.value === SettingsSections.ExpenseJV) &&
                companySettings?.setting?.gstDetails?.scheme ===
                  CompanyGSTTypes.Regular && (
                  <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                    <Form.Item
                      // label="Show Reverse Charge (RCM)"
                      // name={[select.key, "showRCM"]}
                      name="showRCM"
                      className="m-0"
                      // labelCol={{ span: 10 }}
                      valuePropName="checked"
                    >
                      <Checkbox>{"Show Reverse Charge (RCM)"}</Checkbox>
                    </Form.Item>
                  </Col>
                )}
              {(select.value === SettingsSections.CrNote ||
                (select.value as SettingsSections) ===
                  SettingsSections.DrNote) && (
                <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                  <Form.Item
                    label={`${
                      select.value === SettingsSections.CrNote
                        ? "Credit"
                        : "Debit"
                    } Note day limit`}
                    // name={[select.key, "returnNoteDayLimit"]}
                    name="returnNoteDayLimit"
                    className="m-0"
                    // labelCol={{ span: 10 }}
                    layout="horizontal"
                  >
                    {/* <Checkbox style={{ marginTop: "5px" }} /> */}
                    <InputNumber min={0} controls={false} />
                  </Form.Item>
                </Col>
              )}
              {(select.value === SettingsSections.Invoice ||
                select.value === SettingsSections.PInvoice) && (
                <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                  <Form.Item
                    // label={
                    //   select.value === SettingsSections.Invoice
                    //     ? "Show Despatch Details"
                    //     : "Receipt Note"
                    // }
                    // name={[select.key, "showDispatchDetail"]}
                    name="showDispatchDetail"
                    className="m-0"
                    // labelCol={{ span: 10 }}
                    valuePropName="checked"
                  >
                    <Checkbox>
                      {select.value === SettingsSections.Invoice
                        ? "Show Despatch Details"
                        : "Receipt Note"}
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}
              {(select.value === SettingsSections.Invoice ||
                select.value === SettingsSections.PInvoice ||
                select.value === SettingsSections.RCMBill ||
                select.value === SettingsSections.Quotation ||
                select.value === SettingsSections.POrder ||
                select.value === SettingsSections.SOrder) && (
                <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                  <Form.Item
                    // label="Show Freight Type"
                    // name={[select.key, "showFreightType"]}
                    name="showFreightType"
                    className="m-0"
                    valuePropName="checked"
                    // labelCol={{ span: 10 }}
                  >
                    <Checkbox>Show Freight Type</Checkbox>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Divider className="my-7" />
            {/* Round Off Settings */}
            <Row gutter={10}>
              {(select.value === SettingsSections.Invoice ||
                select.value === SettingsSections.PInvoice ||
                select.value === SettingsSections.ExpenseJV ||
                select.value === SettingsSections.RCMBill ||
                select.value === SettingsSections.Quotation ||
                select.value === SettingsSections.SOrder ||
                (select.value as SettingsSections) ===
                  SettingsSections.POrder) && (
                <>
                  <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                    <Form.Item
                      label="Discount policy"
                      // name={[select.key, "discountPolicy"]}
                      name="discountPolicy"
                      className="m-0"
                      // labelCol={{ span: 10 }}
                      // wrapperCol={{ span: 10 }}
                      layout="horizontal"
                      initialValue={Discount_Policy.NoDiscount}
                    >
                      <Select
                        options={[
                          {
                            value: Discount_Policy.NoDiscount,
                            label: "No Discount",
                          },
                          {
                            value: Discount_Policy.OnlyTradeDiscount,
                            label: "Only trade discount",
                          },
                          {
                            value: Discount_Policy.OnlyCashDiscount,
                            label: "Only cash discount",
                          },
                          {
                            value: Discount_Policy.TradenCashDiscount,
                            label: "Trade & cash discount",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                    <Form.Item
                      label="Item rate type"
                      // name={[select.key, "itemRateType"]}
                      name="itemRateType"
                      className="m-0"
                      // labelCol={{ span: 10 }}
                      // wrapperCol={{ span: 10 }}
                      initialValue={ItemRate_Type.Exclusive}
                      layout="horizontal"
                    >
                      <Select
                        options={[
                          {
                            value: ItemRate_Type.Exclusive,
                            label: "Exclusive",
                          },
                          {
                            value: ItemRate_Type.Inclusive,
                            label: "Inclusive",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}

              {(select.value === SettingsSections.Invoice ||
                select.value === SettingsSections.PInvoice ||
                select.value === SettingsSections.ExpenseJV ||
                select.value === SettingsSections.RCMBill ||
                select.value === SettingsSections.CrNote ||
                select.value === SettingsSections.DrNote ||
                select.value === SettingsSections.Payment ||
                select.value === SettingsSections.Receipt ||
                select.value === SettingsSections.Quotation ||
                select.value === SettingsSections.POrder ||
                select.value === SettingsSections.SOrder) && (
                <>
                  <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                    <Form.Item
                      // label="Show RoundOff"
                      // name={[select.key, "autoRoundOff"]}
                      name="autoRoundOff"
                      className="m-0"
                      // labelCol={{ span: 10 }}
                      valuePropName="checked"
                    >
                      <Checkbox>Show RoundOff</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                    <Form.Item
                      label="RoundOff Type"
                      // name={[select.key, "roundOffType"]}
                      name="roundOffType"
                      className="m-0"
                      // labelCol={{ span: 10 }}
                      // wrapperCol={{ span: 10 }}
                      layout="horizontal"
                      initialValue={RoundOff_Type.Normal}
                    >
                      <Select
                        options={[
                          {
                            value: RoundOff_Type.Normal,
                            label: "Normal",
                          },
                          {
                            value: RoundOff_Type.Ceiling,
                            label: "Ceiling",
                          },
                          {
                            value: RoundOff_Type.Floor,
                            label: "Floor",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <Divider className="my-7" />
            {select.value === SettingsSections.Invoice && (
              <Row style={{ marginTop: "14px" }} gutter={10}>
                {/* <Col span={5}>
                    <p>Invoice copy labelling</p>
                  </Col> */}
                <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                  <Form.Item
                    label="Invoice copy labelling"
                    name={["invoiceCopyLabelling", "duplicateCopyType"]} // wrapperCol={{ span: 6 }}
                    initialValue={DuplicateCopy_Type.Original}
                  >
                    <Select
                      style={{ width: "52%" }}
                      onChange={(val: any) => setDuplicateCopy(val)}
                      options={[
                        {
                          value: DuplicateCopy_Type.Original,
                          label: "Oringinal",
                        },
                        {
                          value: DuplicateCopy_Type.Duplicate,
                          label: "Duplicate",
                        },
                        {
                          value: DuplicateCopy_Type.Triplicate,
                          label: "Triplicate",
                        },
                        {
                          value: DuplicateCopy_Type.Quadruplicate,
                          label: "Quadruplicate",
                        },
                        {
                          value: DuplicateCopy_Type.Quintuplicate,
                          label: "Quintuplicate",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                {duplicateCopy === 0 ||
                duplicateCopy === 1 ||
                duplicateCopy === 2 ||
                duplicateCopy === 3 ||
                duplicateCopy === 4 ? (
                  <>
                    <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                      <span>Copy-1</span>
                      <Form.Item
                        name={[
                          // select.key,
                          "invoiceCopyLabelling",
                          "title1",
                        ]}
                        className="m-0"
                        // labelCol={{ span: 12 }}
                        // wrapperCol={{ span: 20 }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                {duplicateCopy === 1 ||
                duplicateCopy === 2 ||
                duplicateCopy === 3 ||
                duplicateCopy === 4 ? (
                  <>
                    <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                      <span>Copy-2</span>
                      <Form.Item
                        name={[
                          // select.key,
                          "invoiceCopyLabelling",
                          "title2",
                        ]}
                        className="m-0"
                        // labelCol={{ span: 10 }}
                        // wrapperCol={{ span: 20 }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                {duplicateCopy === 2 ||
                duplicateCopy === 3 ||
                duplicateCopy === 4 ? (
                  <>
                    <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                      <span>Copy-3</span>

                      <Form.Item
                        name={[
                          // select.key,
                          "invoiceCopyLabelling",
                          "title3",
                        ]}
                        className="m-0"
                        // labelCol={{ span: 10 }}
                        // wrapperCol={{ span: 20 }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                {duplicateCopy === 3 || duplicateCopy === 4 ? (
                  <>
                    <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                      <span>Copy-4</span>

                      <Form.Item
                        name={[
                          // select.key,
                          "invoiceCopyLabelling",
                          "title4",
                        ]}
                        className="m-0"
                        // labelCol={{ span: 10 }}
                        // wrapperCol={{ span: 20 }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  ""
                )}
                {duplicateCopy === 4 ? (
                  <>
                    <Col xxl={6} xl={6} lg={12} sm={12} xs={24}>
                      <span>Copy-5</span>

                      <Form.Item
                        name={[
                          // select.key,
                          "invoiceCopyLabelling",
                          "title5",
                        ]}
                        className="m-0"
                        // labelCol={{ span: 10 }}
                        // wrapperCol={{ span: 20 }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  ""
                )}
              </Row>
            )}

            <Row justify={"end"} style={{ marginTop: "20px" }}>
              <Col>
                {!props.isDrawer && isEdit ? (
                  <Space
                    style={{
                      position: "sticky",
                      bottom: 0,
                    }}
                  >
                    <Button
                      icon={<CloseOutlined />}
                      onClick={() => {
                        setNote(undefined);
                        setIsEdit(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      // className="ms-40"
                      icon={<SaveOutlined />}
                      loading={saveButtonLoading}
                      onClick={() => {
                        saveSettingCutomization();
                      }}
                    >
                      Save
                    </Button>

                    {/* <Button
                      type="primary"
                      // className="ms-40"
                      icon={<SaveOutlined />}
                      loading={saveButtonLoading}
                      onClick={() => {
                        // if (isEdit) {
                        //   setSaveButtonLoading(true);
                        //   form.validateFields().then((val: any) => {
                        //     updateCompanySettings!(
                        //       select.value!,
                        //       {
                        //         ...companySettings,
                        //         setting: {
                        //           ...companySettings?.setting!,
                        //           [select.key!]: val[select?.key],
                        //         },
                        //       },
                        //       val
                        //     ).then((result: any) => {
                        //       if (result?.status) {
                        //         setIsEdit(false);
                        //         setSaveButtonLoading(false);
                        //         openNotification(
                        //           "success",
                        //           "Setting Updated successfully"
                        //         );
                        //       } else {
                        //         setSaveButtonLoading(false);
                        //         openNotification(
                        //           "error",
                        //           "There is some error."
                        //         );
                        //       }
                        //     });
                        //   });
                        // }
                      }}
                    >
                      Only Save All Number
                    </Button> */}
                  </Space>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </>
        </Form>
      </div>
      {openModel.type === "termsAndConditionsModal" && (
        <TermsAndConditionModal
          open={openModel.param.open}
          onCancel={() => setOpenModel({ type: "", param: { open: false } })}
          setTermsAndConditionField={(x: any) => {
            setTnCTemplateID(x);
            if (props.isDrawer) {
              props.onTemplateId(x?.templateId);
            }
          }}
          termsAndConditionField={tnCTemplateID} // setTermsAndConditionField={setTermsAndConditionField}
          // termsAndConditionField={termsAndConditionField}
          voucherType={
            select?.value === SettingsSections.Invoice
              ? Voucher_Type?.SalesVoucher
              : select?.value === SettingsSections.CrNote
              ? Voucher_Type?.CreditNote
              : select?.value === SettingsSections.DrNote
              ? Voucher_Type?.DebitNote
              : select?.value === SettingsSections.PInvoice
              ? Voucher_Type?.PurchaseVoucher
              : select?.value === SettingsSections.Quotation
              ? Voucher_Type?.SalesVoucher
              : select?.value === SettingsSections.POrder
              ? Voucher_Type?.PurchaseVoucher
              : select?.value === SettingsSections.SOrder
              ? Voucher_Type?.SalesVoucher
              : ""
          }
          voucherTitleCustomization={select.label}
        />
      )}
      {openNotes && (
        <AdditionalNotes
          value={note ? note : notesInitial ? notesInitial : ""}
          open={openNotes}
          save={(a: string) => {
            setOpenNotes(false);
            setNote(a);
          }}
          cancel={() => setOpenNotes(false)}
        />
      )}
      {deleteTempleteComformation && (
        <ConfirmationModal
          open={true}
          onNo={() => setDeleteTempleteComformation(false)}
          onYes={async () => {
            const allCustomization = companySettings?.setting?.vouchersSettings;

            const findCurrentData = allCustomization?.find(
              (x) => x.title === select.label
            );

            allCustomization?.splice(fromData.indexEditData!, 1);

            console.log("allCustomization", allCustomization, findCurrentData);

            updateCompanySettings!(
              select.value!,
              {
                ...companySettings,
                setting: {
                  ...companySettings?.setting!,
                  vouchersSettings: allCustomization!,
                },
              },
              findCurrentData,
              select.label,
              IUpdateCompanySettings.delete
            ).then((result: any) => {
              if (result?.status) {
                setIsEdit(false);
                setAddNewVoucherTypeChechbox(false);
                setSaveButtonLoading(false);
                setSelect({
                  value: SettingsSections.Invoice,
                  label: "TAX INVOICE",
                });
                openNotification(
                  "success",
                  `'{${select.label}}' Deleted successfully.`
                );

                setDeleteTempleteComformation(false);
              } else {
                setSaveButtonLoading(false);
                setDeleteTempleteComformation(false);
                openNotification(
                  "error",
                  result.message ?? "There is some error."
                );
              }
            });
          }}
          text={`Are you sure you want to delete this Voucher Template "${select.label}" ?`}
        />
      )}
    </>
  );
};

export default SettingSecCustomization;
