import { Button, Card, Col, Divider, Modal, Row, Upload } from "antd";
import { SliderMarks } from "antd/es/slider";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ThemeContextProvider from "../Context/ThemeContext";
import ThemeSetting from "./ThemeSetting";
import { Image } from "antd";
import "./Profile.css";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useUser } from "../../Context/UserContext";
import Profiletabs from "./Profiletabs";
import { SiMicrosoftoutlook } from "react-icons/si";
import { BiLink } from "react-icons/bi";
import {
  ExclamationCircleOutlined,
  MailOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ProfileService from "../../Services/ProfileService";
import adminContext from "../../Context/AdminContext";
import modal from "antd/es/modal";
import Title from "antd/es/typography/Title";
import { none } from "@hookstate/core";
interface IChangeProps {
  darkMode?: boolean;
  themeColor: string;
  fontSize: number;
  borderRadius: number;
  componentSize: "small" | "middle" | "large" | undefined;
}
const UserProfile = () => {
  const { getUser } = useUser();
  const loginUser: any = { ...getUser() };
  const { getval, setTrigger } = useContext(adminContext);
  const navigate = useNavigate();
  const [onChange, setOnChange] = React.useState<any>({});
  const [data, setData] = React.useState<any>(onChange);
  const [loading, setLoading] = React.useState<any>(false);
  const [segementVal, setSegementVal] = React.useState<any>("1");

  console.log("Login User Detail: ", getval, loginUser);

  React.useEffect(() => {
    if (localStorage.getItem("ci-config")) {
      let x: any = localStorage.getItem("ci-config");
      console.log("useEffect", x);
      setOnChange(JSON.parse(x));
      setData(JSON.parse(x));
      setLoading(true);
    } else {
      setOnChange({
        darkMode: false,
        themeColor: "#3d4998",
        fontSize: 14,
        borderRadius: 6,
        componentSize: "middle",
        tablePageSize: 15,
        silderSize: true,
      });
      setData({
        darkMode: false,
        themeColor: "#3d4998",
        fontSize: 14,
        borderRadius: 6,
        componentSize: "middle",
        tablePageSize: 15,
        silderSize: true,
      });
      setLoading(true);
    }
  }, []);
  const [true1, setTrue1] = React.useState<boolean>();
  const getOutlook = () => {
    window.location.href = "mailto:email@outlook.com";
  };
  const getMail = () => {
    window.location.href = "mailto:email@gmail.com";
  };

  // Post
  const onImageUpload = (userId: string, file: any) => {
    const config = "multipart/form-data";
    const formData = new FormData();
    formData.append("file", file);
    Modal.confirm({
      title: "Confirmation",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to upload profile",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: async () => {
        await ProfileService.uploadProfilePicture(
          userId,
          formData,
          config
        ).then((res: any) => {
          if (res?.status) {
            setTrigger((x: any) => !x);
            console.log("Success");
          } else {
            console.log("Failed");
          }
        });
      },
    });
  };

  return (
    <>
      {loading && (
        <>
          <Row>
            <Col span={24} style={{}}>
              {/* <Card bordered={false}> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* Profile Image */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                >
                  <Image
                    className="custom"
                    style={{
                      padding: "5px",
                      borderRadius: "50%",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                      border: "3px solid #0082ff",
                    }}
                    src={getval?.profilePhoto?.path}
                    width={150}
                    height={150}
                    preview={true}
                  />
                </div>

                {/* Upload Button */}
                <div>
                  <div style={{ marginLeft: "28px" }}>
                    <Upload
                      maxCount={1}
                      onChange={(e) => onImageUpload(loginUser?.id, e.file)}
                      showUploadList={false}
                      accept=".png,.jpg,.jpeg"
                      beforeUpload={() => false}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        style={{
                          borderRadius: "8px",
                          // padding: "8px 24px",
                        }}
                      >
                        Upload Photo
                      </Button>
                    </Upload>
                  </div>
                </div>
              </div>
              {/* </Card> */}

              {/* <div style={{ marginBottom: "16px", width: "100%" }}>
                  <Profiletabs true1={true1} setTrue1={setTrue1} />
                </div> */}
            </Col>
            {/* mailto:example@outlook.com?subject=Subject&body=Body */}
            {/* <Col xl={18} sm={15} xs={15}>
              <Card style={{ height: "100%" }}>
               
              </Card>
            </Col> */}
          </Row>
          {true1 === true &&
            (localStorage.setItem("ci-config", JSON.stringify(data)),
            navigate(-1))}

          {true1 === false && navigate(-1)}

          {data && <ThemeContextProvider data={data} />}
        </>
      )}
    </>
  );
};

export default UserProfile;
