import React, { useEffect, useRef, useState } from "react";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import { useNavigate, useParams } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import {
  Avatar,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import { useLocation } from "react-router-dom";

import { dayjs } from "../../../Utilities/dayjs";
import {
  CloseOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Utils } from "../../../Utilities/Utils";
import VoucherServices, {
  Party_Tax,
  ReconciledFilter,
  Voucher_Type,
} from "../../Services/VoucherServices";
import noteContext from "../../Context/StyleContext";
import deleteImg from "../../../images/deletebutton.svg";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import { AddLedger } from "../Master/Ledger/AddLedger";
import TextArea from "antd/es/input/TextArea";
import Party from "../Parties/Party";
import { number } from "echarts";
import { LocalChunkSize } from "papaparse";
import {
  PurchaseDropdownItems,
  SalesDropdownItems,
} from "./SalesandPurchaseDropdownList";
import {
  ModifierKey,
  useMultipleKeyboardShortcuts,
} from "../../Common/useMultipleKeyboardShortcuts";
import Icon from "@ant-design/icons/lib/components/Icon";
import EntryLevelKeys from "../../Common/EntryLevelKeys";
import DraftService from "../../Services/DraftService";
import { handleRoundoff } from "./AddSalesInvoice";
import DocumentPreview from "../../Common/DocumentPreview";
import TdsTcsServices, {
  Transaction_Type,
} from "../../Services/TdsTcsServices";
import AddTcsTaxRate from "../TcsTds/Tcs/AddTcsTaxRate";
import AddTdsTaxRate from "../TcsTds/Tds/AddTdsTaxRate";
import { PanCardType } from "../../Services/PurchaseService";
import { percentCal } from "../../Common/CAPComponents";
import settingslogo from "../../../images/settingslogo.png";
import SettingCustomizationIsDrawer from "../Settings/SettingCustomizationIsDrawer";
import { RoundOff_Type, SettingsSections } from "../../Services/SettingService";
import { Footer } from "antd/es/layout/layout";

const AddPaymentInNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef: any = useRef(null);
  const selectRefIndex: any = useRef<number[]>([]);
  const { paymentId, draftId } = useParams();
  const { currentClient } = useClient();
  const { windowWidth } = React.useContext(noteContext);
  const { openNotification } = React.useContext(notificationContext);
  // const [itemList, setItemList] = useState<any[]>([]);
  const { companySettings, updateCompanySettings } = useClient();

  const [form] = Form.useForm();
  const [accountLedgerOption, setAccountLedgerOption] = React.useState<any[]>(
    []
  );
  const [partyLedgerOption, setPartyLedgerOption] = React.useState<any[]>([]);
  const [cashDisAndBadDebtsOption, setCashDisAndBadDebtsOption] =
    React.useState<any[]>([]);
  const [selectedPartyOption, setSelectedPartyOption] = React.useState<any[]>(
    []
  );
  const [roundOffAmount, setRoundoffAmount] = useState<number>(0.0);
  const [refresh, setRefresh] = React?.useState(false);
  const [trigger, setTrigger] = React?.useState(false);
  const [settingTigger, setSettingTigger] = useState(false);

  const [openModel, setOpenModel] = React?.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [itemLength, setItemLength] = React.useState<number>(0);
  const [deleteItemList, setDeleteItemList] = React.useState<{
    name: any;
    index: number;
  }>({
    name: "",
    index: -1,
  });
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [currentBalance, setCurrentBalance] = React.useState<number>(0.0);
  const [accountIsParty, setAccountIsParty] = React.useState<Party_Tax>();
  // Invoice Releted data
  const [partyRef, setPartyRef] = React.useState<any[]>([]);
  // Only party select time Invoice Id
  const [selectPartyRef, setSelectPartyRef] = React.useState<any[]>([]);
  const [editPayment, setEditPayment] = React.useState<{
    balance: number;
    id: string;
  }>({
    balance: 0.0,
    id: "",
  });
  // const [referencesByPartyList, getReferencesByPartyList] = React.useState<
  //   any[]
  // >([]);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [totalPaymentIn, setTotalPaymentIn] = React.useState<number>(0);
  const [disabledAmountInputs, setDisabledAmountInputs] = React.useState(
    Array(0).fill(false)
  );
  const [selectedParty, setSelectedParty] = useState<string>("");
  const [selectedPartyIsSystem, setSelectedPartyIsSystem] =
    useState<boolean>(false);
  const [editDate, setEditDate] = useState<any>();
  const [openLedgerDrawer, setOpenLedgerDrawer] = React.useState<{
    open: boolean;
    id: string;
    ledgerByGroupsType?: SearchLedgerByGroupsTypes;
    defaultGroup?: any;
    showName: string;
  }>({
    open: false,
    id: "",
    ledgerByGroupsType: SearchLedgerByGroupsTypes.All,
    defaultGroup: {},
    showName: "",
  });
  const [allUpdatePartyData, setAllUpdatePartyData] = React.useState<any[]>([]);
  const [dataFiled, setDateFiled] = React.useState<any>();
  const [voucherNumberLoader, setVoucherNumberLoader] = React.useState(false);

  const [salesInstantPayment, setSalesInstantPayment] =
    React.useState<boolean>(false);

  const [bankImportData, setBankImportData] = React.useState<{
    bankImportEntryRef: string;
    isReconciled: ReconciledFilter;
  }>({
    bankImportEntryRef: "",
    isReconciled: ReconciledFilter.nonreconciled,
  });

  const [updateTimeData, setUpdateTimeData] = React.useState<{
    roundOff: boolean;
  }>({
    roundOff: false,
  });
  const [deleteUploadId, setDeleteUploadId] = React.useState<string[]>([]);
  const [previewImage, setPreviewImage] = React.useState<{
    isBaseConvert: boolean;
    path: any;
  }>({ isBaseConvert: false, path: "" });
  // const [tdsOrTcs, setTdsOrTcs] = useState<number>(0);
  // const [totalTcs, setTotalTcs] = useState<number>(0);
  const [totalTds, setTotalTds] = useState<number>(0);
  const [tdsList, setTdsList] = React.useState<any[]>([]);
  // const [tcsList, setTcsList] = React.useState<any[]>([]);
  const [tdsListTrigger, setTdsListTrigger] = React.useState<boolean>(false);
  // const [tcsListTrigger, setTcsListTrigger] = React.useState<boolean>(false);
  const [tcsTdsPercentage, setTcsTdsPercentage] = React.useState<number>(0.0);
  const [tdsDetail, setTdsDetail] = React.useState<{
    formNo: string;
    transactionType: Transaction_Type;
  }>({ formNo: "", transactionType: Transaction_Type.Domestic });
  const [partyPanCard, setPartyPanCard] = React.useState<PanCardType>(
    PanCardType.notAvailable
  );

  const [accDropDownLoading, setAccDropDownLoading] = React.useState(false);
  const [nameDropDownLoading, setNameDropDownLoading] = React.useState(false);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  let splitPathInVchType: Voucher_Type;
  let splitPathLedgerByGroupsType: SearchLedgerByGroupsTypes;
  let splitPathCashDisAndBadDebts: SearchLedgerByGroupsTypes;
  if (location.pathname.split("/")[4] === "sales") {
    splitPathInVchType = Voucher_Type.Receipt;
    splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.PaymentIn;
    splitPathCashDisAndBadDebts =
      SearchLedgerByGroupsTypes.FullAmountRecLedgers;
  } else {
    splitPathInVchType = Voucher_Type.Payment;
    splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.PaymentOut;
    splitPathCashDisAndBadDebts =
      SearchLedgerByGroupsTypes.FullAmountPayLedgers;
  }

  const getVoucherTitle =
    splitPathInVchType === Voucher_Type.Receipt
      ? companySettings?.setting?.vouchersSettings.find(
          (x: any) => x?.title === "RECEIPTS"
        )
      : companySettings?.setting?.vouchersSettings.find(
          (x: any) => x?.title === "PAYMENTS"
        );

  const [settingControl, setSettingControl] = React.useState<{
    title: string;
    roundOffType: RoundOff_Type;
    autoRoundOff: boolean;
  }>({
    title: getVoucherTitle?.settings?.basicSettings?.title,
    roundOffType: getVoucherTitle?.settings?.basicSettings?.roundOffType,
    autoRoundOff: getVoucherTitle?.settings?.basicSettings?.autoRoundOff,
  });

  useEffect(() => {
    setSettingControl({
      title: getVoucherTitle?.settings?.basicSettings?.title,
      roundOffType: getVoucherTitle?.settings?.basicSettings?.roundOffType,
      autoRoundOff: getVoucherTitle?.settings?.basicSettings?.autoRoundOff,
    });
  }, [settingTigger]);

  // console.log("location", partyRef, selectPartyRef);

  React.useEffect(() => {
    console.log("location.state?.ledgerId", location.state?.ledgerId);
    if (
      location?.state?.BankTransactionData &&
      accountLedgerOption?.length > 0
    ) {
      // console.log("oooooo", location?.state?.partyOrTax);
      const findBankAccountDetails = accountLedgerOption?.find(
        (x) => x.id === location.state?.ledgerId
      );
      const bankTransactionAmount =
        location?.state?.BankTransactionData?.moneyIn ||
        location?.state?.BankTransactionData?.moneyOut;
      // console.log("findBankAccountDetails", findBankAccountDetails);

      setAccountIsParty(findBankAccountDetails?.partyOrTax);
      setCurrentBalance(findBankAccountDetails.balance);
      setTotalPaymentIn(bankTransactionAmount);
      // const dateImportTransactionD = dayjs(
      //   location?.state?.BankTransactionData?.date,
      //   "DD/MM/YYYY"
      // );
      form?.setFieldsValue({
        payRecReference: location?.state?.BankTransactionData?.reference,
        // date: dateImportTransactionD,
        bankEntryAmount:
          location?.state?.BankTransactionData?.moneyOut ||
          location?.state?.BankTransactionData?.moneyIn,
        effectiveLedger: {
          value: location.state?.ledgerId,
        },
        partyLedger: [
          {
            amount:
              location?.state?.BankTransactionData?.moneyOut ||
              location?.state?.BankTransactionData?.moneyIn,
            // other fields if needed
          },
        ],

        ref: [
          {
            value:
              location?.state?.BankTransactionData?.moneyOut ||
              location?.state?.BankTransactionData?.moneyIn,
            // other fields if needed
          },
        ],
      });
      // setDateFiled(dateImportTransactionD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountLedgerOption]);

  // Date Api
  React.useEffect(() => {
    if (!location.state?.ledgerId) {
      const getDate = async () => {
        if (currentClient?.id && !paymentId) {
          await VoucherServices.getMaxVoucherDate(
            currentClient?.id!,
            splitPathInVchType
          ).then((res) => {
            const givenDate = new Date(res?.result);
            const currentTime = new Date();

            // Set the time part of givenDate to match currentTime
            givenDate?.setHours(
              currentTime.getHours(),
              currentTime.getMinutes(),
              currentTime.getSeconds(),
              currentTime.getMilliseconds()
            );
            form.setFieldValue("date", dayjs(givenDate));

            setDateFiled(res.result);
          });
        }
      };
      getDate();
    } else if (location.state?.ledgerId) {
      const givenDate = new Date(
        dayjs(
          location?.state?.BankTransactionData?.date,
          "DD/MM/YYYY"
        ).toISOString()
      );
      const currentTime = new Date();

      // Set the time part of givenDate to match currentTime
      givenDate?.setHours(
        currentTime.getHours(),
        currentTime.getMinutes(),
        currentTime.getSeconds(),
        currentTime.getMilliseconds()
      );
      form.setFieldValue("date", dayjs(givenDate));

      setDateFiled(givenDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  const setEffectiveLedger = (voucherType: Voucher_Type) => {
    const ledger = companySettings?.setting?.defaultLedgers.find(
      (x) => x.voucherType === voucherType
    );
    if (ledger) {
      form.setFieldValue("effectiveLedger", {
        value: ledger.defaultLedgerId,
      });
    }
  };

  // ----- initial value -----
  React.useEffect(() => {
    if (currentClient?.id && !paymentId && dataFiled) {
      setLoading(true);
      setVoucherNumberLoader(true);
      VoucherServices.getVoucherMaxNo(
        currentClient?.id!,
        splitPathInVchType,
        dayjs(dataFiled).endOf("day").toISOString(),
        getVoucherTitle?.title!
      )
        .then((res) => {
          form.setFieldValue("number", res?.result);
          setVoucherNumberLoader(false);
        })
        .catch((err: any) => {
          openNotification("error", err.message);
          console.error(err.message);
          setVoucherNumberLoader(false);
        });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, dataFiled, splitPathInVchType, settingTigger]);

  // Cash & bank account select
  React.useEffect(() => {
    const cashBankLedger = async () => {
      setAccDropDownLoading(true);
      inputRef?.current?.focus();
      await LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes.BankAndCash
      )
        .then((res: any) => {
          if (res) {
            setAccountLedgerOption(res);
            // user select default ladger in settings (value set in Current Balance)

            const setEffectiveLedger = (voucherTitle: string) => {
              const ledger = companySettings?.setting?.defaultLedgers?.find(
                (x) => x.voucherTitle === voucherTitle
              );

              // x?.voucherTitle === voucherTitle;

              if (ledger) {
                form.setFieldValue("effectiveLedger", {
                  value: ledger.defaultLedgerId,
                });
                return ledger;
              }
              return null;
            };

            const handleLedgerDetails = (voucherType: Voucher_Type) => {
              const ledger = setEffectiveLedger(getVoucherTitle?.title!);
              console.log("ledger", ledger);

              if (ledger) {
                const selectAccount = res.find(
                  (x: any) => x.id === ledger.defaultLedgerId
                );
                if (selectAccount) {
                  setCurrentBalance(selectAccount.balance);
                  setAccountIsParty(selectAccount.partyOrTax);
                  setSelectedParty(ledger.defaultLedgerId);
                  setSelectedPartyIsSystem(selectAccount?.system);
                  setOpenLedgerDrawer({
                    ...openLedgerDrawer,
                    id: ledger.defaultLedgerId,
                  });
                }
              }
            };

            if (!paymentId && !location.state?.ledgerId) {
              // debugger;
              handleLedgerDetails(splitPathInVchType);
            }
          } else {
            console.error("Error", res.message);
          }
          setAccDropDownLoading(false);
        })
        .catch((ex) => {
          console.error("Error", ex);
        });
    };
    cashBankLedger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // party & other payment select
  React.useEffect(() => {
    const partyLedger = async () => {
      setNameDropDownLoading(true);
      await LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        splitPathLedgerByGroupsType
      ).then((res) => {
        if (res) {
          setPartyLedgerOption(res);
          setNameDropDownLoading(false);
        } else {
          setNameDropDownLoading(false);
        }
      });
    };
    partyLedger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // CashInHandDisount And BadDebtsAmount Ledger
  React.useEffect(() => {
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      splitPathCashDisAndBadDebts
    ).then((res) => {
      if (res) {
        setCashDisAndBadDebtsOption(res);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Post payment api
  const save = (isSaveAndNew?: boolean, isDraft?: boolean) => {
    const tempAmount = handleAllLedgerValue();
    form.validateFields().then(async (val: any) => {
      console.log("post", val);
      setSaveButtonLoading(true);
      // console.log("val.date", val.date, "givenDate");
      let givenDate;
      if (paymentId && dayjs(val.date).isSame(dayjs(editDate), "day")) {
        givenDate = editDate;
      } else {
        givenDate = new Date(val.date);
        const currentTime = new Date();

        // Set the time part of givenDate to match currentTime
        givenDate?.setHours(
          currentTime.getHours(),
          currentTime.getMinutes(),
          currentTime.getSeconds(),
          currentTime.getMilliseconds()
        );
      }

      const tdsRatePredentage =
        partyPanCard === PanCardType.HUF
          ? val?.tdsDetail?.title?.rates?.indHUFRate ?? val?.tdsDetail?.title
          : partyPanCard === PanCardType.Other
          ? val?.tdsDetail?.title?.rates?.otherRate ?? val?.tdsDetail?.title
          : val?.tdsDetail?.title?.rates?.ifPANNotRate ?? val?.tdsDetail?.title;

      const data = {
        ...val,
        tdsApplicable: totalTds ? true : false,
        tdsDetail: {
          id: val?.tdsDetail?.value,
          name: val?.tdsDetail?.label,
          rate: tdsRatePredentage,
          value: totalTds,
          transactionType: tdsDetail.transactionType,
          form: tdsDetail.formNo,
        },
        amount: tempAmount + roundOffAmount,
        amountSummary: {
          roundOff: {
            roundOffApplicable: val?.roundoffCheckbox,
            roundOffAmount: roundOffAmount,
          },
          totalInvoiceAmount: tempAmount + roundOffAmount,
          tDSRate: tdsRatePredentage,
          tDSAmt: totalTds,
        },
        draftId: draftId,
        roundOffAmount: roundOffAmount,
        effectiveLedger: [
          {
            id: val?.effectiveLedger.value,
            amount: tempAmount + roundOffAmount,
            partyOrTax: accountIsParty,
          },
        ],
        date: dayjs(givenDate).toISOString(),
        instantPayment: salesInstantPayment,
        isLocked: false,
        isReconciled: location.state?.ledgerId
          ? ReconciledFilter.reconciled
          : bankImportData.isReconciled,
        isAudited: false,
        isActive: true,
        bankImportEntryRef: location.state?.ledgerId
          ? location.state?.bankEntriesId
          : bankImportData.bankImportEntryRef,
        partyLedger: val.partyLedger.map((val: any) => {
          return {
            ...val,
            id: val?.id?.value,
            partyOrTax: val?.id?.title,
            ref:
              val?.ref?.length > 0
                ? val?.ref.map((val: any, index: number) => {
                    // console.log("post", val);
                    if (
                      index === 0 &&
                      splitPathInVchType === Voucher_Type.Payment
                    ) {
                      return {
                        ...val,
                        value: val.value, //- totalTds,
                        totalAmount: Utils.getNumber(
                          val?.value + val?.dueAmount
                        ),
                        name: val?.name?.label ?? val?.name,
                        partyRef: val?.name?.value,
                      };
                    } else
                      return {
                        ...val,
                        totalAmount: Utils.getNumber(
                          val?.value + val?.dueAmount
                        ),
                        name: val?.name?.label ?? val?.name,
                        partyRef: val?.name?.value,
                      };
                  })
                : null,
          };
        }),
        deleteUploadId: deleteUploadId,
        // freightInsLedgers: val.partyLedger
        //   .filter((x: any) => x?.id?.title === 0)
        //   .map((val: any) => {
        //     return {
        //       ...val,
        //       id: val?.id?.value,
        //       partyOrTax: val?.id?.title,
        //       ref: val?.ref?.length > 0 ? val?.ref : null,
        //     };
        //   }),
        // statutoryLedgers: val.partyLedger
        //   .filter((x: any) => x?.id?.title === 2)
        //   .map((val: any) => {
        //     return {
        //       ...val,
        //       id: val?.id?.value,
        //       partyOrTax: val?.id?.title,
        //       ref: val?.ref?.length > 0 ? val?.ref : null,
        //     };
        //   }),
      };

      console.log("post data", data);

      const res = isDraft
        ? await DraftService.postVoucherDraft(
            currentClient?.id!,
            splitPathInVchType,
            data,
            splitPathInVchType === Voucher_Type.Receipt
              ? "RECEIPTS"
              : "PAYMENTS",
            draftId
          )
        : await VoucherServices.postVoucher(
            currentClient?.id!,
            splitPathInVchType,
            data,
            splitPathInVchType === Voucher_Type.Receipt
              ? "RECEIPTS"
              : "PAYMENTS",
            paymentId
          );
      if (res?.status) {
        if (isDraft) {
          openNotification(
            "success",
            `Payment draft ${res.result?.name} ${
              draftId ? "updated successfully." : "added successfully."
            }`
          );
        } else {
          openNotification(
            "success",
            `Payment  ${res.result?.name} ${
              paymentId ? "updated successfully." : "added successfully."
            }`
          );
          if (location.state?.ledgerId) {
            navigate(
              `/accounts/clients/${currentClient?.id}/banking/bankTransactionsDetails/${location.state?.bankEntriesId}`,
              {
                state: {
                  ledgerId: location.state?.ledgerId,
                  bankImportIndex: location.state?.bankImportIndex,
                  voucherRef: [
                    {
                      id: res.result?.id,
                      name: res.result?.name,
                      voucherType: res.result?.voucherType,
                    },
                  ],
                },
              }
            );
          } else navigate(-1);
        }
        // splitPathInVchType === Voucher_Type.Receipt
        //   ? navigate(
        //       `/accounts/clients/${currentClient?.id}/sales/receipts`
        //     )
        //   : navigate(
        //       `/accounts/clients/${currentClient?.id}/purchase/PaymentOut`
        //     );
        console.log("post........", location);
      } else if (res?.message) {
        openNotification("error", res?.message);
        setSaveButtonLoading(false);
      }
    });
  };

  EntryLevelKeys({
    save: () => save(),
    cancel: () => navigate(-1),
  });

  const onClickSelectPartyLedger = (value: string, index: number) => {
    // console.log("x", value, index);
    setSelectedPartyOption((prevState) => {
      const updatedSelectedOptions = [...prevState];
      updatedSelectedOptions[index] = value;
      // console.log(updatedSelectedOptions);
      return updatedSelectedOptions;
    });

    setTimeout(() => {
      const lastInputField = selectRefIndex?.current[index];
      if (lastInputField) {
        lastInputField.current.select();
      }
    }, 0);
  };

  const partySelect: any = async (
    partyId: string,
    index: number,
    title?: Party_Tax
  ) => {
    // if (!paymentId) {
    setSelectPartyRef((prevDataArray) => {
      const newArray = [...prevDataArray];
      if (index < 0 || index >= newArray.length) {
        newArray.push([]);
      } else {
        newArray.splice(index, 1, []);
      }
      return newArray;
    });
    // }
    // user select party
    if (title === Party_Tax.party) {
      // update case if user delete reference & again add same reference
      if (paymentId) {
        let onePartyUpdateData = allUpdatePartyData.find(
          (x) => x.id.value === partyId
        );
        console.log("onePartyUpdateData", onePartyUpdateData);

        if (onePartyUpdateData) {
          form.setFieldValue(["partyLedger", index], {
            ...onePartyUpdateData,
          });

          setSelectPartyRef((prevState: any[]) => {
            const updatedSelectedOptions: any[] = [...prevState];
            updatedSelectedOptions[index] = [...(prevState[index] || [])];
            updatedSelectedOptions[index] = onePartyUpdateData?.ref?.map(
              (x: any) => x?.parentRef
            );
            return updatedSelectedOptions;
          });

          setPartyRef((prevDataArray) => {
            const newArray = [...prevDataArray];
            newArray[index] = onePartyUpdateData?.ref?.map((x: any) => {
              return {
                ...x,
                maxAmount: x?.dueAmount + x?.value,
              };
            });
            return newArray;
          });
        }

        console.log("allUpdatePartyData", onePartyUpdateData);
      }

      const party = partyLedgerOption.find((x: any) => partyId === x.id);
      console.log("party", party);

      let PanType: PanCardType;
      if (party.pan) {
        if (
          party.pan?.split("")[3]?.toUpperCase() === "H" ||
          party.pan?.split("")[3]?.toUpperCase() === "P"
        ) {
          PanType = PanCardType.HUF;
        } else {
          PanType = PanCardType.Other;
        }
      } else {
        PanType = PanCardType.notAvailable;
      }
      setPartyPanCard(PanType);

      if (form.getFieldValue("tdsDetail")?.value) {
        totalTDSAmount(PanType);
      }

      await VoucherServices.getReferencesByParty(
        currentClient?.id!,
        partyId,
        splitPathInVchType
      ).then((res: any) => {
        console.log("result", res.result?.length, index);
        if (res.result?.length > 0) {
          const data = res.result?.map((v: any, i: number) => {
            return {
              name: res.result[i]?.refNo,
              maxAmount: res.result[i]?.dueAmount,
              dueAmount: res.result[i]?.dueAmount,
              // id: res.result[i]?.id,
              parentRef: res.result[i]?.id,
              id: null,
              refType: 1,
            };
          });
          // yadi user ne new REf me 0 amount dala to update me new nahi aahe ga get api se but meko 0 index pe amount aa jahe to
          // update case user party delete & again select delete party
          if (
            paymentId &&
            allUpdatePartyData.find((x) => x.id.value === partyId)
          ) {
            setPartyRef((prevDataArray) => {
              const prevArray = prevDataArray[index];
              // Filter  the common elements based on 'parentRefId'
              const filteredData = data.filter((newItem: any) => {
                return !prevArray.some(
                  (prevItem: any) => prevItem?.parentRef === newItem?.parentRef
                );
              });
              const updatedArray = [...prevArray, ...filteredData];
              console.log("newArray", updatedArray);
              const newArray = [...prevDataArray];
              newArray[index] = updatedArray;
              return newArray;
            });
          } else {
            // debugger;
            form.setFieldValue(["partyLedger", index, "ref"], [{}]);

            form.setFieldValue(["partyLedger", index, "ref", 0], {
              name: form.getFieldValue(["number"]),
              value:
                location.state?.ledgerId && index === 0
                  ? form.getFieldValue(["partyLedger", 0, "amount"])
                  : 0,
              amount: 0,
              refType: 0,
            });

            form.setFieldValue(["partyLedger", index, "ref", 1], {});

            setPartyRef((prevDataArray) => {
              const newArray = [...prevDataArray];
              newArray[index] = data;
              return newArray;
            });
          }
        } else {
          // debugger;
          form.setFieldValue(["partyLedger", index, "ref"], [{}]);
          form.setFieldValue(["partyLedger", index, "ref", 0], {
            name: form.getFieldValue(["number"]),
            value:
              location.state?.ledgerId && index === 0
                ? form.getFieldValue(["partyLedger", 0, "amount"])
                : 0,
            amount: 0,
            refType: 0,
          });
          setPartyRef((prevDataArray) => {
            const newArray = [...prevDataArray];
            newArray[index] = [];
            return newArray;
          });
        }
      });
    } else {
      setPartyRef((prevDataArray) => {
        const newArray = [...prevDataArray];
        newArray[index] = [];
        return newArray;
      });
    }

    setDisabledAmountInputs((prev) => {
      const newDisabledInputs = [...prev];
      newDisabledInputs[index] = title === Party_Tax.party ? true : false;
      return newDisabledInputs;
    });
    // }
    // handleLedgerNetAmount(index);
  };

  //  -------- Edit payment Get API ---------
  React.useEffect(() => {
    if ((paymentId || draftId) && accountLedgerOption?.length > 0) {
      const editData = async (paymentId: string) => {
        setLoading(true);
        try {
          const res = draftId
            ? await DraftService.getByDraftId(currentClient?.id!, draftId)
            : await VoucherServices.getVoucharById(
                currentClient?.id!,
                paymentId,
                splitPathInVchType
              );
          // debugger;
          if (res.result) {
            console.log(
              "result.............................",
              res.result?.instantPayment,
              accountLedgerOption
            );
            setUpdateTimeData({
              roundOff:
                res.result?.amountSummary?.roundOff?.roundOffApplicable ||
                res.result?.amountSummary?.roundOff?.roundOffAmount !== 0,
            });
            setSalesInstantPayment(res.result?.instantPayment ?? false);
            setTotalPaymentIn(
              Math.abs(
                res.result?.amountSummary?.totalInvoiceAmount -
                  res.result?.amountSummary?.roundOff?.roundOffAmount
              )
            );
            setEditPayment({
              balance: res.result?.amountSummary?.totalInvoiceAmount,
              id: res.result?.effectiveLedger[0]?.id,
            });
            setBankImportData({
              bankImportEntryRef: res.result?.bankImportEntryRef,
              isReconciled: res.result?.isReconciled,
            });

            const partyLedger = await Promise.all(
              res.result?.partyLedger?.map(
                async (v: any, partyIndex: number) => {
                  // console.log("partyIndex", partyIndex);

                  // setCurrentBalance(
                  //   accountLedgerOption?.find(
                  //     (x) => x.id === res.result?.effectiveLedger[0]?.id
                  //   )?.balance
                  // );

                  // party ref set only
                  if (v?.partyOrTax === Party_Tax.party) {
                    setSelectPartyRef((prevState: any[]) => {
                      const updatedSelectedOptions: any[] = [...prevState];
                      updatedSelectedOptions[partyIndex] = [
                        ...(prevState[partyIndex] || []),
                      ];
                      const pushNewElement = [null, ...v.ref]; // if refType not specified 0 (like new Ref type not avaliable)
                      updatedSelectedOptions[partyIndex] =
                        v?.ref[0]?.refType === 0
                          ? v?.ref?.map((x: any) => x.parentRef)
                          : pushNewElement?.map((x: any) => x?.parentRef);
                      return updatedSelectedOptions;
                    });

                    //  ref:
                    //   v?.ref[0]?.refType === 0
                    //     ? [...v?.ref, {}]
                    //     : [
                    //         { refType: 0, name: res?.result?.number },
                    //         ...v?.ref,
                    //         {},
                    //       ],

                    setPartyRef((prevDataArray) => {
                      const newArray = [...prevDataArray];
                      // if (v.ref[0]?.refType !== 0) {
                      //   newArray[partyIndex] = {
                      //     id: {
                      //       label: res?.result?.number,
                      //     },
                      //     maxAmount: Infinity,
                      //   };
                      // }
                      // // Separate handling for the first index
                      // else if (v.ref[0]?.refType === 0) {
                      //   newArray[partyIndex] = {
                      //     ...newArray[partyIndex],
                      //     maxAmount: v.ref[0]?.dueAmount + v.ref[0]?.value,
                      //   };
                      // }

                      newArray[partyIndex] = v?.ref?.map(
                        (x: any, refIndex: Number) => {
                          return {
                            ...x,
                            maxAmount: x?.value,
                          };
                        }
                      );
                      return newArray;
                    });
                    // party New Ref.
                    await VoucherServices.getReferencesByParty(
                      currentClient?.id!,
                      v?.id,
                      splitPathInVchType
                    ).then((res) => {
                      const data = res.result?.map((x: any, i: number) => {
                        return {
                          name: res.result[i]?.refNo,
                          maxAmount: res.result[i]?.value,
                          dueAmount: res.result[i]?.dueAmount,
                          // id: res.result[i]?.id,
                          parentRef: res.result[i]?.id,
                          id: null,
                          refType: 1,
                        };
                      });

                      setPartyRef((prevDataArray) => {
                        const prevArray = prevDataArray[partyIndex];
                        // Filter  the common elements based on 'id'
                        const filteredData = data?.filter((newItem: any) => {
                          return !prevArray?.some(
                            (prevItem: any) =>
                              prevItem?.parentRef === newItem?.parentRef
                          );
                        });
                        const updatedArray = [...prevArray, ...filteredData];
                        // console.log("newArray", updatedArray);
                        const newArray = [...prevDataArray];
                        newArray[partyIndex] = updatedArray;
                        return newArray;
                      });
                    });
                  } else {
                    setPartyRef((prevDataArray) => {
                      const newArray = [...prevDataArray];
                      newArray[partyIndex] = [];
                      return newArray;
                    });
                    setSelectPartyRef((prevState: any[]) => {
                      const updatedSelectedOptions: any[] = [...prevState];
                      updatedSelectedOptions[partyIndex] = [];
                      return updatedSelectedOptions;
                    });
                  }

                  setDisabledAmountInputs((prev) => {
                    const newDisabledInputs = [...prev];
                    newDisabledInputs[partyIndex] =
                      v.partyOrTax === Party_Tax.party ? true : false;
                    // console.log(newDisabledInputs);
                    return newDisabledInputs;
                  });
                  setSelectedPartyOption((prevState) => {
                    const updatedSelectedOptions = [...prevState];
                    updatedSelectedOptions[partyIndex] = v?.id;
                    return updatedSelectedOptions;
                  });

                  // send filed  instantPayment: true, update
                  return {
                    ...v,
                    ref:
                      v?.ref[0]?.refType === 0
                        ? [
                            ...v?.ref?.map((x: any) => {
                              return {
                                ...x,
                                maxAmount: Utils.getNumber(x?.value),
                                // value: x?.value,
                                // dueAmount: x?.dueAmount,
                              };
                            }),
                            {},
                          ]
                        : [
                            { refType: 0, name: res?.result?.number },
                            ...v?.ref?.map((x: any) => {
                              return {
                                ...x,
                                maxAmount: Utils.getNumber(x?.value),
                                // value: x?.value,
                                // dueAmount: x?.dueAmount,
                              };
                            }),
                            {},
                          ],
                    amount: Math.abs(v?.amount),
                    id: {
                      value: v?.id,
                      label: v?.ledgerName,
                      title: v?.partyOrTax,
                    },
                  };
                }
              )
            );

            setAccountIsParty(res.result?.effectiveLedger[0]?.partyOrTax);
            setAllUpdatePartyData([...partyLedger]);
            setRoundoffAmount(
              res.result?.amountSummary?.roundOff?.roundOffAmount ?? 0
            );
            setTotalTds(res.result?.amountSummary?.tdsAmt);
            setTcsTdsPercentage(res.result?.tdsDetail?.rate);
            res.result?.tdsApplicable &&
              setTdsDetail({
                formNo: res.result?.tcsDetail?.form,
                transactionType: res.result?.tcsDetail?.transactionType,
              });

            form.setFieldsValue({
              ...res.result,
              tdsDetail: {
                value: res.result?.tdsDetail?.Id,
                label: res.result?.tdsDetail?.name,
                title: res.result?.tdsDetail?.rate,
              },
              roundOff: res.result?.amountSummary?.roundOff?.roundOffAmount,
              roundoffCheckbox:
                res.result?.amountSummary?.roundOff?.roundOffApplicable,
              // roundoffCheckbox: res.result?.amountSummary?.roundOff?.roundOffAmount !== 0,
              effectiveLedger: {
                value: res.result?.effectiveLedger[0]?.id,
                label: res.result?.effectiveLedger[0]?.ledgerName,
              },
              partyLedger: [...partyLedger, { amount: 0 }],
              date: dayjs(res.result?.date),
            });
            setEditDate(res.result?.date);
            setSelectedParty(res.result?.effectiveLedger[0]?.id);
            const bankEffectiveLedger = accountLedgerOption?.find(
              (x) => x.id === res.result?.effectiveLedger[0]?.id
            );
            setCurrentBalance(bankEffectiveLedger?.balance);
            setSelectedPartyIsSystem(bankEffectiveLedger?.system);
            setLoading(false);
          }
        } catch (err: any) {
          openNotification("error", err.message);
          console.error("error..", err);
          setLoading(false);
        }
      };

      editData(paymentId!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId, accountLedgerOption]);

  const handleLedgerNetAmount = (index: number) => {
    // console.log(
    //   "false.............",
    //   form.getFieldValue(["partyLedger", index])?.id?.title
    // );
    let partyLedgerlist = form.getFieldValue(["partyLedger"]);
    const currentPartyLedgerIndexData = partyLedgerlist[index];

    // console.log("currentPartyLedgerIndexData", currentPartyLedgerIndexData);

    form.setFieldValue(["partyLedger", index], {
      ...currentPartyLedgerIndexData,
      amount: currentPartyLedgerIndexData?.ref.reduce(
        (acc: number, curr: any) => {
          return (acc += Utils.getNumber(curr?.value));
        },
        0
      ),
    });

    handleAllLedgerValue();

    return 0;
  };

  const handleAllLedgerValue = () => {
    let partyLedgerlist = form.getFieldValue(["partyLedger"]);
    // console.log("partyLedgerlist", partyLedgerlist);

    const totalPaymentIn = partyLedgerlist?.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.amount));
    }, 0);

    const roundOffAmount = handleRoundoff(
      totalPaymentIn,
      form.getFieldValue("roundoffCheckbox"),
      settingControl.roundOffType
    );

    form.setFieldValue("roundOff", roundOffAmount);
    setRoundoffAmount(roundOffAmount);
    // console.log("ccccccccccccccc.c.c.c.c.", x);

    setTotalPaymentIn(totalPaymentIn);
    totalTDSAmount(partyPanCard, totalPaymentIn);
    return totalPaymentIn;
  };

  const onChangeSetPartyRef = async (
    partyIndex: number,
    refIndex: number,
    partyRefId: string
  ) => {
    console.log("ref.....onChangeSetPartyRef.", selectPartyRef);
    const x = partyRef[partyIndex].find((x: any) => x.parentRef === partyRefId);
    console.log("data........", x);

    if (paymentId) {
      form.setFieldValue(["partyLedger", partyIndex, "ref", refIndex], {
        ...x,
        maxAmount: x.maxAmount,
        value: x.value ?? 0.0,
        dueAmount: x?.dueAmount,
      });
    } else {
      const partyIndexAmount = Utils.getNumber(
        form.getFieldValue(["partyLedger", partyIndex, "amount"])
      );
      const remainingRefAmount = Utils.getNumber(
        form
          .getFieldValue(["partyLedger", partyIndex, "ref"])
          .slice(1)
          .filter((_: any, idx: number) => idx !== refIndex)
          .reduce((acc: number, curr: any) => {
            return (acc += Utils.getNumber(curr?.value));
          }, 0)
      );
      let dueAmount;
      if (partyIndexAmount - (remainingRefAmount + x.dueAmount) < 0) {
        dueAmount = partyIndexAmount - remainingRefAmount;
      } else {
        dueAmount = x.dueAmount;
      }
      form.setFieldValue(["partyLedger", partyIndex, "ref", refIndex], {
        ...x,
        value: dueAmount,
      });
    }

    setSelectPartyRef((prevState: any[]) => {
      const updatedSelectedOptions: any[] = [...prevState];
      // console.log("setSelectPartyRef.....", updatedSelectedOptions);
      updatedSelectedOptions[partyIndex] = [...(prevState[partyIndex] || [])];
      updatedSelectedOptions[partyIndex][refIndex] = partyRefId;
      return updatedSelectedOptions;
    });

    //  setSelectedPartyOption((prevState) => {
    //    const updatedSelectedOptions = [...prevState];
    //    updatedSelectedOptions[i] = v?.id;
    //    return updatedSelectedOptions;
    //  });

    onChangeRefAmountSet(x.dueAmount, partyIndex, false);
  };

  const onChangeRefAmountSet = (
    value: string,
    partyIndex: number,
    isParty: boolean,
    refIndex?: number
  ) => {
    if (refIndex) {
      setSelectPartyRef((prevDataArray) => {
        const newArray = [...prevDataArray];
        newArray[partyIndex] = [...(prevDataArray[partyIndex] || [])];
        newArray[partyIndex].splice(refIndex, 1);
        return newArray;
      });
    }
    // console.log("ref.........., selectPartyRef", value,partyIndex,isParty);

    if (isParty) {
      disabledAmountInputs[partyIndex] &&
        form.setFieldValue(
          ["partyLedger", partyIndex, "ref", 0, "value"],
          Utils.getNumber(value) -
            Utils.getNumber(
              form
                .getFieldValue(["partyLedger", partyIndex, "ref"])
                .slice(1)
                .reduce((acc: number, curr: any) => {
                  return (acc = acc + Utils.getNumber(curr?.value));
                  //  +
                  // Utils.getNumber(curr?.fullAmountLedgerAmount)
                }, 0)
            )
        );
    } else {
      disabledAmountInputs[partyIndex] &&
        form.setFieldValue(
          ["partyLedger", partyIndex, "ref", 0, "value"],
          Utils.getNumber(
            form.getFieldValue(["partyLedger", partyIndex, "amount"])
          ) -
            Utils.getNumber(
              form
                .getFieldValue(["partyLedger", partyIndex, "ref"])
                .slice(1)
                .reduce((acc: number, curr: any) => {
                  return (acc = acc + Utils.getNumber(curr?.value));
                  // +
                  // Utils.getNumber(curr?.fullAmountLedgerAmount)
                }, 0)
            )
        );
    }
    // disabledAmountInputs[index1] &&
    //   form.setFieldValue(
    //     ["partyLedger", index1, "ref", 0, "value"],
    //     Utils.getNumber(form.getFieldValue(["partyLedger", index1, "amount"])) -
    //       Utils.getNumber(
    //         form
    //           .getFieldValue(["partyLedger", index1, "ref"])
    //           .slice(1)
    //           .reduce((acc: number, curr: any) => {
    //             return (acc += Utils.getNumber(curr?.value));
    //           }, 0)
    //       )
    //   );
  };

  const cashInHandDisAndBadDebtsAmountSet = (
    partyIndex: number,
    index: number,
    isFullAomunt?: boolean
  ) => {
    // console.log(
    //   "cashInHandDisAndBadDebtsAmountSet",
    //   form.getFieldValue(["partyLedger", partyIndex, "ref", index, "fullAmount"]),
    //   isFullAomunt
    // );

    console.log(
      "partyRef",
      // partyRef[partyIndex][index - 1]?.fullAmountLedgerAmount,
      paymentId
        ? Utils.getNumber(
            form.getFieldValue([
              "partyLedger",
              partyIndex,
              "ref",
              index,
              "dueAmount",
            ])
          ) +
            Utils.getNumber(
              form.getFieldValue([
                "partyLedger",
                partyIndex,
                "ref",
                index,
                "maxAmount",
              ]) ?? 0
            ) +
            Utils.getNumber(
              allUpdatePartyData[partyIndex]?.ref[index]
                ?.fullAmountLedgerAmount ?? 0
            ) -
            Utils.getNumber(
              form.getFieldValue([
                "partyLedger",
                partyIndex,
                "ref",
                index,
                "value",
              ])
            )
        : Utils.getNumber(
            form.getFieldValue([
              "partyLedger",
              partyIndex,
              "ref",
              index,
              "dueAmount",
            ])
          ),
      Utils.getNumber(
        form.getFieldValue(["partyLedger", partyIndex, "ref", index, "value"])
      )
    );

    splitPathInVchType === Voucher_Type.Receipt
      ? form.setFieldValue(
          ["partyLedger", partyIndex, "ref", index, "fullAmountLedger"],
          "667275a84d2d8577cb84a96b"
        )
      : form.setFieldValue(
          ["partyLedger", partyIndex, "ref", index, "fullAmountLedger"],
          "667275514d2d8577cb84a968"
        );

    if (
      isFullAomunt ||
      form.getFieldValue([
        "partyLedger",
        partyIndex,
        "ref",
        index,
        "fullAmount",
      ])
    ) {
      paymentId
        ? form.setFieldValue(
            ["partyLedger", partyIndex, "ref", index, "fullAmountLedgerAmount"],
            Utils.getNumber(
              form.getFieldValue([
                "partyLedger",
                partyIndex,
                "ref",
                index,
                "dueAmount",
              ])
            ) +
              Utils.getNumber(
                form.getFieldValue([
                  "partyLedger",
                  partyIndex,
                  "ref",
                  index,
                  "maxAmount",
                ]) ?? 0
              ) +
              Utils.getNumber(
                allUpdatePartyData[partyIndex]?.ref[index]
                  ?.fullAmountLedgerAmount ?? 0
              ) -
              Utils.getNumber(
                form.getFieldValue([
                  "partyLedger",
                  partyIndex,
                  "ref",
                  index,
                  "value",
                ])
              )
          )
        : form.setFieldValue(
            ["partyLedger", partyIndex, "ref", index, "fullAmountLedgerAmount"],
            Utils.getNumber(
              form.getFieldValue([
                "partyLedger",
                partyIndex,
                "ref",
                index,
                "dueAmount",
              ])
            ) -
              Utils.getNumber(
                form.getFieldValue([
                  "partyLedger",
                  partyIndex,
                  "ref",
                  index,
                  "value",
                ])
              )
          );
    } else {
      form.setFieldValue(
        ["partyLedger", partyIndex, "ref", index, "fullAmountLedgerAmount"],
        0
      );
    }

    onChangeRefAmountSet(
      form.getFieldValue(["partyLedger", partyIndex, "amount"]),
      partyIndex,
      false
    );
  };

  console.log(
    // "selectedPartyOption",
    // // selectedPartyOption,
    "partyRef",
    partyRef,
    "selectPartyRef",
    selectPartyRef,
    "allUpdatePartyData",
    allUpdatePartyData
  );

  const disAbledCheck = (
    currentBalance1: number,
    splitPathInVchType1: Voucher_Type,
    totalPaymentIn1: number
  ) => {
    return totalPaymentIn1 > currentBalance1 &&
      splitPathInVchType1 === Voucher_Type.Payment &&
      currentBalance1 <= 0
      ? true
      : false;
  };

  const [addLedgerResponse, setAddLedgerResponse] = useState<any>(null);

  useEffect(() => {
    if (addLedgerResponse) {
      const exists = accountLedgerOption.some(
        (option) => option.id === addLedgerResponse.id
      );
      if (exists) {
        form.setFieldValue("effectiveLedger", {
          ...form.getFieldValue("effectiveLedger"),
          key: addLedgerResponse?.id,
          value: addLedgerResponse?.id,
          label: addLedgerResponse?.ledgerName,
        });
        // CustomerSelect(addLedgerResponse.id, true);
        const selectAccount = accountLedgerOption.find(
          (x) => x.id === addLedgerResponse?.id
        );
        setCurrentBalance(selectAccount?.balance);
        setAccountIsParty(selectAccount?.partyOrTax);
        setSelectedParty(addLedgerResponse?.id);
        setSelectedPartyIsSystem(selectAccount?.system);
        setOpenLedgerDrawer({
          ...openLedgerDrawer,
          id: addLedgerResponse?.id,
        });
        setAddLedgerResponse(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountLedgerOption]);

  useEffect(() => {
    try {
      if (addLedgerResponse) {
        const exists = partyLedgerOption.some(
          (option: any) => option.id === addLedgerResponse.id
        );
        if (exists) {
          let partyLedger = [...(form.getFieldValue("partyLedger") || [])];
          partyLedger.pop();
          partyLedger.push(
            {
              amount: 0.0,
              id: {
                label: addLedgerResponse?.ledgerName,
                value: addLedgerResponse.id,
                key: addLedgerResponse.id,
              },
            },
            {}
          );
          const index = partyLedger.findIndex(
            (ledgerArray) => ledgerArray?.id?.value === addLedgerResponse.id
          );
          form.setFieldValue("partyLedger", partyLedger);
          partySelect(
            addLedgerResponse.id,
            index,
            addLedgerResponse?.ledgerName
          );
          onClickSelectPartyLedger(addLedgerResponse.id, index);
          setAddLedgerResponse(null);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyLedgerOption]);

  const shortcuts = [
    {
      key: "ENTER",
      modifiers: ["ctrlKey"] as ModifierKey[], // Ensure modifiers are typed correctly
      callback: () => save(),
    },
  ];
  useMultipleKeyboardShortcuts(shortcuts);

  console
    .log
    // "location?.state?.BankTransactionData?.moneyOut",
    // location?.state?.BankTransactionData.moneyOut,
    // totalPaymentIn
    ();

  const saveDisable = (isDisabled: boolean) => {
    var x: any = "";
    // Current account & short term borring account valid negitive balance
    // debugger;
    if (
      location.state?.ledgerId &&
      splitPathInVchType === Voucher_Type.Payment &&
      accountIsParty === Party_Tax.BanknCash
    ) {
      x =
        Utils.getNumber(
          totalPaymentIn + (roundOffAmount ?? 0) - (totalTds ?? 0)
        ) >
          currentBalance +
            (editPayment.id === openLedgerDrawer.id
              ? editPayment.balance
              : 0) ||
        Utils.getNumber(
          totalPaymentIn + (roundOffAmount ?? 0) - (totalTds ?? 0)
        ) !== location?.state?.BankTransactionData?.moneyOut
          ? `Insufficient balance & Amount must be  ${location?.state?.BankTransactionData?.moneyOut}`
          : "";
    } else if (location.state?.ledgerId) {
      x =
        Utils.getNumber(
          totalPaymentIn +
            (roundOffAmount ?? 0) +
            (splitPathInVchType === Voucher_Type.Receipt ? totalTds : -totalTds)
        ) !==
        (location?.state?.BankTransactionData?.moneyOut ||
          location?.state?.BankTransactionData?.moneyIn)
          ? `$Amount must be  ${location?.state?.BankTransactionData?.moneyOut}`
          : "";
    } else if (
      splitPathInVchType === Voucher_Type.Payment &&
      accountIsParty === Party_Tax.BanknCash
    ) {
      x =
        Utils.getNumber(
          totalPaymentIn + (roundOffAmount ?? 0) - (totalTds ?? 0)
        ) >
        currentBalance +
          (editPayment.id === openLedgerDrawer.id ? editPayment.balance : 0)
          ? "Insufficient balance."
          : "";
    }

    if (isDisabled) {
      if (x !== "") return true;
      else return false;
    } else return x;
  };

  // --------- Get TDS Option List API ---------
  React.useEffect(() => {
    const getTDSList = async (
      start: number,
      length: number,
      isActive?: boolean,
      search?: string
    ) => {
      await TdsTcsServices.getCompanyTDSList(
        currentClient?.id!,
        start,
        length,
        search
      )
        .then((res: any) => {
          if (res.length > 0) {
            setTdsList(res);
            // setTotalRecords(res?.totalRecords);
          } else if (res.length === 0) {
            setTdsList([]);
            // setTotalRecords(res?.totalRecords);
          }
          setLoading(false);
        })
        .catch((ex: any) => {
          console.error(ex.message);
          // openNotification("error", ex.message);
          setLoading(false);
          setTdsList([]);
        });
    };

    getTDSList(0, 0);
  }, [tdsListTrigger]);

  const totalTDSAmount = (panType?: any, totalPaymentUpdate?: number) => {
    // totalPaymentIn;

    //----- Calculate TDS amount -----
    const tdsRate = form.getFieldValue("tdsDetail");
    console.log("Tcs Rate", tdsRate, totalPaymentIn);

    const type = panType ?? partyPanCard;

    const tdsPredentage =
      type === PanCardType.HUF
        ? tdsRate?.title?.rates?.indHUFRate ?? tdsRate?.title
        : type === PanCardType.Other
        ? tdsRate?.title?.rates?.otherRate ?? tdsRate?.title
        : tdsRate?.title?.rates?.ifPANNotRate ?? tdsRate?.title;

    setTcsTdsPercentage(tdsPredentage);
    const tdsAmount = percentCal(
      totalPaymentUpdate ?? totalPaymentIn,
      tdsPredentage
    );
    // console.log("Tds Rate", tdsPredentage, totTds);
    setTotalTds(tdsAmount);

    const roundOffAmount = handleRoundoff(
      (totalPaymentUpdate != null ? totalPaymentUpdate : totalPaymentIn) +
        (splitPathInVchType === Voucher_Type.Receipt ? tdsAmount : -tdsAmount),
      form.getFieldValue("roundoffCheckbox"),
      settingControl.roundOffType
    );

    form.setFieldValue("roundOff", roundOffAmount);
    setRoundoffAmount(roundOffAmount);
  };

  const partyLedgerData =
    form.getFieldsValue(["partyLedger"]).partyLedger || [];

  console.log("data", partyLedgerData[0]?.id?.label);

  return (
    <>
      <Card
        // size="small"
        // className="TableCard"
        style={{ boxShadow: "0px 0px 10px 0px #96A1C84D", marginTop: 7 }}
        title={
          <>
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col>
                <div className="fs-20 fw-200 themeColor ">
                  <span>
                    {splitPathInVchType === Voucher_Type.Receipt
                      ? settingControl?.title
                      : settingControl?.title}{" "}
                  </span>
                  <Dropdown
                    menu={{
                      items:
                        splitPathInVchType === Voucher_Type?.Receipt
                          ? SalesDropdownItems()
                          : PurchaseDropdownItems(),
                      selectable: true,
                      defaultSelectedKeys: [
                        location?.pathname?.split("/")[5] === "addReceipt" ||
                        location?.pathname?.split("/")[5] === "editReceipt" ||
                        location?.pathname?.split("/")[5] === "addPaymentOut" ||
                        location?.pathname?.split("/")[5] === "editPaymentOut"
                          ? "2"
                          : "-1",
                      ],
                    }}
                    trigger={["click"]}
                    autoFocus={true}
                  >
                    <Typography.Link>
                      <Space>
                        <DownOutlined
                        // style={{
                        //   fontSize: "12px",
                        //   color: "black",
                        //   padding: "0px 0px 10px 10px",
                        // }}
                        />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                </div>
              </Col>
              <Col>
                <div>
                  <img
                    alt="settingslogo"
                    src={settingslogo}
                    style={{
                      paddingTop: "3px",
                      marginLeft: "10px",
                    }}
                    onClick={() =>
                      setOpenModel({
                        type: "settingCustomizationDrawer",
                        param: {},
                      })
                    }
                  />
                </div>
              </Col>
            </Row>
          </>
        }
      >
        <Spin indicator={antIcon} spinning={loading}>
          <Form
            form={form}
            name="paymentIn"
            autoComplete="off"
            initialValues={{
              partyLedger: [{}],
              date: dayjs(),
              roundoffCheckbox: settingControl.autoRoundOff,
            }}
          >
            <Row gutter={10}>
              <Col xl={24} lg={24} sm={24}>
                <Row gutter={10}>
                  <Col lg={6} sm={12} xs={24}>
                    <Row justify="space-between">
                      <Col className=" fw-500">
                        <span>Account</span>
                      </Col>
                      {selectedParty && (
                        <Col className="fw-500">
                          {selectedPartyIsSystem ? (
                            <Tooltip title="Default account is not editable">
                              <EyeInvisibleOutlined />
                            </Tooltip>
                          ) : (
                            <Tooltip title="View Account">
                              <EyeOutlined
                                disabled={selectedPartyIsSystem}
                                onClick={() =>
                                  setOpenLedgerDrawer({
                                    ...openLedgerDrawer,
                                    open: true,
                                    id: selectedParty,
                                    ledgerByGroupsType:
                                      SearchLedgerByGroupsTypes.BankAndCash,
                                    showName: "Bank",
                                  })
                                }
                              />
                            </Tooltip>
                          )}
                        </Col>
                      )}
                    </Row>

                    <Form.Item
                      name="effectiveLedger"
                      className="m-0"
                      rules={[{ required: true, message: "Select an account" }]}
                      help={
                        <div>
                          Balance:{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {Math.abs(currentBalance) +
                              (Utils.getNumber(currentBalance) === 0
                                ? ""
                                : Utils.getNumber(currentBalance) > 0
                                ? " Dr"
                                : " Cr")}
                          </span>
                        </div>
                      }
                    >
                      <Select
                        labelInValue
                        showSearch
                        loading={accDropDownLoading}
                        optionFilterProp="children"
                        placeholder="Select account"
                        filterOption={(input, option) =>
                          (option?.label?.toString() ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        ref={inputRef}
                        disabled={location.state?.ledgerId ? true : false}
                        // showArrow={false}
                        notFoundContent={null}
                        onChange={(e) => {
                          const selectAccount = accountLedgerOption.find(
                            (x) => x.id === e?.value
                          );

                          // setCurrentBalance(
                          //   Math.abs(selectAccount?.balance) +
                          //     (Utils.getNumber(selectAccount?.balance) === 0
                          //       ? ""
                          //       : Utils.getNumber(selectAccount?.balance) > 0
                          //       ? " Dr"
                          //       : " Cr")
                          // );
                          setCurrentBalance(selectAccount?.balance);

                          setAccountIsParty(selectAccount?.partyOrTax);
                          setSelectedParty(e?.value);
                          setSelectedPartyIsSystem(selectAccount?.system);
                          setOpenLedgerDrawer({
                            ...openLedgerDrawer,
                            id: e?.value,
                          });
                        }}
                        // labelInValue
                        // onSearch={(value: string) => setSearchCustomer(value)}
                        // onSelect={(value: any) => CustomerSelect(value?.value)}
                        options={accountLedgerOption?.map((value: any) => ({
                          value: value?.id,
                          label: value?.ledgerName.concat(
                            value?.aliasName
                              ? `${" (" + value?.aliasName + ")"}`
                              : ""
                          ),
                          // title: value?.balance,
                        }))}
                        dropdownRender={(customerOptions) => (
                          <>
                            {customerOptions}
                            <Divider style={{ margin: "5px 0px" }} />
                            <Button
                              style={{ width: "100%" }}
                              icon={<PlusOutlined />}
                              type="link"
                              onClick={() => {
                                setOpenLedgerDrawer({
                                  ...openLedgerDrawer,
                                  open: true,
                                  id: "",
                                  ledgerByGroupsType:
                                    SearchLedgerByGroupsTypes?.BankAndCash,
                                  defaultGroup: {
                                    id: "6603af4b99242bad67d446c5",
                                    ledgerName: "Bank Account",
                                    parent: "65af9ce61a48db26effbb598",
                                  },
                                  showName: "Bank",
                                });
                                // setOpenModel({
                                //   type: "addLedger",
                                //   param: {
                                //     open: true,
                                //   },
                                // });
                                // setEditParty({ id: "" });
                              }}
                            >
                              New Account
                            </Button>
                          </>
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={6} sm={12} xs={24}>
                    <span className=" fw-500">Date</span>
                    <Form.Item
                      name="date"
                      className="m-0"
                      rules={[
                        {
                          required: true,
                          message: "Select date",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format="DD/MM/YYYY"
                        disabled={location.state?.ledgerId ? true : false}
                        onChange={(val) => {
                          setDateFiled(dayjs(val).toISOString());
                        }}
                        // disabledDate={(current) =>
                        //   current && current > dayjs().endOf("day")
                        // }
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col lg={6} sm={12} xs={24}>
                    <span className=" fw-500">Current Balance</span>
                    <Input
                      disabled
                      value={
                        Math.abs(currentBalance) +
                        (Utils.getNumber(currentBalance) === 0
                          ? ""
                          : Utils.getNumber(currentBalance) > 0
                          ? " Dr"
                          : " Cr")
                      }
                    />
                  </Col> */}
                  <Col lg={6} sm={12} xs={24}>
                    <span className=" fw-500">
                      {splitPathInVchType === Voucher_Type.Receipt
                        ? "Receipt No."
                        : " Payment No."}
                    </span>
                    <Form.Item
                      name="number"
                      className="m-0"
                      rules={[
                        {
                          required: true,
                          message: "Please enter No.",
                        },
                      ]}
                    >
                      {voucherNumberLoader ? (
                        <Skeleton.Input
                          active={true}
                          size="default"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <Input disabled />
                      )}
                    </Form.Item>
                  </Col>
                  <Col lg={6} sm={12} xs={24}>
                    <span className=" fw-500">Reference</span>
                    <Form.Item name="payRecReference">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div style={{ overflowY: "auto" }}>
              <table
                className="AddPageTabelDesign"
                style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "5%" }} className="textCenter">
                      S.No.
                    </th>

                    <th
                      style={{
                        width: "65%",
                        borderLeft: "1px solid rgb(240, 245, 255)",
                      }}
                      className="textStart ps-6"
                    >
                      Name
                    </th>

                    <th
                      colSpan={2}
                      style={{ width: "26%" }}
                      className="textStart ps-6"
                    >
                      Amount
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <Form.List name="partyLedger">
                    {(fields, { add, remove, move }) => {
                      selectRefIndex.current = fields.map(
                        (_, index) =>
                          selectRefIndex.current[index] || React.createRef()
                      );
                      return (
                        <>
                          {confirmation && (
                            <ConfirmationModal
                              open={confirmation}
                              text={`Do you really want to delete this item "${form.getFieldValue(
                                [
                                  "partyLedger",
                                  deleteItemList?.name,
                                  "id",
                                  "label",
                                ]
                              )}" ?`}
                              onNo={() => setConfirmation(false)}
                              onYes={() => {
                                remove(deleteItemList.name);
                                setConfirmation(false);
                                handleAllLedgerValue();
                                setItemLength(fields.length - 2);

                                setPartyRef((prev) => {
                                  const allPrevArray = [...prev];
                                  // console.log(
                                  //   "allPrevArray----------",
                                  //   allPrevArray,
                                  //   deleteItemList.index
                                  // );
                                  allPrevArray.splice(deleteItemList.index, 1);
                                  return allPrevArray;
                                });

                                setSelectPartyRef((prev: any[]) => {
                                  const allPrevReference = [...prev];
                                  allPrevReference.splice(
                                    deleteItemList.index,
                                    1
                                  );
                                  return allPrevReference;
                                });

                                setDisabledAmountInputs((prev) => {
                                  const newDisabledInputs = [...prev];
                                  // console.log(
                                  //   "newDisabledInputs",
                                  //   newDisabledInputs
                                  // );
                                  newDisabledInputs.splice(
                                    deleteItemList.index,
                                    1
                                  );
                                  return newDisabledInputs;
                                });

                                setSelectedPartyOption((prev) => {
                                  const newDisabledInputs = [...prev];
                                  newDisabledInputs.splice(
                                    deleteItemList.index,
                                    1
                                  );
                                  return newDisabledInputs;
                                });
                              }}
                            />
                          )}
                          {fields.map(({ key, name, ...restField }, index1) => {
                            const isInputDisabled =
                              disabledAmountInputs[index1];
                            return (
                              <>
                                <tr key={key}>
                                  <td>
                                    {fields.length !== index1 + 1 && (
                                      <div className="ps-10">{index1 + 1}.</div>
                                    )}
                                  </td>

                                  {/* Name */}
                                  <td>
                                    <Form.Item
                                      name={[name, "id"]}
                                      className="m-0"
                                      {...restField}
                                    >
                                      <Select
                                        variant="filled"
                                        labelInValue
                                        showSearch
                                        loading={nameDropDownLoading}
                                        placeholder="Search to.."
                                        optionFilterProp="children"
                                        popupMatchSelectWidth={false}
                                        className="variantFilledSelect"
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#FFFFFF",
                                        }}
                                        bordered={false}
                                        dropdownStyle={{ width: "250px" }}
                                        placement="bottomRight"
                                        // onSearch={(value: string) =>
                                        //   setSearchItem(value)
                                        // }

                                        options={partyLedgerOption
                                          .filter(
                                            (option) =>
                                              !selectedPartyOption.includes(
                                                option.id
                                              )
                                          )
                                          .map((option: any) => ({
                                            value: option?.id,
                                            label: option?.ledgerName.concat(
                                              option?.aliasName
                                                ? `${
                                                    " (" +
                                                    option?.aliasName +
                                                    ")"
                                                  }`
                                                : ""
                                            ),
                                            title: option?.partyOrTax,
                                          }))}
                                        filterOption={(input, option) =>
                                          (option?.label?.toString() ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        onChange={(e: any) => {
                                          if (fields.length === index1 + 1)
                                            add();
                                          partySelect(e.value, index1, e.title);
                                          onClickSelectPartyLedger(
                                            e.value,
                                            index1
                                          );
                                        }}
                                        dropdownRender={(customerOptions) => (
                                          <>
                                            {customerOptions}
                                            <Divider
                                              style={{ margin: "5px 0px" }}
                                            />
                                            <Button
                                              style={{ width: "100%" }}
                                              icon={<PlusOutlined />}
                                              type="link"
                                              onClick={() => {
                                                // setOpenModel({
                                                //   type: "addLedger",
                                                //   param: {
                                                //     open: true,
                                                //   },
                                                // });
                                                setOpenLedgerDrawer({
                                                  ...openLedgerDrawer,
                                                  open: true,
                                                  id: "",
                                                  ledgerByGroupsType:
                                                    splitPathLedgerByGroupsType,
                                                });
                                                // setEditParty({ id: "" });
                                              }}
                                            >
                                              New Ledger
                                            </Button>
                                          </>
                                        )}
                                      />
                                    </Form.Item>
                                  </td>
                                  {/* Amount */}
                                  <td>
                                    <Form.Item
                                      className="m-0"
                                      {...restField}
                                      name={[name, "amount"]}
                                      rules={[
                                        ({ getFieldValue }) => ({
                                          validator(_, value) {
                                            if (
                                              fields.length !== index1 + 1 &&
                                              isInputDisabled
                                            ) {
                                              if (
                                                Utils.getNumber(
                                                  getFieldValue([
                                                    "partyLedger",
                                                    index1,
                                                    "amount",
                                                  ])
                                                ) !==
                                                Utils.getNumber(
                                                  getFieldValue([
                                                    "partyLedger",
                                                    index1,
                                                    "ref",
                                                  ]).reduce(
                                                    (
                                                      acc: number,
                                                      curr: any
                                                    ) => {
                                                      return (acc =
                                                        acc +
                                                        Utils.getNumber(
                                                          curr?.value
                                                        ));
                                                      // +
                                                      // Utils.getNumber(
                                                      //   curr?.fullAmountLedgerAmount
                                                      // )
                                                    },
                                                    0
                                                  )
                                                )
                                              ) {
                                                return Promise.reject(
                                                  "Reference amount do not match"
                                                );
                                              }
                                              return Promise.resolve();
                                            }
                                            return Promise.resolve();
                                          },
                                        }),
                                      ]}
                                    >
                                      <InputNumber
                                        defaultValue={0.0}
                                        ref={selectRefIndex?.current[index1]}
                                        controls={false}
                                        variant="filled"
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#FFFFFF",
                                        }}
                                        bordered={false}
                                        step={1}
                                        precision={2}
                                        min={0}
                                        // disabled={isInputDisabled}
                                        // prefix="&#8377;"
                                        onBlur={(e) => {
                                          handleAllLedgerValue();
                                          // handelAmountChange(
                                          //   Utils.getNumber(e.target.value),
                                          //   index
                                          // );
                                          // getAllocatedAmount(Utils.getNumber(e.target.value));
                                        }}
                                        // onKeyPress={(e) =>
                                        //   !/[0-9]/.test(e.key) &&
                                        //   e.preventDefault()
                                        // }
                                        onChange={(e: any) => {
                                          onChangeRefAmountSet(e, index1, true);
                                        }}
                                      />
                                    </Form.Item>
                                  </td>

                                  <td
                                    style={{
                                      width: 24,
                                      verticalAlign: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    {fields.length !== index1 + 1 && (
                                      <img
                                        className="cursor mx-1"
                                        onClick={() => {
                                          setDeleteItemList({
                                            name: name,
                                            index: index1,
                                          });
                                          setConfirmation(true);
                                        }}
                                        src={deleteImg}
                                        alt="Delete"
                                      />
                                    )}
                                  </td>
                                </tr>

                                {/* Nest Form.List */}
                                <tr
                                  style={{
                                    display: !isInputDisabled
                                      ? "none"
                                      : "contents",
                                  }}
                                >
                                  <td></td>
                                  <td>
                                    <table style={{ width: "100%" }}>
                                      <tbody>
                                        <Form.Item className="m-0" name="ref">
                                          <Form.List name={[name, "ref"]}>
                                            {(
                                              subFields,
                                              { add, remove, move }
                                            ) => {
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                  }}
                                                >
                                                  {subFields.map(
                                                    (subField, index) => {
                                                      const isInputDisabled1 =
                                                        subFields.length !==
                                                        index + 1
                                                          ? true
                                                          : false;
                                                      return (
                                                        <tr
                                                          // style={{
                                                          //   paddingLeft:
                                                          //     "105px",
                                                          // }}
                                                          key={index}
                                                        >
                                                          <td
                                                            style={{
                                                              width: "5%",
                                                            }}
                                                          >
                                                            {index === 0 ? (
                                                              <Tooltip
                                                                title="New Reference"
                                                                placement="left"
                                                                color="#389e0d"
                                                              >
                                                                <Avatar
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    verticalAlign:
                                                                      "middle",
                                                                    color:
                                                                      "#389e0d",
                                                                    backgroundColor:
                                                                      "#f6ffed",
                                                                    borderColor:
                                                                      "#b7eb8f",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                  size="small"
                                                                >
                                                                  N
                                                                </Avatar>
                                                              </Tooltip>
                                                            ) : (
                                                              <Tooltip
                                                                title="Old Reference"
                                                                placement="left"
                                                                color="#08979C"
                                                              >
                                                                <Avatar
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    verticalAlign:
                                                                      "middle",
                                                                    color:
                                                                      "#08979C",
                                                                    backgroundColor:
                                                                      "#E6FFFB",
                                                                    borderColor:
                                                                      "rgb(127 231 235)",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                  size="small"
                                                                >
                                                                  A
                                                                </Avatar>
                                                              </Tooltip>
                                                            )}
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "25%",
                                                            }}
                                                          >
                                                            <Form.Item
                                                              className="m-0"
                                                              name={[
                                                                subField.name,
                                                                "name",
                                                              ]}
                                                              rules={[
                                                                {
                                                                  required:
                                                                    index !==
                                                                      0 &&
                                                                    subFields.length !==
                                                                      index +
                                                                        1 &&
                                                                    form.getFieldValue(
                                                                      [
                                                                        "partyLedger",
                                                                        index1,
                                                                        "ref",
                                                                        index,
                                                                        "value",
                                                                      ]
                                                                    ) > 0
                                                                      ? true
                                                                      : false,
                                                                  message:
                                                                    "Select an invoice",
                                                                },
                                                              ]}
                                                            >
                                                              <Select
                                                                disabled={
                                                                  index === 0 &&
                                                                  true
                                                                }
                                                                variant="filled"
                                                                bordered={false}
                                                                style={{
                                                                  backgroundColor:
                                                                    "#FFFFFF",
                                                                  width: "100%",
                                                                }}
                                                                className="variantFilledSelect"
                                                                labelInValue
                                                                showSearch
                                                                optionFilterProp="children"
                                                                placeholder="Select account"
                                                                filterOption={(
                                                                  input,
                                                                  option
                                                                ) =>
                                                                  (
                                                                    option?.label?.toString() ??
                                                                    ""
                                                                  )
                                                                    .toLowerCase()
                                                                    .includes(
                                                                      input.toLowerCase()
                                                                    )
                                                                }
                                                                // showArrow={false}
                                                                notFoundContent={
                                                                  null
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    subFields.length ===
                                                                    index + 1
                                                                  )
                                                                    add();

                                                                  onChangeSetPartyRef(
                                                                    index1,
                                                                    index,
                                                                    e?.value
                                                                  );
                                                                }}
                                                                options={partyRef[
                                                                  index1
                                                                ]
                                                                  ?.filter(
                                                                    (
                                                                      option: any
                                                                    ) =>
                                                                      !selectPartyRef[
                                                                        index1
                                                                      ]?.includes(
                                                                        option.parentRef
                                                                      )
                                                                  )
                                                                  .map(
                                                                    (
                                                                      value: any
                                                                    ) => ({
                                                                      value:
                                                                        value?.parentRef,
                                                                      label:
                                                                        value?.name,
                                                                      // title: value?.balance,
                                                                    })
                                                                  )}
                                                              />
                                                            </Form.Item>
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "14%",
                                                            }}
                                                          >
                                                            <Form.Item
                                                              className="m-0"
                                                              name={[
                                                                subField.name,
                                                                "dueAmount",
                                                              ]}
                                                            >
                                                              <InputNumber
                                                                step={0.01}
                                                                // variant="filled"
                                                                // className="variantFilledSelect"
                                                                disabled
                                                                controls={false}
                                                                bordered={false}
                                                                style={{
                                                                  width: "100%",
                                                                  backgroundColor:
                                                                    "#FFFFFF",
                                                                }}
                                                                placeholder="Amount"
                                                              />
                                                            </Form.Item>
                                                          </td>
                                                          {/* Amount */}
                                                          <td
                                                            style={{
                                                              width: "14%",
                                                            }}
                                                          >
                                                            <Form.Item
                                                              className="m-0"
                                                              name={[
                                                                subField.name,
                                                                "value",
                                                              ]}
                                                              rules={[
                                                                {
                                                                  required:
                                                                    index !==
                                                                      0 &&
                                                                    form.getFieldValue(
                                                                      [
                                                                        "partyLedger",
                                                                        index1,
                                                                        "ref",
                                                                        index,
                                                                        "name",
                                                                      ]
                                                                    )
                                                                      ? true
                                                                      : false,
                                                                  message:
                                                                    "Enter amount",
                                                                },
                                                                {
                                                                  validator: (
                                                                    _,
                                                                    value
                                                                  ) => {
                                                                    if (
                                                                      value < 0
                                                                    ) {
                                                                      return Promise.reject(
                                                                        "Number must be non-negative"
                                                                      );
                                                                    }
                                                                    return Promise.resolve();
                                                                  },
                                                                },
                                                              ]}
                                                            >
                                                              <InputNumber
                                                                step={1}
                                                                precision={2}
                                                                min={0.0}
                                                                max={
                                                                  index !== 0
                                                                    ? paymentId
                                                                      ? Math.round(
                                                                          (form.getFieldValue(
                                                                            [
                                                                              "partyLedger",
                                                                              index1,
                                                                              "ref",
                                                                              index,
                                                                              "dueAmount",
                                                                            ]
                                                                          ) +
                                                                            Utils.getNumber(
                                                                              form.getFieldValue(
                                                                                [
                                                                                  "partyLedger",
                                                                                  index1,
                                                                                  "ref",
                                                                                  index,
                                                                                  "maxAmount",
                                                                                ]
                                                                              ) ??
                                                                                0
                                                                            ) +
                                                                            Utils.getNumber(
                                                                              allUpdatePartyData[
                                                                                index1
                                                                              ]
                                                                                ?.ref[
                                                                                index
                                                                              ]
                                                                                ?.fullAmountLedgerAmount ??
                                                                                0
                                                                            )) *
                                                                            100
                                                                        ) / 100
                                                                      : Math.round(
                                                                          form.getFieldValue(
                                                                            [
                                                                              "partyLedger",
                                                                              index1,
                                                                              "ref",
                                                                              index,
                                                                              "dueAmount",
                                                                            ]
                                                                          ) *
                                                                            100
                                                                        ) / 100
                                                                    : Infinity
                                                                }
                                                                controls={false}
                                                                bordered={false}
                                                                variant="filled"
                                                                style={{
                                                                  width: "100%",
                                                                  backgroundColor:
                                                                    "#FFFFFF",
                                                                }}
                                                                placeholder="Amount"
                                                                // onKeyPress={(e) =>
                                                                //   !/[0-9]/.test(
                                                                //     e.key
                                                                //   ) &&
                                                                //   e.preventDefault()
                                                                // }
                                                                onBlur={(e) => {
                                                                  if (
                                                                    subFields.length ===
                                                                    index + 1
                                                                  )
                                                                    add();

                                                                  if (
                                                                    form.getFieldValue(
                                                                      [
                                                                        "partyLedger",
                                                                        index1,
                                                                        "ref",
                                                                        index,
                                                                        "fullAmount",
                                                                      ]
                                                                    )
                                                                  ) {
                                                                    // condition user click amount > dueAmount && fullAmountLedgerAmount == 0 , update total amount add (dueAmount + maxAmount(current index value) + fullAmountLedgerAmount )
                                                                    if (
                                                                      paymentId
                                                                        ? Number(
                                                                            e
                                                                              .target
                                                                              .value
                                                                          ) >=
                                                                          Math.round(
                                                                            (form.getFieldValue(
                                                                              [
                                                                                "partyLedger",
                                                                                index1,
                                                                                "ref",
                                                                                index,
                                                                                "dueAmount",
                                                                              ]
                                                                            ) +
                                                                              Utils.getNumber(
                                                                                form.getFieldValue(
                                                                                  [
                                                                                    "partyLedger",
                                                                                    index1,
                                                                                    "ref",
                                                                                    index,
                                                                                    "maxAmount",
                                                                                  ]
                                                                                ) ??
                                                                                  0
                                                                              ) +
                                                                              Utils.getNumber(
                                                                                allUpdatePartyData[
                                                                                  index1
                                                                                ]
                                                                                  ?.ref[
                                                                                  index
                                                                                ]
                                                                                  ?.fullAmountLedgerAmount ??
                                                                                  0
                                                                              )) *
                                                                              100
                                                                          ) /
                                                                            100
                                                                        : Number(
                                                                            e
                                                                              .target
                                                                              .value
                                                                          ) >=
                                                                          Math.round(
                                                                            form.getFieldValue(
                                                                              [
                                                                                "partyLedger",
                                                                                index1,
                                                                                "ref",
                                                                                index,
                                                                                "dueAmount",
                                                                              ]
                                                                            ) *
                                                                              100
                                                                          ) /
                                                                            100
                                                                    ) {
                                                                      console.log(
                                                                        "Hello 111"
                                                                      );

                                                                      form.setFieldValue(
                                                                        [
                                                                          "partyLedger",
                                                                          index1,
                                                                          "ref",
                                                                          index,
                                                                          "fullAmountLedgerAmount",
                                                                        ],
                                                                        0
                                                                      );
                                                                    } else {
                                                                      cashInHandDisAndBadDebtsAmountSet(
                                                                        index1,
                                                                        index
                                                                      );
                                                                    }
                                                                  }
                                                                }}
                                                                onChange={(
                                                                  e: any
                                                                ) => {
                                                                  // console.log(
                                                                  //   "x..",
                                                                  //   index1,
                                                                  //   index,
                                                                  //   Math.round(
                                                                  //     partyRef[
                                                                  //       index1
                                                                  //     ][index]
                                                                  //       ?.dueAmount
                                                                  //   )
                                                                  // );

                                                                  onChangeRefAmountSet(
                                                                    e,
                                                                    index1,
                                                                    false
                                                                  );

                                                                  if (
                                                                    Utils.getNumber(
                                                                      form.getFieldValue(
                                                                        [
                                                                          "partyLedger",
                                                                          index1,
                                                                          "amount",
                                                                        ]
                                                                      )
                                                                    ) !==
                                                                    Utils.getNumber(
                                                                      form
                                                                        .getFieldValue(
                                                                          [
                                                                            "partyLedger",
                                                                            index1,
                                                                            "ref",
                                                                          ]
                                                                        )
                                                                        .reduce(
                                                                          (
                                                                            acc: number,
                                                                            curr: any
                                                                          ) => {
                                                                            return (acc +=
                                                                              Utils.getNumber(
                                                                                curr?.value
                                                                              ));
                                                                          },
                                                                          0
                                                                        )
                                                                    )
                                                                  ) {
                                                                    console.log(
                                                                      "error"
                                                                    );
                                                                    form.validateFields(
                                                                      [
                                                                        "partyLedger",
                                                                        index1,
                                                                        "amount",
                                                                      ]
                                                                    );
                                                                    return Promise.resolve();
                                                                  }
                                                                }}
                                                                // onBlur={() =>
                                                                //   handleLedgerNetAmount(
                                                                //     index1
                                                                //   )subField
                                                                // }
                                                              />
                                                            </Form.Item>
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "25%",
                                                              // border: "none",
                                                            }}
                                                          >
                                                            {index !== 0 &&
                                                              subFields.length !==
                                                                index + 1 && (
                                                                <Row>
                                                                  <Col span={4}>
                                                                    <Form.Item
                                                                      className="m-0"
                                                                      name={[
                                                                        subField.name,
                                                                        "fullAmount",
                                                                      ]}
                                                                      valuePropName="checked"
                                                                    >
                                                                      <Checkbox
                                                                        onChange={(
                                                                          e
                                                                        ) => {
                                                                          cashInHandDisAndBadDebtsAmountSet(
                                                                            index1,
                                                                            index,
                                                                            e
                                                                              .target
                                                                              .checked
                                                                          );
                                                                        }}
                                                                      />
                                                                    </Form.Item>
                                                                  </Col>
                                                                  {/* {form.getFieldValue(
                                                                    [
                                                                      "partyLedger",
                                                                      index1,
                                                                      "ref",
                                                                      index,
                                                                      "fullAmount",
                                                                    ]
                                                                  ) && ( */}
                                                                  <Col
                                                                    span={20}
                                                                  >
                                                                    <Form.Item
                                                                      className="m-0"
                                                                      name={[
                                                                        subField.name,
                                                                        "fullAmountLedger",
                                                                      ]}
                                                                    >
                                                                      <Select
                                                                        className="variantFilledSelect"
                                                                        bordered={
                                                                          false
                                                                        }
                                                                        variant="filled"
                                                                        style={{
                                                                          backgroundColor:
                                                                            "#FFFFFF",
                                                                        }}
                                                                        options={cashDisAndBadDebtsOption?.map(
                                                                          (
                                                                            value: any
                                                                          ) => ({
                                                                            value:
                                                                              value?.id,
                                                                            label:
                                                                              value?.ledgerName,
                                                                          })
                                                                        )}
                                                                      />
                                                                    </Form.Item>
                                                                  </Col>
                                                                  {/* )} */}
                                                                </Row>
                                                              )}
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "10%",
                                                              // border: "none",
                                                            }}
                                                          >
                                                            {index !== 0 &&
                                                              subFields.length !==
                                                                index + 1 && (
                                                                // form.getFieldValue(
                                                                //   [
                                                                //     "partyLedger",
                                                                //     index1,
                                                                //     "ref",
                                                                //     index,
                                                                //     "fullAmount",
                                                                //   ]
                                                                // ) &&
                                                                <Col span={20}>
                                                                  <Form.Item
                                                                    className="m-0"
                                                                    name={[
                                                                      subField.name,
                                                                      "fullAmountLedgerAmount",
                                                                    ]}
                                                                  >
                                                                    <InputNumber
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                      }}
                                                                      disabled
                                                                      bordered={
                                                                        false
                                                                      }
                                                                    />
                                                                  </Form.Item>
                                                                </Col>
                                                              )}
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "7%",
                                                              // border: "none",
                                                            }}
                                                          >
                                                            <td
                                                              style={{
                                                                // width: 25,
                                                                // width: "9%",
                                                                border: "none",
                                                              }}
                                                            >
                                                              {index !== 0 &&
                                                                subFields.length !==
                                                                  index + 1 && (
                                                                  <CloseOutlined
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                      paddingLeft:
                                                                        "6px",
                                                                    }}
                                                                    onClick={() => {
                                                                      remove(
                                                                        subField.name
                                                                      );

                                                                      onChangeRefAmountSet(
                                                                        "0",
                                                                        index1,
                                                                        false,
                                                                        index
                                                                      );
                                                                    }}
                                                                  />
                                                                )}
                                                            </td>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              );
                                            }}
                                          </Form.List>
                                        </Form.Item>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      );
                    }}
                  </Form.List>
                </tbody>
                {/* {itemLength > 0 && (
                <tfoot>
                  <tr>                   
                    <th colSpan={2} className="textEnd pe-14">
                      {footerTotal.qty}
                    </th>
                    <th colSpan={2} className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.unitPrice)}
                    </th>
                    <th colSpan={2} className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.discount)}
                    </th>
                    {isGSTEnabled && (
                      <th colSpan={2} className="textEnd pe-12">
                        ₹ {Utils.getFormattedNumber(footerTotal.gst)}
                      </th>
                    )}
                    <th className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.amount)}
                    </th>
                  </tr>
                </tfoot>
              )} */}
              </table>
            </div>

            <Row className="pt-10" justify="space-between">
              <Col xl={6} lg={8} md={24} xs={24}>
                <Form.Item name="narration" className="m-0">
                  <TextArea placeholder="Write narration" rows={4} />
                </Form.Item>

                <Form.Item
                  name="attachments"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => {
                    return e.fileList;
                  }}
                  // className="m-0"
                  style={{ paddingTop: "10px", margin: "0px" }}
                >
                  <Upload
                    multiple
                    // accept={".png, .jpg, .jpeg, .pdf"}
                    // accept={".png, .jpg, .jpeg"}
                    beforeUpload={() => {
                      return false;
                    }}
                    onRemove={(e: any) => {
                      if (paymentId && e?.id) {
                        setDeleteUploadId([...deleteUploadId, e?.id]);
                      }
                    }}
                    onPreview={(e: any) => {
                      console.log("preview", e);
                      if (paymentId && e?.path) {
                        setPreviewImage({
                          isBaseConvert: false,
                          path: e,
                        });
                      } else if (e.type?.split("/")[0] === "image") {
                        setPreviewImage({
                          isBaseConvert: true,
                          path: e,
                        });
                      }
                    }}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ backgroundColor: "ButtonFace" }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              {/* <Col xl={10} xs={0}></Col> */}

              <Col xl={8} lg={24} md={24} xs={24}>
                <Row gutter={[0, 8]}>
                  {/* <Col lg={12}>Bank Amount Set</Col>

                <Col lg={12} className="textEnd">
                  ₹{" "}
                  {Utils.getFormattedNumber(
                    location?.state?.BankTransactionData?.moneyIn ||
                      location?.state?.BankTransactionData?.moneyOut
                  )}
                </Col> */}

                  <Col lg={17} span={17}>
                    <Row>
                      <Col lg={5} span={5}>
                        TDS
                      </Col>
                      <Col lg={19} span={19}>
                        <Row gutter={5}>
                          <Col lg={20} span={20}>
                            <Form.Item name="tdsDetail" className="m-0">
                              <Select
                                labelInValue
                                optionFilterProp="children"
                                // disabled
                                placement="bottomRight"
                                popupMatchSelectWidth={false}
                                size="small"
                                onChange={(e) => {
                                  const tdsSelect = tdsList.find(
                                    (x) => x.id === e.value
                                  );
                                  // console.log("any", tdsSelect, e);
                                  form.setFieldValue("tdsDetail", {
                                    value: e.value,
                                    label: tdsSelect?.section,
                                    title: tdsSelect?.rateDetail,
                                  });
                                  setTdsDetail({
                                    formNo: tdsSelect.form,
                                    transactionType: tdsSelect.transactionType,
                                  });
                                  totalTDSAmount();
                                }}
                                options={tdsList?.map((value: any) => ({
                                  value: value?.id,
                                  label: (
                                    <div>
                                      <Col>
                                        <p
                                          style={{
                                            fontWeight: "bold",
                                            color: "dimgray",
                                          }}
                                        >
                                          {value?.section}
                                        </p>
                                      </Col>

                                      <div>
                                        <span>{value?.natureOfPayment}</span>
                                      </div>
                                    </div>
                                  ),
                                  title:
                                    value?.TransactionType ===
                                    Transaction_Type.Domestic
                                      ? value?.domesticSegment
                                      : value?.internationalSegment,
                                }))}
                                filterOption={(input, option) =>
                                  (option?.label?.toString() ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                // dropdownRender={(menu) => (
                                //   <>
                                //     <div>
                                //       {menu}
                                //       <Divider style={{ margin: "5px 0px" }} />
                                //       <Button
                                //         style={{ width: "100%" }}
                                //         icon={<PlusOutlined />}
                                //         type="link"
                                //         onClick={() => {
                                //           // setEditParty({ id: "" });
                                //           setOpenModel({
                                //             type: "addTdsRate",
                                //             param: {
                                //               open: true,
                                //             },
                                //           });
                                //         }}
                                //       >
                                //         TDS Rate
                                //       </Button>
                                //     </div>
                                //   </>
                                // )}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={4} span={4} style={{ paddingTop: "2.5px" }}>
                            {tcsTdsPercentage}%
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={7} span={9} className="textEnd">
                    <span>₹ {Utils.getFormattedNumber(totalTds)}</span>
                  </Col>

                  {(settingControl.autoRoundOff || updateTimeData.roundOff) && (
                    <>
                      <Col lg={5} span={5}>
                        <span>Round off</span>
                      </Col>

                      <Col lg={4} span={4}>
                        <Form.Item
                          valuePropName="checked"
                          name="roundoffCheckbox"
                          className="inputBoxMb"
                        >
                          <Checkbox
                            onChange={(e: any) => {
                              const roundOffAmount = handleRoundoff(
                                totalPaymentIn +
                                  (splitPathInVchType === Voucher_Type.Receipt
                                    ? totalTds
                                    : -totalTds),
                                e.target.checked,
                                settingControl.roundOffType
                              );
                              console.log("roundOffAmount", roundOffAmount);

                              form.setFieldValue("roundOff", roundOffAmount);
                              setRoundoffAmount(roundOffAmount);
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        lg={15}
                        span={15}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Form.Item
                          className="discountInput inputBoxMb textEnd"
                          name="roundOff"
                        >
                          <InputNumber
                            step={0.01}
                            precision={2}
                            className="inputNumberTextEnd"
                            size="small"
                            controls={false}
                            placeholder="0.0"
                            // bordered={false}
                            // controls={false}
                            style={{
                              width: "100%",
                            }}
                            onChange={(e: any) => {
                              setRoundoffAmount(e);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  <Col
                    lg={11}
                    xs={12}
                    style={{ fontWeight: 600, fontSize: "16px" }}
                  >
                    <span>
                      Total
                      {splitPathInVchType === Voucher_Type.Receipt
                        ? " Receipt "
                        : " Payments "}
                      Amount
                    </span>
                  </Col>

                  <Col
                    lg={13}
                    xs={12}
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      textAlign: "end",
                    }}
                    className="textEnd"
                  >
                    <span>
                      ₹{" "}
                      {Utils.getFormattedNumber(
                        totalPaymentIn +
                          (roundOffAmount ?? 0) +
                          (splitPathInVchType === Voucher_Type.Receipt
                            ? totalTds
                            : -totalTds)
                      )}
                    </span>
                  </Col>
                </Row>

                <Divider
                  style={{
                    borderBottom: "2px solid rgb(203, 203, 203)",
                  }}
                  className="my-5"
                />
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
      <Footer
        style={{
          bottom: 0,
          position: "fixed",
          width: "100%",
          background: "#fff",
          marginLeft: "-14px",
          zIndex: 999,
          height: "45px",
          lineHeight: "46px",
        }}
      >
        <Row justify="center" style={{ marginLeft: "54%" }}>
          <Col>
            <Button
              onClick={() => {
                if (totalPaymentIn > 0 && !paymentId && selectedParty) {
                  save(false, true);
                }
                location.state?.ledgerId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/banking/bankTransactionsDetails/${location.state?.bankEntriesId}`,
                      {
                        state: {
                          ledgerId: location.state?.ledgerId,
                          bankImportIndex: null,
                        },
                      }
                    )
                  : navigate(-1);
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col className="ps-14">
            <Form.Item>
              <Tooltip
                title={
                  saveDisable(false)

                  // totalPaymentIn >
                  //   currentBalance +
                  //     (editPayment.id === openLedgerDrawer.id
                  //       ? editPayment.balance
                  //       : 0) &&
                  // splitPathInVchType === Voucher_Type.Payment
                  //   ? "Current Balance less than 0"
                  //   : ""
                }
                placement="top"
                color="red"
              >
                <Button
                  style={{ marginTop: "6px" }}
                  type="primary"
                  onClick={() => save()}
                  loading={saveButtonLoading}
                  disabled={
                    saveDisable(true) ||
                    Utils.getNumber(totalPaymentIn ?? 0) <= 0
                  }

                  //     Utils.getFormattedNumber(
                  //   totalPaymentIn +
                  //     (roundOffAmount ?? 0) +
                  //     (splitPathInVchType === Voucher_Type.Receipt
                  //       ? totalTds
                  //       : -totalTds)
                  // )
                  // totalPaymentIn >
                  //   currentBalance +
                  //     (editPayment.id === openLedgerDrawer.id
                  //       ? editPayment.balance
                  //       : 0) &&
                  // splitPathInVchType === Voucher_Type.Payment
                  //   ? true
                  //   : false
                >
                  {paymentId ? "Update" : "Save"}
                </Button>
              </Tooltip>
            </Form.Item>
          </Col>

          {/* <Col className="ps-14">
                  <Form.Item>
                    <Button
                      // loading={saveNewLoading}
                      // onClick={() => {
                      //   addDeliveryChallan(true);
                      // }}
                      type="primary"
                    >
                      {paymentId ? "Update & New" : "Save & New"}
                    </Button>
                  </Form.Item>
                </Col> */}
        </Row>
      </Footer>
      {openLedgerDrawer.open && (
        <AddLedger
          openDrawer={openLedgerDrawer.open}
          id={openLedgerDrawer?.id}
          setOpenDrawer={() =>
            setOpenLedgerDrawer({ open: false, id: "", showName: "" })
          }
          setTrigger={() => setTrigger(!trigger)}
          type={openLedgerDrawer.ledgerByGroupsType}
          setAddLedgerResponse={setAddLedgerResponse}
          defaultGroup={openLedgerDrawer?.defaultGroup}
          showName={openLedgerDrawer?.showName}
        />
      )}
      {/* {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={openModel?.param.open}
          id={""}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() => setTrigger(!trigger)}
          setAddLedgerResponse={setAddLedgerResponse}
          // setRefresh={setRefresh}
        />
      )} */}
      {previewImage.path && (
        <DocumentPreview
          open={true}
          onCancel={() =>
            setPreviewImage({
              path: "",
              isBaseConvert: false,
            })
          }
          documentDetails={previewImage}
        />
      )}

      {/* --------- Add TDS Model--------- */}
      {openModel.type === "addTdsRate" && (
        <AddTdsTaxRate
          open={true}
          setOpen={() => setOpenModel({ type: "", param: "" })}
          setTrigger={setTdsListTrigger}
          editData={openModel.param}
        />
      )}
      {openModel.type === "settingCustomizationDrawer" && (
        <SettingCustomizationIsDrawer
          isDrawer={true}
          settingsSections={
            splitPathInVchType === Voucher_Type.Payment
              ? SettingsSections.Payment
              : SettingsSections.Receipt
          }
          settingLabelName={getVoucherTitle?.title!}
          onCancel={() => setOpenModel({ type: "", param: {} })}
          trigger={() => setSettingTigger(!settingTigger)}
          editId={paymentId ?? draftId}
        />
      )}
    </>
  );
};


export default AddPaymentInNew;