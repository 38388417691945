import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  message,
  Modal,
  notification,
  Row,
  Table,
  TableColumnsType,
  Upload,
} from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../../Context/ClientContext";
import { useTheme } from "../../../Context/ThemeContext";
import * as XLSX from "xlsx";
import { BusinessTypes } from "../../../Services/ChartofAccountServicesNew";
import {
  BankAccountTypes,
  BusinessNatures,
  DeducteeTypes,
} from "../../../Services/ClientService";
import {
  CompanyGSTTypes,
  IndianStates,
} from "../../../../Types/CommonConstants";
import LedgerService, {
  Calc_Methods,
  Duty_Type,
  GSTTax_Type,
  ImportLedgerAddType,
  TypeOfAddress,
} from "../../../Services/LedgerService";
import { notificationContext } from "../../../Common/PageRoute";
import { AssetStatus } from "../../../Services/FixedAssetsServiceNew";
import { AssetPurType, CalcMethod } from "../../Assets/AssetModel";
import { dayjs } from "../../../../Utilities/dayjs";

type ParsedDataType = {
  [key: string]: any;
};

interface ColumnType {
  title: string;
  dataIndex: string;
  key: string;
}

interface IProps {
  onCancel: any;
  ledgerId?: string;
  partyOrTax?: any;
  filterDate?: any;
}

const ImportLedgerModal = (props: IProps) => {
  const { currentClient, companySettings } = useClient();
  const [form] = Form.useForm();
  const { themeData } = useTheme();
  const [load, setLoad] = useState<boolean>(false);
  const [headerData, setHeaderData] = useState<any>([]);
  const [showHeaderData, setShowHeaderData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [forData, setForData] = useState<any>([]);
  const [result, setResult] = useState<any>();
  const [ledgerCount, setLedgerCount] = useState<number>(0);
  const [addLedName, setAddLedName] = useState<number>(0);
  const [denLedName, setDenLedName] = useState<number>(0);
  const [exiLedName, setExiLedName] = useState<number>(0);

  // const handleBeforeUpload = (file: any) => {
  //   const reader = new FileReader();
  //   reader.onload = (e: any) => {
  //     Papa.parse(e.target.result, {
  //       quoteChar: '"',
  //       escapeChar: '"',
  //       delimiter: ",",
  //       header: true,
  //       skipEmptyLines: true,
  //       complete: function (results: Papa.ParseResult<any>) {
  //         const rowsArray: any[] = [];
  //         const valuesArray: any[] = [];
  //         results.data.map((d: any) => {
  //           rowsArray.push(Object.keys(d));
  //           valuesArray.push(Object.values(d));
  //         });
  //         setParsedData(results.data);
  //         setTableRows(rowsArray[0]);
  //         setValues(valuesArray);
  //         navigate(
  //           `/accounts/clients/${currentClient?.id}/banking/bankAccount/bankTransactionsDetails`,
  //           {
  //             state: { CSVData: rowsArray[0], parsedData: results.data },
  //           }
  //         );
  //       },
  //     });
  //   };
  // reader.readAsText(file);
  // return false;
  // };

  //   console.log("pope", ledgerId);

  const handleBeforeUpload = (file: File) => {
    setLoad(true);
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target?.result as string;
      const workbook = XLSX.read(binaryStr, { type: "binary" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Convert sheet to JSON where headers are used as keys
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      }) as any[][];

      if (jsonData.length > 0) {
        // Extract header row
        const header = jsonData[2];
        const showheader = jsonData[3];
        const dataArray = jsonData.slice(4); // Data rows
        const rowsArray = dataArray.filter(
          (r) => r[0] !== "" && r[0] !== null && r[0] !== undefined
        ); // Data rows
        console.log("DAta: ", rowsArray.length);

        // console.log("Header", header);

        // Convert rowsArray to array of objects with column headers as keys
        const formattedData: ParsedDataType[] = rowsArray.map((row) =>
          row.reduce((acc, value, index) => {
            acc[header[index]] = value;
            return acc;
          }, {} as ParsedDataType)
        );
        setHeaderData(header);
        setShowHeaderData(
          showheader?.filter((field: string) => field !== "Parent_Id")
        );

        const finalVal = formattedData?.map((item: any) => {
          debugger;
          let bankDetail = null;
          let addressDetail = null;
          let payTerm = null;
          let contactInformation = null;
          let assetDetail = null;

          if (
            item?.Parent_Group === "Trade Payables - Sundry Creditors" ||
            item?.Parent_Group === "Trade Receivables - Sundry Debtors"
          ) {
            bankDetail = {
              // ledgerName: item?.BankLedgerName,
              uPIId: item?.UPIId,
              accountHolderName: item?.AccountHolderName,
              accountNumber: item?.AccountNumber,
              accountType: BankAccountTypes[item?.AccountType],
              bankName: item?.BankName,
              iFSCode: item?.IFSCode,
              branch: item?.Branch,
              oDLimit: item?.ODLimit,
              isDefault: item?.BankIsDefault,
            };

            addressDetail = [
              (Object.hasOwn(item, "Add1Name") && item?.Add1Name !== null) ||
              undefined
                ? {
                    type: TypeOfAddress[item.Add1Type],
                    addressName: item.Add1Name,
                    address: {
                      building: item?.Add1Building,
                      street: item?.Add1Street,
                      landMark: item?.Add1Landmark,
                      city: item?.Add1City,
                      district: item?.Add1District,
                      pincode: item?.Add1Pincode,
                      state: IndianStates[item?.Add1State?.split(" ")[2]],
                      // state1: item?.Add1State,
                      country: item?.Add1Country,
                    },
                  }
                : null,
              (Object.hasOwn(item, "Add2AddressName") &&
                item?.Add2AddressName !== null) ||
              undefined
                ? {
                    type: TypeOfAddress[item?.Add2Type],
                    addressName: item?.Add2AddressName,
                    address: {
                      building: item?.Add2Building,
                      street: item?.Add2Street,
                      landMark: item?.Add2Landmark,
                      city: item?.Add2City,
                      district: item?.Add2District,
                      pincode: item?.Add2Pincode,
                      state: IndianStates[item?.Add2State?.split(" ")[2]],
                      // state2: item?.Add2State,

                      country: item?.Add2Country,
                    },
                  }
                : null,
            ];
            // console.log("addressDetail =>", addressDetail);

            payTerm = {
              creditPeriod: item?.CreditPeriod,
              creditLimit: item?.CreditLimit,
            };
            contactInformation = {
              name: item?.Name,
              designation: item?.Designation,
              phone: item?.Phone,
              email: item?.Email,
              cCTo: item?.CCTo,
              isDefault: item?.IsDefault,
            };
          } else if (item?.Parent_Group === "Tangible Fixed Assets") {
            let SelGST = isNaN(item?.GST)
              ? `GST ${item?.GST}`
              : `GST ${item?.GST}%`;
            assetDetail = {
              assetType: item?.AssetType,
              isDepreciable: item?.IsDepreciable,
              description: item?.Description,
              category: item?.Category,
              depMethod:
                item?.DepMethod === "SLM - Straight Line Method"
                  ? CalcMethod.StraightLine
                  : CalcMethod.WrittenDownValue,
              depRate: item?.DepRate,
              lifeCycleDuration: item?.LifeCycleDuration,
              invoiceDetail: [
                {
                  date: dayjs("1900-01-01", "YYYY-MM-DD")
                    .add(item?.PurchaseDate - 2, "day")
                    .toISOString(),
                  key: 0,
                  price: item?.AssetValue,
                  qty: 1,
                  taxCredit: item?.TaxCredit,
                  purchaseType:
                    item?.AssetPurchaseType === "OldPurchase"
                      ? AssetPurType.OldPurchase
                      : AssetPurType.NewPurchase,
                  gst: companySettings?.gsts.find((g) => g.name === SelGST),
                  childAssetDetail: [
                    {
                      key: 0,
                      childAssetName: item?.LedgerName,
                      childStatus: AssetStatus.Active,
                      price: item?.AssetValue,
                      lifeCycleDuration: item?.LifeCycleDuration,
                      residualValue: item?.ResidualValue,
                      depStart: dayjs("1900-01-01", "YYYY-MM-DD")
                        .add(item?.DepreciationStartDate - 2, "day")
                        .toISOString(),
                    },
                  ],
                },
              ],
            };
          } else if (item?.Parent_Group === "Bank Account") {
            bankDetail = {
              // ledgerName: item?.BankLedgerName,
              uPIId: item?.UPIId,
              accountHolderName: item?.AccountHolderName,
              accountNumber: item?.AccountNumber,
              accountType: BankAccountTypes[item?.AccountType],
              bankName: item?.BankName,
              iFSCode: item?.IFSCode,
              branch: item?.Branch,
              oDLimit: item?.ODLimit,
              isDefault: item?.BankIsDefault,
            };
          }
          // let Duty = null;
          // if (
          //   item?.Parent_Group === "Duties & Taxes - GST" ||
          //   "Duties & Taxes - Income Tax"
          // ) {
          //   Duty = {
          //     typeOfDuty: Duty_Type[item?.TypeOfDuty],
          //     gSTTaxType: GSTTax_Type[item?.GSTTaxType],
          //     gSTRate:
          //       item?.GSTRate === "Exempt" || "Nil" || "0" ? 0 : item?.GSTRate,
          //   };
          // }
          // setData(finalVal);
          return {
            Parent: item?.Parent_Id,
            LedgerName: item?.LedgerName,
            AliasName: item?.AliasName,
            bankDetail: [bankDetail],
            contactInformation: [contactInformation],
            addressDetail: addressDetail,
            payTerm: payTerm,
            BusinessType: BusinessTypes[item?.BusinessType],
            businessNature: item?.BusinessNature
              ? [BusinessNatures[item?.BusinessNature]]
              : [],
            mSMENo: item?.MSMENo,
            state: IndianStates[item?.State?.split(" -")[1]],
            website: item?.Website,
            partyDisRate: item?.PartyDisRate,
            isTransporter: item?.isTransporter,
            tCSCollecteeType: item?.TCSCollecteeType,
            ledgerTCSApplicability: item?.LedgerTCSApplicability,
            deductTDSinSameVoucher: item?.DeductTDSinSameVoucher,
            tDSDeducteeType: DeducteeTypes[item?.TDSDeducteeType],
            tDSDeductible: item?.TDSDeductible,
            iEC: item?.IEC,
            tAN: item?.TAN,
            pAN: item?.PAN,
            gSTIN: item?.GSTIN,
            gSTType: CompanyGSTTypes[item?.GSTType],
            assetDetail: assetDetail,
          };
        });

        setData(finalVal);
        setForData(formattedData);
        const ledgerCount = finalVal.filter((item) => item.LedgerName).length;
        setLedgerCount(ledgerCount);
        LedgerService.ImportLedgers(currentClient?.id, finalVal).then(
          (res: any) => {
            if (res.status) {
              message.success("Ledger(s) imported sucessfully");
              setResult(res?.result);
              const den = res?.result.filter(
                (item: any) => item.added === 2
              ).length;
              const add = res?.result.filter(
                (item: any) => item.added === 0
              ).length;

              const exi = res?.result.filter(
                (item: any) => item.added === 1
              ).length;

              setDenLedName(den);
              setAddLedName(add);
              setExiLedName(exi);

              setLoad(false);
            } else {
              message.error(res?.message);
            }
          }
        );
      }
    };
    reader.readAsArrayBuffer(file);
    return false;
  };

  console.log("dataaaa", data.length);
  console.log("headerData", headerData);

  const handleDownload = () => {
    // Public folder ke andar Docs folder mein rakhi file ka URL
    const fileUrl = `${process.env.PUBLIC_URL}/Docs/LedgerMaster.xlsx`;
    // Hidden anchor element banaye jo file ko download karega
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "LedgerMaster.xlsx"; // File ka naam jo download hoga
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const dataSource = [
    // {
    //   key: "1",
    //   name: "Ignore",
    //   required: "",
    //   notes: "Any data in this field is ignored",
    // },
    {
      key: "1",
      name: "Basic detail",
      required: "",
      notes: "",
      subtitle: true,
    },
    {
      key: "2",
      name: "LedgerName",
      required: "YES",
      notes: (
        <>
          1. Only alphanumeric with space and some special characters i.e. '_',
          '(', ')', '&', '-', '.' and ',' are allowed.
          <br />
          2. Length must be Minimum 3 or Maximum 80 characters long.
        </>
      ),
    },
    // {
    //   key: "3",
    //   name: "Amount",
    //   required: "",
    //   notes:
    //     "Use this field for statements which provide single column for money-in and money-out.",
    // },
    {
      key: "4",
      name: "AliasName",
      required: "",
      notes: (
        <>
          1. Only alphanumeric with space and some special characters i.e. '_',
          '(', ')', '&', '-', '.' and ',' are allowed. <br />
          2. Length must be Minimum 3 or Maximum 20 characters long.
        </>
      ),
    },
    {
      key: "5",
      name: "Parent_Group",
      required: "YES",
      notes: "Selection of parent group is mandatory.",
    },
    // {
    //   key: "6",
    //   name: "BankLedgerName",
    //   required: "",
    //   notes: "Enter the BankLedgerName",
    // },
    {
      key: "6",
      name: "Bank detail (in case Parent group is bank type or party type group)",
      required: "",
      notes: "",
      subtitle: true,
    },

    {
      key: "8",
      name: "AccountHolderName",
      required: "YES",
      notes: (
        <>
          1. Only alphanumeric with space and some special characters i.e. '_',
          '(', ')', '&', '-', '.' and ',' are allowed. <br />
          2. Length must be Minimum 3 or Maximum 80 characters long.
        </>
      ),
    },
    {
      key: "9",
      name: "AccountNumber",
      required: "YES",
      notes: (
        <>
          1. Enter valid account number in digits.
          <br />
          2. Length must be Minimum 6 or Maximum 18 characters long.
        </>
      ),
    },
    {
      key: "10",
      name: "AccountType",
      required: "YES",
      notes: "Select the appropriate Account Type.",
    },
    {
      key: "11",
      name: "BankName",
      required: "",
      notes: (
        <>
          1. Only alphanumeric with space and some special characters i.e. '_',
          '(', ')', '&', '-', '.' and ',' are allowed. <br />
          2. Length must be Minimum 3 or Maximum 80 characters long.
        </>
      ),
    },
    {
      key: "12",
      name: "IFSCode",
      required: "YES",
      notes: "Enter valid 11 alphanumeric IFS code.",
    },
    {
      key: "13",
      name: "Branch",
      required: "",
      notes:
        "Enter the branch name. Length must be Minimum 3 or Maximum 50 characters long.",
    },
    {
      key: "14",
      name: "ODLimit",
      required: "",
      notes: "Enter the limit in amount.",
    },
    {
      key: "7",
      name: "UPIId",
      required: "",
      notes: "Enter the valid UPIId",
    },

    {
      key: "15",
      name: "BankIsDefault",
      required: "",
      notes: "Select true or false.",
    },
    // {
    //   key: "16",
    //   name: "TypeOfDuty",
    //   required: "Yes",
    //   notes: "Select the type of duty.",
    // },
    // {
    //   key: "17",
    //   name: "GSTTaxType",
    //   required: "YES",
    //   notes: "Select the GST tax type if type of duty is GST.",
    // },
    // {
    //   key: "18",
    //   name: "GSTRate",
    //   required: "YES",
    //   notes: "Select the GST Rate Detail",
    // },
    {
      key: "18",
      name: "Party detail",
      required: "",
      notes: "",
      subtitle: true,
    },

    {
      key: "19",
      name: "Add1Type",
      required: "",
      notes: "Select the address type.",
    },
    {
      key: "20",
      name: "Add1Name",
      required: "YES",
      notes: (
        <>
          1. Only alphabets with space and some special characters i.e. '(', ')'
          and '&' are allowed. <br />
          2. Length must be Minimum 3 or Maximum 30 characters long.
        </>
      ),
    },
    {
      key: "21",
      name: "Add1Building",
      required: "",
      notes: "Maximum 150 characters are allowed.",
    },
    {
      key: "22",
      name: "Add1Street",
      required: "",
      notes: "Maximum 150 characters are allowed.",
    },
    {
      key: "23",
      name: "Add1Landmark",
      required: "",
      notes: "Maximum 150 characters are allowed.",
    },
    {
      key: "24",
      name: "Add1City",
      required: "",
      notes: (
        <>
          1. Only alphabets with space and some special characters i.e. '(', ')'
          and '&' are allowed. <br />
          2. Length must be Minimum 3 or Maximum 30 characters.
        </>
      ),
    },
    {
      key: "25",
      name: "Add1District",
      required: "",
      notes: (
        <>
          1. Only alphabets with space and some special characters i.e. '(', ')'
          and '&' are allowed. <br />
          2. Length must be Minimum 3 or Maximum 30 characters.
        </>
      ),
    },
    {
      key: "26",
      name: "Add1Pincode",
      required: "",
      notes: "Enter valid 6 digit Pincode.",
    },
    {
      key: "27",
      name: "Add1State",
      required: "YES",
      notes: "Select the appropriate State.",
    },
    {
      key: "28",
      name: "Add1Country",
      required: "",
      notes: "Enter the Country name.",
    },
    {
      key: "29",
      name: "Add2Type",
      required: "",
      notes: "Select the address type.",
    },
    {
      key: "30",
      name: "Add2Name",
      required: "YES",
      notes: (
        <>
          1. Only alphabets with space and some special characters i.e. '(', ')'
          and '&' are allowed. <br />
          2. Length must be Minimum 3 or Maximum 30 characters long.
        </>
      ),
    },
    {
      key: "31",
      name: "Add2Building",
      required: "",
      notes: "Maximum 150 characters are allowed.",
    },
    {
      key: "32",
      name: "Add2Street",
      required: "",
      notes: "Maximum 150 characters are allowed.",
    },
    {
      key: "33",
      name: "Add2Landmark",
      required: "",
      notes: "Maximum 150 characters are allowed.",
    },
    {
      key: "34",
      name: "Add2City",
      required: "",
      notes: (
        <>
          1. Only alphabets with space and some special characters i.e. '(', ')'
          and '&' are allowed. <br />
          2. Length must be Minimum 3 or Maximum 30 characters.
        </>
      ),
    },
    {
      key: "35",
      name: "Add2District",
      required: "",
      notes: (
        <>
          1. Only alphabets with space and some special characters i.e. '(', ')'
          and '&' are allowed. <br />
          2. Length must be Minimum 3 or Maximum 30 characters.
        </>
      ),
    },
    {
      key: "36",
      name: "Add2Pincode",
      required: "",
      notes: "Enter valid 6 digit Pincode.",
    },
    {
      key: "37",
      name: "Add2State",
      required: "YES",
      notes: "Select the appropriate State.",
    },
    {
      key: "38",
      name: "Add2Country",
      required: "",
      notes: "Enter the Country name.",
    },
    {
      key: "40",
      name: "CreditPeriod",
      required: "",
      notes: "Enter the credit period in days.",
    },
    {
      key: "41",
      name: "CreditLimit",
      required: "",
      notes: "Enter the credit limit amount.",
    },
    {
      key: "42",
      name: "Name",
      required: "",
      notes: (
        <>
          1. Only alphanumeric with space and some special characters i.e. '_',
          '(', ')', '&' and '-' are allowed. <br />
          2. Length must be minimum 3 or maximum 30 characters.
        </>
      ),
    },
    {
      key: "43",
      name: "Designation",
      required: "",
      notes: (
        <>
          1. Only alphanumeric with space and some special characters i.e. '_',
          '(', ')', '&' and '-' are allowed. <br />
          2. Length must be minimum 3 or maximum 30 characters.
        </>
      ),
    },
    {
      key: "44",
      name: "Phone",
      required: "",
      notes: "Length must be 10 digits long.",
    },
    {
      key: "45",
      name: "Email",
      required: "",
      notes:
        "Enter a valid email address and length must be minimum 10 or maximum 80 characters long.",
    },
    {
      key: "46",
      name: "CCTo",
      required: "",
      notes:
        "Enter the valid email address(es) and length must be minimum 10 or maximum 250 characters long.",
    },
    {
      key: "47",
      name: "IsDefault",
      required: "",
      notes: "Select true or false.",
    },
    {
      key: "",
      name: "Business detail (only in case of company, firm, etc.)",
      required: "",
      notes: "",
      subtitle: true,
    },

    {
      key: "48",
      name: "BusinessType",
      required: "",
      notes: "Select the business type.",
    },
    {
      key: "49",
      name: "BusinessNature",
      required: "",
      notes: "Select the nature of the business.",
    },
    {
      key: "50",
      name: "MSMENo",
      required: "",
      notes: "Enter the MSME No, if have.",
    },
    {
      key: "51",
      name: "State",
      required: "",
      notes: "Select the State name.",
    },
    {
      key: "52",
      name: "Website",
      required: "",
      notes: "Enter the website url of the business.",
    },
    {
      key: "53",
      name: "GSTType",
      required: "",
      notes: "Select the GST Type like Regular or Unregistered.",
    },
    {
      key: "54",
      name: "GSTIN",
      required: "YES",
      notes: "Enter the GST No. if business is GST registered.",
    },
    {
      key: "55",
      name: "PAN",
      required: "YES",
      notes: "Enter the PAN No. of the business.",
    },
    {
      key: "56",
      name: "TAN",
      required: "",
      notes: "Enter the TAN No. of the business.",
    },
    {
      key: "57",
      name: "IEC",
      required: "",
      notes: "Enter the import export code (IEC) of the business, if have any.",
    },
    {
      key: "58",
      name: "TDSDeductible",
      required: "",
      notes: "Select if business deduct the TDS.",
    },
    {
      key: "59",
      name: "TDSDeducteeType",
      required: "",
      notes: "Select the TDS Deductee Type if business deducts the TDS.",
    },
    {
      key: "60",
      name: "DeductTDSinSameVoucher",
      required: "",
      notes: "Select if you want to deduct the TDS in same voucher.",
    },
    {
      key: "61",
      name: "LedgerTCSApplicability",
      required: "",
      notes: "Select if business collect the TCS.",
    },
    {
      key: "62",
      name: "TCSCollecteeType",
      required: "",
      notes: "Select the TCS Collectee Type if business collects the TCS.",
    },
    {
      key: "63",
      name: "PartyDisRate",
      required: "",
      notes: "Enter the discount rate applied to specific party.",
    },
    {
      key: "64",
      name: "In case of Creditors only",
      required: "",
      notes: "",
      subtitle: true,
    },
    {
      key: "65",
      name: "IsTransporter",
      required: "",
      notes: "Select if party is transporter.",
    },
  ];

  const columns: TableColumnsType<any> = [
    {
      title: "Field Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (text, record) => (record.subtitle ? <u>{text}</u> : <>{text}</>),
      onCell: (record, index) => ({
        colSpan: record?.subtitle ? 3 : 1,
      }),
    },
    {
      title: "Required",
      dataIndex: "required",
      key: "require",
      width: "10%",
      onCell: (record, index) => ({
        colSpan: record?.subtitle ? 0 : 1,
      }),
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      onCell: (record, index) => ({
        colSpan: record?.subtitle ? 0 : 1,
      }),
    },
  ];

  const columnsSec: TableColumnsType<any> = headerData
    ?.filter((field: string) => field !== "Parent_Id")
    ?.map((field: string, index: number) => ({
      title: showHeaderData[index],
      dataIndex: field,
      key: field,
      // width: "15%",
      fixed: index < 3 ? "left" : undefined,
    }));

  columnsSec.unshift({
    title: "Result",
    dataIndex: "result",
    key: "0",
    fixed: "left",
    render: (_: any, record: any, index: number) => {
      return result?.[index]?.added === ImportLedgerAddType.Added ? (
        <span style={{ color: "green" }}>Imported</span>
      ) : result?.[index]?.added === ImportLedgerAddType.Denied ? (
        <span style={{ color: "red" }}>{result?.[index]?.erroemessage}</span>
      ) : (
        ImportLedgerAddType[result?.[index]?.added] || "No Data"
      );
    },
  });

  data.unshift({
    result,
  });

  console.log("forData", forData);

  return (
    <>
      <Modal
        width={1200}
        // open={open}
        open
        title="Ladger Transaction"
        onCancel={() => props.onCancel()}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button onClick={() => props.onCancel()}>Close</Button>
          </div>
        }
      >
        <Row
          gutter={20}
          style={{ justifyContent: "space-between", padding: "15px" }}
        >
          <Col span={7} className="card">
            <p
              style={{
                fontSize: "15px",
                paddingTop: "1px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Step 1. Download our ledger master template file.
            </p>
            <p
              style={{
                paddingTop: "10px",
                lineHeight: "1.4rem",
                color: "black",
              }}
            >
              You can download our XLSX ledger master template file. The file
              has got accurate prescribed headings which are to be imported.
            </p>
            <p
              style={{
                color: "blue",
                cursor: "pointer",
                paddingTop: "5px",
              }}
              onClick={handleDownload}
            >
              <span>
                <DownloadOutlined /> Download Template
              </span>
            </p>
          </Col>
          <Col span={7} className="card" style={{ margin: "0px 10px" }}>
            <p
              style={{
                fontSize: "15px",
                paddingTop: "1px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Step 2. Copy Data into template file.
            </p>
            <p
              style={{
                paddingTop: "10px",
                height: "100%",
                lineHeight: "1.4rem",
                color: "black",
              }}
            >
              You can copy your data into the template file using excel or any
              other spreadsheet editor. Make sure the ledger master data you
              copied, matches the column headings provided in the template.
              Headings are not changeable.
            </p>
          </Col>
          <Col span={7} className="card">
            <p
              style={{
                fontSize: "15px",
                paddingTop: "1px",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Step 3. Import the filled template file.
            </p>
            <p
              style={{
                paddingTop: "10px",
                height: "100%",
                lineHeight: "1.4rem",
                color: "black",
              }}
            >
              <Upload
                //   {...Props}
                accept=".xlsx"
                showUploadList={false}
                beforeUpload={handleBeforeUpload}
              >
                <Button
                  disabled={load}
                  icon={<UploadOutlined />}
                  type="primary"
                >
                  Import
                </Button>
              </Upload>
            </p>
          </Col>
        </Row>
        <div style={{ marginTop: "20px" }}>
          {data?.length === 1 ? (
            <>
              <p
                style={{
                  padding: "5px 10px 5px 5px",
                  // boxShadow: " 0 2px 6px rgba(0, 0, 0, 0.1)",
                  // width: "auto",
                  fontSize: "16px",
                  // borderRadius: "5px",
                  fontStyle: "oblique",
                }}
              >
                <u>Available Fields</u>
              </p>
              <Table
                bordered
                pagination={false}
                className={`Tabel-style table-${
                  themeData?.componentSize ?? "middle"
                }`}
                size="small"
                columns={columns}
                dataSource={dataSource}
                scroll={{ y: window.innerHeight - 600 }}
              />
            </>
          ) : (
            <>
              <Table
                bordered
                pagination={false}
                className={`Tabel-style table-${
                  themeData?.componentSize ?? "middle"
                }`}
                size="small"
                columns={columnsSec}
                dataSource={forData}
                // scroll={{ y: window.innerHeight - 600 }}
                style={{ width: "100%" }}
                scroll={{ x: "max-content" }}
              />

              <div style={{ marginTop: "20px" }}>
                <div
                  style={{
                    marginTop: "20px",
                    // background: "#f9f9f9",
                    padding: "16px",
                    // borderRadius: "8px",
                    // boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Row gutter={[16, 16]} justify="space-between" align="middle">
                    <Col>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#1890ff",
                        }}
                      >
                        Total Ledgers Imported:
                      </span>
                      <span
                        style={{
                          marginLeft: "8px",
                          fontSize: "16px",
                          color: "#333",
                        }}
                      >
                        {ledgerCount}
                      </span>
                    </Col>
                    <Col>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#52c41a",
                        }}
                      >
                        Imported Ledgers:
                      </span>
                      <span
                        style={{
                          marginLeft: "8px",
                          fontSize: "16px",
                          color: "#333",
                        }}
                      >
                        {addLedName}
                      </span>
                    </Col>
                    <Col>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#faad14",
                        }}
                      >
                        Existed Ledgers:
                      </span>
                      <span
                        style={{
                          marginLeft: "8px",
                          fontSize: "16px",
                          color: "#333",
                        }}
                      >
                        {exiLedName}
                      </span>
                    </Col>
                    <Col>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "#b80000",
                        }}
                      >
                        Denied Ledgers:
                      </span>
                      <span
                        style={{
                          marginLeft: "8px",
                          fontSize: "16px",
                          color: "#333",
                        }}
                      >
                        {denLedName}
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ImportLedgerModal;
