import { BusinessTypes, IndianStates } from "../../Types/CommonConstants";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { ClientStatus, LedgerStatus } from "./LedgerService";

export enum Product_Module {
  OnePlan,
  Bookkeeping,
  InventoryWithBookkeeping,
  Billing,
  Taxation,
}
export enum DeducteeTypes {
  Unspecified,
  AOP_Co_NonResident, //        [Display(Name = "Association of Person (Companies as Members) - Non Resident")]
  AOP_Co_Resident, //        [Display(Name = "Association of Person (Companies as Members) - Resident")]
  AOP_NonResident, //        [Display(Name = "Association of Person - Non Resident")]
  AOP_Resident, //        [Display(Name = "Association of Person - Resident")]
  AJP_NonResident, //        [Display(Name = "Artificial Judicial Person - Non Resident")]
  AJP_Resident, //         [Display(Name = "Artificial Judicial Person - Resident")]
  BOI_NonResident, //         [Display(Name = "Body of Individuals - Non Resident")]
  BOI_Resident, //         [Display(Name = "Body of Individuals - Resident")]
  Company_NonResident, //        [Display(Name = "Company - Non Resident")]
  Company_Resident, //         [Display(Name = "Company - Resident")]
  Co_op_Society_NonResident, //         [Display(Name = "Co-operative Society - Non Resident")]
  Co_op_Society_Resident, //         [Display(Name = "Co-operative Society - Resident")]
  PartnershipFirm_NonResident, //        [Display(Name = "Partnership Firm - Non Resident")]
  PartnershipFirm_Resident, //         [Display(Name = "Partnership Firm - Resident")]
  Individual_NonResident, //         [Display(Name = "Individual - Non Resident")]
  Individual_Resident, //         [Display(Name = "Individual - Resident")]
  HUF_NonResident, //         [Display(Name = "HUF (Hindu Undivided Family) - Non Resident")]
  HUF_Resident, //         [Display(Name = "HUF (Hindu Undivided Family) - Resident")]
  Government, //         [Display(Name = "Government")]
  LocalAuthority, //         [Display(Name = "Local Authority")]
  Other_NonResident, //         [Display(Name = "Other - Non Resident")]
  Other_Resident, //         [Display(Name = "Other - Resident")]
}

export const getTDSDeducteeTypeName = (type: string) => {
  let str = "";
  switch (type) {
    case DeducteeTypes.AOP_Co_NonResident.toString():
      str = "Association of Person (Companies as Members) - Non Resident";
      break;
    case DeducteeTypes.AOP_Co_Resident.toString():
      str = "Association of Person (Companies as Members) - Resident";
      break;
    case DeducteeTypes.AOP_NonResident.toString():
      str = "Association of Person - Non Resident";
      break;
    case DeducteeTypes.AOP_Resident.toString():
      str = "Association of Person - Resident";
      break;
    case DeducteeTypes.AJP_NonResident.toString():
      str = "Artificial Judicial Person - Non Resident";
      break;
    case DeducteeTypes.AJP_Resident.toString():
      str = "Artificial Judicial Person - Resident";
      break;
    case DeducteeTypes.BOI_NonResident.toString():
      str = "Body of Individuals - Non Resident";
      break;
    case DeducteeTypes.BOI_Resident.toString():
      str = "Body of Individuals - Resident";
      break;
    case DeducteeTypes.Company_NonResident.toString():
      str = "Company - Non Resident";
      break;
    case DeducteeTypes.Company_Resident.toString():
      str = "Company - Resident";
      break;
    case DeducteeTypes.Co_op_Society_NonResident.toString():
      str = "Co-operative Society - Non Resident";
      break;
    case DeducteeTypes.Co_op_Society_Resident.toString():
      str = "Co-operative Society - Resident";
      break;
    case DeducteeTypes.PartnershipFirm_NonResident.toString():
      str = "Partnership Firm - Non Resident";
      break;
    case DeducteeTypes.PartnershipFirm_Resident.toString():
      str = "Partnership Firm - Resident";
      break;
    case DeducteeTypes.Individual_NonResident.toString():
      str = "Individual - Non Resident";
      break;
    case DeducteeTypes.Individual_Resident.toString():
      str = "Individual - Resident";
      break;
    case DeducteeTypes.HUF_NonResident.toString():
      str = "HUF (Hindu Undivided Family) - Non Resident";
      break;
    case DeducteeTypes.HUF_Resident.toString():
      str = "HUF (Hindu Undivided Family) - Resident";
      break;
    case DeducteeTypes.Government.toString():
      str = "Government";
      break;
    case DeducteeTypes.LocalAuthority.toString():
      str = "Local Authority";
      break;
    case DeducteeTypes.Other_NonResident.toString():
      str = "Other - Non Resident";
      break;
    case DeducteeTypes.Other_Resident.toString():
      str = "Other - Resident";
      break;
    default:
      str = "Unspecified";
  }
  return str;
};

export const getBusinessTypeName = (type: BusinessTypes) => {
  let str = "";
  switch (type) {
    case BusinessTypes.PrivateLimited:
      str = "Private Limited";
      break;
    case BusinessTypes.PublicLimited:
      str = "Public Limited";
      break;
    case BusinessTypes.SoleProprietorship:
      str = "Sole Proprietorship";
      break;
    case BusinessTypes.Partnership:
      str = "Partnership";
      break;
    case BusinessTypes.LLP:
      str = "LLP";
      break;
    case BusinessTypes.LLC:
      str = "LLC";
      break;
    case BusinessTypes.JointVenture:
      str = "Joint Ventures";
      break;
    case BusinessTypes.HUF:
      str = "Hindu Undivided Family";
      break;
    case BusinessTypes.NGO:
      str = "NGO";
      break;
    case BusinessTypes.GA:
      str = "Government Authority (Local Authority)";
      break;
  }
  return str;
};

export const getIndianStates = (type: IndianStates) => {
  let str = "";
  switch (type) {
    case IndianStates.JammuAndKashmir:
      str = "Jammu and Kashmir";
      break;
    case IndianStates.HimachalPradesh:
      str = "Himachal Pradesh";
      break;
    case IndianStates.Punjab:
      str = "Punjab";
      break;
    case IndianStates.Chandigarh:
      str = "Chandigarh";
      break;
    case IndianStates.Uttarakhand:
      str = "Uttarakhand";
      break;
    case IndianStates.Haryana:
      str = "Haryana";
      break;
    case IndianStates.Delhi:
      str = "Delhi";
      break;
    case IndianStates.Rajasthan:
      str = "Rajasthan";
      break;
    case IndianStates.UttarPradesh:
      str = "Uttar Pradesh";
      break;
    case IndianStates.Bihar:
      str = "Bihar";
      break;
    case IndianStates.Sikkim:
      str = "Sikkim";
      break;
    case IndianStates.ArunachalPradesh:
      str = "Arunachal Pradesh";
      break;
    case IndianStates.Nagaland:
      str = "Nagaland";
      break;
    case IndianStates.Manipur:
      str = "Manipur";
      break;
    case IndianStates.Mizoram:
      str = "Mizoram";
      break;
    case IndianStates.Tripura:
      str = "Tripura";
      break;
    case IndianStates.Meghalaya:
      str = "Meghalaya";
      break;
    case IndianStates.Assam:
      str = "Assam";
      break;
    case IndianStates.WestBengal:
      str = "West Bengal";
      break;
    case IndianStates.Jharkhand:
      str = "Jharkhand";
      break;
    case IndianStates.Orissa:
      str = "Orissa";
      break;
    case IndianStates.Chhattisgarh:
      str = "Chhattisgarh";
      break;
    case IndianStates.MadhyaPradesh:
      str = "Madhya Pradesh";
      break;
    case IndianStates.Gujarat:
      str = "Gujarat";
      break;
    case IndianStates.DadraAndNagarHaveliDamanAndDiu:
      str = "Dadra and Nagar Haveli and Daman and Diu";
      break;
    case IndianStates.Maharashtra:
      str = "Maharashtra";
      break;
    case IndianStates.AndhraPradeshOld:
      str = "Andhra Pradesh (Old)";
      break;
    case IndianStates.Karnataka:
      str = "Karnataka";
      break;
    case IndianStates.Goa:
      str = "Goa";
      break;
    case IndianStates.Lakshadweep:
      str = "Lakshadweep";
      break;
    case IndianStates.Kerala:
      str = "Kerala";
      break;
    case IndianStates.TamilNadu:
      str = "TamilNadu";
      break;
    case IndianStates.Puducherry:
      str = "Puducherry";
      break;
    case IndianStates.AndamanAndNicobarIslands:
      str = "Andaman and NicobarIslands";
      break;
    case IndianStates.Telangana:
      str = "Telangana";
      break;
    case IndianStates.AndhraPradeshNew:
      str = "Andhra Pradesh (New)";
      break;
    case IndianStates.Ladakh:
      str = "Ladakh";
      break;
    case IndianStates.OtherTerritory:
      str = "Other Territory";
      break;
    case IndianStates.CentreJurisdiction:
      str = "Centre Jurisdiction";
      break;
  }
  return str;
};

export enum BusinessNatures {
  All,
  Manufacturer,
  ServiceProvider,
  Trader,
  // Hybrid,
}
export enum BankAccountTypes {
  All,
  SavingAccount,
  CurrentAccount,
  CashCreditAccount,
  OverdraftAccount,
  CreditCard,
}
class ClientService {
  route = "/API/Acc_New_Client";

  getStateOption = (withEmpty: boolean) =>
    ApiUtility.get(`${this.route}/StateOptions?withEmpty=${withEmpty}`);

  // https: //localhost:7142/v1/api/Acc_New_Company/6463611020350584c0d5c017/GetList?type=0
  // new : https://localhost:7142/API/Acc_New_Client/GetList?start=0&length=15&sortCol=Name&sortDir=asc
  getClientList = (
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    type?: BusinessTypes,
    status: ClientStatus = ClientStatus.Active,
    module: Product_Module = Product_Module.Bookkeeping
  ) =>
    ApiUtility.getResult(`${this.route}/GetList?`, {
      start,
      length,
      sortCol,
      sortDir,
      type,
      search,
      status,
      module,
    });

  getClient = (id: string) => ApiUtility.get(`${this.route}${`/` + id}`);

  post = (params: any, id?: any) =>
    ApiUtility.post(`${this.route}${id ? "/id?id=" + id : ""}`, params);

  searchClientName = (search?: string) =>
    ApiUtility.get(`${this.route}/search?search=${search}`);
  // https: //localhost:7142/API/Acc_Client/search?search=av

  //------- Account Group && Account Type Api Services -------
  // clientAccountsProduction = () =>
  //   ApiUtility.get(`/v1/api/Context_AccountsProduction`);

  // ------- GST Api ---------

  //  https://localhost:7142/V1/API/Acc_Gst
  // https: //localhost:7142/V1/API/Acc_Gst/1
  postGst = (params: any, id?: number) =>
    ApiUtility.post(`/v1/API/Acc_Gst/${id ? id : ""}`, params);

  // https: //localhost:7142/V1/API/Acc_Gst?start=0&length=15&IsActive=true
  gstGstList = (
    start: number,
    length: number,
    IsActive: boolean,
    search?: string
  ) =>
    ApiUtility.getResult(`/v1/API/Acc_Gst`, {
      start,
      length,
      IsActive,
      search,
    });

  getClientSummary = (module?: Product_Module) =>
    ApiUtility.get(`${this.route}/ClientSummary?Module=${module}`);

  deleteClientRow = (clientId: any) =>
    ApiUtility.delete(`${this.route}/${clientId}`);

  changeCompanyStatus = (companyId: string) =>
    ApiUtility.put(`${this.route}/ActiveDeactiveClient/${companyId}`, {});

  // https://localhost:7142/API/Acc_Client/cashBalance/6463611020350584c0d5c017
  // getCashInHandBalance = (clientId: string) =>
  //   ApiUtility.get(`${this.route}/cashBalance/${clientId}`);
}
export default new ClientService();
