import {
  Avatar,
  Col,
  Divider,
  Modal,
  Row,
  Tooltip,
  Button,
  Checkbox,
  Spin,
} from "antd";
import React from "react";
import { useClient } from "../../Context/ClientContext";
import { NavLink, useNavigate } from "react-router-dom";
import noteContext from "../../Context/StyleContext";
import { Utils } from "../../../Utilities/Utils";
import "../../Common/AddPageCommon.css";
import ExpensesAndIncomeService from "../../Services/ExpensesAndIncomeService";
import { useIndianState } from "../../Context/StateOptionsContext";
import { IIndianState } from "../../../Types";
import { LoadingOutlined } from "@ant-design/icons";

const ViewIncome: React.FC<{
  open: boolean;
  onCancel: any;
  invoiceId: string;
}> = (props) => {
  console.log(props);
  const navigate = useNavigate();
  const { windowWidth } = React.useContext(noteContext);
  const { currentClient } = useClient();
  const { stateOptions } = useIndianState();
  const [itemLength, setItemLength] = React.useState<number>(0);
  const [data, setData] = React.useState<any>({});
  const [gstCheckbox, setGstCheckbox] = React.useState<boolean>(false);
  const [sameStateOption, setSameStateOption] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const isStateSame = currentClient?.registeredAddress?.state!;

  React.useEffect(() => {
    if (props.invoiceId) {
      setLoading(true);
      ExpensesAndIncomeService.getExpensesInvoice(
        currentClient?.id!,
        props.invoiceId
      )
        .then((res: any) => {
          setLoading(false);
          if (res.result) {
            setGstCheckbox(res?.result?.isGst);
            setSameStateOption(isStateSame === res?.result?.supplyState);
            const state = stateOptions?.find(
              (itm: IIndianState) => itm?.id === res?.result?.supplyState
            ) as IIndianState | undefined;
            console.log("viewdata", res.result);
            setItemLength(res.result.items?.length);
            setData({
              tradingPartner: res?.result?.tradingPartner,
              number: res?.result?.number,
              paymentType: res?.result?.paymentType === 1 ? "Cash" : "Credit",
              date: Utils.getFormattedDate(res?.result?.date),
              supplyState: state?.name,
              items: res.result.items,
              totalQty: Utils.getFormattedNumber(
                res.result.amountSummary?.totalQty
              ),
              totalUnitPrice: Utils.getFormattedNumber(
                res.result.amountSummary?.totalPrice
              ),
              totalDiscount: Utils.getFormattedNumber(
                res.result.amountSummary?.totalDis
              ),
              sellPrice: Utils.getFormattedNumber(
                res.result.amountSummary?.sellPrice
              ),
              dueBalance: Utils.getFormattedNumber(
                res.result.newAmount?.dueBalance
              ),
              totalgst: res.result.amountSummary?.igst
                ? res.result.amountSummary?.igst
                : res.result.amountSummary?.sgst +
                  res.result.amountSummary?.cgst,
              receivedAmount: Utils.getFormattedNumber(
                res.result.amountSummary?.receivedAmount
              ),
              totalInvoiceAmount: Utils.getFormattedNumber(
                res.result.amountSummary?.totalInvoiceAmount
              ),
            });
          }
        })
        .catch((ex: any) => {
          setLoading(false);
          console.error(ex);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, props.invoiceId]);
  console.log("viewdatastate", data);

  return (
    <Modal
      open={props.open}
      width={1600}
      style={{ maxHeight: `calc(100% - 50px)`, overflowY: "auto" }}
      maskClosable={false}
      closable={false}
      onCancel={() => props.onCancel()}
      centered
      footer={
        <div style={{ marginTop: "0px" }} className="pe-15">
          <Button
            onClick={() => {
              props.onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            style={{ width: "90px" }}
            onClick={async () => {
              navigate(
                `/accounts/clients/${currentClient?.id}/income/editIncome/${props.invoiceId}`
              );
            }}
            disabled={
              data.totalInvoiceAmount === data.receivedAmount ||
              data.dueBalance! === 0
            }
          >
            Edit
          </Button>
        </div>
      }
    >
      <div style={{ padding: 15 }}>
        <Spin indicator={antIcon} spinning={loading}>
          {/* {windowWidth <= 1024 ? (
          <h3 className="fs-35 fw-500 pt-20 themeColor textCenter ">
            Quotation/Estimate
          </h3>
        ) : (
          ""
        )} */}
          <Row justify="space-between">
            <Col xl={12} lg={18} md={24} className="dashedBoxStyle">
              <Row gutter={20}>
                <Col lg={8} className="themeLightBgColor ml-10">
                  <span className="themeColor fw-500">Party Name</span>
                  <div>
                    <NavLink
                      to={`/accounts/clients/${currentClient?.id}/parties/${data.tradingPartner?.id}`}
                    >
                      {data.tradingPartner?.name}
                    </NavLink>
                  </div>
                  <span className="themeColor fw-500">Payment Method</span>
                  <div>{data.paymentType}</div>
                </Col>
                <Col lg={4}>
                  <span className="themeColor fw-500">Income No.</span>
                  <div>{data.number}</div>
                  <span className="themeColor fw-500">Date</span>
                  <div>{data.date}</div>
                </Col>
                <Col lg={7}>
                  {gstCheckbox ? (
                    <>
                      <Checkbox checked={gstCheckbox} disabled>
                        <span className="themeColor fw-500">Gst</span>
                      </Checkbox>
                    </>
                  ) : null}
                  {gstCheckbox ? (
                    <>
                      <div className="themeColor fw-500">Place of Supply</div>
                      <div>{data.supplyState}</div>
                    </>
                  ) : null}
                </Col>
              </Row>
            </Col>
            {windowWidth <= 1024 ? (
              ""
            ) : (
              <Col
                xl={4}
                lg={7}
                md={7}
                className="fs-35 fw-400 themeColor textEnd"
              >
                Income
              </Col>
            )}
          </Row>

          <table
            className="ca-viewmodel-table"
            style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
          >
            <thead>
              <tr>
                <th
                  rowSpan={2}
                  style={{ width: "4%" }}
                  className="textStart ps-10"
                >
                  S.No.
                </th>
                <th
                  rowSpan={2}
                  style={{
                    width: "14%",
                    borderLeft: "1px solid rgb(240, 245, 255)",
                  }}
                  className="textStart"
                >
                  Item
                </th>
                <th rowSpan={2} style={{ width: "5%" }} className="textStart">
                  Qty
                </th>
                <th rowSpan={2} style={{ width: "9%" }} className="textStart">
                  Unit Price
                </th>
                {gstCheckbox ? (
                  <>
                    <th
                      colSpan={2}
                      style={{ width: "12%" }}
                      className="textCenter"
                    >
                      Discount
                    </th>
                    <th
                      colSpan={2}
                      style={{ width: "12%" }}
                      className="textCenter "
                    >
                      GST %
                    </th>
                  </>
                ) : null}
                <th
                  rowSpan={2}
                  style={{ width: "14%" }}
                  className="pe-10 textEnd"
                >
                  Total Amount
                </th>
              </tr>
              {gstCheckbox ? (
                <>
                  <tr>
                    <th style={{ width: "5%" }} className="textCenter">
                      %
                    </th>
                    <th style={{ width: "5%" }} className="textCenter">
                      ₹
                    </th>
                    <th style={{ width: "6%" }} className="textCenter">
                      %
                    </th>
                    <th style={{ width: "4%" }} className="textCenter">
                      ₹
                    </th>
                  </tr>
                </>
              ) : null}
            </thead>

            <tbody>
              {data.items?.map((item: any, i: any) => {
                return (
                  <tr key={i}>
                    <td className="ps-10">{i + 1}.</td>
                    <td>{item?.name}</td>
                    <td className="textStart">
                      {Utils.getFormattedNumber(item.amount?.qty)}
                    </td>
                    <td className="textStart">
                      {Utils.getFormattedNumber(item.amount?.price)}
                    </td>
                    {gstCheckbox ? (
                      <>
                        <td className="textCenter">{item.amount?.discount}</td>
                        <td className="textCenter">
                          {Utils.getFormattedNumber(item.amount?.discountRupee)}
                        </td>
                        <td className="textCenter">{item.amount?.tax?.name}</td>
                        <td className="textCenter">
                          {Utils.getFormattedNumber(item.amount?.taxRs)}
                        </td>
                      </>
                    ) : null}
                    <td className="pe-10 textEnd">
                      {/* {gstCheckbox ? ( */}
                      <> {Utils.getFormattedNumber(item.amount?.totalAmount)}</>
                      {/* ) : (
                      <>{Utils.getFormattedNumber(item.amount?.price)} </>
                    )} */}
                    </td>
                  </tr>
                );
              })}
            </tbody>

            {itemLength > 0 && (
              <tfoot>
                <tr>
                  <th colSpan={2} className="textStart ps-10">
                    Total
                  </th>
                  <th className={"textStart"}>{data.totalQty}</th>
                  <th className="textStart">₹ {data.totalUnitPrice}</th>
                  {gstCheckbox ? (
                    <>
                      <th colSpan={2} className="textEnd pe-35">
                        ₹ {data.totalDiscount}
                      </th>
                      <th colSpan={2} className="textEnd pe-30">
                        ₹ {Utils.getFormattedNumber(data.totalgst)}
                      </th>
                    </>
                  ) : null}
                  <th colSpan={2} className="textEnd pe-10">
                    ₹ {data.totalInvoiceAmount}
                  </th>
                </tr>
              </tfoot>
            )}
          </table>

          <Row className="dashedBorderTop pt-10 pe-10">
            <Col lg={13} style={{ paddingTop: "15px" }}>
              {/* <p>Description : {data?.description}</p> */}
            </Col>
            <Col lg={6} offset={5}>
              <Row gutter={[0, 8]}>
                <Col lg={9}>Selling Price</Col>
                <Col lg={15} className="textEnd">
                  ₹ {data?.sellPrice}
                </Col>
                {gstCheckbox ? (
                  <>
                    <Col lg={9}>Discount</Col>
                    <Col lg={15} className="textEnd">
                      ₹ {data?.totalDiscount}
                    </Col>

                    {sameStateOption ? (
                      <>
                        <Col lg={9}>CGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {Utils.getFormattedNumber(data?.totalgst / 2)}
                        </Col>
                        <Col lg={9}>SGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {Utils.getFormattedNumber(data?.totalgst / 2)}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg={9}>IGST</Col>
                        <Col lg={15} className="textEnd">
                          ₹ {Utils.getFormattedNumber(data?.totalgst)}
                        </Col>
                      </>
                    )}
                  </>
                ) : null}
              </Row>
              <Divider className="my-5 grayBg" />
              <Row gutter={[12, 2]}>
                <Col lg={18}>Total Value</Col>

                <Col lg={6} className="textEnd">
                  ₹ {data?.totalInvoiceAmount}
                </Col>
              </Row>
              <Divider className="my-5 grayBg" />
            </Col>
          </Row>
        </Spin>
      </div>
    </Modal>
  );
};
export default ViewIncome;
