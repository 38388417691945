import React, { useEffect } from "react";
import { Button, Divider, Form, Input, Modal, Select, Spin } from "antd";
import "./ExpensesAndIncome.css";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import CategoryService from "../../Services/CategoryService";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";
import { LoadingOutlined } from "@ant-design/icons";
interface AddCategoryProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTrigger?: any;
  editCategory?: any;
  setNewCategory?: any;
  setnamecategory?: number;
  isDisable?: boolean;
}
const categoryOptions: any = [
  { name: "Expenses", id: 1 },
  { name: "Income", id: 2 },
];
const natureOptions: any = [
  { name: "Direct ", id: 0 },
  { name: "Indirect ", id: 1 },
];

const accountOption: any = [
  { name: "Compensation expense", id: 1 },
  { name: "Employee benefits", id: 2 },
  { name: "Dividend Income", id: 4 },
  { name: "Other Incomes", id: 5 },
  { name: "Taxes", id: 6 },
];

const AddCategoryModel = ({
  isModalOpen,
  setIsModalOpen,
  editCategory,
  setNewCategory,
  setTrigger,
  setnamecategory,
  isDisable,
}: AddCategoryProps) => {
  const [form] = Form.useForm();
  const [confirmation, setIsConfirmation] = React.useState<any>(false);
  const { openNotification } = React.useContext(notificationContext);
  const { currentClient } = useClient();
  const [modelLoading, setModelLoading] = React.useState<boolean>(false);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const onDismissConfirm = () => {
    setIsConfirmation(false);
  };

  const onDismissBothModels = () => {
    setIsConfirmation(false);
    setIsModalOpen(false);
    form.resetFields();
  };
  const handleCancel = () => {
    const findvalue = form.isFieldsTouched();
    if (findvalue) {
      setIsConfirmation(true);
    } else {
      setIsModalOpen(false);
    }
  };
  console.log("chek", isDisable);

  // --------Get Category with Id------------//
  useEffect(() => {
    if (isModalOpen) {
      if (editCategory?.id != null) {
        setModelLoading(true);
        CategoryService.getCategoryById(currentClient!.id, editCategory?.id)
          .then((res: any) => {
            if (res.result) {
              //console.log("getcategorydata", res.result);
              form.setFieldsValue({
                ...res?.result,
                accountType: {
                  value: res.result.accountType?.id,
                  label: res.result.accountType?.name,
                },
              });
            }

            setModelLoading(false);
          })
          .catch((err: any) => {
            setModelLoading(true);
            console.log("error", err);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  // --------Post Category------------//
  const addCategory = () => {
    form
      .validateFields()
      .then((values) => {
        setSaveButtonLoading(true);
        const category = {
          ...values,
          accountType: {
            name: values?.accountType?.label,
            id: Number(values?.accountType?.value),
          },
        };
        console.log("categoryconsole", category);
        CategoryService.PostCategory(
          currentClient?.id!,
          category,
          editCategory.id
        ).then((res) => {
          if (res?.status) {
            console.log("message", res);
            setNewCategory({
              id: res?.result.id,
              name: res?.result.name,
              isSaved: true,
            });
            openNotification(
              "success",
              `${
                editCategory?.id
                  ? "Category updated successfully."
                  : "Category added successfully."
              }`
            );
            form.resetFields();
            setSaveButtonLoading(false);
            setIsModalOpen(false);
            setTrigger((x: any) => !x);
          } else {
            setSaveButtonLoading(false);
            openNotification("error", `${category.name} already exists`);
          }
        });
      })
      .catch((err: any) => {
        console.log("error", err);
        setSaveButtonLoading(false);
      });
    //  });
  };

  return (
    <>
      <Modal
        title={editCategory?.id ? "Edit Category" : "Add Category"}
        destroyOnClose
        maskClosable={false}
        open={isModalOpen}
        onCancel={handleCancel}
        // onOk={() => setIsModalOpen(false)}
        width={400}
        className="ftmb"
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={addCategory}
              loading={saveButtonLoading}
            >
              {editCategory?.id ? "Update" : "Save"}
            </Button>
          </div>
        }
        //style={{ top: "70px" }}
        // centered={true}
      >
        <Divider className="ca-model-css" />
        <Spin indicator={antIcon} spinning={modelLoading}>
          <Form
            form={form}
            name="basic"
            autoCapitalize="false"
            requiredMark={false}
            disabled={false}
            colon={false}
            labelAlign="left"
            initialValues={{ category: setnamecategory }}
            // labelWrap={true}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 15 }}
            // style={{ marginTop: "13px" }}
          >
            <Form.Item
              name="category"
              label="Category Type"
              className="mb"
              rules={[
                {
                  required: true,
                  message: "Select category type",
                },
              ]}
            >
              <Select
                optionFilterProp="children"
                placeholder="Select type"
                filterOption={false}
                notFoundContent={null}
                options={categoryOptions.map((value: any) => ({
                  value: value.id,
                  label: value.name,
                }))}
                disabled={isDisable}
              />
            </Form.Item>
            <Form.Item
              name="name"
              label="Category Name"
              className="mb"
              rules={[
                {
                  required: true,
                  message: "Category can't be empty",
                },
              ]}
            >
              <Input
                onKeyPress={(e) =>
                  !/[A-Za-z\s]/.test(e.key) && e.preventDefault()
                }
              />
            </Form.Item>
            <Form.Item name="type" label="Nature Type" className="mb">
              <Select
                optionFilterProp="children"
                placeholder="Select type"
                filterOption={false}
                notFoundContent={null}
                options={natureOptions.map((value: any) => ({
                  value: value.id,
                  label: value.name,
                }))}
              />
            </Form.Item>
            <Form.Item name="accountType" label="Account Type" className="mb">
              <Select
                optionFilterProp="children"
                placeholder="Select type"
                labelInValue
                filterOption={false}
                notFoundContent={null}
                options={accountOption.map((value: any) => ({
                  value: value.id,
                  label: value.name,
                }))}
              />
            </Form.Item>
          </Form>
        </Spin>
        {confirmation && (
          <>
            <ConfirmationModal
              open={confirmation}
              onNo={onDismissConfirm}
              onYes={onDismissBothModels}
              text={
                "All details filled will be lost, are you sure you want to continue?"
              }
            />
          </>
        )}
      </Modal>
    </>
  );
};
export default AddCategoryModel;
