import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import React from "react";
import { NameTitles } from "../Services/SalesService";
import ClientService from "../Services/ClientService";
import { Utils } from "../../Utilities/Utils";
import {
  ITrialBalanceSettings,
  SettingsSections,
} from "../Services/SettingService";
import TextArea from "antd/es/input/TextArea";
import { useClient } from "../Context/ClientContext";

export const NameComponent = ({
  label,
  FormItemName,
  value,
  innerLabel,
  onChangeInput,
}: {
  label?: string;
  FormItemName: any;
  value: any;
  innerLabel: boolean;
  onChangeInput?: any;
}) => {
  const [full, setFull] = React.useState<string | undefined>(undefined);
  const [showFull, setShowFull] = React.useState<boolean>(true);
  const containerRef = React.useRef<any>();
  const [subLabel, setSubLabel] = React.useState<boolean>(true);
  const [width, SetWidth] = React.useState(window.innerWidth);

  // { value: NameTitles.Unknown, label: " " },
  const titles = [
    { value: NameTitles.Mr, label: "Mr." },
    { value: NameTitles.Mrs, label: "Mrs." },
    { value: NameTitles.Miss, label: "Miss." },
    { value: NameTitles.Sir, label: "Sir." },
    { value: NameTitles.Ms, label: "Ms." },
    { value: NameTitles.Dr, label: "Dr." },
    { value: NameTitles.Prof, label: "Prof." },
    { value: NameTitles.Madam, label: "Madam" },
    { value: NameTitles.Maam, label: "Ma'am" },
    { value: NameTitles.Lord, label: "Lord" },
    { value: NameTitles.Lady, label: "Lady" },
    { value: NameTitles.Revere, label: "Revere" },
  ];

  React.useEffect(() => {
    let fullName = "";

    if (value) {
      // console.log("value........", value);
      if (value?.title !== undefined) {
        fullName += value?.title ? titles[value?.title - 1]?.label + " " : " ";
      }
      if (value?.first) fullName += value?.first + " ";
      if (value?.last) fullName += value?.last + " ";
      setFull(fullName);
    }
    console.log(fullName, "valuee..................");
    setSubLabel(innerLabel);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [full, value]);

  window.addEventListener("resize", () => {
    SetWidth(window.innerWidth);
  });

  return (
    <div>
      {showFull ? (
        <>
          <span className="grayColor">{label}</span>
          <Input
            value={full}
            // className="inputBgColor"
            onFocus={(e) => {
              e.preventDefault();
              setShowFull(false);
            }}
            style={{ width: window.innerWidth === 350 ? "300px" : "" }}
          />
        </>
      ) : (
        <div
          ref={containerRef}
          onBlur={(e) => {
            if (!containerRef.current.contains(e.relatedTarget))
              setShowFull(true);
          }}
        >
          <Input.Group
            style={{ width: window.innerWidth === 350 ? "300px" : "" }}
          >
            <Row justify="space-between">
              <Col
                lg={18}
                span={16}
                style={{
                  display: "flex",
                  width: window.innerWidth === 350 ? "300px" : "",
                }}
              >
                <Form.Item
                  className="inputBoxMb"
                  name={[...FormItemName, "title"]}
                  style={{ width: 110 }}
                >
                  <Select
                    allowClear
                    // className="selectBgColor"
                    placeholder="Select"
                    popupMatchSelectWidth={false}
                    options={titles}
                  />
                </Form.Item>
                
                <Form.Item
                  className="inputBoxMb Errorname"
                  name={[...FormItemName, "first"]}
                >
                  <Input
                    placeholder={subLabel ? "" : "First"}
                    onChange={onChangeInput}
                    onKeyPress={(e) =>
                      !/[A-Za-z\s]/.test(e.key) && e.preventDefault()
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                lg={6}
                span={6}
                style={{ width: window.innerWidth === 350 ? "300px" : "" }}
              >
                <Form.Item
                  className="inputBoxMb"
                  name={[...FormItemName, "last"]}
                >
                  <Input
                    placeholder={subLabel ? "" : "Last "}
                    onChange={onChangeInput}
                    onKeyPress={(e) =>
                      !/[A-Za-z\s]/.test(e.key) && e.preventDefault()
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
        </div>
      )}
    </div>
  );
};

export interface IAddress {
  building: string;
  district: string;
  city: string;
  pincode: string;
  state: string;
}

export enum AddressViewTypes {
  Single,
  Double,
  Tripple,
}

export const AddressView = ({
  onFocus,
  address,
  view = AddressViewTypes.Double,
  viewOnly = false,
  disabled,
}: {
  onFocus?: () => void;
  address: Partial<IAddress>;
  view?: AddressViewTypes;
  viewOnly?: boolean;
  disabled?: boolean;
}) => {
  const [fragments, setFragments] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (address) {
      let frags: string[] = [];
      if (address.building) frags.push(address.building);
      if (address.district) frags.push(address.district);
      if (address.city) frags.push(address.city);
      if (address.pincode) frags.push(address.pincode);
      if (address.state) frags.push(address.state);
      setFragments(frags);
    }
  }, [address]);
  return (
    <div
      style={{
        height:
          view === AddressViewTypes.Double
            ? 52
            : view === AddressViewTypes.Tripple
            ? 78
            : 26,
      }}
      onClick={(e) => {
        e.preventDefault();
        if (onFocus && !disabled) onFocus();
      }}
    >
      {view === AddressViewTypes.Double ? (
        fragments.length > 0 ? (
          <div>
            <div style={{ height: 20, paddingLeft: 10 }}>
              {fragments.slice(0, 2).join(", ")}
            </div>
            <div style={{ height: 20, paddingLeft: 10 }}>
              {fragments.slice(2, fragments.length).join(", ")}
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{ height: 20, color: "rgb(163 159 159)", paddingLeft: 10 }}
            >
              Building, district, City,
            </div>
            <div
              style={{ height: 20, color: "rgb(163 159 159)", paddingLeft: 10 }}
            >
              PinCode, State
            </div>
          </div>
        )
      ) : view === AddressViewTypes.Tripple ? (
        fragments.length > 0 ? (
          fragments.length > 2 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "9px 13px",
              }}
            >
              <label
                style={{ height: 20, color: "rgba(0, 0, 0, 0.88)" }}
                title={fragments.slice(0, 2).join(", ")}
              >
                {fragments.slice(0, 2).join(", ")}
              </label>
              {fragments.length > 2 && (
                <label
                  style={{
                    height: 20,
                    color: "rgba(0, 0, 0, 0.88)",
                  }}
                  title={fragments.slice(2, 4).join(", ")}
                >
                  {fragments.slice(2, 4).join(", ")}
                </label>
              )}
              {fragments.length > 4 && (
                <label
                  style={{
                    height: 20,
                    color: "rgba(0, 0, 0, 0.88)",
                  }}
                  title={fragments.slice(4, fragments.length).join(", ")}
                >
                  {fragments.slice(4, fragments.length).join(", ")}
                </label>
              )}
            </div>
          ) : (
            <div style={{ height: 20, color: "rgba(0, 0, 0, 0.88)" }}>
              {fragments.join(", ")}
            </div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "9px 13px",
            }}
          >
            <label style={{ height: 20, color: "rgb(163 159 159)" }}>
              Building, District,
            </label>
            <label style={{ height: 20, color: "rgb(163 159 159)" }}>
              City, Pincode,
            </label>
            <label style={{ height: 20, color: "rgb(163 159 159)" }}>
              State
            </label>
          </div>
        )
      ) : (
        <div></div>
      )}
    </div>
  );
};

export const AddressFields = ({
  formItemName,
  address,
  view = AddressViewTypes.Double,
  disabled,
  onChange,
}: {
  formItemName: any;
  address: IAddress | undefined;
  view?: AddressViewTypes;
  disabled?: boolean;
  onChange?: (value: IAddress) => void;
}) => {
  const [showFull, setShowFull] = React.useState<boolean>(true);
  const [statesOption, setStatesOption] = React.useState<any[]>([]);
  const [viewAddress, setViewAddress] = React.useState<string>("");
  const [value, setValue] = React.useState<IAddress>({
    building: "",
    district: "",
    city: "",
    pincode: "",
    state: "",
  });
  const containerRef = React.useRef<any>();

  React.useEffect(() => {
    ClientService.getStateOption(false).then((res: any) => {
      setStatesOption(res?.result);
      setViewAddress(
        res?.result[Utils.getNumber(address?.state) - 1]?.name || ""
      );
    });
  }, [address?.state]);

  React.useEffect(() => {
    console.log("address in cap", address);
    if (address) {
      setValue({
        ...address,
      });
    }
  }, [address]);

  const viewAddressData: Partial<IAddress> = { ...address, state: viewAddress };
  return (
    <>
      {showFull ? (
        <AddressView
          address={viewAddressData}
          onFocus={() => {
            setShowFull(false);
          }}
          view={view}
          disabled={disabled}
        />
      ) : (
        <div
          ref={containerRef}
          onBlur={(e) => {
            if (!containerRef.current.contains(e.relatedTarget)) {
              setShowFull(true);
              if (
                onChange &&
                (value.building !== address?.building ||
                  value.district !== address?.district ||
                  value.city !== address?.city ||
                  value.pincode !== address?.pincode ||
                  value.state !== address?.state)
              )
                onChange(value);
            }
          }}
        >
          {view === AddressViewTypes.Double ? (
            <Input.Group>
              <Row>
                <Col span={8}>
                  <Form.Item
                    name={[formItemName, "building"]}
                    className="inputBoxMb"
                  >
                    <Input
                      // className="inputBgColor"
                      placeholder="Building"
                      value={value.building}
                      onChange={(e) => {
                        setValue({ ...value, building: e.target.value });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[formItemName, "Street"]}
                    className="inputBoxMb"
                  >
                    <Input
                      // className="inputBgColor"
                      placeholder="Street"
                      value={value.district}
                      onChange={(e) => {
                        setValue({
                          ...value,
                          district: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={[formItemName, "landmark"]}
                    className="inputBoxMb"
                  >
                    <Input
                      // className="inputBgColor"
                      placeholder="Landmark"
                      value={value.city}
                      onChange={(e) => {
                        setValue({
                          ...value,
                          city: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Form.Item
                    name={[formItemName, "city"]}
                    className="inputBoxMb"
                  >
                    <Input
                      // className="inputBgColor"
                      placeholder="City"
                      value={value.building}
                      onChange={(e) => {
                        setValue({ ...value, building: e.target.value });
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name={[formItemName, "pincode"]}
                    className="inputBoxMb"
                  >
                    <Input
                      // className="inputBgColor"
                      placeholder="Pincode"
                      value={value.pincode}
                      onChange={(e) => {
                        setValue({
                          ...value,
                          pincode: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name={[formItemName, "district"]}
                    className="inputBoxMb"
                  >
                    <Input
                      // className="inputBgColor"
                      placeholder="District"
                      value={value.district}
                      onChange={(e) => {
                        setValue({
                          ...value,
                          district: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={8}>
                  <Form.Item
                    name={[formItemName, "state"]}
                    className="inputBoxMb"
                  >
                    {/* <Input className="inputBgColor" placeholder="state" /> */}
                    <Select
                      // className="selectBgColor"
                      showSearch
                      placeholder="State"
                      optionFilterProp="children"
                      value={value.state}
                      onChange={(e) => {
                        setValue({
                          ...value,
                          state: e!,
                        });
                      }}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={statesOption.map((x: any) => ({
                        value: x.id,
                        label: x.name,
                      }))}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name={[formItemName, "country"]}
                    className="inputBoxMb"
                  >
                    {/* <Input className="inputBgColor" placeholder="state" /> */}
                    <Select
                      // className="selectBgColor"
                      showSearch
                      placeholder="Country"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={statesOption.map((x: any) => ({
                        value: x.id,
                        label: x.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </>
  );
};

export const ReportSettings = ({
  isOpen,
  type,
  setOpen,
  value,
  refresh,
  setRefresh,
}: {
  isOpen: boolean;
  setOpen: (value: boolean) => void;
  type: "PnL" | "TB" | "Ageing";
  value?: ITrialBalanceSettings;
  refresh: boolean;
  setRefresh: any;
}) => {
  const [form] = Form.useForm();
  const { companySettings, updateCompanySettings } = useClient();
  //const { openNotification } = useContext(notificationContext);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (value) {
      const setData = {
        ...value,
        showAccounts: {
          label: value?.showAccounts?.name,
          value: value?.showAccounts?.id,
        },
      };
      form.setFieldsValue(setData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onSubmit = () => {
    form.validateFields().then((val) => {
      const data = {
        tbSettings: {
          ...val,
          showAccounts: {
            id: val?.showAccounts?.value,
            name: val?.showAccounts?.label,
          },
        },
      };
      console.log(data);
      setSaveButtonLoading(true);
      updateCompanySettings!(
        SettingsSections.TBSettings,
        {
          ...companySettings,
          setting: {
            ...companySettings?.setting!,
            tbSettings: data?.tbSettings,
          },
        },
        data
      )
        .then((result: any) => {
          if (result?.status) {
            setSaveButtonLoading(false);
            //  openNotification("success", "Setting Updated successfully");
            setOpen(false);
            setRefresh(!refresh);
          } else {
            //  openNotification("error", "there is some error");
            setSaveButtonLoading(false);
          }
        })
        .catch((ex: any) => {
          setSaveButtonLoading(false);
          //   openNotification("error", ex.message);
        });
    });
  };

  return (
    <Modal
      title="Customise Report"
      open={isOpen}
      maskClosable={false}
      // okText={"Save"}
      // cancelText={"Cancel"}
      // onOk={() => onSubmit()}
      onCancel={() => setOpen(false)}
      width={520}
      footer={
        <Space>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            type="primary"
            loading={saveButtonLoading}
            onClick={() => onSubmit()}
          >
            Save
          </Button>
        </Space>
      }
    >
      <Form name="CustomiseReport" form={form}>
        <div className="fw-600 themeColor">Contents</div>
        <div className="fw-600">Number format</div>
        <Form.Item
          name="hideDecimals"
          valuePropName="checked"
          className="mb-10"
        >
          <Checkbox>Show decimals</Checkbox>
        </Form.Item>
        <Row gutter={[0, 35]}>
          <Col lg={7}>
            <span className="fw-500">Round-off</span>
            <Form.Item
              name="roundToThousand"
              valuePropName="checked"
              className="mb-10"
            >
              <Checkbox>In thousands</Checkbox>
            </Form.Item>
            <Form.Item
              name="roundToMillion"
              valuePropName="checked"
              className="m-0"
            >
              <Checkbox>In millions</Checkbox>
            </Form.Item>
          </Col>
          <Col lg={7}>
            <span className="fw-500">Show zero as</span>
            <Form.Item name="showZeros" className="m-0">
              <Radio.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "8px",
                  rowGap: "10px",
                }}
              >
                <Radio value={true}>0</Radio>
                <Radio value={false}>-</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={10}>
            <span className="fw-500">Show negative numbers as</span>
            <Form.Item name="showNegative" className="m-0">
              <Radio.Group
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "8px",
                  rowGap: "10px",
                }}
              >
                <Radio value={true}>{`-500`}</Radio>
                <Radio value={false}>{`(500)`}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Divider className="my-12" />
        {type !== "Ageing" && (
          <>
            <Form.Item
              label="Show accounts"
              name="showAccounts"
              wrapperCol={{ span: 10 }}
              className="mb-10"
            >
              <Select
                labelInValue
                // defaultValue="Account with transaction"
                options={[
                  { value: 0, label: "Accounts with transactions" },
                  { value: 1, label: "Accounts with balance" },
                ]}
              />
            </Form.Item>
            <Divider className="my-12" />
          </>
        )}
        {type === "TB" && (
          <>
            <div className="fw-600 themeColor mb-10">Change format to</div>
            <Row>
              <Col span={10}>
                <Form.Item
                  name="detailed"
                  valuePropName="checked"
                  className="mb-10"
                >
                  <Checkbox>Detailed trial balance</Checkbox>
                </Form.Item>
              </Col>
              <Col span={14}>
                <Form.Item
                  name="monthly"
                  valuePropName="checked"
                  className="mb-10"
                >
                  <Checkbox>Monthly trial balance</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider className="my-12" />
          </>
        )}
        <div className="fw-600 themeColor mb-10">Notes</div>
        <Form.Item
          name="notes"
          className="mb-10"
          // wrapperCol={{ span: 12 }}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const percentCal = (val: number, per: number) => {
  if (isNaN(val) || isNaN(per)) {
    return 0;
  }
  const calculatedValue = (per / 100) * val;
 // console.log("GSTC = ", calculatedValue);
  return calculatedValue;
};

export const percentToRs = (rs: number, val: number) => {
  if (isNaN(val) || isNaN(rs)) {
    return 0;
  }

  const calculatedValue = (100 * rs) / val;
  return calculatedValue;
};

export const removeElementAtIndex = (arr: any[], index: number) => {
  if (index < 0 || index >= arr?.length) {
    return arr; // Return the original array if the index is out of bounds
  } else {
    return arr.slice(0, index).concat(arr.slice(index + 1));
  }
};
