import React from "react";
import "./Template2.css";
import logoIcon from "../../Images/preview_company_logo.png";
import SignatureImg from "../../Images/preview_signature.png";
import qrCode from "../../Images/172x170.svg";

const Template2 = ({ currentClient, isChanged }: any) => {
  return (
    <>
      <div
        className={`Template` + isChanged?.font + ` ` + `main-invoice`}
        style={{
          paddingTop: `${isChanged?.topMargin}px`,
        }}
      >
        <div className="main-invoice-space">
          <div className="pre-printed-top-space pre-printed-bottom-space">
            <div>
              <div className="main-tax-invoice">
                <h2 className="" style={{ margin: 0, textAlign: "center" }}>
                  Tax Invoice
                </h2>
              </div>
            </div>

            <div className="invoice-table-section">
              {/* version-8 start*/}
              <div className="invoice-first-table">
                {/* pre printed format start end */}
                <table
                  style={{
                    border: "1px solid #000000",
                    borderCollapse: "collapse",
                    borderBottom: 0,
                  }}
                  className="temp2table"
                >
                  <tbody>
                    <tr style={{ verticalAlign: "top" }}>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          width: "50%",
                        }}
                        rowSpan={8}
                        className="invoice-padd-5"
                      >
                        <div
                          className="invoice-logo-content"
                          style={{
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <div
                            // className="invoice-logo"
                            // style={{ marginRight: 5 }}
                            style={{
                              marginRight: 5,
                              display: isChanged?.showLogo ? "" : "none",
                            }}
                          >
                            <img
                              src={
                                "https://placehold.co/100x100/EEE/31343C?font=montserrat&text=Logo"
                              }
                              alt="logo"
                            />
                          </div>
                          <div style={{ width: "72%" }}>
                            <h3 className="invoice-hedding-fnt-16">
                              {currentClient?.businessName}
                            </h3>
                            <p
                              className="invoice-fnt-11"
                              style={{
                                // margin: "0px",
                                display: isChanged?.showCompanyAddress
                                  ? ""
                                  : "none",
                              }}
                            >
                              Address: sample address, sample address2, Jodhpur,
                              Rajasthan, 342012
                            </p>
                            <p
                              className="invoice-fnt-11"
                              style={{
                                display: isChanged?.showEmail ? "" : "none",
                              }}
                            >
                              Email: demo.name@example.com
                            </p>
                            <p
                              className="invoice-fnt-11"
                              style={{
                                display: isChanged?.showWebsite ? "" : "none",
                              }}
                            >
                              Website: www.test.com
                            </p>
                            <p
                              className="invoice-fnt-11"
                              style={{
                                display: isChanged?.showPhone ? "" : "none",
                              }}
                            >
                              Phone no.: +919898989898
                            </p>

                            <p className="invoice-fnt-11">
                              <b
                                className="invoice-fnt-11"
                                style={{
                                  display: isChanged?.showGSTIN ? "" : "none",
                                }}
                              >
                                GSTIN: 08ABCDE9999F1Z8
                              </b>
                              <br
                                style={{
                                  display: isChanged?.showGSTIN ? "" : "none",
                                }}
                              />
                              <b
                                className="invoice-fnt-11"
                                style={{
                                  display: isChanged.showPan ? "" : "none",
                                }}
                              >
                                PAN: ABCCA4785G
                              </b>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td style={{}} className="invoice-fnt-11 invoice-padd-5">
                        <b>Invoice Number</b>
                        <p className="invoice-padd-5 invoice-lh-normal">
                          INV0001
                        </p>
                      </td>
                      <td style={{}} className="invoice-fnt-11 invoice-padd-5">
                        <b>Date</b>
                        <p className="invoice-padd-5 invoice-lh-normal">
                          DD-MM-YYYY
                        </p>
                      </td>
                      <td style={{}} className="invoice-fnt-11 invoice-padd-5">
                        <b>Due date</b>
                        <p className="invoice-padd-5 invoice-lh-normal">
                          DD-MM-YYYY
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{ padding: 0 }}>
                        <div className="invoice-fnt-11 invoice-padd-5">
                          <p>
                            <b>Transporter: </b>
                            Transport Pvt Ltd.
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{ padding: 0 }}>
                        <div
                          style={{ display: "flex" }}
                          className="invoice-fnt-11 tax-border-lf"
                        >
                          <div
                            style={{
                              flex: 1,
                              display: isChanged.showEBillNo ? "" : "none",
                            }}
                            className="invoice-padd-5"
                          >
                            <p>
                              <b>E-way Bill No: </b>
                              1Dummy6
                            </p>
                          </div>
                          <div
                            style={{
                              flex: 1,
                              display: isChanged.showEInvoiceNo ? "" : "none",
                            }}
                            className="invoice-padd-5"
                          >
                            <p>
                              <b>e-Invoice No. : </b>
                              1Demo4
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{ padding: 0 }}>
                        <div
                          style={{ display: "flex" }}
                          className="invoice-fnt-11 tax-border-lf"
                        >
                          <div
                            style={{
                              flex: 1,
                              display: isChanged.showEBillNo ? "" : "none",
                            }}
                            className="invoice-padd-5"
                          >
                            <p>
                              <b>E-way Bill Date: </b>
                              DD-MM-YYYY
                            </p>
                          </div>
                          <div
                            style={{
                              flex: 1,
                              display: isChanged.showEInvoiceNo ? "" : "none",
                            }}
                            className="invoice-padd-5"
                          >
                            <p>
                              <b>e-Invoice Date: </b>
                              DD-MM-YYYY
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3} style={{ padding: 0 }}>
                        <div
                          style={{ display: "flex" }}
                          className="invoice-fnt-11 tax-border-lf"
                        >
                          <div
                            style={{ flex: 1, height: "100%" }}
                            className="invoice-padd-5"
                          >
                            <p>
                              <b>Vehicle No:</b>
                              RJ-0X-OO-5XX5
                            </p>
                          </div>
                          <div
                            style={{ flex: 1, height: "100%" }}
                            className="invoice-padd-5"
                          >
                            <p>
                              <b>Quotation No:</b>
                              QDemo19
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table
                  style={{ borderCollapse: "collapse" }}
                  className="temp2table"
                >
                  <tbody>
                    <tr>
                      <td style={{ padding: 0, borderTop: 0 }}>
                        <div style={{ display: "flex" }}>
                          <div className="invoice-padd-5" style={{ flex: 1 }}>
                            <p className="invoice-fnt-11">PO No.: POO1</p>
                          </div>
                          <div
                            className="invoice-padd-5"
                            style={{
                              flex: 1,
                              borderLeft: "1px solid #000000",
                              marginLeft: "-0.5px",
                            }}
                          >
                            <p className="invoice-fnt-11">
                              PO Date: DD-MM-YYYY
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr
                      style={{
                        borderCollapse: "collapse",
                        pageBreakInside: "avoid",
                      }}
                      className="bill-data"
                    >
                      <td
                        style={{
                          borderCollapse: "collapse",
                          padding: 0,
                          borderBottom: 0,
                          borderTop: 0,
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div className="invoice-padd-5" style={{ flex: 1 }}>
                            <p className="invoice-fnt-11">
                              <b>Buyer (Bill to):</b>
                            </p>
                            <h5 style={{ fontSize: 11, margin: 0 }}>
                              <b>Billing Party Name</b>
                            </h5>
                            <p />
                            <p className="invoice-fnt-11">
                              GSTIN: 24XXXXXXXXXXXXX
                            </p>
                            <p className="invoice-fnt-11">
                              Address Line 1, Address Line 2, Selected with
                              Account City, State Name, Pincode
                            </p>
                            <p className="invoice-fnt-11">
                              Phone no.: 9500000000
                            </p>
                            <p className="invoice-fnt-11">
                              Email: xyz@gmail.com
                            </p>
                            <div style={{ display: "flex" }}>
                              <p className="invoice-fnt-11">
                                <b style={{ display: "initial" }}>Country:</b>
                                Country Name
                              </p>
                            </div>
                            <p className="invoice-fnt-11" />
                          </div>
                          <div
                            className="invoice-padd-5"
                            style={{
                              flex: 1,
                              borderLeft: "1px solid #000000",
                              marginLeft: "-0.5px",
                              display: isChanged.showShipping ? "" : "none",
                            }}
                          >
                            <p className="invoice-fnt-11">
                              <b>Consignee (Ship to): </b>
                            </p>
                            <h5 style={{ fontSize: 11, margin: 0 }}>
                              <b>Shipping Party Name</b>
                            </h5>
                            <p />
                            <p className="invoice-fnt-11">
                              GSTIN: 24XXXXXXXXXXXXX
                            </p>
                            <p className="invoice-fnt-11">
                              Address Line 1, Address Line 2, Shipping City,
                              State Name, Shipping Pincode
                            </p>
                            <p className="invoice-fnt-11">
                              Phone no.: 9500000000
                            </p>
                            <p className="invoice-fnt-11">
                              Email: xyz@gmail.com
                            </p>
                            <div style={{ display: "flex" }}>
                              <p
                                className="invoice-fnt-11"
                                style={{ marginRight: 5 }}
                              >
                                <b style={{ display: "initial" }}>
                                  Place Of Supply:
                                </b>
                                State Name
                              </p>
                              <p className="invoice-fnt-11">
                                <b style={{ display: "initial" }}>Country:</b>
                                Country Name
                              </p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* version-8 end*/}
              <div className="invoice-second-table">
                <table
                  style={{ borderCollapse: "collapse" }}
                  className="temp2table"
                >
                  <thead>
                    <tr
                      style={{
                        borderCollapse: "collapse",
                        pageBreakInside: "avoid",
                      }}
                    >
                      <th style={{ textAlign: "center" }}>
                        <b>Sr no.</b>
                      </th>
                      <th>
                        <b>Product/Service</b>
                      </th>
                      <th
                        className="break-all"
                        style={{
                          display: isChanged.showHSN ? "" : "none",
                        }}
                      >
                        <b>HSN/SAC</b>
                      </th>
                      <th className="break-all">
                        <b>Qty</b>
                      </th>
                      <th className="break-all">
                        <b>Rate</b>
                      </th>
                      <th>
                        <b>Discount</b>
                      </th>
                      <th className="break-all">
                        <b>Taxable Amt</b>
                      </th>
                      <th className="break-all">
                        <b>Tax</b>
                      </th>
                      <th className="break-all">
                        <b>Total (₹)</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style={{
                        borderCollapse: "collapse",
                        border: "none !important",
                      }}
                    >
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        1
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "left",
                        }}
                      >
                        <b>Product 1</b>
                        <p className="invoice-lh-inherit invoice-fnt-10">
                          best item
                        </p>
                        <p className="invoice-lh-inherit invoice-fnt-10">
                          Challan no.: C0001
                        </p>
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                          display: isChanged.showHSN ? "" : "none",
                        }}
                      >
                        415278
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                        }}
                      >
                        1.000
                        <div
                          style={{
                            display: isChanged.showUnit ? "" : "none",
                          }}
                        >
                          PCS
                        </div>
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                        }}
                      >
                        75000.00
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                        }}
                      >
                        0.00
                        <div>(0.00%)</div>
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                        }}
                      >
                        75000.00
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                        }}
                      >
                        2250.00
                        <div>(3%)</div>
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                        }}
                      >
                        77600.00
                      </td>
                    </tr>
                    {/* <tr style={{}}>
                      <td style={{ borderCollapse: "collapse" }} />
                      <td style={{ borderCollapse: "collapse" }} />
                      <td style={{ borderCollapse: "collapse" }} />
                      <td style={{ borderCollapse: "collapse" }} />
                      <td style={{ borderCollapse: "collapse" }} />
                      <td style={{ borderCollapse: "collapse" }} />
                      <td style={{ borderCollapse: "collapse" }} />
                      <td style={{ borderCollapse: "collapse" }} />
                      <td style={{ borderCollapse: "collapse" }} />
                    </tr> */}
                    <tr
                      style={{
                        borderCollapse: "collapse",
                        pageBreakInside: "avoid",
                        borderTop: "1px solid #000000",
                      }}
                    >
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "left",
                        }}
                      />
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "left",
                        }}
                      >
                        <b>Sub Total</b>
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                          display: isChanged.showHSN ? "" : "none",
                        }}
                      />
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                        }}
                      >
                        1.000
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                        }}
                      />
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                        }}
                      >
                        0.00
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                        }}
                      >
                        75000.00
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                        }}
                      >
                        2250.00
                      </td>
                      <td
                        style={{
                          borderCollapse: "collapse",
                          textAlign: "right",
                        }}
                      >
                        <b>77600.00</b>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr />
                  </tfoot>
                </table>
              </div>

              <div
                className="main-bank-detail"
                style={{ pageBreakInside: "avoid" }}
              >
                <div className="main-inner-bank-detail">
                  <div
                    className="bank-tax-detail conditions"
                    style={{ width: "50%" }}
                  >
                    <div
                      className="invoice-padd-5"
                      style={{
                        borderBottom: "1px solid #000000",
                        display: isChanged.showBankDetail ? "" : "none",
                      }}
                    >
                      <table className="">
                        <tbody>
                          <tr>
                            <td colSpan={4}>
                              <b>Bank Details</b>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ whiteSpace: "nowrap" }}>
                              A/C Holder Name:
                            </td>
                            <td colSpan={3}>Account Holder Name</td>
                          </tr>
                          <tr>
                            <td>Bank Name:</td>
                            <td colSpan={3}>Your Bank Name</td>
                          </tr>
                          <tr>
                            <td>A/C No:</td>
                            <td>17550XXXXXX1008</td>
                            <td
                              style={{
                                paddingLeft: 2,
                                whiteSpace: "nowrap",
                                width: 57,
                              }}
                            >
                              IFSC Code:
                            </td>
                            <td style={{ paddingLeft: 2 }}>IFSCOCODES</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      className="invoice-padd-5"
                      style={{
                        paddingTop: 2,
                        display: isChanged.showQRorUPI ? "" : "none",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td>UPI Id:</td>
                            <td>john.doe@sbi</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div
                      className="invoice-fnt-11 invoice-padd-5"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p>
                        <b>Outstanding Amt:</b>
                      </p>
                      <p>₹XXX0.00 Cr</p>
                    </div>

                    <div
                      className="invoice-fnt-11 invoice-padd-5"
                      style={{
                        display: "flex",
                        borderTop: "1px solid #000000",
                      }}
                    >
                      <p
                        className="invoice-lh-normal"
                        style={{ whiteSpace: "nowrap", marginRight: 2 }}
                      >
                        <b>Special Notes:</b>
                      </p>
                      <p
                        className="invoice-lh-normal"
                        style={{ width: "100%" }}
                      >
                        {isChanged?.specialNotes}
                      </p>
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="bank-tax-amount">
                      <div className="invoice-fnt-11">
                        <div
                          className="invoice-padd-5"
                          style={{ borderBottom: "1px solid #000000" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="invoice-lh-normal">Taxable Amt</p>
                            <p className="invoice-lh-normal">75000.00</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="invoice-lh-normal">CGST</p>
                            <p className="invoice-lh-normal"> 1125.00</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="invoice-lh-normal">SGST</p>
                            <p className="invoice-lh-normal"> 1125.00</p>
                          </div>
                          {/* <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p className="invoice-lh-normal">CESS</p>
                            <p className="invoice-lh-normal">345.00</p>
                          </div> */}
                        </div>
                        <div
                          className="invoice-padd-5"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p>
                            <b>Total Amount(₹)</b>
                          </p>
                          <p>
                            <b>77250.00</b>
                          </p>
                        </div>
                        <div
                          className="invoice-padd-5"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderTop: "1px solid #000000",
                          }}
                        >
                          <p>
                            <b> Received Amount(₹)</b>
                          </p>
                          <p>
                            <b>0.00</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="invoice-amount-words invoice-fnt-11 invoice-padd-5"
                      style={{ borderTop: "1px solid #000000" }}
                    >
                      <p className="invoice-lh-normal">Amount in Words</p>
                      <p>
                        <b>
                          seventy seven thousand two hundred fifty Rupees Only
                        </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="invoice-third-table m-b">
                <table
                  style={{
                    border: "1px solid #000000",
                    borderCollapse: "collapse",
                    display: isChanged.showTaxableValue ? "" : "none",
                  }}
                >
                  <thead className="">
                    <tr
                      style={{
                        border: "1px solid #000000",
                        borderCollapse: "collapse",
                        pageBreakInside: "avoid",
                      }}
                    >
                      <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          width: "18%",
                          textAlign: "center",
                          wordBreak: "break-word",
                        }}
                        rowSpan={2}
                      >
                        <b>HSN/SAC</b>
                      </th>
                      <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                          width: "10%",
                          wordBreak: "break-word",
                        }}
                        rowSpan={2}
                      >
                        <b>Taxable Amt(₹)</b>
                      </th>
                      <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                          wordBreak: "break-word",
                        }}
                        colSpan={2}
                      >
                        <b>IGST</b>
                      </th>
                      <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                          wordBreak: "break-word",
                        }}
                        colSpan={2}
                      >
                        <b>CGST</b>
                      </th>
                      <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                          wordBreak: "break-word",
                        }}
                        colSpan={2}
                      >
                        <b>SGST</b>
                      </th>
                      {/* <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                          wordBreak: "break-word",
                        }}
                        colSpan={2}
                      >
                        <b>CESS</b>
                      </th> */}
                      <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                          wordBreak: "break-word",
                        }}
                        rowSpan={2}
                      >
                        <b>Total Tax Amt(₹)</b>
                      </th>
                    </tr>
                    <tr
                      style={{
                        border: "1px solid #000000",
                        borderCollapse: "collapse",
                        pageBreakInside: "avoid",
                      }}
                    >
                      <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        <b>Rate</b>
                      </th>
                      <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        <b>Amount(₹)</b>
                      </th>
                      <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        <b>Rate</b>
                      </th>
                      <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        <b>Amount(₹)</b>
                      </th>
                      <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        <b>Rate</b>
                      </th>
                      {/* <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        <b>Amount(₹)</b>
                      </th> */}
                      {/* <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        <b>Rate</b>
                      </th> */}
                      <th
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                          textAlign: "center",
                        }}
                      >
                        <b>Amount(₹)</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      style={{
                        border: "1px solid #000000",
                        borderCollapse: "collapse",
                        pageBreakInside: "avoid",
                      }}
                    >
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        415278
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        75000.00
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        0%
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        0.00
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        1.5%
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        1125.00
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        1.5%
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        1125.00
                      </td>
                      {/* <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        10%
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        345.00
                      </td> */}
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        2595.00
                      </td>
                    </tr>
                    <tr
                      style={{
                        border: "1px solid #000000",
                        borderCollapse: "collapse",
                        pageBreakInside: "avoid",
                      }}
                    >
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        <b>Total(₹)</b>
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        <b>75000.00</b>
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        <b />
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        <b> 0.00 </b>
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        <b />
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        <b> 1125.00 </b>
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        <b />
                      </td>
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        <b> 1125.00 </b>
                      </td>
                      {/* <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        <b />
                      </td> */}
                      {/* <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        <b> 345.00 </b>
                      </td> */}
                      <td
                        style={{
                          border: "1px solid #000000",
                          borderCollapse: "collapse",
                        }}
                      >
                        <b>2595.00</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                className="qr-section m-b invoice-fnt-11"
                style={{
                  pageBreakInside: "avoid",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className={isChanged?.showQRorUPI ? "qr-section-tax " : ""}
                  style={{ width: isChanged?.showQRorUPI ? "75%" : "100%" }}
                >
                  <div className="qr-section-tax-inner">
                    <div
                      className="invoice-padd-5"
                      style={{
                        display: isChanged.showDispatchDetail ? "" : "none",
                      }}
                    >
                      <p>
                        <span>
                          <b className=""> Dispatch Detail: </b>
                        </span>
                      </p>
                      <p>
                        <span>Transporter Name: </span>
                        <span>: 1Dummy3</span>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>
                          <span>Driver Name: </span>
                          <span>: 1XXXX5</span>
                        </p>
                        <p>
                          <span>Contact No.:</span>
                          <span>9878598575</span>
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>
                          <span>LR / Bilty No.: </span>
                          <span>: </span>
                        </p>
                        <p>
                          <span>LR / Bilty Date: </span>
                          <span>: DD-MM-YYYY</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="invoice-fnt-11 invoice-padd-5"
                    style={{
                      pageBreakInside: "avoid",
                      borderTop: isChanged.showDispatchDetail
                        ? "1px solid #000000"
                        : "",
                      wordBreak: "break-all",
                    }}
                  >
                    <p>
                      <b>Terms &amp; Conditions:</b>
                    </p>
                    <p>
                      Disclaimer: <br /> 1. Certified that the particular given
                      above are true and correct and the amount indicated
                      represents the price actually charged and there is no
                      additional consideration flowing, directly or indirectly
                      from the buyer. <br /> 2. Subject to Jodhpur Jurisdiction
                      only.
                    </p>
                  </div>{" "}
                </div>

                <div
                  className="invoice-padd-5 qr-code-scan-img"
                  style={{
                    margin: "auto",
                    display: isChanged.showQRorUPI ? "" : "none",
                  }}
                >
                  <div style={{ width: 172, height: 170 }}>
                    <img
                      src="https://placehold.co/172x170"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="bottom-section invoice-padd-5 d-flex"
                // style={{ pageBreakInside: "avoid" }}
              >
                <div style={{ marginLeft: "auto", textAlign: "right" }}>
                  <p className="invoice-fnt-11">For, Demo Company</p>
                  <div style={{ marginLeft: "auto", width: 120, height: 66 }}>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={SignatureImg}
                    />
                  </div>
                  <p className="invoice-fnt-11">Signature</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Template2;
