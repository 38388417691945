import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Spin, Table } from "antd";
import React from "react";
import { useClient } from "../../Context/ClientContext";
import SalesService from "../../Services/SalesService";
import { useTheme } from "../../Context/ThemeContext";
import { ColumnsType } from "antd/es/table";
import { Utils } from "../../../Utilities/Utils";
import ViewSalesInvoice from "./ViewSalesInvoice";
import ViewCreditNote from "./ViewCreditNote";
import ViewPaymentIn from "./ViewPaymentIn";
import { Link } from "react-router-dom";
import { IIndianState } from "../../../Types";
import { useUser } from "../../../Context/UserContext";

interface DataType {
  sno: number;
  id: string;
  date: string;
  type: number;
  number: string;
  amount: any;
  paymentIn: any[];
  receiptAmount: number;
  balanceDue: number;
}

const ViewSalesInvoiceNoDetails: React.FC<{
  open: boolean;
  onCancel: any;
  invoiceId: string;
}> = (props: any) => {
  console.log(props, "props...........");
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const { getAllIndStates } = useUser();
  const stateOptions: any = getAllIndStates();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [invoiceNoDetailsList, setInvoiceNoDetailsList] = React.useState<any>(
    []
  );
  const [invoiceDetails, setInvoiceDetails] = React.useState<any>({});
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRecords, setTotalRecords] = React.useState<number>();

  const [filter, setfilter] = React.useState<{
    sortCol: string;
    sortDir: string;
  }>({
    sortCol: "",
    sortDir: "",
  });
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    id: string;
  }>({
    type: "",
    id: "",
  });
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  // --------- Table Columns  ---------
  const columns: ColumnsType<DataType> = [
    {
      title: "S.No.",
      dataIndex: "sno",
      width: "8%",
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      // width: "22%",
      render: (_, record) => (
        <div
          style={{ color: "#1677FF", cursor: "pointer" }}
          onClick={() => {
            record.type === 1
              ? setOpenModel({ type: "viewSalesInvoice", id: record.id })
              : record.type === 2
              ? setOpenModel({ type: "viewCrediteNote", id: record.id })
              : setOpenModel({ type: "viewPaymentIn", id: record.id });
          }}
        >
          {record.number}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // sorter: true,
      width: "25%",
    },
    {
      title: "Amount",
      dataIndex: "invAmount",
      key: "accountHead",
      width: "25%",
      render: (_, record) => {
        if (record?.type === 1)
          return (
            <span style={{ color: "#00A846" }}>
              ₹{record.amount.netPayable}
            </span>
          );
        else if (record?.type === 2)
          return (
            <span style={{ color: "#DC362E" }}>
              ₹{record.amount.sellingPrice}
            </span>
          );
        else if (record?.type === 3)
          return (
            <span style={{ color: "#00A846" }}>
              ₹{record.paymentIn[0].allocatedAmount}
            </span>
          );
      },
    },
  ];
  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "AutoNumber",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
  };

  React.useEffect(() => {
    setLoading(true);
    if (currentClient?.id! && props?.invoiceId) {
      getInvoiceNumberDetails(
        currentClient?.id!,
        props.invoiceId,
        (page - 1) * pageSize,
        pageSize,
        filter.sortCol,
        filter.sortDir
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, page, pageSize]);

  const getInvoiceNumberDetails = async (
    clientId: string,
    invoiceId: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string
  ) => {
    await SalesService.getSalesInvoiceNumberDetails(
      clientId,
      invoiceId,
      start,
      length,
      sortCol,
      sortDir
    )
      .then((res: any) => {
        // debugger
        console.log("helooooo.......", res.items);
        if (res?.items.length > 0) {
          const state = Object.entries(stateOptions)?.find(
            ([key, itm]: any) => itm?.id === res.items[0]?.sState
          ) as IIndianState | undefined;

          // console.log(state, "state...........");

          setInvoiceDetails({
            tPartner: res.items[0]?.tPartner,
            dueDate: res.items[0]?.dueDate,
            sState: state?.name,
          });
          setInvoiceNoDetailsList(
            res.items?.map((v: any, i: number) => {
              return {
                key: i,
                id: v?.id,
                number: v?.number,
                type: v?.type,
                date: Utils.getFormattedDate(v?.date),
                amount: {
                  allocatedAmount: Utils.getFormattedNumber(
                    v?.amount?.allocatedAmount
                  ),
                  bankAmount: Utils.getFormattedNumber(v?.amount?.bankAmount),
                  discount: Utils.getFormattedNumber(v?.amount?.discount),
                  due: Utils.getFormattedNumber(v?.amount?.due),
                  netPayable: Utils.getFormattedNumber(v?.amount?.netPayable),
                  paid: Utils.getFormattedNumber(v?.amount?.paid),
                  refund: Utils.getFormattedNumber(v?.amount?.refund),
                  tax: Utils.getFormattedNumber(v?.amount?.tax),
                  sellingPrice: Utils.getFormattedNumber(
                    v?.amount?.sellingPrice
                  ),
                },
                paymentIn: v?.paymentIn,
                sno: v?.sno,
              };
            })
          );
          setTotalRecords(res?.totalRecords);
        } else {
          setInvoiceNoDetailsList([]);
          setTotalRecords(res?.totalRecords);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
    setLoading(false);
  };

  console.log(invoiceNoDetailsList, "invoiceNoDetailsList..................");

  return (
    <React.Fragment>
      <Modal
        open={props.open}
        width={1000}
        className="modelSaveButtonMargin"
        style={{ maxHeight: `calc(100% - 50px)`, overflowY: "auto" }}
        maskClosable={false}
        closable={false}
        onCancel={() => props.onCancel()}
        centered
        footer={
          <div style={{ marginTop: "0px" }} className="pe-15">
            <Button
              onClick={() => {
                props.onCancel();
              }}
            >
              Cancel
            </Button>
          </div>
        }
      >
        <Spin indicator={antIcon} spinning={loading}>
          <div>
            <Row>
              <Col lg={6} sm={12} xs={24}>
                <span
                  style={{
                    paddingRight: "10px",
                    color: "rgb(139, 143, 165)",
                  }}
                >
                  Customer:
                </span>
                <span>
                  <Link
                    to={`/accounts/clients/${currentClient?.id!}/parties/${
                      invoiceDetails?.tPartner?.id
                    }`}
                    style={{ color: "#3a5dd3" }}
                    className="modelSaveButtonMargin"
                  >
                    {invoiceDetails?.tPartner?.name}
                  </Link>
                </span>
              </Col>
              <Col lg={6} sm={12} xs={24}>
                <span
                  style={{
                    paddingRight: "10px",
                    color: "rgb(139, 143, 165)",
                  }}
                >
                  Date:
                </span>
                {invoiceNoDetailsList[0]?.date}
              </Col>
              <Col lg={6} sm={12} xs={24}>
                <span
                  style={{
                    paddingRight: "10px",
                    color: "rgb(139, 143, 165)",
                  }}
                >
                  Due Date:
                </span>
                {invoiceDetails?.dueDate}
              </Col>
              <Col lg={6} sm={12} xs={24}>
                <span
                  style={{
                    paddingRight: "10px",
                    color: "rgb(139, 143, 165)",
                  }}
                >
                  State:
                </span>
                {invoiceDetails?.sState}
              </Col>

              <Col lg={6} sm={12} xs={24}>
                <span
                  style={{
                    paddingRight: "10px",
                    color: "rgb(139, 143, 165)",
                  }}
                >
                  Invoice Amt:
                </span>
                ₹{invoiceNoDetailsList[0]?.amount?.netPayable}
              </Col>
              <Col lg={6} sm={12} xs={24}>
                <span
                  style={{
                    paddingRight: "10px",
                    color: "rgb(139, 143, 165)",
                  }}
                >
                  Refund:
                </span>
                ₹{invoiceNoDetailsList[0]?.amount?.allocatedAmount}
              </Col>
              <Col lg={6} sm={12} xs={24}>
                <span
                  style={{
                    paddingRight: "10px",
                    color: "rgb(139, 143, 165)",
                  }}
                >
                  Received:
                </span>
                ₹{invoiceNoDetailsList[0]?.amount?.paid}
              </Col>
              <Col lg={6} sm={12} xs={24}>
                <span
                  style={{
                    paddingRight: "10px",
                    color: "rgb(139, 143, 165)",
                  }}
                >
                  Due Amount:
                </span>
                ₹{invoiceNoDetailsList[0]?.amount?.due}
              </Col>
            </Row>
          </div>
          <Table
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            // loading={loading}
            locale={{
              triggerDesc: "",
              triggerAsc: "",
              cancelSort: "",
            }}
            columns={columns}
            dataSource={invoiceNoDetailsList}
            onChange={tableSort}
            style={{ overflowY: "scroll" }}
            //  marginTop: "-22px"
            scroll={pageSize > 15 ? { x: 700, y: 370 } : { x: 600 }}
            pagination={{
              total: totalRecords,
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              onChange: (page: number, pageSize: number) => {
                setPage(page);
                setPageSize(pageSize);
              },
              pageSizeOptions: ["10", "15", "25", "50", "100"],
              showTotal: (totalRecords, page) =>
                `${page[0]}-${page[1]} of ${totalRecords} items`,
            }}
          />
        </Spin>
      </Modal>
      {openModel.type === "viewSalesInvoice" && (
        <ViewSalesInvoice
          open={openModel.type === "viewSalesInvoice"}
          onCancel={() => setOpenModel({ type: "", id: "" })}
          invoiceId={openModel.id}
        />
      )}
      {openModel.type === "viewCrediteNote" && (
        <ViewCreditNote
          open={openModel.type === "viewCrediteNote"}
          onCancel={() => setOpenModel({ type: "", id: "" })}
          creditNoteId={openModel.id}
        />
      )}
      {openModel.type === "viewPaymentIn" && (
        <ViewPaymentIn
          open={openModel.type === "viewPaymentIn"}
          onCancel={() => setOpenModel({ type: "", id: "" })}
          paymentId={openModel?.id}
        />
      )}
    </React.Fragment>
  );
};

export default ViewSalesInvoiceNoDetails;
