import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { AssetPurType } from "../Pages/Assets/AssetModel";

export enum AssetStatus {
  All,
  Active,
  Disposed,
}

export enum Asset_Type {
  Single,
  Collective,
  All,
}

export const appendObjectToFormData = (
  formData: any,
  obj: any,
  parentKey = ""
) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const propKey = parentKey ? `${parentKey}[${key}]` : key;
      if (typeof obj[key] === "object" && obj[key] !== null) {
        appendObjectToFormData(formData, obj[key], propKey);
      } else {
        formData.append(propKey, obj[key]);
      }
    }
  }
};

class FixedAssetsServiceNew {
  route = "/v1/api/Acc_New_FixedAsset";

  // Get Initial data and edit by id
  getFixedAsset = (clientId: string, id?: string, isDisposed?: boolean) =>
    ApiUtility.get(
      `${this.route}/${clientId}/?${
        id ? `id=${id}&` : ""
      }isDisposed=${isDisposed}`
    );

  getAssetMaxNo = (
    companyId: string,
    voucherDate?: string,
    VoucherTitle?: string
  ) =>
    ApiUtility.get(
      `${this.route}/${companyId}/GetAssetMaxNo/?voucherDate=${voucherDate}`,
      {
        VoucherTitle,
      }
    );

  getAssetDetails = (companyId: string | undefined, id: string) =>
    ApiUtility.get(`${this.route}/${companyId}?id=${id}`);

  PostFixedAssets = (clientId: string, params: any, id?: any) =>
    ApiUtility.postForm(
      `${this.route}/${clientId}${id ? "/" + id : ""}`,
      params
    );

  getChildAssetList = (
    companyId: string | undefined,
    from: string,
    to: string,
    start: number,
    length: number,
    sortCol?: any,
    sortDir?: string,
    search?: string,
    type?: Asset_Type,
    status?: any,
    purchaseType?: AssetPurType
  ) =>
    ApiUtility.getResult(`${this.route}/${companyId}/GetChildAssetList`, {
      from,
      to,
      start,
      length,
      sortCol,
      sortDir,
      search,
      type,
      status,
      purchaseType,
    });

  getFixedAssetsList = (
    companyId: string,
    start: number,
    length: number,
    sortCol: any,
    sortDir: string,
    search: string,
    status: AssetStatus
    // type?: string,
    // lastAccDate?: string,
    // isDispose?: boolean
  ) =>
    ApiUtility.getResult(`${this.route}/${companyId}/GetList`, {
      start,
      length,
      sortCol,
      sortDir,
      search,
      status,
      // type,
      // lastAccDate,
      // isDispose,
    });

  // deleteAsset = (clientId: string, id: string, reactivate: boolean) =>
  //   ApiUtility.post(
  //     `${this.route}/${clientId}/${id}/Delete?reactivate=${reactivate}`,
  //     ""
  //   );

  // searchAsset = (clientId: string, term?: string, length?: number) =>
  //   ApiUtility.get(`${this.route}/${clientId}/SearchAsset`, { term, length });

  postDispose = (clientId: string, params: any) =>
    ApiUtility.post(`${this.route}/${clientId}/Disposalentry`, params);

  // reActivateAsset = (clientId: string, id: string) =>
  //   ApiUtility.post(
  //     `${this.route}/${clientId}/${id}/Activate`,
  //     id
  //   );
}
export default new FixedAssetsServiceNew();
