import {
  ArrowLeftOutlined,
  FilterFilled,
  LoadingOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Popover,
  Row,
  Select,
  Skeleton,
  Spin,
  Table,
  Tooltip,
} from "antd";
import React from "react";
import { useTheme } from "../../Context/ThemeContext";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { useClient } from "../../Context/ClientContext";
import "../../Common/Acc_Common.css";
import ItemsService, {
  InventoryType,
  ItemType,
} from "../../Services/ItemsService";
import { Utils } from "../../../Utilities/Utils";
import InventoryAdjustModel from "./InventoryAdjustModel";
import ViewSalesInvoice from "../Sales/ViewSalesInvoice";
import ViewCreditNote from "../Sales/ViewCreditNote";
import ViewPurchaseInvoice from "../Purchase/ViewPurchaseInvoice";
import ViewDebitNote from "../Purchase/ViewDebitNote";
import AddItems from "../Purchase/AddItems";
import { dayjs } from "../../../Utilities/dayjs";
import InfinitScroll from "react-infinite-scroll-component";
import SalesService, { TradingPartnerType } from "../../Services/SalesService";
import VoucherDetailsByLedger from "../Reports/VoucherDetailsByLedger";
import { useUser } from "../../../Context/UserContext";

interface IItemDataType {
  id: any;
  key: React.Key;
  name: string;
  qty: number;
}
interface IInventoryDataType {
  type: any;
  itemDetails: { id: string; number: string };
  date: string;
  qty: number;
  price: number;
  tPartnerName: string;
  tPartnerId: string;
}

const { RangePicker } = DatePicker;

const GoodsItems: React.FC<{
  goodsItm: { type: ItemType; id: string; isCurrentItem: boolean } | undefined;
}> = (props) => {
  const navigate = useNavigate();
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const { getAllIndStates } = useUser();
  const stateOptions: any = getAllIndStates();
  const [itemTotalRecords, setItemTotalRecords] = React.useState<number>(0);
  const [inventoryTotalRecords, setInventoryTotalRecords] =
    React.useState<number>(0);
  const [itemLoding, setItemLoding] = React.useState<boolean>(false);
  const [inventoryLoading, setInventoryLoading] =
    React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [itemsList, setItemsList] = React.useState<IItemDataType[]>([]);
  const [inventoryList, setInventoryList] = React.useState<any[]>([]);
  const [searchItem, setSearchItem] = React.useState<string>("");
  const [pageInv, SetPageInv] = React.useState<number>(1);
  const [pageSizeInv, setPageSizeInv] = React.useState<number>(15);
  const [pageItem, setPageItem] = React.useState<number>(1);
  const [pageSizeItem, setPageSizeItem] = React.useState<number>(15);
  const [itemId, setItemId] = React.useState<string>("");
  const [popoverOpen, setPopoverOpen] = React.useState<{
    filter: boolean;
    itemTable: boolean;
    inventoryTable: boolean;
  }>({ filter: false, itemTable: false, inventoryTable: false });
  const [filterOption, setFilterOption] = React.useState<{
    gstRate: number[];
    partyType: number;
    iState: number;
  }>({
    gstRate: [],
    partyType: 0,
    iState: 0,
  });
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });
  const [itemInventoryDetails, setItemInventoryDetails] = React.useState<any>(
    {}
  );
  const [filterOrder, setFilterOrder] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [filterOrderInventory, setfilterOrderInventory] = React.useState<{
    invSortCol: string;
    invSortDir: string;
  }>({
    invSortCol: "",
    invSortDir: "",
  });
  const [type, setType] = React.useState<number[]>([]);
  const [partyIdFilter, setPartyIdFilter] = React.useState<string[]>([]);
  const [triggerInventory, setTriggerInvemtory] =
    React.useState<boolean>(false);
  const [ledgerName, setLedgerName] = React.useState<string>();
  const [startpage, setStartpage] = React.useState<number>(0);
  const [endpage, setEndpage] = React.useState<number>(30);
  const [scrollloading, setScrollloading] = React.useState<boolean>(false);
  const [searchCustomer, setSearchCustomer] = React.useState<{
    search: string;
    length: number;
  }>({
    search: " ",
    length: 15,
  });
  const [customerOptions, setCustomerOptions] = React.useState<any[]>([]);
  console.log("itemTotalRecords", itemTotalRecords);

  console.log("locationId", props);

  // Get all Party list api
  // React.useEffect(() => {
  //   SalesService.searchParties(
  //     currentClient?.id!,
  //     searchCustomer.search,
  //     TradingPartnerType.All,
  //     searchCustomer.length
  //   ).then((res: any) => {
  //     setCustomerOptions(res?.result);
  //   });
  // }, [currentClient?.id, searchCustomer]);

  // Get Api All Item List (only Goods Item)
  React.useEffect(() => {
    setItemLoding(true);
    if (currentClient?.id) {
      getGoodsItemList(
        currentClient?.id,
        (pageItem - 1) * pageSizeItem,
        pageSizeItem,
        filterOrder.sortCol,
        filterOrder.sortDir,
        searchItem,
        undefined,
        props.goodsItm?.type,
        filterOption.gstRate,
        true
      );
    } else {
      console.error("currentClient is null Items");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // props.trigger,
    trigger,
    pageInv,
    pageItem,
    searchItem,
    filterOrder.sortCol,
    filterOrder.sortDir,
    props.goodsItm?.type,
    filterOption.gstRate,
  ]);

  // --------- Get Items TableData API ---------
  const getGoodsItemList = async (
    clientId: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    status?: any,
    type?: ItemType,
    gstRate?: number[],
    itemInventory?: boolean
  ) => {
    await ItemsService.getListItems(
      clientId,
      start,
      length,
      sortCol,
      sortDir,
      search,
      status,
      type,
      gstRate,
      itemInventory
    )
      .then((res: any) => {
        if (res.items.length > 0) {
          setItemsList(
            res?.items?.map((v: any, i: any) => {
              return {
                key: i,
                id: v?._id,
                name: v?.Name,
                qty: v?.Qty,
              };
            })
          );
          if (props.goodsItm?.id && props.goodsItm?.isCurrentItem) {
            setItemId(props?.goodsItm?.id);
          } else {
            setItemId(res?.items[0]._id);
          }
          setItemTotalRecords(res?.totalRecords);
          console.log("res", res);
        } else if (res.items.length === 0) {
          setItemsList([]);
          setItemTotalRecords(res?.totalRecords);
        }
        setItemLoding(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setItemLoding(false);
        setItemsList([]);
      });
  };

  // --------- Goods Item Table Columns  ---------
  const itemColumns: ColumnsType<IItemDataType> = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => (
        <Skeleton
          active
          loading={itemLoding}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div
            // to={`/accounts/clients/${currentClient?.id}/sales/inventory/${record.id}`}
            onClick={() => {
              setItemId(record.id);
              setLedgerName(record?.name);
            }}
            style={{
              cursor: "pointer",
              color: "black",
            }}
          >
            {record.name}
          </div>
        </Skeleton>
      ),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={itemLoding}
          paragraph={{ rows: 1 }}
          title={false}
          className="cursor"
        >
          {record.qty}
        </Skeleton>
      ),
    },
  ];

  // Add Qty
  function type1(v: any) {
    if (v?.sType === 2) {
      return v?.tQty;
    } else if (v?.pType === 1) {
      return v?.tQty;
    } else if (v?.invType === 1) {
      return v?.tQty;
    } else if (v?.invType === 3) {
      return v?.tQty;
    }
  }
  // Reduce Qty
  function type2(v: any) {
    if (v?.sType === 1) {
      return v?.tQty;
    } else if (v?.pType === 2) {
      return v?.tQty;
    } else if (v?.invType === 2) {
      return v?.tQty;
    }
  }

  //------- Get Api Item Details -------
  React.useEffect(() => {
    async function details(id: string | undefined) {
      id &&
        (await ItemsService.getItemDetails(currentClient?.id!, id!)
          .then((result: any) => {
            if (result) {
              // debugger;
              setItemInventoryDetails({
                name: result.name?.trim(),
                image: "",
                isService: result.isService,
                isTaxable: result.isTaxable,
                taxRate: result.taxRate,
                salesItemPrice: result.salesItemPrice,
                purchasePrice: result.purchasePrice,
                qty: result.qty,
                tAmount: result.tAmount,
              });
            }
            console.log("result000000000000000", result);
          })
          .catch((err: any) => {
            console.log(err);
          }));
    }
    details(itemId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  // ------ Get Item Inventory Goods Details All Api ------

  // React.useEffect(() => {
  //   if ((currentClient?.id, itemId)) {
  //     setInventoryLoading(true);
  //     getGoodsInventoryList(
  //       currentClient?.id!,
  //       itemId,
  //       (pageInv - 1) * pageSizeInv,
  //       pageSizeInv,
  //       filterDate.from,
  //       filterDate.to,
  //       filterOrderInventory.invSortCol,
  //       filterOrderInventory.invSortDir,
  //       type,
  //       partyIdFilter
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   currentClient?.id,
  //   itemId,
  //   type,
  //   filterDate,
  //   filterOrderInventory,
  //   triggerInventory,
  //   partyIdFilter,
  // ]);

  // const getGoodsInventoryList = async (
  //   clientId: string,
  //   itemId: string,
  //   start: number,
  //   length: number,
  //   from?: string,
  //   to?: string,
  //   invSortCol?: string,
  //   invSortDir?: string,
  //   type?: number[],
  //   partyIdFilter?: string[]
  // ) => {
  //   await ItemsService.getItemInventoryList(
  //     clientId,
  //     itemId,
  //     start,
  //     length,
  //     from,
  //     to,
  //     invSortCol,
  //     invSortDir,
  //     type,
  //     partyIdFilter
  //   )
  //     .then((res: any) => {
  //       if (res) {
  //         setInventoryList(
  //           res.result.items.map((v: any, i: any) => {
  //             return {
  //               key: i,
  //               id: v?._id,
  //               date: Utils.getFormattedDate(v?.date),
  //               type:
  //                 v?.sType === 1
  //                   ? "Sales"
  //                   : v?.sType === 2
  //                   ? "Credit Note"
  //                   : v?.pType === 1
  //                   ? "Purchase"
  //                   : v?.pType === 2
  //                   ? "Debit Note"
  //                   : v?.invType === 1
  //                   ? "Add Adjustment"
  //                   : v?.invType === 2
  //                   ? "Reduce"
  //                   : "Opening Stock",
  //               itemDetails: { id: v?.itemId, number: v?.number },
  //               status: v?.status,
  //               totalPrice: Utils.getFormattedNumber(v?.tPrice / v?.tQty),
  //               addQty: type1(v),
  //               reduceQty: type2(v),
  //               tPartnerName: v?.tPartnerName,
  //               tPartnerId: v?.tPartnerId,
  //             };
  //           })
  //         );
  //         setInventoryTotalRecords(res.result.totalRecords);
  //         setInventoryLoading(false);
  //       }
  //     })
  //     .catch((ex: any) => {
  //       console.error(ex);
  //       setInventoryLoading(false);
  //     });
  // };

  // const getTaskScroll = (start: number, end: number) => {
  //   setScrollloading(true);
  //   ItemsService.getItemInventoryList(
  //     currentClient?.id!,
  //     itemId!,
  //     start,
  //     15,
  //     filterDate.from,
  //     filterDate.to,
  //     filterOrderInventory.invSortCol,
  //     filterOrderInventory.invSortDir,
  //     type
  //   ).then((res: any) => {
  //     if (res.result && res.result.length > 0) {
  //       console.log(inventoryList, "inventoryList...");

  //       setInventoryList([
  //         ...inventoryList,
  //         ...res.result.items.map((v: any, i: any) => {
  //           return {
  //             key: i,
  //             id: v?._id,
  //             date: Utils.getFormattedDate(v?.date),
  //             type:
  //               v?.sType === 1
  //                 ? "Sales"
  //                 : v?.sType === 2
  //                 ? "Credit Note"
  //                 : v?.pType === 1
  //                 ? "Purchase"
  //                 : v?.pType === 2
  //                 ? "Debit Note"
  //                 : v?.invType === 1
  //                 ? "Add Adjustment"
  //                 : v?.invType === 2
  //                 ? "Reduce"
  //                 : "Opening Stock",
  //             itemDetails: { id: v?.itemId, number: v?.number },
  //             status: v?.status,
  //             totalPrice: Utils.getFormattedNumber(v?.tPrice / v?.tQty),
  //             addQty: type1(v),
  //             reduceQty: type2(v),
  //           };
  //         }),
  //       ]);
  //       setStartpage(startpage + 30);
  //       setEndpage(endpage + 30);
  //       setScrollloading(false);
  //     } else {
  //       setScrollloading(false);
  //     }
  //   });
  // };

  // ------- Goods Item Inventory Table Columns -------
  // const inventoryColumns: ColumnsType<IInventoryDataType> = [
  //   {
  //     title: "Type",
  //     dataIndex: "type",
  //     key: "type",
  //     width: "20%",
  //     render: (_, record) => {
  //       if (record?.type === "Sales")
  //         return (
  //           <Skeleton
  //             active
  //             loading={inventoryLoading}
  //             paragraph={{ rows: 1 }}
  //             title={false}
  //           >
  //             <span
  //               style={{ cursor: "pointer", color: "#1677ff" }}
  //               onClick={() => {
  //                 setOpenModel({
  //                   type: "salesInvoice",
  //                   param: { id: record.itemDetails?.id },
  //                 });
  //               }}
  //             >
  //               {record.type + ` (${record.itemDetails?.number})`}
  //             </span>
  //           </Skeleton>
  //         );
  //       else if (record?.type === "Credit Note")
  //         return (
  //           <Skeleton
  //             active
  //             loading={inventoryLoading}
  //             paragraph={{ rows: 1 }}
  //             title={false}
  //           >
  //             <div
  //               style={{ cursor: "pointer", color: "#1677ff" }}
  //               onClick={() => {
  //                 setOpenModel({
  //                   type: "crediteNote",
  //                   param: { id: record.itemDetails?.id },
  //                 });
  //               }}
  //             >
  //               {record.type + ` (${record.itemDetails?.number})`}
  //             </div>
  //           </Skeleton>
  //         );
  //       else if (record?.type === "Purchase")
  //         return (
  //           <Skeleton
  //             active
  //             loading={inventoryLoading}
  //             paragraph={{ rows: 1 }}
  //             title={false}
  //           >
  //             <div
  //               style={{ cursor: "pointer", color: "#1677ff" }}
  //               onClick={() => {
  //                 setOpenModel({
  //                   type: "purchaseInvoice",
  //                   param: { id: record.itemDetails?.id },
  //                 });
  //               }}
  //             >
  //               {record.type + ` (${record.itemDetails?.number})`}
  //             </div>
  //           </Skeleton>
  //         );
  //       else if (record?.type === "Debit Note")
  //         return (
  //           <Skeleton
  //             active
  //             loading={inventoryLoading}
  //             paragraph={{ rows: 1 }}
  //             title={false}
  //           >
  //             <div
  //               style={{ cursor: "pointer", color: "#1677ff" }}
  //               onClick={() => {
  //                 setOpenModel({
  //                   type: "debitNote",
  //                   param: { id: record.itemDetails?.id },
  //                 });
  //               }}
  //             >
  //               {record.type + ` (${record.itemDetails?.number})`}
  //             </div>
  //           </Skeleton>
  //         );
  //       else if (record?.type === "Add Adjustment")
  //         return (
  //           <Skeleton
  //             active
  //             loading={inventoryLoading}
  //             paragraph={{ rows: 1 }}
  //             title={false}
  //           >
  //             <div
  //               style={{ cursor: "pointer", color: "#1677ff" }}
  //               onClick={() => {
  //                 setOpenModel({
  //                   type: "adjustyInventory",
  //                   param: {
  //                     id: record.itemDetails?.id,
  //                     type: InventoryType.Update,
  //                   },
  //                 });
  //               }}
  //             >
  //               {record.type}
  //             </div>
  //           </Skeleton>
  //         );
  //       else if (record?.type === "Reduce")
  //         return (
  //           <Skeleton
  //             active
  //             loading={inventoryLoading}
  //             paragraph={{ rows: 1 }}
  //             title={false}
  //           >
  //             <div
  //               style={{ cursor: "pointer", color: "#1677ff" }}
  //               onClick={() => {
  //                 setOpenModel({
  //                   type: "adjustyInventory",
  //                   param: {
  //                     id: record.itemDetails?.id,
  //                     type: InventoryType.Update,
  //                   },
  //                 });
  //                 console.log("record.......", record.itemDetails.id);
  //               }}
  //             >
  //               {record.type}
  //             </div>
  //           </Skeleton>
  //         );
  //       else if (record?.type === "Opening Stock")
  //         return (
  //           <Skeleton
  //             active
  //             loading={inventoryLoading}
  //             paragraph={{ rows: 1 }}
  //             title={false}
  //           >
  //             <div
  //             // style={{ cursor: "pointer", color: "#1677ff" }}
  //             // onClick={() => {
  //             //   setOpenModel({
  //             //     type: "adjustyInventory",
  //             //     param: {
  //             //       id: record.itemDetails?.id,
  //             //       type: InventoryType.Update,
  //             //     },
  //             //   });
  //             //   console.log("record.......", record.itemDetails.id);
  //             // }}
  //             >
  //               {record.type}
  //             </div>
  //           </Skeleton>
  //         );
  //     },
  //   },
  //   {
  //     title: "Party Name",
  //     dataIndex: "tPartnerName",
  //     key: "TPartnerName",
  //     sorter: true,
  //     width: "30%",
  //     render: (_: any, record: any) => (
  //       <Skeleton
  //         active
  //         loading={inventoryLoading}
  //         paragraph={{ rows: 1 }}
  //         title={false}
  //       >
  //         <NavLink
  //           to={`/accounts/clients/${currentClient?.id}/parties/${record?.tPartnerId}`}
  //         >
  //           {record?.tPartnerName}
  //         </NavLink>
  //       </Skeleton>
  //     ),
  //   },
  //   {
  //     title: "Date",
  //     dataIndex: "date",
  //     key: "date",
  //     sorter: true,
  //     width: "10%",
  //     render: (_: any, record: any) => (
  //       <Skeleton
  //         active
  //         loading={inventoryLoading}
  //         paragraph={{ rows: 1 }}
  //         title={false}
  //       >
  //         {record?.date}
  //       </Skeleton>
  //     ),
  //   },
  //   // { title: "Quantity", dataIndex: "totalQty", key: "totalQty" },
  //   {
  //     title: "Add Quantity",
  //     dataIndex: "addQty",
  //     key: "tQty",
  //     width: "12%",
  //     render: (_: any, record: any) => (
  //       <Skeleton
  //         active
  //         loading={inventoryLoading}
  //         paragraph={{ rows: 1 }}
  //         title={false}
  //       >
  //         {record?.addQty}
  //       </Skeleton>
  //     ),
  //   },
  //   {
  //     title: "Reduce Quantity",
  //     dataIndex: "reduceQty",
  //     key: "tQty",
  //     width: "12%",
  //     render: (_: any, record: any) => (
  //       <Skeleton
  //         active
  //         loading={inventoryLoading}
  //         paragraph={{ rows: 1 }}
  //         title={false}
  //       >
  //         {record?.reduceQty}
  //       </Skeleton>
  //     ),
  //   },
  //   {
  //     title: "Avg. Price",
  //     dataIndex: "totalPrice",
  //     key: "tPrice",
  //     width: "12%",
  //     sorter: true,
  //     align: "right",
  //     render: (_: any, record: any) => (
  //       <Skeleton
  //         active
  //         loading={inventoryLoading}
  //         paragraph={{
  //           rows: 1,
  //           style: { display: "flex", justifyContent: "end" },
  //         }}
  //         title={false}
  //       >
  //         {record?.totalPrice}
  //       </Skeleton>
  //     ),
  //   },
  //   {
  //     title: " ",
  //     dataIndex: "",
  //     key: "",
  //     render: (_, record) => (
  //       <Skeleton
  //         active
  //         loading={inventoryLoading}
  //         paragraph={{ rows: 1 }}
  //         title={false}
  //         className="cursor"
  //       >
  //         <Popover
  //           trigger="click"
  //           placement="bottom"
  //           arrow={{ pointAtCenter: true }}
  //           // open={popoverOpen.inventoryTable}
  //           // onOpenChange={(x: boolean) =>
  //           //   setPopoverOpen({ ...filter, inventoryTable: x })
  //           // }
  //           content={
  //             <Row style={{ maxWidth: "100px" }}>
  //               <Col lg={24}>
  //                 <Button
  //                   style={{ width: 100 }}
  //                   onClick={() =>
  //                     setOpenModel({
  //                       type: "adjustyInventory",
  //                       param: {
  //                         id: record.itemDetails.id,
  //                         type: InventoryType.Update,
  //                       },
  //                     })
  //                   }
  //                 >
  //                   Edit
  //                 </Button>
  //               </Col>
  //               <Col lg={24} style={{ marginTop: "6px" }}>
  //                 <Button
  //                   style={{ width: 100 }}
  //                   onClick={() =>
  //                     setOpenModel({
  //                       type: "adjustyInventory",
  //                       param: {
  //                         id: record.itemDetails.id,
  //                         type: InventoryType.Delete,
  //                       },
  //                     })
  //                   }
  //                 >
  //                   Delete
  //                 </Button>
  //               </Col>
  //               <Col lg={24} style={{ marginTop: "6px" }}>
  //                 <Button
  //                   style={{ width: 100 }}
  //                   onClick={() =>
  //                     setOpenModel({
  //                       type: "adjustyInventory",
  //                       param: {
  //                         id: record.itemDetails.id,
  //                         type: InventoryType.Add,
  //                       },
  //                     })
  //                   }
  //                 >
  //                   Duplicate
  //                 </Button>
  //               </Col>
  //               <Col lg={24} style={{ marginTop: "6px" }}>
  //                 <Button style={{ width: 100 }}>Add</Button>
  //               </Col>
  //             </Row>
  //           }
  //         >
  //           <MoreOutlined style={{ fontSize: "16px" }} />
  //         </Popover>
  //       </Skeleton>
  //     ),
  //   },
  // ];

  //----- Table Ascending & Desending Order -----
  // const tableSortInventory = (
  //   pagination?: any,
  //   sort?: any,
  //   filterOrderInventory?: any
  // ) => {
  //   setfilterOrderInventory({
  //     invSortCol:
  //       filterOrderInventory.column !== undefined
  //         ? filterOrderInventory.columnKey
  //         : "CreatedDate",
  //     invSortDir:
  //       filterOrderInventory.column !== undefined
  //         ? filterOrderInventory.order === "descend"
  //           ? "desc"
  //           : "asc"
  //         : "desc",
  //   });
  //   console.log("sort", filterOrderInventory, filterOrderInventory.column);
  // };

  return (
    <div>
      <Row>
        <Col lg={5} style={{ paddingRight: "5px", paddingTop: "15px" }}>
          <Card className="ca-card-body">
            <div>
              <Row align="middle" style={{ borderBottom: "1px solid #DFE9F7" }}>
                <Col lg={1} style={{ margin: "0px 8px" }}>
                  {/* <NavLink
                    // to={`/accounts/clients/${currentClient?.id}/sales/items`}
                  > */}
                  <ArrowLeftOutlined
                    style={{ color: "#1677FF" }}
                    onClick={() => window.history.back()}
                  />
                </Col>

                <Col lg={17}>
                  <Input
                    allowClear
                    // width: "220px",
                    style={{ margin: "5px 0px 5px 0px" }}
                    placeholder="Search item name"
                    onChange={(e: any) => {
                      setItemLoding(true);
                      if (!e.cancelable) {
                        const searchItem = setTimeout(() => {
                          SetPageInv(1);
                          setSearchItem(e.target.value);
                        }, 1000);
                        return () => clearTimeout(searchItem);
                      } else {
                        // console.log("Click cancle............");
                        setSearchItem(e.target.value);
                      }
                    }}
                    suffix={itemLoding || searchItem ? "" : <SearchOutlined />}
                  />
                </Col>
                <Col lg={2} style={{ marginLeft: "8px" }}>
                  <PlusOutlined
                    onClick={() => {
                      setOpenModel({
                        type: "addItem",
                        param: {
                          id: "",
                          type: "",
                        },
                      });
                    }}
                  />
                </Col>
                <Col lg={2}>
                  <Popover
                    trigger="click"
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                    open={popoverOpen.filter}
                    onOpenChange={(x: boolean) =>
                      setPopoverOpen({ ...popoverOpen, filter: x })
                    }
                    content={
                      <Row style={{ maxWidth: "230px" }}>
                        <Col lg={24}>
                          <Select
                            value={filterOption.partyType}
                            style={{ width: "100%" }}
                            onSelect={(e: any) => {
                              setFilterOption({
                                ...filterOption,
                                partyType: e,
                              });
                              // console.log("Clicked", e);
                            }}
                            options={[
                              {
                                label: "All",
                                value: 0,
                              },
                              { label: "Customer", value: 1 },
                              { label: "Suppliers", value: 2 },
                            ]}
                          />
                        </Col>
                        <Col lg={24} style={{ marginTop: "6px" }}>
                          <Select
                            // value={
                            //   filterOption.gstRate === 3
                            //     ? ""
                            //     : filterOption.gstRate
                            // }
                            allowClear
                            // onClear={() =>
                            //   setFilterOption({ ...filterOption, gstRate: 3 })
                            // }
                            style={{ width: "100%" }}
                            placeholder="GST type"
                            // value={itemId}
                            onSelect={(e: any) => {
                              // console.log("Clicked", e);
                              setFilterOption({ ...filterOption, gstRate: e });
                              // setItemId(e);
                            }}
                            options={[
                              {
                                label: "Unregistered",
                                value: 0,
                              },
                              { label: "Regular", value: 1 },
                              { label: "Composition", value: 2 },
                            ]}
                          />
                        </Col>
                        <Col lg={24} style={{ marginTop: "6px" }}>
                          <Select
                            value={
                              filterOption.iState === 0
                                ? ""
                                : filterOption.iState
                            }
                            allowClear
                            onClear={() =>
                              setFilterOption({ ...filterOption, iState: 0 })
                            }
                            showSearch
                            style={{ width: "100%" }}
                            placeholder="State"
                            optionFilterProp="children"
                            onSelect={(e: any) => {
                              setFilterOption({ ...filterOption, iState: e });
                            }}
                            options={Object.entries(stateOptions)?.map(
                              ([key, x]: any) => ({
                                value: x?.id,
                                label: x?.name,
                              })
                            )}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </Col>
                        <Col lg={24} style={{ marginTop: "6px" }}>
                          <Row justify="space-around">
                            <Button
                              type="primary"
                              onClick={() => {
                                setTrigger((x) => !x);
                                setPopoverOpen({
                                  ...popoverOpen,
                                  filter: false,
                                });
                              }}
                            >
                              Apply
                            </Button>
                            <Button
                              danger
                              onClick={() => {
                                setFilterOption({
                                  partyType: 0,
                                  gstRate: [],
                                  iState: 0,
                                });
                                setTrigger((x) => !x);
                                setPopoverOpen({
                                  ...popoverOpen,
                                  filter: false,
                                });
                              }}
                            >
                              Reset
                            </Button>
                            <Button
                              onClick={() => {
                                setPopoverOpen({
                                  ...popoverOpen,
                                  filter: false,
                                });
                              }}
                            >
                              Close
                            </Button>
                          </Row>
                        </Col>
                      </Row>
                    }
                  >
                    <FilterFilled
                      style={{ color: "#9AAEC1", fontSize: "16px" }}
                    />
                  </Popover>
                </Col>
              </Row>
            </div>
            <div
              style={{
                maxHeight: "100%",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <Table
                rowClassName={(record, index) => {
                  return record.id === itemId ? "ca-active-table" : "";
                }}
                className={`Tabel-style-none table-${
                  themeData?.componentSize ?? "middle"
                }`}
                // showHeader={false}
                dataSource={
                  itemLoding
                    ? (Array.from({ length: 20 }, (_, index) => ({
                        key: `loading-${index}`,
                      })) as IItemDataType[])
                    : itemsList
                }
                // rowSelection={rowSelection}
                columns={itemColumns}
                pagination={false}
                footer={() => (
                  <>
                    <div
                      style={{
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: "#F5F8FF",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th className="textStart">
                              Total Item
                              {/* {filterOption.partyType === 0
                              ? " Party"
                              : filterOption.partyType === 1
                              ? " Customer"
                              : " Supplier"} */}
                            </th>
                            <th style={{}}>{itemTotalRecords}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              />
            </div>
          </Card>
        </Col>

        <Col
          lg={19}
          style={{ paddingLeft: "5px", paddingTop: "15px" }}
          className="ca-card-body-padding"
        >
          <Card style={{ marginBottom: "10px" }}>
            <Row>
              <Col span={6}>
                <div>
                  {itemInventoryDetails.name?.length > 30 ? (
                    <Tooltip
                      title={itemInventoryDetails?.name}
                      placement="top"
                      color="#3D4998"
                    >
                      {itemInventoryDetails?.name.slice(0, 30) + "..."}
                    </Tooltip>
                  ) : (
                    itemInventoryDetails?.name
                  )}
                </div>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    View Image:
                  </span>
                  {itemInventoryDetails?.image}
                </div>
              </Col>
              <Col span={5}>
                <div>
                  <span
                    style={{
                      paddingRight: "14px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Sale Price:
                  </span>
                  {itemInventoryDetails.salesItemPrice?.length > 23 ? (
                    <Tooltip
                      title={itemInventoryDetails?.salesItemPrice}
                      placement="top"
                      color="#3D4998"
                    >
                      {"₹" +
                        itemInventoryDetails?.salesItemPrice.slice(0, 23) +
                        "..."}
                    </Tooltip>
                  ) : (
                    "₹" + itemInventoryDetails?.salesItemPrice
                  )}
                </div>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Purchase Price:
                  </span>
                  {itemInventoryDetails.purchasePrice?.length > 15 ? (
                    <Tooltip
                      title={itemInventoryDetails?.purchasePrice}
                      placement="bottom"
                      color="#3D4998"
                    >
                      {"₹" +
                        itemInventoryDetails?.purchasePrice.slice(0, 35) +
                        "..."}
                    </Tooltip>
                  ) : (
                    "₹" + itemInventoryDetails?.purchasePrice
                  )}
                </div>
              </Col>
              <Col span={5}>
                <div>
                  <span
                    style={{
                      paddingRight: "16px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    GST Rate:
                  </span>
                  {itemInventoryDetails?.taxRate + "%"}
                </div>

                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Tax Preference:
                  </span>
                  {itemInventoryDetails?.isTaxable}
                </div>
              </Col>
              {/* style={{ marginRight: "30px" }} */}
              <Col span={5}>
                <div>
                  <span
                    style={{
                      paddingRight: "16px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Stock Quantity:
                  </span>
                  {itemInventoryDetails?.qty}
                </div>

                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Stock Value:
                  </span>
                  {itemInventoryDetails.tAmount?.length > 55 ? (
                    <Tooltip
                      title={itemInventoryDetails?.tAmount}
                      placement="bottom"
                      color="#3D4998"
                    >
                      {"₹" + itemInventoryDetails?.tAmount.slice(0, 30) + "..."}
                    </Tooltip>
                  ) : (
                    "₹" + itemInventoryDetails?.tAmount
                  )}
                </div>
              </Col>
              <Col span={3} style={{ textAlign: "center" }}>
                {/* <div>
                  <Tooltip placement="top" color="#3D4998">
                    <FormOutlined
                      className="ca-edit-btn"
                      style={{ color: "#a5abdd" }}
                      onClick={() => {
                        setOpenDrawer(true);
                        setEditParty({ id: itemId });
                      }}
                    />
                  </Tooltip>
                </div> */}

                <div style={{ paddingTop: "6px" }}>
                  <Button
                    onClick={() => {
                      setOpenModel({
                        type: "adjustyInventory",
                        param: { id: "", type: InventoryType.Add },
                      });
                    }}
                    type="primary"
                    disabled
                  >
                    Adjust Item
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>

          <Card className="ca-card-body">
            <div
              style={{
                height: `calc(100vh - 216px)`,
                // backgroundColor: "azure",
                overflowY: "auto",
              }}
            >
              {/* <Row gutter={10} justify="start" style={{ marginTop: "10px" }}>
                <Col span={6}>
                  <RangePicker
                    format="DD-MMM-YYYY"
                    onChange={(e: any) => {
                      e === null
                        ? setFilterDate({})
                        : setFilterDate({
                            ...filterDate,
                            from: dayjs(e[0]).format("DD/MM/YYYY"),
                            to: dayjs(e[1]).format("DD/MM/YYYY"),
                          });
                      SetPageInv(1);
                    }}
                    presets={[
                      {
                        label: "Today",
                        value: [dayjs().add(0, "d"), dayjs()],
                      },
                      {
                        label: "Last 7 Days",
                        value: [dayjs().add(-7, "d"), dayjs()],
                      },
                      {
                        label: "Last 15 Days",
                        value: [dayjs().add(-15, "d"), dayjs()],
                      },
                      {
                        label: "This Month",
                        value: [dayjs().startOf("month"), dayjs()],
                      },
                      {
                        label: "Last Month",
                        value: [
                          dayjs().date(0).startOf("month"),
                          dayjs().date(0),
                        ],
                      },
                      {
                        label: "Quarter 1",
                        value: [
                          dayjs().month(3).startOf("month"),
                          dayjs().month(5).endOf("month"),
                        ],
                      },
                      {
                        label: "Quarter 2",
                        value: [
                          dayjs().month(6).startOf("month"),
                          dayjs().month(8).endOf("month"),
                        ],
                      },
                      {
                        label: "Quarter 3",
                        value: [
                          dayjs().month(9).startOf("month"),
                          dayjs().month(11).endOf("month"),
                        ],
                      },
                      {
                        label: "Quarter 4",
                        value: [
                          dayjs().month(0).startOf("month"),
                          dayjs().month(2).endOf("month"),
                        ],
                      },
                      {
                        label: "This Year",
                        //           dayjs().month(0).startOf("month"),
                        // dayjs(),
                        value: [dayjs().month(0).startOf("month"), dayjs()],
                      },
                      {
                        label: "Last Year",
                        value: [
                          dayjs().month(-12).startOf("month"),
                          dayjs().month(-1).endOf("month"),
                        ],
                      },
                    ]}
                  />
                </Col>
                <Col span={6}>
                  <Select
                    // defaultValue={[null]}
                    mode="multiple"
                    maxTagCount={"responsive"}
                    style={{ minWidth: "270px" }}
                    allowClear
                    onChange={(e: any) => {
                      console.log(e);
                      setType(e);
                      SetPageInv(1);
                      // e === null ? setGstRate([]) :  (e);
                      // setGstRate(e === null ? null : e);
                      // console.log(gstRate);
                    }}
                    placeholder="All Items"
                    options={[
                      {
                        label: "Sales ",
                        options: [
                          { value: 1, label: "Sales" },
                          { value: 2, label: "Credit Note" },
                        ],
                      },
                      {
                        label: "Purchase",
                        options: [
                          { value: 3, label: "Purchase" },
                          { value: 4, label: "Debit Note" },
                        ],
                      },
                      {
                        label: "Adjust Item",
                        options: [
                          { value: 5, label: "Add" },
                          { value: 6, label: "Reduce" },
                          { value: 7, label: "Opening Stock" },
                        ],
                      },
                    ]}
                  />
                </Col>
                <Col span={6}>
                  <Select
                    mode="multiple"
                    maxTagCount={"responsive"}
                    style={{ minWidth: "270px" }}
                    showSearch
                    allowClear
                    placeholder="Party Name"
                    filterOption={false}
                    // className="selectedItem"
                    onSearch={(value: string) =>
                      setSearchCustomer({ search: value, length: 15 })
                    }
                    onChange={(e: any) => {
                      setPartyIdFilter(e);
                      // SetPageInv(1);
                    }}
                    // onSelect={(value: any) => CustomerSelect(value)}
                    options={customerOptions?.map((value: any) => ({
                      value: value?.id,
                      label: value?.name,
                    }))}
                    dropdownRender={(customerOptions) => (
                      <>
                        {customerOptions}
                        <Divider style={{ margin: "5px 0px" }} />
                        <Button
                          style={{ width: "100%" }}
                          icon={<PlusOutlined />}
                          type="link"
                          // onClick={() => {
                          //   setOpenCustomerDrawer(true);
                          //   setEditParty({ id: null });
                          // }}
                        >
                          New Customer
                        </Button>
                      </>
                    )}
                  />
                </Col>
              </Row> */}
              <div
                style={{
                  marginTop: 15,
                  fontFamily: "lato",
                }}
              >
                {inventoryList?.length > 0 ? (
                  <InfinitScroll
                    dataLength={endpage}
                    next={() => {
                      // getTaskScroll(startpage + 15, endpage + 15);
                    }}
                    hasMore={true}
                    scrollableTarget="scrollableDiv"
                    loader={inventoryLoading && <h4></h4>}
                  >
                    <div
                      id="scrollableDiv"
                      style={{
                        display: "block",
                        height: "60vh",
                        overflowX: "scroll",
                      }}
                    >
                      {/* <Table
                        className={`Tabel-style-none table-${
                          themeData?.componentSize ?? "middle"
                        }`}
                        locale={{
                          triggerDesc: "",
                          triggerAsc: "",
                          cancelSort: "",
                        }}
                        onChange={tableSortInventory}
                        columns={inventoryColumns}
                        dataSource={inventoryList}
                        pagination={false}
                        // pagination={{
                        //   total: inventoryTotalRecords,
                        //   current: pageInv,
                        //   pageSize: pageSizeInv,
                        //   showSizeChanger: true,
                        //   onChange: (page: number, pageSize: number) => {
                        //     SetPageInv(page);
                        //     setPageSizeInv(pageSize);
                        //   },
                        //   pageSizeOptions: ["10", "15", "25", "50", "100"],
                        //   showTotal: (inventoryTotalRecords, pageInv) =>
                        //     `${pageInv[0]}-${pageInv[1]} of ${inventoryTotalRecords} items`,
                        // }}
                      /> */}
                    </div>
                    {scrollloading && (
                      <div
                        style={{
                          margin: 0,
                          marginTop: -18,
                          textAlign: "center",
                        }}
                      >
                        <Spin
                          indicator={
                            <LoadingOutlined style={{ fontSize: 24 }} spin />
                          }
                        />
                      </div>
                    )}
                  </InfinitScroll>
                ) : (
                  <div
                    id="scrollableDiv"
                    style={{
                      height: "70vh",
                      overflowX: "scroll",
                    }}
                  >
                    <div>
                      {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10%",
                        }}
                      >
                        <img
                          alt="Inventory Management"
                          src={process.env.PUBLIC_URL + "/noTask.svg"}
                          height="100px"
                        />
                      </div> */}
                      <div>
                        <h3
                          style={{
                            margin: 0,
                            fontFamily: "lato",
                            fontSize: 20,
                          }}
                        >
                          <VoucherDetailsByLedger
                            refresh={""}
                            ledgerId={""}
                            // partyOrTax={bank?.partyOrTax}
                            ledgerName={ledgerName}
                            itemId={itemId}
                          />
                        </h3>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      {/* -------------ADD Items ----------*/}
      {openModel.type === "addItem" && (
        <AddItems
          open={openModel.type === "addItem"}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() => setTrigger(!trigger)}
          isServicesItem={
            props.goodsItm?.type === ItemType.AllItemServices ? true : false
          }
          // setNewItem={setNewItem}
        />
      )}
      {openModel.type === "adjustyInventory" && (
        <InventoryAdjustModel
          trigger={() => setTriggerInvemtory(!triggerInventory)}
          open={true}
          type={openModel.param?.type}
          id={openModel.param?.id}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          item={{ id: itemId, name: itemInventoryDetails?.name }}
          allItems={itemsList}
        />
      )}
      {openModel.type === "salesInvoice" && (
        <ViewSalesInvoice
          open={openModel.type === "salesInvoice"}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          invoiceId={openModel.param?.id}
        />
      )}
      {openModel.type === "crediteNote" && (
        <ViewCreditNote
          open={openModel.type === "crediteNote"}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          creditNoteId={openModel.param?.id}
        />
      )}
      {openModel.type === "purchaseInvoice" && (
        <ViewPurchaseInvoice
          open={openModel.type === "purchaseInvoice"}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          invoiceId={openModel.param?.id}
        />
      )}
      {openModel.type === "debitNote" && (
        <ViewDebitNote
          open={openModel.type === "debitNote"}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          debitNoteId={openModel.param?.id}
        />
      )}
    </div>
  );
};

export default GoodsItems;
