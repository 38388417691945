import {
  DeleteOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Row, Spin, Table, Tooltip } from "antd";
import React from "react";
import AddTermsAndCondition from "./AddTermsAndCondition";
import TermsAndConditionsService from "../../Services/TermsAndConditionsService";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";
import { Voucher_Type } from "../../Services/VoucherServices";
import { ColumnsType } from "antd/es/table";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { useTheme } from "../../Context/ThemeContext";
import { voucherType } from "../Reports/VoucherDetailsByLedger";
import Search from "antd/es/transfer/search";

const TermsAndConditions = () => {
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const { openNotification } = React.useContext(notificationContext);
  const [conditionList, setConditionList] = React.useState<any[]>([]);
  const [id, setId] = React.useState<any>();
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [page, setPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [isModelOpen, setIsModelOpen] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [voucher, setVoucher] = React.useState<Voucher_Type>();
  const [voucherTitle, setVoucherTitle] = React.useState<string>();
  const [refresh, setRefresh] = React.useState<Boolean>();
  React.useEffect(() => {
    getTnC();
  }, [(page - 1) * pageSize, pageSize, search, refresh]);

  const getTnC = async () => {
    setLoading(true);
    let res = await TermsAndConditionsService?.getTermsAndConditionList(
      currentClient?.id,
      Voucher_Type?.All,
      (page - 1) * pageSize,
      pageSize,
      search
    );
    if (res) {
      setConditionList(
        res?.result?.map((d: any, index: number) => {
          return {
            SNo: index + 1,
            id: d?.id,
            templateName: d?.templateName,
            termNConditions: d?.termNConditions,
            voucherType: d?.voucherType,
            voucherTitle: d?.voucherTitle,
          };
        })
      );
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const deleteDatabyId = async () => {
    let res = await TermsAndConditionsService?.deleteDatabyId(
      currentClient?.id,
      id
    );
    if (res?.status) {
      openNotification("success", "Deleted successfully.");
      getTnC();
      setConfirmation(false);
    } else {
      openNotification("error", res?.message);
      setConfirmation(false);
    }
  };

  const columns: ColumnsType<any> = [
    { title: "S.No.", dataIndex: "SNo", width: "5%" },
    { title: "Template Name", dataIndex: "templateName", width: "20%" },
    {
      title: "Terms and Conditions",
      dataIndex: "termNConditions",
      // width: "20%",
      render: (_: any, record: any) => (
        <>
          <div
            dangerouslySetInnerHTML={{
              __html: record.termNConditions,
            }}
          />
        </>
      ),
    },
    {
      title: "Voucher Title",
      dataIndex: "voucherTitle",
      width: "12%",
      render: (_: any, record: any) => <>{record?.voucherTitle}</>,
    },
    {
      title: "Voucher Type",
      dataIndex: "voucherType",
      width: "12%",
      render: (_: any, record: any) => <>{voucherType[record?.voucherType]}</>,
    },
    {
      title: "Action",
      dataIndex: "",
      width: "7%",
      render: (record: any) => (
        <>
          <Row gutter={10}>
            <Col>
              <Tooltip title="Edit">
                <FormOutlined
                  onClick={() => {
                    setId(record?.id);
                    setVoucher(record?.voucherType);
                    setVoucherTitle(record?.voucherTitle);
                    setIsModelOpen(true);
                  }}
                />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title="Delete" color="red">
                <DeleteOutlined
                  style={{ color: "red" }}
                  onClick={() => {
                    setId(record?.id);
                    setConfirmation(true);
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        </>
      ),
    },
  ];
  return (
    <>
      <Card
        size="small"
        className="TableCard"
        style={{
          // marginTop: "6px",
          marginTop: 35,
          // padding: "48px 0px 0px 0px",
          boxShadow: "0px 0px 10px 0px #96A1C84D",
        }}
      >
        <div>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 20 }} />}
            spinning={loading}
          >
            <div>
              <Row justify={"space-between"}>
                <Col>
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      setIsModelOpen(true);
                      setId("");
                    }}
                  >
                    Add Terms & Conditions
                  </Button>
                </Col>
                <Col>
                  <Search
                    placeholder="Search..."
                    onChange={(e: any) => setSearch(e.target.value)}
                  />
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: "15px" }}>
              <Table
                className={`Tabel-style table-${
                  themeData?.componentSize ?? "middle"
                }`}
                columns={columns}
                dataSource={conditionList}
                pagination={{
                  total: totalRecords,
                  current: page,
                  pageSize: pageSize,
                  showSizeChanger: true,
                  onChange: (page: number, pageSize: number) => {
                    setPage(page);
                    setPageSize(pageSize);
                  },
                  pageSizeOptions: ["10", "15", "25", "50", "100"],
                }}
                scroll={
                  pageSize > 15
                    ? { x: 1500, y: window.innerHeight - 300 }
                    : undefined
                }
              />
            </div>
          </Spin>
        </div>
      </Card>
      {isModelOpen && (
        <AddTermsAndCondition
          onCancel={() => setIsModelOpen(false)}
          isModelOpen={isModelOpen}
          id={id}
          voucher={voucher}
          setRefresh={setRefresh}
          voucherTitle={voucherTitle}
        />
      )}
      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            deleteDatabyId();
          }}
          text={"Are you sure you want to delete this data ? "}
        />
      )}
    </>
  );
};

export default TermsAndConditions;
