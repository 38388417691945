import { FC, Fragment, useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Divider,
  Spin,
  InputRef,
  Checkbox,
  Select,
  InputNumber,
  DatePicker,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";
import { dayjs } from "../../../Utilities/dayjs";
import { Voucher_Type } from "../../Services/VoucherServices";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";

const AddSalesInvoiceDespatchDetails: FC<{
  open: boolean;
  onCancel: any;
  data: any;
  saveData: any;
  voucherType: Voucher_Type;
}> = (props) => {
  console.log("props", props);
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  // const [groupData, setGroupData] = useState<any[]>();

  const [loading, setLoading] = useState<boolean>(false);
  const [transporterCoName, setTransporterCoName] = useState<any[]>([]);

  const saveGroup = () => {
    form.validateFields().then(async (value) => {
      //   setSaveButtonLoading(true);
      const saveData = {
        ...value,
        transporterCoName:
          props.voucherType === Voucher_Type.SalesVoucher
            ? {
                id: value?.transporterCoName?.value,
                name: value?.transporterCoName?.label,
              }
            : {
                name: value?.transporterCoName,
              },
      };
      props.data(saveData);
      console.log(saveData);
    });
  };

  useEffect(() => {
    const getApi = async () => {
      if (props.voucherType === Voucher_Type.SalesVoucher) {
        try {
          setLoading(true);
          await LedgerService.getLedgersByGroupName(
            currentClient?.id!,
            SearchLedgerByGroupsTypes.TransporterOnly
          ).then((res: any) => {
            setTransporterCoName(res);
            setLoading(false);
          });
        } catch (err: any) {
          console.error("error", err);

          openNotification("error", err.message);
          setLoading(false);
        }
      }
    };
    getApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.voucherType]);

  useEffect(() => {
    if (props.saveData) {
      const data = {
        ...props.saveData,
        transporterCoName:
          props.voucherType === Voucher_Type.SalesVoucher
            ? {
                value: props.saveData?.transporterCoName?.id,
                label: props.saveData?.transporterCoName?.name,
              }
            : props.saveData?.transporterCoName?.name,
      };
      form.setFieldsValue(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.saveData]);

  return (
    <Fragment>
      <Modal
        open={props.open}
        title="Add Despatch Details"
        maskClosable={false}
        width={500}
        style={{ top: 44 }}
        onCancel={() => props.onCancel({})}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancel({});
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => saveGroup()}
              style={{ width: "80px" }}
              loading={saveButtonLoading}
              className="modelSaveButtonMargin"
            >
              Save
            </Button>
          </div>
        }
      >
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          spinning={loading}
        >
          <Form
            name="despatchDetail"
            form={form}
            autoComplete="off"
            colon={false}
            labelAlign="left"
            // initialValues={intialValue}
            labelCol={{ xs: 7, sm: 10 }}
            wrapperCol={{ xs: 16, sm: 14 }}
            labelWrap={true}
          >
            <Divider className="ca-model-css" />
            <Form.Item
              name="delNoteNo"
              label={
                props.voucherType === Voucher_Type.SalesVoucher
                  ? "Delivery Note No."
                  : "Receipt Note No."
              }
              className="mb-14"
              // className="inputBoxMb8"
              //   rules={[{ required: true, message: "Account Code is required" }]}
            >
              <Input autoFocus={true} ref={inputRef} />
            </Form.Item>

            <Form.Item
              name="delNoteDate"
              label="Date"
              className="mb-14"
              //   rules={[{ required: true, message: "Account Code is required" }]}
            >
              <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              name="desDocNo"
              label={
                props.voucherType === Voucher_Type.SalesVoucher
                  ? "Despatch Doc. No"
                  : "Receipt Doc. No"
              }
              className="mb-12"
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="transporterCoName"
              label="Transporter CoName"
              className="mb-14"
              //   rules={[{ required: true, message: "Account Type is required" }]}
            >
              {props.voucherType === Voucher_Type.SalesVoucher ? (
                <Select
                  showSearch
                  allowClear
                  labelInValue
                  optionFilterProp="children"
                  placeholder="Transporter's name"
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={transporterCoName?.map((value: any) => ({
                    value: value?.id,
                    label: value?.ledgerName.concat(
                      value?.aliasName ? `${" (" + value?.aliasName + ")"}` : ""
                    ),
                  }))}
                />
              ) : (
                <Input />
              )}
            </Form.Item>

            <Form.Item
              name="transporterOwnerName"
              label="Transporter Owner Name"
              className="mb-12"
            >
              <Input />
            </Form.Item>

            <Form.Item name="vehicleNo" label="Vehicle No." className="mb-12">
              <Input />
            </Form.Item>

            {props.voucherType === Voucher_Type.SalesVoucher && (
              <>
                <Form.Item
                  name="driverName"
                  label="Driver Name"
                  className="mb-12"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="driverContactNo"
                  label="Driver Contact No."
                  className="mb-12"
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="9876543210"
                    controls={false}
                  />
                </Form.Item>
              </>
            )}

            <Form.Item
              name="destination"
              label="Destination"
              className="mb-14"
              //   rules={[{ required: true, message: "Nature is required" }]}
            >
              <Input />
            </Form.Item>
          </Form>
          <Divider className="divider-account" />
        </Spin>
      </Modal>
    </Fragment>
  );
};

export default AddSalesInvoiceDespatchDetails;
