import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { TimeUnits } from "./SettingService";

export enum Party_Tax {
  other,
  party,
  tax,
  BanknCash,
  TangibleFixedAsset,
  Depreciation,
  RoundOff,
  Discount,
  CC,
}
export enum Voucher_Type {
  All,
  SalesVoucher,
  CreditNote,
  PurchaseVoucher,
  DebitNote,
  Payment,
  Receipt,
  Contra,
  PurchaseOrder,
  SalesOrder,
  Journal,
  Quotation,
  ExportInvoice,
  DeemedExportInvoice,
  SEZInvoice,
  ExportBill,
  DeemedExportBill,
  SEZBill,
  RCMBill,
  ExpenseJV,
  DChallan,
  RChallan,
  Asset,
  StockVoucher,
  Requisition,
  Expenses,
  Income,
}
export enum ReconcileTypes {
  Both,
  Reconciled,
  Nonreconciled,
}

export enum LedgerTypes {
  All,
  PartiesOnly,
  PartiesWithBank,
  BankAndCash,
  Sales,
  Purchase,
  DirectExpenses,
  IndirectExpenses,
  PaymentIn,
  PaymentOut,
  DutiesAndTaxes,
}
export enum VoucherFilterStatus {
  All,
  Active,
  Due,
  Overdue,
  Paid,
  Deleted,
  Draft,
}
export enum OrderStatus {
  All,
  Pending,
  Completed,
  Deleted,
  Draft,
}

export enum VoucherStatus_New {
  Active,
  Deleted,
}

export enum AddressEnum {
  BillingAddress,
  ShippingAddress,
}

export enum RefType {
  newRef,
  AgnstRef,
}

export enum ReconciledFilter {
  both,
  reconciled,
  nonreconciled,
}

export enum Freight_Type {
  //  [Display(Name = "Ex-Works")]
  ExWorks = 1,
  //  [Display(Name = "Cost, Insurance and Freight")]
  CIF,
  //  [Display(Name = "Freight on Board")]
  FOB,
}

export const convertToDays = (duration: number, durationUnit?: TimeUnits) => {
  let days;
  if (duration <= 0) {
    return 0;
  } else {
    switch (durationUnit) {
      case TimeUnits.Day:
        days = duration;
        break;
      case TimeUnits.Week:
        days = duration * 7;
        break;
      case TimeUnits.Month:
        days = duration * 30; // Approximation, adjust as necessary
        break;
      case TimeUnits.Year:
        days = duration * 365; // Approximation, adjust as necessary
        break;
      default:
        throw new Error("Invalid duration unit");
    }
    return days;
  }
};

export enum DiscountType {
  NoDiscount,
  PercentageDiscount,
  RupeeDiscount,
}

class VoucherService {
  route = "v1/API/Acc_New_Voucher";

  //localhost:7142/v1/API/Acc_New_Voucher/65cdd05235241f48a55de738/GetVoucherListByPartyId?voucherType=2&voucherType=660a7f238413a5a73dd8ca11

  getVoucherListByPartyId = (
    companyId: string,
    voucherType: Voucher_Type,
    partyId: string
  ) =>
    ApiUtility.get(`${this.route}/${companyId}/GetVoucherListByPartyId`, {
      voucherType,
      partyId,
    });

  // GetMaxVoucherDate;
  // https://localhost:7142/v1/API/Acc_New_Voucher/6646e10e5038e585a6b5e816/GetMaxVoucherDate?voucherType=1
  getMaxVoucherDate = (companyId: string, voucherType: Voucher_Type) =>
    ApiUtility.get(`${this.route}/${companyId}/GetMaxVoucherDate`, {
      voucherType,
    });

  // https: //localhost:7142/v1/API/Acc_New_Voucher/65cdd05235241f48a55de738/GetVoucherMaxNo?voucherType=1
  //get party vise references max no
  getVoucherMaxNo = (
    companyId: string,
    voucherType: Voucher_Type,
    voucherDate: any,
    VoucherTitle: string
  ) =>
    ApiUtility.get(
      `${this.route}/${companyId}/GetVoucherMaxNo?voucherType=${voucherType}`,
      {
        VoucherTitle,
        voucherDate,
      }
    );

  //https://localhost:7142/v1/API/Acc_New_Voucher/65cdd05235241f48a55de738/GetReferencesByParty?partyId=65d74367983b0a7e7dda7721

  // get party vise references
  getReferencesByParty = (
    companyId: string,
    partyId: string,
    voucher_Type: Voucher_Type
  ) =>
    ApiUtility.get(`${this.route}/${companyId}/GetReferencesByParty`, {
      partyId,
      voucher_Type,
    });

  // Download/CSV

  exportCsv = (
    companyId: any,
    voucherType: Voucher_Type | any,
    from: string,
    to: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    partyId?: string,
    reconciled?: ReconcileTypes | any,
    search?: string,
    status?: VoucherFilterStatus
  ) =>
    ApiUtility.downloadFile(`${this.route}/${companyId}/Download/CSV`, {
      voucherType,
      start,
      length,
      from,
      to,
      sortCol,
      sortDir,
      partyId,
      reconciled,
      search,
      status,
    });
  // public async Task<IActionResult> DownloadCSV(string companyId, Voucher_Type voucherType, int start = 0, int length = 15, string from = null, string to = null, string sortCol = "", string sortDir = "", string partyId = null, ReconciledFilter reconciled = ReconciledFilter.both, string search = "", VoucherFilterStatus status = VoucherFilterStatus.Active)

  //https://localhost:7142/v1/API/Acc_New_Voucher/65cdd05235241f48a55de738/GetList?voucherType=0&start=0&length=15&partyId=65d5a8435b08e02630da8017
  //https://localhost:7142/v1/API/Acc_New_Voucher/65cdd05235241f48a55de738/GetList?voucherType=0&start=0&length=15&from=20-03-2024&to=20-03-2024

  getVoucharList = (
    companyId: any,
    voucherType: Voucher_Type | any,
    from: string,
    to: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    partyId?: string,
    reconciled?: ReconcileTypes | any,
    search?: string,
    status?: VoucherFilterStatus,
    itemId?: string
  ) =>
    ApiUtility.get(`${this.route}/${companyId}/GetList`, {
      voucherType,
      from,
      to,
      start,
      length,
      sortCol,
      sortDir,
      partyId,
      reconciled,
      search,
      status,
      itemId,
    });

  //https: //localhost:7142/v1/API/Acc_New_Voucher/65cdd05235241f48a55de738/65faa00a2200123bddd4a24e?voucherType=10

  // get Vouchar Id
  getVoucharById = (
    companyId: string | undefined,
    voucharId: string | undefined,
    voucherType?: Voucher_Type
  ) =>
    ApiUtility.get(`${this.route}/${companyId}/${voucharId}`, {
      voucherType,
    });

  //https://localhost:7142/v1/API/Acc_New_Voucher/65cdd05235241f48a55de738/65fd5954085e97708cd14693?voucherType=10
  // post Ledger Api

  postVoucher = (
    companyId: string | undefined,
    voucherType: Voucher_Type | undefined,
    params: any,
    VoucherTitle: string,
    id?: string
    // attachments?: any,
    // config?: string,
  ) =>
    ApiUtility.postForm(
      `${this.route}/${companyId}${
        id ? "/" + id : ""
      }?voucherType=${voucherType}&VoucherTitle=${VoucherTitle}`,
      params
      // config,
      // attachments,
    );

  // https: //localhost:7142/v1/API/Acc_New_Voucher/65cdd05235241f48a55de738/66211d2d3a14b834a3366c28
  // delete vouchar api
  deleteVoucher = (companyId: string | undefined, voucherId: string) =>
    ApiUtility.delete(`${this.route}/${companyId}/${voucherId}`);

  postReconcileVoucher = (companyId: string | undefined, reconciledData: any) =>
    ApiUtility?.post(
      `${this.route}/${companyId}/ReconcileVoucher`,
      reconciledData
    );

  // https://localhost:7142/v1/API/Acc_New_Voucher/6646e10e5038e585a6b5e816/OpeningEntry

  openingEntryGet = (companyId: string) =>
    ApiUtility.get(`${this.route}/${companyId}/OpeningEntry`);

  GetLastReconcileHistory = (companyId: string | undefined, ledgerId: string) =>
    ApiUtility.get(
      `${this.route}/${companyId}/LedgerId/${ledgerId}/GetLastReconcileHistory`
    );

  GetAllReconcileHistory = (
    companyId: string | undefined,
    ledgerId: string,
    fromDate: string,
    toDate: string
  ) =>
    ApiUtility.get(
      `${this.route}/${companyId}/LedgerId/${ledgerId}/GetAllReconcileHistory?fromDate=${fromDate}&toDate=${toDate}`
    );
}
export default new VoucherService();
