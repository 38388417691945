import { ApiUtility } from "../../Utilities/ApiUtility.axios";
export enum PeriodRange {
  Unspecified,
  Today,
  Yesterday,
  CurrentWeek,
  LastWeek,
  ThisMonth,
  LastMonth,
  //ThisYear,
  //LastYear
}

class DashboardService {
  // route = "V1/API/Acc_Dashboard";
  route = "V1/API/Acc_New_Dashboard";
  getDashboardData = (
    companyId: string,
    from: string,
    to: string,
    period: PeriodRange = PeriodRange.Unspecified
  ) => {
    return ApiUtility.get(`${this.route}/Data?companyId=${companyId}`, {
      fromDate: from,
      toDate: to,
      period: period,
    });
  };
}
export default new DashboardService();
