import {
  BookTwoTone,
  CopyTwoTone,
  FilePdfTwoTone,
  FileTextTwoTone,
  FileZipTwoTone,
  FolderOpenTwoTone,
  PlusCircleFilled,
  SaveTwoTone,
  SnippetsTwoTone,
} from "@ant-design/icons";
import { Button, Card, Col, Divider, Row } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import { Product_Module } from "../../Services/ClientService";

const Reports = () => {
  const { currentClient, companySettings } = useClient();
  const [agingType, setAgingType] = React?.useState<number>();
  const navigate = useNavigate();

  console.log("companySettings data: ", companySettings);

  const financialData = [
    {
      title: "Profit & Loss",
      description:
        "The Profit and loss (P&L) statement is a financial statement that summarizes the revenues, costs, and expenses incurred during a specified period.",
      icon: <SnippetsTwoTone />,
      link: `/accounts/clients/${currentClient?.id}/reports/profitAndLoss`,
    },
    {
      title: "Balance Sheet",
      description:
        "Balance sheet is a financial report that outlines a company's assets, liabilities, and shareholders' equity for a particular period of time.",
      icon: <CopyTwoTone />,
      link: `/accounts/clients/${currentClient?.id}/reports/BalanceSheet`,
    },
    {
      title: "Trial Balance",
      description:
        "Trial balance is a summary of all the balances in the ledger account at the end of the accounting period.",
      icon: <CopyTwoTone />,
      link: `/accounts/clients/${currentClient?.id}/reports/trialbalancenew`,
    },

    // {
    //   title: "Statement of Cashflow",
    //   description:
    //     "The cash flow statement is a financial statement that summarizes the movement of cash and cash equivalents that come in and go out of a company.",
    //   icon: <FilePdfTwoTone />,
    //   link: ``,
    // },
  ];

  const payablenReceivable = [
    {
      title: "Aged Debtors",
      description:
        "Aged debtors report is a totalled list of all outstanding sales invoices, minus any credit notes that have been issued to customers, and not yet refunded.",
      icon: <FolderOpenTwoTone />,
      link: `/accounts/clients/${currentClient?.id}/reports/ageing/debtors`,
      type: 0,
    },
    {
      title: "Aged Creditors",
      description:
        "Aged creditors report is a totalled list of all the purchase bills that have not been paid yet, minus any credit notes that have been issued to suppliers, and not yet refunded.",
      icon: <SaveTwoTone />,
      link: `/accounts/clients/${currentClient?.id}/reports/ageing/creditors`,
      type: 1,
    },

    {
      title: "Customer Outstanding",
      description:
        "The customer outstanding list shows the customers who owe your company, how much they owe, and how long it has been since they were sent an invoice for payment.",
      icon: <FolderOpenTwoTone />,
      link: `/accounts/clients/${currentClient?.id}/reports/partyOutstanding/customer`,
      type: 0,
    },
    {
      title: "Supplier Outstanding",
      description:
        "The supplier outstanding report gives details of all the suppliers balances during the period.",
      icon: <SaveTwoTone />,
      link: `/accounts/clients/${currentClient?.id}/reports/partyOutstanding/supplier`,
      type: 1,
    },
  ];

  const general = [
    {
      title: "General Ledger",
      description:
        "General ledger is a set of numbered accounts a business uses to keep track of its financial transactions and to prepare financial reports.",
      icon: <SnippetsTwoTone />,
      link: `/accounts/clients/${currentClient?.id}/reports/generalLedger`,
    },
    {
      title: "Fixed Assest Register",
      description:
        "A fixed asset register is a detailed list of various assets that a business owns and is used to summarise both accounting and depreciation expenses.",
      icon: <BookTwoTone />,
      link: `/accounts/clients/${currentClient?.id}/reports/far`,
    },
    {
      title: "Bank Reconcilation",
      description:
        "A bank reconciliation statement is the summary of the banking and business accounts that reconciles a company's bank account with its financial record.",
      icon: <FilePdfTwoTone />,
      link: `/accounts/clients/${currentClient?.id}/reports/brsReports`,
    },
  ];

  const GSTReports = [
    {
      title: "GST Reports",
      description:
        "GST reports is the summary of all Sales and Purchases made during the designated period with GST received and paid on respectively. Difference in Sale and Purchase GST, if it is positive then it is payable else refundable.",
      icon: <FilePdfTwoTone />,
      link: `/accounts/clients/${currentClient?.id}/reports/GSTR1`,
    },
  ];

  const handleCardClick = (link: string) => {
    navigate(link);
    console.log(link);
  };
  return (
    <div>
      <h3 style={{ margin: "0px 0px 0px 0px", color: "rgb(22, 119, 255)" }}>
        Reports
      </h3>
      <Divider style={{ margin: "10px 0px 0px 0px" }} />
      <div
        style={{
          maxHeight: "80vh",
          overflowY: "scroll",
          overflowX: "hidden",
          padding: "5px",
        }}
      >
        {currentClient?.productModule !== Product_Module.Billing && (
          <>
            <h3 style={{ margin: "10px 0px 10px 0px" }}>Financial Reports</h3>
            <Row gutter={[20, 20]}>
              {financialData?.map((data: any) => (
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                  <Link to={data?.link}>
                    <div className="report-card">
                      <Row>
                        <Col>
                          <span
                            style={{
                              fontSize: "18px",
                              padding: "5px 7px 0px 0px",
                            }}
                          >
                            {data?.icon}
                          </span>
                        </Col>
                        <Col>
                          <p
                            style={{
                              fontSize: "15px",
                              paddingTop: "1px",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            {data?.title}
                          </p>
                        </Col>
                      </Row>

                      <p
                        style={{
                          paddingTop: "10px",
                          height: "100%",
                          lineHeight: "1.4rem",
                          color: "black",
                        }}
                      >
                        {data?.description}
                      </p>
                      <div>{data?.button}</div>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
            <Divider style={{ margin: "20px 0px 0px 0px" }} />
          </>
        )}

        <h3 style={{ margin: "10px 0px 10px 0px" }}>
          Payables and Receivables Reports
        </h3>
        <Row gutter={[20, 20]}>
          {payablenReceivable?.map((data: any) => (
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={6}
              style={{ height: "100%" }}
            >
              <Link to={data?.link}>
                <div className="report-card">
                  <Row>
                    <Col>
                      <span
                        style={{
                          fontSize: "18px",
                          padding: "5px 7px 0px 0px",
                        }}
                      >
                        {data?.icon}
                      </span>
                    </Col>
                    <Col>
                      <p
                        style={{
                          fontSize: "15px",
                          paddingTop: "1px",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {data?.title}
                      </p>
                    </Col>
                  </Row>

                  <p
                    style={{
                      paddingTop: "10px",
                      height: "100%",
                      lineHeight: "1.4rem",
                      color: "black",
                    }}
                  >
                    {data?.description}
                  </p>
                  <div>{data?.button}</div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>

        {currentClient?.productModule !== Product_Module.Billing && (
          <>
            <Divider style={{ margin: "20px 0px 0px 0px" }} />
            <h3 style={{ margin: "10px 0px 10px 0px" }}>General</h3>
            <Row gutter={[20, 20]}>
              {general?.map((data: any) => (
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                  <Link to={data?.link}>
                    <div className="report-card">
                      <Row>
                        <Col>
                          <span
                            style={{
                              fontSize: "18px",
                              padding: "5px 7px 0px 0px",
                            }}
                          >
                            {data?.icon}
                          </span>
                        </Col>
                        <Col>
                          <p
                            style={{
                              fontSize: "15px",
                              paddingTop: "1px",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            {data?.title}
                          </p>
                        </Col>
                      </Row>

                      <p
                        style={{
                          paddingTop: "10px",
                          height: "100%",
                          lineHeight: "1.4rem",
                          color: "black",
                        }}
                      >
                        {data?.description}
                      </p>
                      <div>{data?.button}</div>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </>
        )}

        {companySettings?.setting.gstDetails.isGSTEnabled && (
          <>
            {" "}
            <Divider style={{ margin: "20px 0px 0px 0px" }} />
            <h3 style={{ margin: "10px 0px 10px 0px" }}>GST Reports</h3>
            <Row gutter={[20, 20]}>
              {GSTReports?.map((data: any) => (
                <Col
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                  style={{ height: "100%" }}
                >
                  <Link to={data?.link}>
                    <div className="report-card">
                      <Row>
                        <Col>
                          <span
                            style={{
                              fontSize: "18px",
                              padding: "5px 7px 0px 0px",
                            }}
                          >
                            {data?.icon}
                          </span>
                        </Col>
                        <Col>
                          <p
                            style={{
                              fontSize: "15px",
                              paddingTop: "1px",
                              color: "black",
                              fontWeight: "bold",
                            }}
                          >
                            {data?.title}
                          </p>
                        </Col>
                      </Row>

                      <p
                        style={{
                          paddingTop: "10px",
                          height: "100%",
                          color: "black",
                          lineHeight: "1.4rem",
                        }}
                      >
                        {data?.description}
                      </p>
                      <div>{data?.button}</div>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default Reports;
