import { Card, Row, Col } from "antd";
import React from "react";

import logoIcon from "../../Images/preview_company_logo.png";

const Template1 = ({ currentClient, isChanged }: any) => {
  return (
    <>
      <div
        // className={"Template" + isChanged?.font}
        className={`Template` + isChanged?.font + ` ` + `main-invoice`}
        style={{
          // height: "820px",
          width: "770px",
          paddingTop: `${isChanged?.topMargin}px`,
          // marginBottom: `${isChanged?.bottomMargin}px`,
        }}
      >
        {/* <Card
          style={{
            margin: "0px 39px",
            width: "770px",
            overflowY: "scroll",
            height: "810px",
          }}
          className="template"
        > */}
        <div
          style={{
            backgroundColor: "#F3F8FF",
            padding: "5px",
            maxHeight: "100%px",
            margin: isChanged.topMargin,
            // maxWidth:""
          }}
        >
          <div
            style={{
              maxWidth: "1000px",
              margin: "0px auto",
            }}
          >
            {/* <Row justify={"space-between"}>
              <Col
                lg={12}
                style={{
                  textAlign:
                    isChanged.logoAlign === "left"
                      ? "start"
                      : isChanged.logoAlign === "right"
                      ? "end"
                      : "start",
                  paddingRight:
                    isChanged.logoAlign === "center" ? "70px" : "0px",
                }}
              > */}
            <img
              // src={logoIcon}
              src="https://placehold.co/100x100/EEE/31343C?font=montserrat&text=Logo"
              alt="Logo"
              style={{
                height: "65%",
                display: isChanged.showLogo ? "" : "none",
              }}
            />
            {/* <Row>
                        <Typography.Title level={5}>
                          {currentClient?.businessName}
                        </Typography.Title>
                      </Row> */}
            {/* </Col> */}
            {/* <Col lg={12} style={{ textAlign: "end" }}>
                        <Typography.Title level={2} underline>
                          Tax Invoice
                        </Typography.Title>
                      </Col> */}
            {/* </Row> */}

            <div
              style={{
                backgroundColor: "#4e6fda",
                justifyContent: "space-between",
                display: "flex",
                padding: "5px",
                color: "white",
                fontFamily: isChanged?.font,
              }}
            >
              <div>
                <h3
                  style={{
                    margin: "0px",
                    fontSize: "23px",
                    borderRightColor: "white",
                  }}
                >
                  {currentClient?.businessName}
                </h3>
                <p
                  style={{
                    margin: "0px",
                    display: isChanged.showCompanyAddress ? "" : "none",
                  }}
                >
                  Basni Industrial Area, Jodhpur
                </p>
                <p style={{ margin: "0px" }}>
                  <strong> State</strong>:08-Rajasthan,
                  <strong
                    style={{
                      display: isChanged.showGSTIN ? "" : "none",
                    }}
                  >
                    GSTIN
                  </strong>
                  <span
                    style={{
                      display: isChanged.showGSTIN ? "" : "none",
                    }}
                  >
                    : 08ABCCA4785G1ZZ,
                  </span>
                  <span
                    style={{
                      display: isChanged.showPan ? "" : "none",
                    }}
                  >
                    <strong>PAN</strong>: ABCCA4785G
                  </span>
                </p>
                <p style={{ margin: "0px" }}>
                  <span
                    style={{
                      display: isChanged.showEmail ? "" : "none",
                    }}
                  >
                    <strong>Email:</strong> test@gmail.com,
                  </span>
                  <span
                    style={{
                      display: isChanged.showPhone ? "" : "none",
                    }}
                  >
                    <strong>Mobile:</strong> +919898989898
                  </span>
                  ,
                  <span
                    style={{
                      display: isChanged.showWebsite ? "" : "none",
                    }}
                  >
                    <strong>Website:</strong> www.test.com,
                  </span>
                </p>
              </div>
              <div>
                <h4 style={{ margin: "0px" }}>( Invoice Copy)</h4>
                <h2
                  style={{
                    margin: "0px",
                    textAlign: "end",
                    paddingTop: "10px",
                  }}
                >
                  Tax Invoice
                </h2>
              </div>
            </div>
            <table
              style={{
                width: "100%",
                marginTop: "2px",
                borderCollapse: "collapse",
              }}
              className="bordertable"
            >
              <tbody>
                <tr>
                  <td
                    rowSpan={12}
                    style={{
                      width: "50%",
                      textAlign: "start",
                      fontSize: "16px",
                      verticalAlign: "sub",
                      lineHeight: "1.4rem",
                    }}
                  >
                    <u>Buyer (Bill to)</u>
                    {/* <div
                              style={{
                                width: "20%",
                                backgroundColor: "black",
                                height: "1px",
                              }}
                            ></div> */}
                    <p style={{ margin: "0px" }}>ABC & Sons</p>
                    <p style={{ margin: "0px" }}>
                      Sardarpura Jodhpur Rajasthan India
                    </p>
                    <p style={{ margin: "0px" }}>
                      <strong>State</strong>:08-Rajasthan,{" "}
                      <strong>GSTIN</strong>
                      <span>: 08ABCCA4785G1ZZ</span>
                    </p>
                    <p style={{ margin: "0px", paddingTop: "45px" }}>
                      <u
                        style={{
                          display: isChanged.showShipping ? "" : "none",
                        }}
                      >
                        Ship to
                      </u>
                    </p>

                    <p
                      style={{
                        margin: "0px",
                        display: isChanged.showShipping ? "" : "none",
                      }}
                    >
                      ABC & Sons
                    </p>
                    <p
                      style={{
                        margin: "0px",
                        display: isChanged.showShipping ? "" : "none",
                      }}
                    >
                      Sardarpura Jodhpur Rajasthan India
                    </p>
                  </td>
                  <td style={{ fontWeight: "bold" }}>Invoice No.</td>
                  <td style={{ fontWeight: "bold" }}>Date</td>
                  <td style={{ fontWeight: "bold" }}>Reference No.</td>
                </tr>
                <tr>
                  <td style={{ height: "14px" }}></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>Delivery Note No.</td>
                  <td style={{ fontWeight: "bold" }}>Delivery Note Date</td>
                  <td style={{ fontWeight: "bold" }}>Dispatch Through</td>
                </tr>
                <tr>
                  <td style={{ height: "14px" }}></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }}>Place of Supply</td>
                  <td style={{ fontWeight: "bold" }} colSpan={2}>
                    Mode/Terms of Payment
                  </td>
                </tr>
                <tr>
                  <td style={{ height: "14px" }}></td>
                  <td colSpan={2}></td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold" }} colSpan={3}>
                    Terms & Conditions
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} style={{ height: "14px" }}></td>
                </tr>
                <tr
                  style={{
                    display: isChanged.showEBillNo ? "" : "none",
                  }}
                >
                  <td style={{ fontWeight: "bold" }}>EWay Bill No.</td>
                  <td colSpan={3}></td>
                </tr>
                <tr
                  style={{
                    display: isChanged.showEBillNo ? "" : "none",
                  }}
                >
                  <td style={{ fontWeight: "bold" }}>EWay Bill Date</td>
                  <td colSpan={2}></td>
                </tr>
                <tr
                  style={{
                    display: isChanged.showEInvoiceNo ? "" : "none",
                  }}
                >
                  <td
                    style={{
                      fontWeight: "bold",
                      display: isChanged.showEInvoiceNo ? "" : "none",
                    }}
                  >
                    e-Invoice No.
                  </td>
                  <td colSpan={2}></td>
                </tr>
                <tr
                  style={{
                    display: isChanged.showEInvoiceNo ? "" : "none",
                  }}
                >
                  <td style={{ fontWeight: "bold" }}>e-Invoice Date</td>
                  <td colSpan={2}></td>
                </tr>
              </tbody>
            </table>

            <table
              style={{
                width: "100%",
                marginTop: "3px",
                borderCollapse: "collapse",
              }}
              className="bordertable"
            >
              <tbody>
                <tr>
                  <th rowSpan={2} style={{ verticalAlign: "sub" }}>
                    Sr.
                  </th>
                  <th rowSpan={2} style={{ verticalAlign: "sub" }}>
                    Item(s)
                  </th>
                  <th rowSpan={2} style={{ verticalAlign: "sub" }}>
                    Qty
                  </th>
                  <th
                    rowSpan={2}
                    style={{
                      verticalAlign: "sub",
                      display: isChanged.showUnit ? "" : "none",
                    }}
                  >
                    <span
                      style={{
                        display: isChanged.showUnit ? "" : "none",
                      }}
                    >
                      Unit
                    </span>
                  </th>
                  <th rowSpan={2} style={{ verticalAlign: "sub" }}>
                    Rate
                  </th>
                  <th rowSpan={2} style={{ verticalAlign: "sub" }}>
                    Taxable <br /> Amount
                  </th>
                  <th colSpan={2}>CGST</th>
                  <th colSpan={2}>SGST</th>
                </tr>
                <tr>
                  <th>Rate</th>
                  <th>Amt</th>
                  <th>Rate</th>
                  <th>Amt</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    Chair S1 <br /> (Any description will be displayed here)
                  </td>
                  <td style={{ textAlign: "end" }}>01</td>
                  <td
                    style={{
                      textAlign: "center",
                      display: isChanged.showUnit ? "" : "none",
                    }}
                  >
                    <span style={{}}>Pcs</span>
                  </td>
                  <td style={{ textAlign: "end" }}>1000.00</td>
                  <td style={{ textAlign: "end" }}>1000.00</td>
                  <td style={{ textAlign: "center" }}>2.5</td>
                  <td style={{ textAlign: "end" }}>25.00</td>
                  <td style={{ textAlign: "center" }}>2.5</td>
                  <td style={{ textAlign: "end" }}>25.00</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    Sofa SF1 <br /> (Any description will be displayed here){" "}
                  </td>
                  <td style={{ textAlign: "end" }}>01</td>
                  <td
                    style={{
                      textAlign: "center",
                      display: isChanged.showUnit ? "" : "none",
                    }}
                  >
                    <span
                    // style={{
                    //   display: isChanged.showUnit ? "" : "none",
                    // }}
                    >
                      Pcs
                    </span>
                  </td>
                  <td style={{ textAlign: "end" }}>1000.00</td>
                  <td style={{ textAlign: "end" }}>1000.00</td>
                  <td style={{ textAlign: "center" }}>2.5</td>
                  <td style={{ textAlign: "end" }}>25.00</td>
                  <td style={{ textAlign: "center" }}>2.5</td>
                  <td style={{ textAlign: "end" }}>25.00</td>
                </tr>
                {Array.from({ length: 2 }, (_, i) => (
                  <tr key={i}>
                    <td style={{ height: "18px" }}>{i + 3}</td>
                    <td></td>
                    <td
                      style={{
                        display: isChanged.showUnit ? "" : "none",
                      }}
                    ></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <th style={{ textAlign: "end" }}>Items Total (A)</th>
                  <th style={{ textAlign: "end" }}>02</th>
                  <th
                    style={{
                      display: isChanged.showUnit ? "" : "none",
                    }}
                  >
                    Pcs
                  </th>
                  <td style={{ textAlign: "end" }}></td>
                  <th style={{ textAlign: "end" }}>2000.00</th>
                  <td style={{ textAlign: "end" }}></td>
                  <th style={{ textAlign: "end" }}>50.00</th>
                  <td></td>
                  <th style={{ textAlign: "end" }}>50.00</th>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <u>Other Expenses (if any)</u>
                  </td>
                  <td></td>
                  <td
                    style={{
                      display: isChanged.showUnit ? "" : "none",
                    }}
                  ></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>Freight</td>
                  <td></td>
                  <td
                    style={{
                      display: isChanged.showUnit ? "" : "none",
                    }}
                  ></td>
                  <td></td>
                  <td style={{ textAlign: "end" }}>500.00</td>
                  <td style={{ textAlign: "center" }}>2.5</td>
                  <td style={{ textAlign: "end" }}>12.50</td>
                  <td style={{ textAlign: "center" }}>2.5</td>
                  <td style={{ textAlign: "end" }}>12.50</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Packing</td>
                  <td></td>
                  <td
                    style={{
                      display: isChanged.showUnit ? "" : "none",
                    }}
                  ></td>
                  <td></td>
                  <td style={{ textAlign: "end" }}>100.00</td>
                  <td style={{ textAlign: "center" }}>2.5</td>
                  <td style={{ textAlign: "end" }}>2.50</td>
                  <td style={{ textAlign: "center" }}>2.5</td>
                  <td style={{ textAlign: "end" }}>2.50</td>
                </tr>
                <tr>
                  <td></td>
                  <td style={{ textAlign: "end", fontWeight: "bold" }}>
                    Total Expenses (B)
                  </td>
                  <td></td>
                  <td
                    style={{
                      display: isChanged.showUnit ? "" : "none",
                    }}
                  ></td>
                  <td></td>
                  <td style={{ textAlign: "end", fontWeight: "bold" }}>
                    600.00
                  </td>
                  <td></td>
                  <td style={{ textAlign: "end", fontWeight: "bold" }}>
                    15.00
                  </td>
                  <td></td>
                  <td style={{ textAlign: "end", fontWeight: "bold" }}>
                    15.00
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style={{ textAlign: "end", fontWeight: "bold" }}>
                    Sub Total (A+B)
                  </td>
                  <td></td>
                  <td
                    style={{
                      display: isChanged.showUnit ? "" : "none",
                    }}
                  ></td>
                  <td></td>
                  <td style={{ textAlign: "end", fontWeight: "bold" }}>
                    2600.00
                  </td>
                  <td></td>
                  <td style={{ textAlign: "end", fontWeight: "bold" }}>
                    65.00
                  </td>
                  <td></td>
                  <td style={{ textAlign: "end", fontWeight: "bold" }}>
                    65.00
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={4}
                    style={{
                      backgroundColor: "rgb(207, 236, 248)",
                      fontSize: "20px",
                      textAlign: "end",
                      fontWeight: "bold",
                    }}
                  >
                    Total Invoice Value
                  </td>
                  <td
                    colSpan={6}
                    style={{
                      backgroundColor: "rgb(207, 236, 248)",
                      fontSize: "20px",
                      textAlign: "end",
                      fontWeight: "bold",
                    }}
                  >
                    2730.00
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={4}
                    style={{
                      fontSize: "16px",
                      textAlign: "end",
                      fontWeight: "bold",
                    }}
                  >
                    Amount (in words)
                  </td>
                  <td
                    colSpan={6}
                    style={{ fontSize: "16px", textAlign: "end" }}
                  >
                    INR Two Thousand Seven Hundred Thirty only
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                width: "100%",
                marginTop: "3px",
                display: isChanged.showTaxableValue ? "" : "none",
                borderCollapse: "collapse",
              }}
              className="bordertable"
            >
              <tbody>
                <tr>
                  <th
                    rowSpan={2}
                    style={{
                      verticalAlign: "super",
                      fontWeight: "bold",
                      textAlign: "end",
                    }}
                  >
                    Taxable Value
                  </th>
                  <th colSpan={2}>IGST</th>
                  <th colSpan={2}>CGST</th>
                  <th colSpan={2}>SGST/UTGST</th>
                  <th rowSpan={2} style={{ verticalAlign: "sub" }}>
                    Total Tax Amount
                  </th>
                </tr>
                <tr>
                  <th>Rate (%)</th>
                  <th>Amount</th>
                  <th>Rate (%)</th>
                  <th>Amount</th>
                  <th>Rate (%)</th>
                  <th>Amount</th>
                </tr>
                <tr>
                  <td style={{ textAlign: "end" }}>2600.00</td>
                  <td style={{ textAlign: "center" }}>-</td>
                  <td style={{ textAlign: "center" }}>-</td>
                  <td style={{ textAlign: "center" }}>2.5</td>
                  <td style={{ textAlign: "end" }}>65.00</td>
                  <td style={{ textAlign: "center" }}>2.5</td>
                  <td style={{ textAlign: "end" }}>65.00</td>
                  <td style={{ textAlign: "end" }}>130.00</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "end", fontWeight: "bold" }}>
                    Total
                  </td>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>-</td>
                  <td style={{ textAlign: "center", fontWeight: "bold" }}>-</td>
                  <td style={{ textAlign: "center" }}></td>
                  <td style={{ fontWeight: "bold", textAlign: "end" }}>
                    65.00
                  </td>
                  <td style={{ textAlign: "center" }}></td>
                  <td style={{ textAlign: "end", fontWeight: "bold" }}>
                    65.00
                  </td>
                  <td style={{ textAlign: "end", fontWeight: "bold" }}>
                    130.00
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                width: "100%",
                marginTop: "3px",
                display: isChanged.showBankDetail ? "" : "none",
                borderCollapse: "collapse",
              }}
              className="bordertable"
            >
              <tbody>
                <tr>
                  <th colSpan={4} style={{ textAlign: "start" }}>
                    <u>
                      Bank Detail:
                      <span style={{ fontWeight: 400 }}></span>
                    </u>
                  </th>
                </tr>
                <tr>
                  <th style={{ width: "30%", textAlign: "start" }}>
                    Account Name:
                  </th>
                  <th style={{ width: "25%" }}></th>
                  <th style={{ textAlign: "start" }}>Bank Name:</th>
                  <th style={{ width: "30%" }}></th>
                </tr>
                <tr>
                  <th style={{ width: "30%", textAlign: "start" }}>
                    Account No.:
                  </th>
                  <th style={{ width: "25%" }}></th>
                  <th style={{ textAlign: "start" }}>IFS Code:</th>
                  <th style={{ width: "30%" }}></th>
                </tr>
                <tr>
                  <th style={{ width: "30%", textAlign: "start" }}>Branch:</th>
                  <th style={{ width: "25%" }}></th>
                  <th style={{ textAlign: "start" }}>UPI ID / QRC:</th>
                  <th style={{ width: "30%" }}></th>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                width: "100%",
                marginTop: "3px",
                display: isChanged.showDispatchDetail ? "" : "none",
                borderCollapse: "collapse",
              }}
              className="bordertable"
            >
              <tbody>
                <tr>
                  <th colSpan={4} style={{ textAlign: "start" }}>
                    <u> Dispatch Detail: </u>
                  </th>
                </tr>
                <tr>
                  <th style={{ width: "30%", textAlign: "start" }}>
                    Transporter Name:
                  </th>
                  <th style={{ width: "25%" }}></th>
                  <th style={{ textAlign: "start" }}>Vehicle No.:</th>
                  <th style={{ width: "30%" }}></th>
                </tr>
                <tr>
                  <th style={{ width: "30%", textAlign: "start" }}>
                    Driver Name:
                  </th>
                  <th style={{ width: "25%" }}></th>
                  <th style={{ textAlign: "start" }}>Contact No.:</th>
                  <th style={{ width: "30%" }}></th>
                </tr>
                <tr>
                  <th style={{ width: "30%", textAlign: "start" }}>
                    LR / Bilty No.:
                  </th>
                  <th style={{ width: "25%" }}></th>
                  <th style={{ textAlign: "start" }}>LR / Bilty Date:</th>
                  <th style={{ width: "30%" }}></th>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                width: "100%",
                marginTop: "3px",
                borderCollapse: "collapse",
              }}
              className="bordertable"
            >
              <tbody>
                <tr>
                  <td style={{ width: "60%" }}>
                    <p style={{ margin: "0px" }}>
                      <u>Disclaimer:</u>
                    </p>

                    <p style={{ margin: "0px" }}>
                      1. Certified that the particular given above are true and
                      correct and the amount indicated represents the price
                      actually charged and there is no additional consideration
                      flowing, directly or indirectly from the buyer.
                    </p>
                    <p style={{ margin: "0px" }}>
                      2. Subject to Jodhpur Jurisdiction only.
                    </p>
                  </td>
                  <td>
                    <h3
                      style={{
                        marginTop: "0px",
                        textAlign: "end",
                        paddingBottom: "20px",
                      }}
                    >
                      For LATIYAL HANDICRAFT
                    </h3>
                    <p
                      style={{
                        marginBottom: "0px",
                        textAlign: "end",
                        verticalAlign: "bottom",
                        paddingTop: "46px",
                      }}
                    >
                      (Authorized Signatory)
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* </Card> */}
      </div>
    </>
    // <div>Template1</div>
  );
};

export default Template1;
