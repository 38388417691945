import { FormOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Row,
  Skeleton,
  Space,
  Switch,
  Table,
  Tooltip,
  notification,
} from "antd";
import React from "react";
// import AddGst from "./AddGst";
import { ColumnsType } from "antd/es/table";
// import ClientService from "../../Services/ClientService";
// import "../Purchase/Purchase.css";
import { CheckboxChangeEvent } from "antd/es/checkbox";
// import search from "../../Images/search.svg";
import ClientService from "../../../Services/ClientService";
import AddGst from "../../GST/AddGst";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";
import { useTheme } from "../../../Context/ThemeContext";
import TcsModel from "../../Sales/TcsModel";
import TdsTcsServices from "../../../Services/TdsTcsServices";
import AddTcsTaxRate from "./AddTcsTaxRate";
import { dayjs } from "../../../../Utilities/dayjs";
import AddTdsTaxRate from "../Tds/AddTdsTaxRate";

interface ITableDataType {
  key: string;
  sno: number;
  section: string;
  natureOfCollection: string;
  rate: string;
  effectiveDate: string;
}
type NotificationType = "success" | "error";

const TcsList = () => {
  const [itemsList, setTcsList] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [page, setPage] = React.useState(1);
  const [searchItem, setSearchItem] = React.useState<string>("");
  const [selectChange, setSelectChange] = React.useState<boolean>(true);
  const [isActive, setIsActive] = React.useState<any>({});
  const [api, contextHolder] = notification.useNotification();
  const [openModel, setOpenModel] = React.useState<any>({
    type: "",
    param: {},
  });
  const { themeData } = useTheme();

  const openNotification = (type: NotificationType, message: string) => {
    api[type]({
      message: "",
      description: message,
      duration: 2,
      closeIcon: false,
      style: {
        width: 350,
      },
    });
  };

  // --------- Table Columns ---------
  const columns: ColumnsType<ITableDataType> = [
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
      width: "20%",
      render: (_: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.section}
        </Skeleton>
      ),
    },
    {
      title: "Nature Of Collection",
      dataIndex: "natureOfCollection",
      key: "natureOfCollection",
      width: "20%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.natureOfCollection}
        </Skeleton>
      ),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      width: "15%",
      render: (_: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.rate}
        </Skeleton>
      ),
    },
    {
      title: "Effective Date",
      dataIndex: "effectiveDate",
      key: "effectiveDate",
      width: "15%",
      render: (_: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {dayjs(record?.effectiveDate).format("DD/MM/YYYY")}
        </Skeleton>
      ),
    },
    {
      title: "Edit",
      dataIndex: "",
      key: "edit",
      render: (_: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <Tooltip title="Edit" placement="top" arrow={true}>
            <FormOutlined
              className="ca-edit-btn"
              style={{ color: "#a5abdd" }}
              onClick={async () => {
                // console.log("data", record);

                setOpenModel({
                  type: "addTCS",
                  param: {
                    open: true,
                    section: record?.section,
                    natureOfCollection: record?.natureOfCollection,
                    rate: record?.rate,
                    effectiveDate: record?.effectiveDate,
                    id: record?.id,
                  },
                });
              }}
            />
          </Tooltip>
        </Skeleton>
      ),
    },
    {
      title: selectChange ? "Active" : "InActive",
      dataIndex: "",
      key: "action",
      render: (_: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <Tooltip title={selectChange ? "InActive" : "Active"} placement="top">
            <Checkbox
              checked={record?.id === isActive?.id ? true : false}
              onChange={(e: CheckboxChangeEvent) => {
                // console.log(e.target.checked, record);
                setOpenModel({
                  type: "confirmationModel",
                  param: {
                    open: true,
                    text: `Are you sure you want to ${
                      selectChange ? "InActive" : "Active"
                    } Gst Rate?`,
                  },
                });
                setIsActive({
                  abbreviation: record?.abbreviation,
                  rate: record?.rate,
                  isActive: selectChange ? false : true,
                  name: record?.name,
                  id: record?.id,
                });
              }}
            />
          </Tooltip>
        </Skeleton>
      ),
    },
  ];

  // --------- Get TCS TableData API ---------
  const getList = async (
    start: number,
    length: number,
    isActive?: boolean,
    search?: string
  ) => {
    await TdsTcsServices.getTCSList(start, length, isActive, search)
      .then((res: any) => {
        console.log("res", res);

        if (res.length > 0) {
          setTcsList(res);
          setTotalRecords(res?.totalRecords);
        } else if (res.length === 0) {
          setTcsList([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        // openNotification("error", ex.message);
        setLoading(false);
        setTcsList([]);
      });
  };

  React.useEffect(() => {
    setLoading(true);
    getList((page - 1) * pageSize, pageSize, selectChange, searchItem);
  }, [trigger, page, pageSize, selectChange, searchItem]);

  return (
    <div>
      <Card
        size="small"
        className="TableCard"
        style={{
          justifyContent: "center",
          margin: "20px 20px",
          // border: "1px solid #eef2fe",
          boxShadow: "0px 0px 10px 0px #96A1C84D",
        }}
        // style={{
        //   backgroundColor: "white",
        //   padding: "20px 20px 0px 20px",
        //   height: `calc(100vh - 73px)`,
        //   overflowY: "auto",
        // }}
      >
        <div>
          <Row justify="space-between">
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setOpenModel({
                    type: "addTCS",
                    param: { open: true },
                  });
                }}
              >
                TDS
              </Button>
            </Col>
            <Col>
              <Space>
                {selectChange ? "Active" : "InActive"}
                <Switch
                  checked={selectChange}
                  onChange={setSelectChange}
                  // loading={loading}
                />

                {/* <Checkbox
                  className="style"
                  checked={selectChange}
                  onChange={(e: CheckboxChangeEvent) =>
                    setSelectChange(e.target.checked)
                  }
                >
                  {selectChange ? "Active" : "InActive"}
                </Checkbox> */}
                {/* <Input
                  onChange={(e: any) => {
                    setPage(1);
                    setSearchItem(e.target.value);
                  }}
                  suffix={
                    loading || searchClient ? (
                      ""
                    ) : (
                      <img alt={"search client"} src={search} />
                    )
                  }
                  allowClear={true}
                /> */}
                <Input
                  style={{
                    width: "200px",
                    marginTop: "-5px",
                    border: "1px solid #f1f3ff",
                  }}
                  allowClear={true}
                  placeholder="Search TCS"
                  onChange={(e: any) => {
                    setLoading(true);
                    if (!e.cancelable) {
                      const data = setTimeout(() => {
                        setPage(1);
                        setSearchItem(e.target.value);
                      }, 1000);
                      return () => clearTimeout(data);
                    } else {
                      setSearchItem(e.target.value);
                    }
                  }}
                />
                {/* <Select
                  defaultValue={true}
                  style={{ marginRight: "10px", width: "100px" }}
                  options={[
                    { value: true, label: "Active" },
                    { value: false, label: "InActive" },
                  ]}
                  onChange={(e: any) => setSelectChange(e)}
                /> */}
              </Space>
            </Col>
          </Row>

          {/* --------- Table --------- */}
          <Table
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            columns={columns}
            dataSource={
              loading
                ? (Array.from({ length: 20 }, (_, index) => ({
                    key: `loading-${index}`,
                  })) as ITableDataType[])
                : itemsList
            }
            size="small"
            pagination={{
              total: totalRecords,
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              onChange: (page: number, pageSize: number) => {
                setPage(page);
                setPageSize(pageSize);
              },
              pageSizeOptions: ["10", "15", "25", "50", "100"],
            }}
            scroll={itemsList.length > 15 ? { x: 700, y: 620 } : { x: 600 }}
          />

          {/* --------- Add or Edit TCS Model--------- */}
          {openModel.type === "addTCS" && (
            <AddTcsTaxRate
              open={true}
              setOpen={() => setOpenModel({ type: "", param: "" })}
              setTrigger={setTrigger}
              editData={openModel.param}
            />
          )}

          {/*--------- Active & InActive Confirmation Modle --------- */}
          {openModel.type === "confirmationModel" && (
            <ConfirmationModal
              {...openModel.param}
              onNo={() => {
                setOpenModel({ type: "", param: "" });
                setIsActive({});
              }}
              onYes={async () =>
                await ClientService.postGst(isActive, isActive?.id)
                  .then((res: any) => {
                    if (res?.result) {
                      setOpenModel({ type: "", param: "" });
                      setIsActive({});
                      setTrigger((x: boolean) => !x);
                      openNotification(
                        "success",
                        `${
                          selectChange ? "Inactived" : "Actived"
                        } successfully.`
                      );
                    }
                  })
                  .catch((error: any) => console.error("eroor", error))
              }
            />
          )}
        </div>
      </Card>
      {contextHolder}
    </div>
  );
};

export default TcsList;
