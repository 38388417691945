import {
  Button,
  Col,
  Input,
  Row,
  Space,
  DatePicker,
  Select,
  Table,
  Tooltip,
  Tag,
  Popover,
  Skeleton,
} from "antd";
import React, { useContext } from "react";
import noteContext from "../../Context/StyleContext";
import { dayjs } from "../../../Utilities/dayjs";
import { useTheme } from "../../Context/ThemeContext";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import {
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import ButtonComponent from "../../Common/ButtonComponent";
import { useClient } from "../../Context/ClientContext";
import ExpensesAndIncomeService, {
  EICategoryStatus,
  ExpensesIncomeTypes,
} from "../../Services/ExpensesAndIncomeService";
import { Utils } from "../../../Utilities/Utils";
import ViewIncome from "./ViewIncome";
import { FiDownload } from "react-icons/fi";
import search from "../../Images/search.svg";
import { HiDotsHorizontal } from "react-icons/hi";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";

interface IIncomeListData {
  key: string;
  id: string;
  number: { no: string; id: string };
  date: string;
  CategoryName: string;
  tradingPartner: string;
  naturetype: string;
  categorytype: string;
  status: string;
  receivedAmount: number;
  refundAmount: number;
  totalInvoiceAmount: number;
  dueBalance: number;
}

interface IncomeInvoiceprops {
  incomeList: any;
  setIncomeList: (value: any) => void;
}

const IncomeTable = (props: IncomeInvoiceprops) => {
  const navigate = useNavigate();
  const { windowWidth } = useContext(noteContext);
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const { themeData } = useTheme();
  const { RangePicker } = DatePicker;
  const [filterDate, setFilterDate] = React.useState<any>({ from: "", to: "" });
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [totalRecords, setTotalRecords] = React.useState<number>();
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [invoiceId, setInvoiceId] = React.useState<string>("");
  const [searchExpenses, setSearchCExpenses] = React.useState<string>("");
  const [invoiceStatus, setInvoiceStatus] = React.useState<number>(
    EICategoryStatus.Active
  );
  const [deleteOrRevive, setDeleteOrRevive] = React.useState<any>({
    id: "",
    isRev: null,
  });
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const { incomeList, setIncomeList } = props;

  const columns: ColumnsType<IIncomeListData> = [
    {
      title: "Income No.",
      dataIndex: "number",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span
            style={{ color: "#1677FF", cursor: "pointer" }}
            onClick={() => {
              setIsOpen(true);
              setInvoiceId(record?.number?.id);
            }}
          >
            {record?.number?.no}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Party Name",
      dataIndex: "tradingPartner",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.tradingPartner}
        </Skeleton>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Inv. Amount",
      dataIndex: "totalInvoiceAmount",
      sorter: true,
      key: "TotalInvoiceAmount",
      align: "right",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          ₹{Utils.getFormattedNumber(record?.totalInvoiceAmount)}
        </Skeleton>
      ),
    },
    {
      title: "Rec. Amount",
      dataIndex: "receivedAmount",
      sorter: true,
      key: "ReceivedAmount",
      align: "right",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          ₹{Utils.getFormattedNumber(record?.receivedAmount)}
        </Skeleton>
      ),
    },
    {
      title: "Due Amount",
      dataIndex: "dueBalance",
      sorter: true,
      key: "DueBalance",
      align: "right",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          ₹{Utils.getFormattedNumber(record?.dueBalance)}
        </Skeleton>
      ),
    },
    {
      title: "Status",
      dataIndex: "address",
      width: "5%",
      align: "center",
      render: (_, record) => (
        <div>
          {record?.dueBalance ? (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="warning"
                style={{
                  textAlign: "center",
                  width: 75,
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Due
              </Tag>
            </Skeleton>
          ) : (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="success"
                style={{
                  textAlign: "center",
                  width: 75,
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                Received
              </Tag>
            </Skeleton>
          )}
        </div>
      ),
    },
  ];

  if (invoiceStatus === EICategoryStatus.Deleted) {
    columns.push({
      title: " ",
      dataIndex: "icon",
      width: "10%",
      align: "left",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
          className="cursor"
        >
          <Tooltip title="Revive Expenses and its effects on calculations">
            <SyncOutlined
              onClick={() => {
                setConfirmation(true);
                setInvoiceId(record?.id);
              }}
            />
          </Tooltip>
        </Skeleton>
      ),
    });
  } else {
    columns.push(
      {
        title: "  ",
        dataIndex: "icon",
        width: "3%",
        align: "center",
        render: () => (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
            className="cursor"
          >
            <FiDownload style={{ color: "#395cd2" }} />
          </Skeleton>
        ),
      },
      {
        title: "  ",
        dataIndex: "icon2",
        width: "3%",
        align: "center",
        render: (_, record) => (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
            className="cursor"
          >
            <Tooltip
              title={
                record?.totalInvoiceAmount === record?.dueBalance ||
                record?.dueBalance !== 0
                  ? "Edit"
                  : "Expense Paid Can't be Edit"
              }
              placement="top"
              color={
                record?.totalInvoiceAmount === record?.dueBalance ||
                record?.dueBalance !== 0
                  ? "#3D4998"
                  : "gray"
              }
            >
              <EditOutlined
                style={{
                  color:
                    record?.totalInvoiceAmount === record?.dueBalance ||
                    record?.dueBalance !== 0
                      ? "#444653"
                      : "rgb(216 207 207)",
                }}
                className={
                  record?.totalInvoiceAmount === record?.dueBalance ||
                  record?.dueBalance !== 0
                    ? "ca-edit-btn"
                    : ""
                }
                onClick={() => {
                  if (
                    record?.totalInvoiceAmount === record?.dueBalance ||
                    record?.dueBalance !== 0
                  )
                    navigate(
                      `/accounts/clients/${currentClient?.id}/income/editIncome/${record.id}`
                    );
                  console.log("chekcr", record?.totalInvoiceAmount);
                }}
              />
            </Tooltip>
          </Skeleton>
        ),
      },
      {
        title: "  ",
        width: "2%",
        align: "center",
        render: (_, record, index) => (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
            className="cursor"
          >
            <Popover
              arrow={{ pointAtCenter: true }}
              placement="bottomRight"
              content={ellipsisContent(record, index)}
              trigger="click"
              // open={openPopArray[index]}
              // onOpenChange={(newOpen) => handlePopoverChange(index, newOpen)}
            >
              <HiDotsHorizontal
                style={{ color: "#395cd2", cursor: "pointer" }}
              />
            </Popover>
          </Skeleton>
        ),
      }
    );
  }

  const ellipsisContent = (record: any, index: number) => {
    return (
      <div>
        <Tooltip
          title={
            record?.totalInvoiceAmount === record?.dueBalance ||
            record?.dueBalance !== 0
              ? "Delete temporarily"
              : "Can't be Delete"
          }
        >
          <Button
            disabled={record?.receivedAmount > 0 ? true : false}
            style={{ width: 100 }}
            onClick={() => {
              setConfirmation(true);
              setInvoiceId(record?.id);
              console.log(record?.id, "idcheck");
              //handlePopoverChange(index, false);
            }}
          >
            Delete
          </Button>
        </Tooltip>
      </div>
    );
  };

  // --------- Get TableList API ---------//
  React.useEffect(() => {
    if (deleteOrRevive.id) {
      setLoading(true);
      ExpensesAndIncomeService.deleteExpensesIncome(
        currentClient?.id!,
        deleteOrRevive.id,
        deleteOrRevive.isRev,
        ExpensesIncomeTypes.Income
      )
        .then((res: any) => {
          setLoading(true);
          if (res.result) {
            setRefreshAtDelete(!refreshAtDelete);
            openNotification(
              "success",
              `Income ${
                invoiceStatus === 4 ? "Revived" : "Deleted"
              } successfully`
            );
          }
          console.log(res, "resincomedelete");
        })
        .catch((err: any) => {
          console.log(err, "incomedeleteerror");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOrRevive]);

  // --------- Get TableList API ---------//
  React.useEffect(() => {
    setLoading(true);
    if (currentClient?.id) {
      getExpensesList(
        currentClient?.id,
        ExpensesIncomeTypes.Income,
        invoiceStatus,
        (page - 1) * pageSize,
        pageSize,
        filter.sortCol,
        filter.sortDir,
        searchExpenses,
        filterDate.from,
        filterDate.to
      );
    } else if (currentClient?.id === null) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    pageSize,
    invoiceStatus,
    filter.sortCol,
    filter.sortDir,
    searchExpenses,
    filterDate.from,
    filterDate.to,
    refreshAtDelete,
  ]);
  const getExpensesList = async (
    clientId: string,
    eIType: ExpensesIncomeTypes.Income,
    status: EICategoryStatus.All,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    from?: any,
    to?: any
  ) => {
    await ExpensesAndIncomeService.getExpensesList(
      clientId,
      eIType,
      status,
      start,
      length,
      sortCol,
      sortDir,
      search,
      from,
      to
    )
      .then((res: any) => {
        console.log("getIncomeList", res);
        if (res?.items?.length > 0) {
          setIncomeList({
            incomeListRes: res?.items?.map((v: any, i: any) => {
              return {
                key: i,
                id: v?.id,
                tradingPartner: v?.tradingPartner.name,
                number: { no: v?.number, id: v?.id },
                date: v?.date,
                totalInvoiceAmount: v?.totalInvoiceAmount,
                receivedAmount: v?.receivedAmount,
                dueBalance: v?.dueBalance,
              };
            }),
            tableTotalExpensesIncomeAmount:
              res?.total?.tableTotalExpensesIncomeAmount,
            tableTotalRecieved: res?.total?.tableTotalRecieved,
            tableTotalUnpaid: res?.total.tableTotalUnpaid,
            chartTotalRecieved: res?.total?.chartTotalRecieved,
            chartTotalUnpaid: res?.total?.chartTotalUnpaid,
            chartTotalTotalExpensesIncomeAmount:
              res?.total?.chartTotalTotalExpensesIncomeAmount,
          });
          // const x: number =
          //   incomeList?.reduce((acc: any, curr: any) => {
          //     return (acc += Number(curr?.dueBalance));
          //   }, 0) || 0;
          // setTotalDue(x);
          setTotalRecords(res?.totalRecords);
        } else if (res.items.length === 0) {
          setIncomeList([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err.message);
        setLoading(false);
        setIncomeList([]);
      });
  };
  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "Date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("sort", filter, filter.column);
    // console.log("inlit", incomeList);
  };
  return (
    <div>
      <Row
        justify="space-between"
        style={{
          paddingBottom: 5,
          marginTop: 10,
          padding: "10px 18px 3px 18px",
        }}
        gutter={windowWidth === 1024 ? 65 : 0}
      >
        <Col xl={6} lg={4} md={12}>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                navigate(
                  `/accounts/clients/${currentClient?.id}/income/addIncome`
                );
              }}
            >
              Income
            </Button>
            <Button type="default" icon={<DownloadOutlined />}>
              Export
            </Button>
          </Space>
        </Col>
        <Col
          xl={18}
          lg={20}
          md={18}
          style={{ marginTop: windowWidth <= 768 ? "15px" : "0" }}
        >
          <Row justify="end" gutter={8}>
            <Col>
              <Input
                style={{ width: "200px", border: "1px solid #f1f3ff" }}
                placeholder="Search Party"
                allowClear
                suffix={
                  loading || searchExpenses ? (
                    ""
                  ) : (
                    <img alt="Search.." src={search} />
                  )
                }
                onChange={(e: any) => {
                  setLoading(true);
                  if (!e.cancelable) {
                    //----- Search Api call in 500 ms -----
                    const searchItem = setTimeout(() => {
                      setPage(1);
                      setSearchCExpenses(e.target.value);
                    }, 500);
                    return () => clearTimeout(searchItem);
                  } else {
                    setSearchCExpenses(e.target.value);
                  }
                }}
              />
            </Col>
            <Col>
              <RangePicker
                allowClear={false}
                format="DD/MM/YYYY"
                style={{ width: "220px", border: "1px solid #f1f3ff" }}
                onChange={(e: any) => {
                  console.log(e);
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    //           dayjs().month(0).startOf("month"),
                    // dayjs(),
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            <Col>
              <Select
                defaultValue={"Active"}
                style={{ width: 120 }}
                options={[
                  { value: EICategoryStatus.Active, label: "Active" },
                  { value: EICategoryStatus.Due, label: "Due" },
                  { value: EICategoryStatus.Paid, label: "Paid" },
                  { value: EICategoryStatus.Deleted, label: "Deleted" },
                  { value: EICategoryStatus.All, label: "ALL" },
                ]}
                onChange={(e: any) => {
                  setPage(1);
                  setInvoiceStatus(e);
                }}
              />
            </Col>
            <Col>
              <ButtonComponent />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        onChange={tableSort}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as IIncomeListData[])
            : incomeList.incomeListRes
        }
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
        scroll={incomeList.length > 10 ? { x: 600, y: 380 } : { x: 600 }}
        footer={() => (
          <Row>
            <Col
              style={{ width: "45%", paddingRight: "176px" }}
              className="textEnd"
            >
              Total
            </Col>
            <Col
              style={{ width: "12%", paddingRight: "32px" }}
              className="textEnd"
            >
              ₹{" "}
              {Utils.getFormattedNumber(
                props.incomeList?.tableTotalExpensesIncomeAmount
              )}
            </Col>
            <Col style={{ width: "13%" }} className="textEnd">
              ₹ {Utils.getFormattedNumber(props.incomeList?.tableTotalRecieved)}
            </Col>
            <Col style={{ width: "15%" }} className="textEnd pe-3">
              ₹ {Utils.getFormattedNumber(props.incomeList?.tableTotalUnpaid)}
            </Col>
          </Row>
        )}
      />
      {/* <AddRefno open={open} handleCancel={() => setIsOpen(false)} type={type} /> */}
      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            setDeleteOrRevive({
              id: invoiceId,
              isRev: invoiceStatus === EICategoryStatus.Deleted ? 0 : 1,
            });
            setConfirmation(false);
          }}
          text={
            invoiceStatus === EICategoryStatus.Deleted
              ? `Are you sure you want to revive ?`
              : `Are you sure you want to Delete this row ?`
          }
        />
      )}
      {open && (
        <ViewIncome
          open={open}
          onCancel={() => setIsOpen(false)}
          invoiceId={invoiceId}
        />
      )}
    </div>
  );
};

export default IncomeTable;
