import React, { FC, useContext } from "react";
import { SettingsSections } from "../../Services/SettingService";
import { Button, Card, Drawer, Form, Space } from "antd";
import SettingSecCustomization from "./SettingSecCustomization";
import { CloseOutlined } from "@ant-design/icons";
import { getwidth } from "../Master/Ledger/AddLedger";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";

const SettingCustomizationIsDrawer: FC<{
  isDrawer: boolean;
  settingsSections: SettingsSections;
  settingLabelName: string;
  editId?: string;
  onCancel?: any;
  trigger?: any;
}> = (props) => {
  const [form] = Form.useForm();
  const { companySettings, updateCompanySettings } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [templateId, setTemplateId] = React.useState<any>();
  const [postObjName, setPostObjName] = React.useState<string>("");
  const [indexEditData, setIndexEditData] = React.useState<number>(-1);

  React.useEffect(() => {
    if (props.isDrawer) {
      if (props.settingsSections === SettingsSections.Invoice) {
        setPostObjName("invoiceSettings");
      } else if (
        props.settingsSections === SettingsSections.PInvoice ||
        props.settingsSections === SettingsSections.RCMBill ||
        props.settingsSections === SettingsSections.ExpenseJV
      ) {
        setPostObjName("billSettings");
      } else if (
        props.settingsSections === SettingsSections.CrNote ||
        props.settingsSections === SettingsSections.DrNote
      ) {
        setPostObjName("returnNoteSettings");
      } else if (
        props.settingsSections === SettingsSections.POrder ||
        props.settingsSections === SettingsSections.SOrder ||
        props.settingsSections === SettingsSections.Quotation
      ) {
        setPostObjName("orderSettings");
      } else {
        setPostObjName("basicSettings");
      }

      setIndexEditData(
        companySettings?.setting?.vouchersSettings?.findIndex(
          (x) => x?.title === props.settingLabelName
        )!
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.settingsSections]);

  const saveSettingCutomization = () => {
    form.validateFields().then((val: any) => {
      const currentPostData = {
        ...val,
        tnCTemplateId: templateId,
      };

      let newVal = {
        title: props.settingLabelName,
        settingType: props.settingsSections,
        settings: {
          [postObjName]: currentPostData,
        },
      };

      console.log("New val", val, newVal);

      const newValpostData = {
        vouchersSettings: [newVal],
      };

      const allCustomization = companySettings?.setting?.vouchersSettings;

      allCustomization?.splice(indexEditData!, 1, newVal)!;

      updateCompanySettings!(
        props.settingsSections,
        {
          ...companySettings,
          setting: {
            ...companySettings?.setting!,
            vouchersSettings: allCustomization!,
          },
        },
        newValpostData,
        props.settingLabelName
      ).then((result: any) => {
        if (result?.status) {
          // setSaveButtonLoading(false);
          openNotification("success", "Setting updated successfully.");
          if (!props?.editId) {
            props.trigger();
          }
          props.onCancel();
        } else {
          // setSaveButtonLoading(false);
          openNotification("error", result.message ?? "There is some error.");
        }
      });
    });
  };

  const onSetTemplateId = (x: any) => {
    setTemplateId(x);
  };

  return (
    <div>
      {props.isDrawer ? (
        <Drawer
          title={`Edit ${props.settingLabelName}`}
          open={props.isDrawer}
          // onClose={onClose}
          width={getwidth()}
          maskClosable={false}
          closable={false}
          extra={
            <Space>
              <CloseOutlined onClick={() => props.onCancel()} />
            </Space>
          }
          footer={
            <Space
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: 20,
              }}
            >
              <Button onClick={() => props?.onCancel()}>Cancel</Button>
              <Button
                onClick={() => saveSettingCutomization()}
                type="primary"
                // loading={saveButtonLoading}
              >
                Update
              </Button>
            </Space>
          }
          className="ledgerdrawerstyle"
        >
          <SettingSecCustomization
            isDrawer={props.isDrawer}
            settingsSections={props.settingsSections}
            settingLabelName={props.settingLabelName}
            form={form}
            onTemplateId={onSetTemplateId}
          />
        </Drawer>
      ) : (
        <Card
          size="small"
          className="TableCard"
          style={{
            // marginTop: "6px",
            marginTop: 35,
            // padding: "48px 0px 0px 0px",
            boxShadow: "0px 0px 10px 0px #96A1C84D",
          }}
        >
          <div
            style={{
              // padding: "15px",
              maxHeight: "750px",
              overflowY: "auto",
              position: "relative",
            }}
          >
            <SettingSecCustomization
              isDrawer={false}
              settingsSections={props.settingsSections}
              settingLabelName={props.settingLabelName}
              form={form}
            />
          </div>
        </Card>
      )}
    </div>
  );
};

export default SettingCustomizationIsDrawer;
