import {
  DeleteTwoTone,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  InputRef,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  TableColumnType,
  Tag,
  Tooltip,
} from "antd";
import React from "react";
import type { ColumnsType } from "antd/es/table";
import "./Purchase.css";
import ItemsServices, { ItemType } from "../../Services/ItemsService";
import AddItems from "./AddItems";
import { useClient } from "../../Context/ClientContext";
import { NavLink, useLocation } from "react-router-dom";
import { useTheme } from "../../Context/ThemeContext";
import "../../Common/Acc_Common.css";
import search from "../../Images/search.svg";
import { Utils } from "../../../Utilities/Utils";
import InvoiceExportService from "../../Services/InvoiceExportDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../Redex/Store";
import {
  FilterDropdownProps,
  TableRowSelection,
} from "antd/es/table/interface";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";

// import { ItemType } from "antd/es/breadcrumb/Breadcrumb";

const TaxPreferenceOption = [
  {
    label: "Exclusive Tax",
    value: false,
  },
  {
    label: "Inclusive Tax",
    value: true,
  },
];

const ItemTypeOption = [
  {
    label: "Goods",
    value: false,
  },
  {
    label: "Services",
    value: true,
  },
];

interface IItemListData {
  key: string;
  sno: number;
  id: string;
  name: string;
  number: string;
  // description: string;
  HSNCode: string;
  SACCode: string;
  itemType: boolean;
  isTaxable: boolean;
  gstRate: number;
  taxType: boolean;
  // unitPrice: number;
  salesPrice: number;
  purchasePrice: number;
  accountHead: string;
  vat: string;
}

const Items: React.FC<{
  itemPage: ItemType;
  trigger?: boolean;
  setTrigger?: any;
  partyId?: string;
}> = (props) => {
  const { currentClient, companySettings } = useClient();
  const user = useSelector((state: RootState) => state.data.User);
  const indianState = useSelector((state: RootState) => state.data.IndianState);
  const current = useSelector((state: RootState) => state.clientRedus);
  const { openNotification } = React.useContext(notificationContext);
  const searchInput = React.useRef<InputRef>(null);
  const searchSelect = React.useRef<any>(null);
  const [openDropdown, setOpenDropdown] = React.useState<DataIndex | null>(
    null
  );
  type DataIndex = keyof IItemListData;

  // console.log("User", user, current, indianState, current);

  const { themeData } = useTheme();
  const location = useLocation();
  const [openModel, setOpenModel] = React.useState<any>({
    type: "",
    param: {},
  });
  const [itemsList, setItemsList] = React.useState<IItemListData[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [page, setPage] = React.useState(1);
  const [searchItem, setSearchItem] = React.useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  // const [isService, setIsServices] = React.useState<string>();
  const [gstRate, setGstRate] = React.useState<any[]>([]);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [exportItemsData, setExportItemsData] = React.useState<any>([]);
  const [exportType, setExportType] = React.useState<number>(-1);
  const [type, setType] = React.useState<ItemType>(props.itemPage);
  const [searchColumns, setSearchColumns] = React.useState<{
    totalNoOfColumnSearch: number;
    name: string;
    number: string;
    purchasePrice: string;
    salesPrice: string;
    itemType: boolean[] | null;
    HSNCode: string;
    isTaxable: boolean[] | null;
  }>({
    totalNoOfColumnSearch: -1,
    name: "",
    number: "",
    purchasePrice: "",
    salesPrice: "",
    itemType: null,
    HSNCode: "",
    isTaxable: null,
  });

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  React.useEffect(() => {
    // setLoading(true);
    if (currentClient?.id && (exportType === -1 || exportType)) {
      getList(
        currentClient?.id,
        exportType >= 1 ? 0 : (page - 1) * pageSize,
        exportType >= 1 ? 0 : pageSize,
        // (page - 1) * pageSize,
        // pageSize,
        filter.sortCol,
        filter.sortDir,
        searchItem,
        undefined,
        type,
        gstRate,
        false,
        searchColumns.name,
        searchColumns.number,
        searchColumns.purchasePrice,
        searchColumns.salesPrice,
        searchColumns.itemType,
        searchColumns.HSNCode,
        searchColumns.isTaxable
      );
    } else if (currentClient?.id === null) {
      console.error("currentClient is null Items");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.trigger,
    trigger,
    page,
    pageSize,
    searchItem,
    location.pathname,
    filter.sortCol,
    filter.sortDir,
    gstRate,
    type,
    exportType,
    searchColumns,
  ]);

  // --------- Get List TableData API ---------
  const getList = async (
    clientId: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    status?: any,
    type?: ItemType,
    gstRate?: number[],
    itemInventory?: boolean,
    name?: string,
    number?: string,
    purchasePrice?: string,
    salesPrice?: string,
    itemType?: boolean[] | null,
    HSNCode?: string,
    isTaxable?: boolean[] | null
  ) => {
    // debugger;
    if (exportType < 1) {
      setLoading(true);
    }
    await ItemsServices.getListItems(
      clientId,
      start,
      length,
      sortCol,
      sortDir,
      search,
      status,
      type,
      gstRate,
      itemInventory,
      name,
      number,
      purchasePrice,
      salesPrice,
      itemType,
      HSNCode,
      isTaxable
    )
      .then((res: any) => {
        if (res.items.length > 0) {
          let ItemsData = res?.items?.map((v: any, i: any) => {
            return {
              key: i,
              sno: v?.Sno,
              id: v?._id,
              name: v?.Name,
              number: v?.Number,

              // description:
              //   type === "purchase"
              //     ? v?.PurchaseItem?.Description
              //     : v?.SalesItem?.Description,
              HSNCode: v?.HSNCode,
              SACCode: v?.SACCode,
              itemType: v?.IsService,
              taxType: v?.IsTaxable,
              salesPrice: v?.sPrice,
              purchasePrice: v?.pPrice,
              // unitPrice:
              //   type === ItemType.PurchaseItems
              //     ? v?.PurchaseItem?.Price
              //     : v?.SalesItem?.Price,
              gstRate: v?.TaxRate,
            };
          });
          if (exportType >= 1) {
            setExportItemsData(ItemsData);
            if (exportType === 1) {
              exportCsv(ItemsData);
            } else if (exportType === 2) {
              exportPDF(ItemsData);
            } else if (exportType === 3) {
              exportExcel(ItemsData);
            }
          } else {
            setItemsList(ItemsData);
            setTotalRecords(res?.totalRecords);
          }
          setTotalRecords(res?.totalRecords);
          // console.log("table,,,,,,,,,,,", props.trigger);
        } else if (res.items.length === 0) {
          setItemsList([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setItemsList([]);
      });
  };

  // console.log("page", props?.itemPage);

  React.useEffect(() => {
    const handleKeyDown = (e: any) => {
      console.log("useEffect", e.shiftKey);

      if (e.shiftKey && e.key === "I") {
        setOpenModel({
          type: "addItem",
          param: { open: true },
        });
      }
      if (e.altKey) {
        if (e.shiftKey && e.key.toLowerCase() === "c") {
          setOpenModel({ type: "", param: "" });
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Table Search Inide
  const handleSearch = (
    selectedKeys: string[] | any,
    confirm: any,
    dataIndex: DataIndex
  ) => {
    console.log("handleSearch", selectedKeys, searchColumns);
    switch (dataIndex) {
      case "name":
        if (selectedKeys[0] || searchColumns.name) {
          setSearchColumns({
            ...searchColumns,
            name: selectedKeys[0],
          });
        }
        break;
      case "number":
        if (selectedKeys[0] || searchColumns.number) {
          setSearchColumns({ ...searchColumns, number: selectedKeys[0] });
        }
        break;
      case "purchasePrice":
        if (selectedKeys[0] || searchColumns.purchasePrice) {
          setSearchColumns({
            ...searchColumns,
            purchasePrice: selectedKeys[0],
          });
        }
        break;
      case "salesPrice":
        if (selectedKeys[0] || searchColumns.salesPrice) {
          setSearchColumns({ ...searchColumns, salesPrice: selectedKeys[0] });
        }
        break;
      case "itemType":
        if (selectedKeys[0] || searchColumns.itemType) {
          setSearchColumns({ ...searchColumns, itemType: selectedKeys });
        }
        break;
      case "HSNCode":
        if (selectedKeys[0] || searchColumns.HSNCode) {
          setSearchColumns({ ...searchColumns, HSNCode: selectedKeys[0] });
        }
        break;
      case "isTaxable":
        if (selectedKeys[0] || searchColumns.isTaxable) {
          setSearchColumns({ ...searchColumns, isTaxable: selectedKeys });
        }
        break;
      case "gstRate":
        if (selectedKeys[0] || gstRate) {
          setGstRate(selectedKeys);
        }
        break;
    }
    confirm();
    setOpenDropdown(null); // Close the dropdown
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    clearFilters();
    confirm();
    console.log("dataIndex", dataIndex, searchColumns.name);

    switch (dataIndex) {
      case "name":
        if (searchColumns.name) {
          setSearchColumns({
            ...searchColumns,
            name: "",
          });
        }
        break;
      case "number":
        if (searchColumns.number) {
          setSearchColumns({ ...searchColumns, number: "" });
        }
        break;
      case "purchasePrice":
        if (searchColumns.purchasePrice) {
          setSearchColumns({ ...searchColumns, purchasePrice: "" });
        }
        break;
      case "salesPrice":
        if (searchColumns.salesPrice) {
          setSearchColumns({ ...searchColumns, salesPrice: "" });
        }
        break;
      case "itemType":
        if (searchColumns.itemType) {
          setSearchColumns({ ...searchColumns, itemType: null });
        }
        break;
      case "HSNCode":
        if (searchColumns.HSNCode) {
          setSearchColumns({ ...searchColumns, HSNCode: "" });
        }
        break;
      case "isTaxable":
        if (searchColumns.isTaxable) {
          setSearchColumns({ ...searchColumns, isTaxable: null });
        }
        break;
      case "gstRate":
        if (gstRate.length > 0) {
          setGstRate([]);
        }
        break;
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<IItemListData> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        {dataIndex === "gstRate" ? (
          <Checkbox.Group
            options={companySettings?.gsts?.map((gst: any) => ({
              key: gst.name,
              value: gst.id,
              title: gst.rate,
              label: gst.name,
            }))}
            style={{
              marginBottom: 8,
              display: "flex",
              flexDirection: "column",
              gap: "6px",
            }}
            // value={searchColumns.gstRate}
            value={selectedKeys}
            onChange={(e: any) => {
              setSelectedKeys(e ? [...e] : []);
            }}
          />
        ) : dataIndex === "isTaxable" ? (
          <Checkbox.Group
            style={{ marginBottom: 8, display: "block", width: "200px" }}
            onChange={(e: any) => {
              setSelectedKeys(e ? [...e] : []);
            }}
            options={TaxPreferenceOption?.map((x: any) => ({
              value: x.value,
              label: x.label,
            }))}
          />
        ) : dataIndex === "itemType" ? (
          <div style={{ marginBottom: 8, display: "block", width: "200px" }}>
            <Checkbox.Group
              style={{ marginBottom: 8, display: "block", width: "140px" }}
              onChange={(e: any) => {
                setSelectedKeys(e ? [...e] : []);
              }}
              options={ItemTypeOption?.map((x: any) => ({
                value: x.value,
                label: x.label,
              }))}
            />
          </div>
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search..`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            style={{ marginBottom: 8, display: "block" }}
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            // icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Clear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    filterDropdownOpen: openDropdown === dataIndex,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setOpenDropdown(dataIndex);
        setTimeout(() => {
          if (dataIndex === "gstRate") {
            searchSelect.current?.focus(); // Use focus for Select component
          } else {
            searchInput.current?.select(); // Use select for Input component
          }
        }, 100);
      } else {
        setOpenDropdown(null);
      }
    },
  });

  // --------- Table Columns  ---------
  const columns: ColumnsType<IItemListData> = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.sno}
        </Skeleton>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: width <= 1440 ? "13%" : "18%",
      filteredValue: searchColumns.name ? [searchColumns.name] : null,
      onFilter: (value: any, record) => true,
      render: (_, record) =>
        type === ItemType.SalesItems ? (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <NavLink
              to={`/accounts/clients/${currentClient?.id}/master/items/inventory`}
              state={{
                id: record.id,
                type: type,
                itemType: record.itemType ? "AllItemServices" : "AllItemGoods",
              }}
            >
              {record?.name}
            </NavLink>
          </Skeleton>
        ) : (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <NavLink
              to={`/accounts/clients/${currentClient?.id}/master/items/inventory`}
              state={{
                id: record.id,
                type: type,
                itemType: record.itemType ? "AllItemServices" : "AllItemGoods",
              }}
            >
              {record?.name}
            </NavLink>
          </Skeleton>
        ),
      ...getColumnSearchProps("name"),
    },
    {
      title: "Code",
      dataIndex: "number",
      key: "Number",
      width: "7%",
      // sorter: true,
      filteredValue: searchColumns.number ? [searchColumns.number] : null,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.number}
        </Skeleton>
      ),
      ...getColumnSearchProps("number"),
    },
    {
      title: "Pur. Price",
      dataIndex: "unitPrice",
      key: "PurchaseItem.Price",
      // sorter: true,
      filteredValue: searchColumns.purchasePrice
        ? [searchColumns.purchasePrice]
        : null,
      width: width <= 1440 ? "13%" : "9%",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {Utils.getFormattedNumber(record?.purchasePrice)}
        </Skeleton>
      ),
      ...getColumnSearchProps("purchasePrice"),
    },
    {
      title: "Sales Price",
      dataIndex: "unitPrice",
      key: "SalesItem.Price",
      filteredValue: searchColumns.salesPrice
        ? [searchColumns.salesPrice]
        : null,
      // sorter: true,
      width: width <= 1440 ? "13%" : "9%",
      align: "right",
      render: (_: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {Utils.getFormattedNumber(record?.salesPrice)}
        </Skeleton>
      ),
      ...getColumnSearchProps("salesPrice"),
    },

    {
      title: "Item Type",
      dataIndex: "itemType",
      key: "IsService",
      width: "10%",
      filteredValue:
        searchColumns.itemType || searchColumns.itemType === false
          ? searchColumns.itemType
          : null,
      // sorter: true,
      render: (_, record) => {
        if (record?.itemType)
          return (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="geekblue"
                style={{
                  textAlign: "center",
                  width: 52,
                }}
              >
                Service
              </Tag>
            </Skeleton>
          );
        else
          return (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="green"
                style={{
                  textAlign: "center",
                  width: 52,
                }}
              >
                Goods
              </Tag>
            </Skeleton>
          );
      },
      ...getColumnSearchProps("itemType"),
    },
    {
      title: "HSN/SAC Code",
      dataIndex: "HSNCode",
      key: "description",
      width: "14%",
      filteredValue: searchColumns.HSNCode ? [searchColumns.HSNCode] : null,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.HSNCode ?? record?.SACCode}
          {/* SACCode */}
        </Skeleton>
      ),
      ...getColumnSearchProps("HSNCode"),
    },
    {
      title: "Tax Preference",
      dataIndex: "isTaxable",
      key: "IsTaxable",
      filteredValue:
        searchColumns.isTaxable || searchColumns.isTaxable === false
          ? searchColumns.isTaxable
          : null,
      width: width <= 1440 ? "12%" : "10%",
      // sorter: true,
      render: (_, record) => {
        if (record?.taxType === true)
          return (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                color="cyan"
                style={{
                  textAlign: "center",
                  width: 85,
                }}
              >
                Inclusive Tax
              </Tag>
            </Skeleton>
          );
        else
          return (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tag
                // bordered={false}
                color="purple"
                style={{
                  textAlign: "center",
                  width: 85,
                }}
              >
                Exclusive Tax
              </Tag>
            </Skeleton>
          );
      },
      ...getColumnSearchProps("isTaxable"),
    },
    {
      title: "GST",
      dataIndex: "gstRate",
      key: "Rate",
      width: "8%",
      filteredValue: gstRate ? gstRate : null,
      // sorter: true,
      // filters: [
      //   {
      //     text: "5%",
      //     value: 5,
      //   },
      //   {
      //     text: "3%",
      //     value: 3,
      //   },
      // ],
      onFilter: (value: any, record) => true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.gstRate}
        </Skeleton>
      ),
      ...getColumnSearchProps("gstRate"),

      // filtered: true,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div style={{ display: "flex", gap: "15px" }}>
            <div>
              <Tooltip title="Edit" placement="top" color="#3D4998">
                <EditOutlined
                  // className="ca-edit-btn"
                  style={{ color: "#444653" }}
                  onClick={async () => {
                    setOpenModel({
                      type: "addItem",
                      param: {
                        open: true,
                        id: record.id,
                      },
                    });
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Delete" placement="top" color="red">
                <DeleteTwoTone
                  className="ca-delete-btn cursor"
                  twoToneColor="#ff879d"
                  onClick={() => {
                    setOpenModel({
                      type: "deleteItem",
                      param: {
                        name: record?.name,
                        id: record?.id,
                      },
                    });
                  }}
                />
              </Tooltip>
            </div>
          </div>
        </Skeleton>
      ),
    },
  ];

  //----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "number",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("sort1111111111111", sort?.TaxRate?.Rate, sort);
  };

  // const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
  //   console.log("selectedRowKeys changed: ", newSelectedRowKeys);
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  // Table Download
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // Get the selected row data
  const selectedRows = itemsList.filter((item) =>
    selectedRowKeys.includes(item.key)
  );

  let path = "Items List";

  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => "");
  };

  const columnsA = [
    "S.No.",
    "Name",
    "Code",
    "Pur. Price",
    "Sales Price",
    "Item Type",
    "HSN/SAC Code",
    "Tax Preference",
    "GST",
  ];

  const fieldMapping = {
    "S.No.": (item: any) => item?.sno,
    Name: (item: any) => item?.name,
    Code: (item: any) => item?.number,
    "Pur. Price": (item: any) => Utils.getFormattedNumber(item?.purchasePrice),
    "Sales Price": (item: any) => Utils.getFormattedNumber(item?.salesPrice),
    "Item Type": (item: any) => (item?.itemType ? "Service" : "Goods"),
    "HSN/SAC Code": (item: any) => item?.HSNCode ?? item?.SACCode,
    "Tax Preference": (item: any) =>
      item?.taxType === true ? "Inclusive Tax" : "Exclusive Tax",
    GST: (item: any) => item?.gstRate,
  };

  const exportCsv = (invExportDataa: any[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportDataa;
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      "",
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (invExportDataa: any[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportDataa;
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path
    );
    setExportType(0);
  };

  const exportExcel = async (invExportDataa: any[]) => {
    const rowData = selectedRowKeys.length > 0 ? selectedRows : invExportDataa;
    await InvoiceExportService.generateExcel(
      rowData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportItemsData.length > 0) {
              exportCsv(exportItemsData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportItemsData.length > 0) {
              exportPDF(exportItemsData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSX"
          onClick={() => {
            if (exportItemsData.length > 0) {
              exportExcel(exportItemsData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSX
        </Button>
      </div>
    </Space>
  );

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        // marginTop: "6px",
        // padding: "48px 0px 0px 0px",
        marginTop: 35,
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      {props.trigger === true || (
        <>
          {/* <Affix offsetTop={130}> */}
          <Row
            justify="space-between"
            style={{
              // paddingTop: props?.partyId ? "10px" : "0px"
              // marginTop: 10,
              padding: "0px 0px 3px 0px",
            }}
            // style={{ position: "sticky",zIndex:1, top: 0, padding: "5px" }}
          >
            <Col>
              <Space>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setOpenModel({
                      type: "addItem",
                      param: { open: true },
                    });
                  }}
                >
                  Item
                </Button>
                {/* <Button
                  style={{
                    marginLeft: "10px",
                  }}
                  icon={<ArrowDownOutlined />}
                >
                  Export
                </Button> */}
                {itemsList?.length === 0 ? (
                  <>
                    <Button
                      type="default"
                      icon={<DownloadOutlined />}
                      // onClick={() => exportCsv()}
                      disabled
                    >
                      Export
                    </Button>
                  </>
                ) : (
                  <>
                    <Popover
                      content={content}
                      placement="rightTop"
                      trigger="hover"
                    >
                      <Button
                        type="default"
                        icon={<DownloadOutlined />}
                        // onClick={() => exportCsv()}
                      >
                        Export
                      </Button>
                    </Popover>
                  </>
                )}
              </Space>
            </Col>

            <Col
              style={{
                marginTop: width <= 768 ? 15 : "",
              }}
            >
              <Row gutter={width <= 768 ? 98 : 8}>
                <Col>
                  <Input
                    allowClear
                    style={{ width: "200px", border: "1px solid #f1f3ff" }}
                    placeholder="Search.."
                    onChange={(e: any) => {
                      setLoading(true);
                      setExportItemsData([]);
                      if (!e.cancelable) {
                        //console.log("typeing user............");
                        const searchItem = setTimeout(() => {
                          setPage(1);
                          setSearchItem(e.target.value);
                        }, 1000);
                        return () => clearTimeout(searchItem);
                      } else {
                        // console.log("Click cancle............");
                        setSearchItem(e.target.value);
                      }
                    }}
                    suffix={
                      loading || searchItem ? (
                        ""
                      ) : (
                        <img alt="Search.." src={search} />
                      )
                    }
                  />
                </Col>
                <Col>
                  <Select
                    // defaultValue={[null]}
                    mode="multiple"
                    maxTagCount={"responsive"}
                    // value={"GST = " + (gstRate === 0 ? "All" : gstRate + "%")}
                    style={{ minWidth: "158px" }}
                    allowClear
                    value={gstRate}
                    onChange={(e: any) => {
                      setExportItemsData([]);
                      console.log(e);
                      setGstRate(e);
                      setPage(1);

                      // e === null ? setGstRate([]) :  (e);
                      // setGstRate(e === null ? null : e);
                      // console.log(gstRate);
                    }}
                    placeholder="GST"
                    options={companySettings?.gsts?.map((gst: any) => ({
                      value: gst?.id,
                      title: gst?.rate,
                      label: gst?.name,
                    }))}
                    // options={[
                    //   companySettings?.gsts
                    //   // { value: 0, label: "All" },
                    //   { value: 0, label: "0%" },
                    //   { value: 0.25, label: "0.25%" },
                    //   { value: 3, label: "3%" },
                    //   { value: 5, label: "5%" },
                    //   { value: 12, label: "12%" },
                    //   { value: 18, label: "18%" },
                    //   { value: 28, label: "28%" },
                    // ]}
                  />
                </Col>
                {/* <Col>
                  <Select
                    defaultValue="All"
                    style={{ width: "100px" }}
                    onSelect={() => setPage(1)}
                    options={[
                      { value: "Active", label: "Active" },
                      { value: "Deleted", label: "Deleted" },
                    ]}
                  />
                </Col> */}
                <Col>
                  {location.pathname.split("/")[4] === "master" && (
                    <>
                      <Select
                        defaultValue={ItemType.All}
                        style={{ width: "140px" }}
                        onChange={(val: ItemType) => {
                          setExportItemsData([]);
                          // setType(1);
                          setType(val);
                        }}
                        options={[
                          { value: ItemType.All, label: "All Items" },
                          { value: ItemType.SalesItems, label: "Sales Items" },
                          {
                            value: ItemType.PurchaseItems,
                            label: "Purchase Items",
                          },
                        ]}
                      />
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          {/* </Affix> */}

          {/* --------- Table --------- */}
          <Table
            columns={columns}
            dataSource={
              loading
                ? (Array.from({ length: pageSize }, (_, index) => ({
                    key: `loading-${index}`,
                  })) as IItemListData[])
                : itemsList
            }
            locale={{
              triggerDesc: "",
              triggerAsc: "",
              cancelSort: "",
            }}
            onChange={tableSort}
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            rowSelection={rowSelection}
            pagination={{
              total: totalRecords,
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              onChange: (page: number, pageSize: number) => {
                setPage(page);
                setPageSize(pageSize);
              },
              pageSizeOptions: ["10", "15", "25", "50", "100"],
              showTotal: (totalRecords, page) =>
                `${page[0]}-${page[1]} of ${totalRecords} items`,
            }}
            // y: 640
            // scroll={{ x: "max-content", y: `calc(100vh - 500px)` }}
            // scroll={itemsList.length > 15 ? { x: 600 } : { x: 600 }}
            // scroll={
            //   pageSize > 10
            //     ? {
            //         x: width <= 768 ? 1500 : width <= 1024 ? 1150 : 700,
            //         y: 370,
            //       }
            //     : { x: width <= 768 ? 1500 : width <= 1024 ? 1150 : 600 }
            // }
            scroll={
              pageSize > 15
                ? { x: 1000, y: window.innerHeight - 300 }
                : undefined
            }
          />

          {/* --------- AddItem --------- */}
          {openModel.type === "addItem" && (
            <AddItems
              open={openModel?.param.open}
              onCancel={() => setOpenModel({ type: "", param: "" })}
              id={openModel?.param.id}
              // setTrigger={setTrigger((x) => !x)}
              setTrigger={setTrigger}
            />
          )}
          {openModel.type === "deleteItem" && (
            <ConfirmationModal
              open={true}
              onNo={() => setOpenModel({ type: "", param: "" })}
              onYes={async () => {
                try {
                  const deleteData = await ItemsServices.deleteItem(
                    currentClient?.id!,
                    openModel?.param?.id
                  );
                  if (deleteData.result) {
                    openNotification(
                      "success",
                      `Item "${openModel?.param?.name}" deleted sucessfully.`
                    );

                    setTrigger((x) => !x);
                    setOpenModel({ type: "", param: "" });
                  } else {
                    openNotification("error", deleteData?.message);
                    console.log("error", deleteData?.message);
                    setOpenModel({ type: "", param: "" });
                  }
                } catch (err) {
                  openNotification("error", err);
                  console.log("error", err);
                  setOpenModel({ type: "", param: "" });
                }
              }}
              text={`Are you sure you want to delete this "${openModel?.param?.name}" Item. ?`}
            />
          )}
        </>
      )}
    </Card>
  );
};

export default Items;
