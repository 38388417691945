import {
  Button,
  Card,
  Col,
  Input,
  Popover,
  Row,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import React from "react";
import ItemsService, { UnitTypes } from "../../Services/ItemsService";
import { useTheme } from "../../Context/ThemeContext";
import { useClient } from "../../Context/ClientContext";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import UnitModel from "./UnitModel";
import InvoiceExportService from "../../Services/InvoiceExportDetails";

interface IItemDataType {
  key: string;
  sno: number;
  companyId: string;
  id: number;
  name: string;
  type: string;
  shortName: string;
  tAmount: number;
  system: boolean;
}

const UnitsItems = () => {
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [unitsList, setUnitsList] = React.useState<any[]>([]);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [searchUnit, setSearchUnit] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openModel, setOpenModel] = React.useState<boolean>(false);
  const [unitData, setUnitData] = React.useState<any>({});
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [exportType, setExportType] = React.useState<number>(-1);
  const [exportUnitData, setExportUnitData] = React.useState<any[]>([]);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });

  React.useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      getUnitsList(
        currentClient?.id,
        exportType >= 1 ? 0 : (page - 1) * pageSize,
        exportType >= 1 ? 0 : pageSize,
        filter.sortCol,
        filter.sortDir,
        searchUnit
      );
    } else if (currentClient?.id === null) {
      console.error("currentClient is null Items");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trigger,
    page,
    pageSize,
    searchUnit,
    // location.pathname,
    filter.sortCol,
    filter.sortDir,
    exportType,
  ]);

  // --------- Get Units TableData API ---------
  const getUnitsList = async (
    clientId: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string
  ) => {
    if (exportType < 1) {
      setLoading(true);
    }
    await ItemsService.getUnitsList(
      clientId,
      start,
      length,
      sortCol,
      sortDir,
      search
    )
      .then((res: any) => {
        if (res.result?.items?.length > 0) {
          console.log(res.result.items);
          let unitData = res.result.items?.map((v: any, i: any) => {
            return {
              companyId: v?.companyId,
              name: v?.name,
              type: v?.type,
              shortName: v?.shortName,
              sno: v?.sno,
              id: v?.id,
              system: v?.system,
            };
          });

          if (exportType >= 1) {
            setExportUnitData(unitData);
            if (exportType === 1) {
              exportCsv(unitData);
            } else if (exportType === 2) {
              exportPDF(unitData);
            } else if (exportType === 3) {
              exportExcel(unitData);
            }
          } else {
            setUnitsList(unitData);
            setLoading(false);
            // setTotalRecords(res.result?.totalRecords);
          }
          setTotalRecords(res.result?.totalRecords);
        } else if (res.result?.items?.length === 0) {
          setUnitsList([]);
          setTotalRecords(res.result.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setUnitsList([]);
      });
  };

  // --------- Units Table Columns  ---------
  const itemColumns: ColumnsType<IItemDataType> = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.sno}
        </Skeleton>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      width: "40%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "40%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {UnitTypes[record?.type]}
        </Skeleton>
      ),
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      key: "shortName",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.shortName}
        </Skeleton>
      ),
    },
    {
      title: " ",
      dataIndex: "",
      key: "edit",
      render: (record: IItemDataType) =>
        record?.system === false ? (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <Row gutter={18}>
              <Col>
                <Tooltip title="Edit" placement="top" color="#3D4998">
                  <EditOutlined
                    // className="ca-edit-btn"
                    style={{
                      color: "#444653",

                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenModel(true);
                      setUnitData({
                        id: record?.id,
                        companyId: record?.companyId,
                        name: record?.name,
                        type: record?.type,
                        shortName: record?.shortName,
                      });
                    }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title="Delete" placement="top" color="red">
                  <DeleteOutlined
                    // className="ca-edit-btn"
                    style={{
                      color: "red",

                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Skeleton>
        ) : (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            <Row gutter={18}>
              <Col>
                <Tooltip title="Not Editable" placement="top" color="#3D4998">
                  <EditOutlined
                    style={{ color: "rgb(216 207 207)", cursor: "pointer" }}
                  />
                </Tooltip>
              </Col>
              <Col>
                <Tooltip
                  title="Default unit are not delete"
                  placement="top"
                  color="#3D4998"
                >
                  <DeleteOutlined
                    style={{ color: "rgb(216 207 207)", cursor: "pointer" }}
                  />
                </Tooltip>
              </Col>
            </Row>
          </Skeleton>
        ),
    },
  ];

  let path = "Unit List";
  const columnsA = ["Full Name", "Type", "Short Name"];

  const fieldMapping = {
    "Full Name": (item: any, index: number) => item?.name,
    Type: (item: any, index: number) => UnitTypes[item?.type],
    "Short Name": (item: any, index: number) => item?.shortName,
  };

  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => { });
  };

  const exportCsv = (exportData: any[]) => {
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      "",
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (exportData: any[]) => {
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path
    );
    setExportType(0);
  };

  const exportExcel = async (exportData: any[]) => {
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportUnitData.length > 0) {
              exportCsv(exportUnitData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportUnitData.length > 0) {
              exportPDF(exportUnitData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSX"
          onClick={() => {
            if (exportUnitData.length > 0) {
              exportExcel(exportUnitData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSX
        </Button>
      </div>
    </Space>
  );

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        // marginTop: "6px",
        marginTop: 35,
        // padding: "48px 0px 0px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <div>
        <Row justify="space-between">
          <Col>
            <Row gutter={15}>
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setOpenModel(true)}
                >
                  Unit
                </Button>
              </Col>
              <Col>
                {unitsList.length === 0 ? (
                  <Button icon={<DownloadOutlined />} disabled type="primary">
                    Export
                  </Button>
                ) : (
                  <Popover
                    content={content}
                    placement="rightTop"
                    trigger="hover"
                  >
                    <Button
                      type="default"
                      icon={<DownloadOutlined />}
                    // onClick={() => exportCsv()}
                    >
                      Export
                    </Button>
                  </Popover>
                )}
              </Col>
            </Row>
          </Col>
          <Col>
            <Input
              allowClear
              style={{ width: "220px" }}
              placeholder="Search here..."
              onChange={(e: any) => {
                setLoading(true);
                if (!e.cancelable) {
                  //console.log("typeing user............");
                  const searchUnit = setTimeout(() => {
                    setPage(1);
                    setSearchUnit(e.target.value);
                  }, 1000);
                  setExportUnitData([]);
                  setExportType(-1);
                  return () => clearTimeout(searchUnit);
                } else {
                  // console.log("Click cancle............");
                  setSearchUnit(e.target.value);
                }
              }}
              suffix={loading || searchUnit ? "" : <SearchOutlined />}
            />
          </Col>
        </Row>
        <Table
          className={`Tabel-style table-${themeData?.componentSize ?? "middle"
            }`}
          // loading={tableLoading}
          columns={itemColumns}
          dataSource={
            loading
              ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as IItemDataType[])
              : unitsList
          }
          scroll={
            pageSize > 15 ? { x: 1500, y: window.innerHeight - 300 } : undefined
          }
          pagination={{
            total: totalRecords,
            current: page,
            pageSize: pageSize,
            showSizeChanger: true,
            onChange: (page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            },
            pageSizeOptions: ["15", "25", "50", "100"],
            showTotal: (totalRecords, page) =>
              `${page[0]}-${page[1]} of ${totalRecords} items`,
          }}
        />
        {openModel && (
          <UnitModel
            open={true}
            data={unitData}
            onCancel={() => setOpenModel(false)}
            setTrigger={setTrigger}
          />
        )}
      </div>
    </Card>
  );
};

export default UnitsItems;
