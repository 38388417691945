import React from "react";
import { Card, Col, Collapse, Row } from "antd";
import { DashboardChart, SalesCard } from "../../Common/DashboardChart";
import { useClient } from "../../Context/ClientContext";
import IncomeInvoiceTable from "./IncomeTable";
import { Utils } from "../../../Utilities/Utils";
import plusImg from "../../Images/plus.svg";
import equalImg from "../../Images/equal.svg";
const Income: React.FC = () => {
  const { Panel } = Collapse;
  const { activeKey } = useClient();
  const [incomeList, setIncomeList] = React.useState<any>([]);
  // console.log("chartdata", incomeList);

  const option = {
    tooltip: {
      trigger: "item",
    },
    color: ["#ADADC9", "#91cc75", "#fac858"],
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["45%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
          fontSize: 18,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 14,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: 0, name: "No data Available" },
          {
            value: incomeList?.chartTotalRecieved,
            name: `Paid (${Utils.getFormattedNumber(
              (incomeList?.chartTotalRecieved * 100) /
                incomeList?.chartTotalTotalExpensesIncomeAmount
            )} %)`,
          },
          {
            value: incomeList?.chartTotalUnpaid,
            name: `Due (${Utils.getFormattedNumber(
              (incomeList?.chartTotalUnpaid * 100) /
                incomeList?.chartTotalTotalExpensesIncomeAmount
            )} %)`,
          },
        ],
      },
    ],
  };

  const yAxisFormatter = (value: any) => {
    const absValue = Math.abs(value);
    if (absValue >= 1e12) {
      return `${value / 1e12}t`; // trillion
    } else if (absValue >= 1e9) {
      return `${value / 1e9}b`; // billion
    } else if (absValue >= 1e6) {
      return `${value / 1e6}m`; // million
    } else if (absValue >= 1e3) {
      return `${value / 1e3}k`; // thousand
    }
    return value;
  };
  return (
    // <>
    //   <div
    //     style={{
    //       backgroundColor: "#f7f8fa",
    //       padding: "20px",
    //       borderRadius: "10px",
    //     }}
    //   >
    //     <Collapse className="customcssColl" activeKey={activeKey} ghost>
    //       <Panel
    //         className="customcss"
    //         showArrow={false}
    //         key={"1"}
    //         header={<></>}
    //       >
    //         <Row gutter={[30, 36]}>
    //           <Col lg={5}>
    //             <div>
    //               <DashboardChart
    //                 title={"Received Income"}
    //                 paymentstatus2={"Paid"}
    //                 paymentstatus={"Outstanding"}
    //                 amount={incomeList?.recieved}
    //                 option={""}
    //               />
    //             </div>
    //           </Col>
    //           <Col lg={1}>
    //             <PlusOutlined
    //               style={{ marginTop: "50px", paddingLeft: "5px" }}
    //             />
    //           </Col>
    //           <Col lg={5}>
    //             <div>
    //               <

    //                 title={"Income Due"}
    //                 paymentstatus2={"Overdue"}
    //                 amount={incomeList?.unpaid}
    //                 paymentstatus={"Due"}
    //                 option={""}
    //               />
    //             </div>
    //           </Col>{" "}
    //           <Col lg={1}>
    //             <FaEquals style={{ marginTop: "50px" }} />
    //           </Col>
    //           <Col lg={7} style={{}}>
    //             <SalesCard
    //               title={"Total Income Amount"}
    //               paymentstatus2={"Overdue"}
    //               amount={incomeList?.totalExpensesIncomeAmount}
    //               paymentstatus={"Due"}
    //               option={option}
    //             />
    //           </Col>
    //         </Row>
    //       </Panel>
    //     </Collapse>
    //   </div>
    //   <Row>
    //     <Col lg={24}>
    //       <IncomeInvoiceTable
    //         incomeList={incomeList}
    //         setIncomeList={setIncomeList}
    //       />
    //     </Col>
    //   </Row>
    // </>
    <>
      <div>
        <div
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            borderRadius: "10px",
            marginTop: 7,
            padding: "10px",
            marginLeft: "9px",
          }}
        >
          <Collapse className="customcssColl" activeKey={activeKey} ghost>
            <Collapse.Panel
              className="customcss"
              showArrow={false}
              key={"1"}
              header={<></>}
            >
              <Row gutter={[20, 36]}>
                <Col lg={5}>
                  <div>
                    <DashboardChart
                      title={"Received Income"}
                      paymentstatus2={"Overdue"}
                      amount={
                        // LoadSkeleton ? (
                        //   <AmountSkeleton />
                        // ) : (
                        Utils.getFormattedNumber(
                          incomeList?.chartTotalRecieved
                          // )
                        )
                      }
                      paymentstatus={"Due"}
                      option={""}
                    />
                  </div>
                </Col>
                <Col>
                  <img
                    alt="Indian-rupee"
                    src={plusImg}
                    style={{
                      marginTop: "60px",
                    }}
                  />
                </Col>
                <Col lg={5}>
                  <div>
                    <DashboardChart
                      title={"Income Due"}
                      paymentstatus2={"Paid"}
                      paymentstatus={"Outstanding"}
                      amount={Utils.getFormattedNumber(
                        incomeList?.chartTotalUnpaid
                      )}
                      option={""}
                    />
                  </div>
                </Col>
                <Col>
                  <img
                    alt="Indian-rupee"
                    src={equalImg}
                    style={{
                      marginTop: "60px",
                    }}
                  />
                </Col>

                <Col lg={5}>
                  <SalesCard
                    title={" Total Income "}
                    paymentstatus2={"Overdue"}
                    amount={Utils.getFormattedNumber(
                      incomeList?.chartTotalTotalExpensesIncomeAmount
                    )}
                    paymentstatus={"Due"}
                    option={option}
                  />
                </Col>
              </Row>
            </Collapse.Panel>
          </Collapse>
        </div>
        <Card
          size="small"
          className="TableCard"
          style={{
            width: "97%",
            marginLeft: "16px",
            marginTop: "30px",
            border: "1px solid #eef2fe",
          }}
        >
          <Row>
            <Col lg={24}>
              <IncomeInvoiceTable
                incomeList={incomeList}
                setIncomeList={setIncomeList}
              />
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
export default Income;
