import React from "react";
import { Button, Col, DatePicker, Row, Skeleton, Space, Tabs } from "antd";
import { Table } from "antd";
import type { TableColumnsType } from "antd";
import { useTheme } from "../../../../Context/ThemeContext";
import { useClient } from "../../../../Context/ClientContext";
import { dayjs } from "../../../../../Utilities/dayjs";
import { ITrialBalanceSettings } from "../../../Reports/Ageing";
import { getSettingsAmount } from "../../../Reports/TrialBalance";
import ReportsServiceNew, {
  GroupList_Type,
} from "../../../../Services/ReportsServiceNew";
import { Utils } from "../../../../../Utilities/Utils";
import { ReportSettings } from "../../../../Common/CAPComponents";
import { NavLink, useLocation } from "react-router-dom";
import VoucherDetailsByLedger from "../../../Reports/VoucherDetailsByLedger";

interface InterfaceProfitandLoss {
  key: React.Key;
  id: string;
  name: string;
  value: number;
  children: InterfaceProfitandLoss[];
}

const GroupDetailsTree: React.FC<{
  groupId?: string;
  groupName: string;
  filterFromDate?: any;
  filterToDate?: any;
}> = (props) => {
  console.log("props.....", props);
  const { RangePicker } = DatePicker;
  const { themeData } = useTheme();
  const location = useLocation();
  const { currentClient, companySettings } = useClient();
  const [shouldRefresh, setShouldRefresh] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [groupTreeDataList, setGroupTreeDataList] = React.useState<any[]>();
  const [totalAmount, setTotalAmount] = React.useState<string>("0");
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<any[]>([]);
  const lockPeriod = JSON.parse(localStorage.getItem("LockPeriod") || "null");

  const [filterDate, setFilterDate] = React.useState<any>({
    from: props.filterFromDate
      ? props.filterFromDate
      : lockPeriod?.from
      ? lockPeriod.from
      : dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: props.filterToDate
      ? props.filterToDate
      : lockPeriod?.to
      ? lockPeriod.to
      : dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });
  // const [filterDate, setFilterDate] = React.useState<any>({
  //   from:
  //     props.filterFromDate ??
  //     dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
  //   to:
  //     props.filterToDate ??
  //     dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  // });
  const [settings, setSettings] = React.useState<
    ITrialBalanceSettings | undefined
  >();
  const [activeKey, setActiveKey] = React.useState("");
  const [ledgerTabs, setLedgerTabs] = React.useState<any[]>([]);

  // Tree Table in Group list
  const columns: TableColumnsType<InterfaceProfitandLoss> = [
    {
      title: "Name",
      dataIndex: "name",
      width: "60%",
      align: "left",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {/* {record?.children?.length > 0 ? (
            <div>{record?.name}</div>
          ) : (
             <div>{record?.name}</div>
       
          )} */}

          {record?.children?.length > 0 ? (
            record?.id === props?.groupId ? (
              <div>{record?.name}</div>
            ) : (
              <NavLink
                to={`/accounts/clients/${currentClient?.id}/master/group/${record?.id}`}
                state={{
                  groupList: location.state?.groupList,
                }}
              >
                {record?.name}
              </NavLink>
            )
          ) : (
            <div
              className="hoverLinkColor cursorPointer"
              onClick={() => {
                const newActiveKey = record?.id;
                if (ledgerTabs.find((x) => x.key === newActiveKey)) {
                  setActiveKey(newActiveKey);
                } else {
                  setLedgerTabs([
                    ...ledgerTabs,
                    {
                      label: `${record?.name}`,
                      children: "New Tab Pane",
                      key: newActiveKey,
                    },
                  ]);
                  setActiveKey(newActiveKey);
                }
              }}
            >
              {record?.name}
            </div>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Debit (in ₹)",
      dataIndex: "value",
      width: "20%",
      align: "right",
      render: (x, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record.value > 0 ? Utils.getFormattedNumber(record.value) : "0.00"}
          {/* {          
            expandedRowKeys.includes(record.key)
              ? ""
              : getSettingsAmount(
                  false,
                  x,
                  companySettings?.setting?.tbSettings,
                  true,
                  false,
                  0,
                  0
                )
            // x !== 0 && x !== undefined
            // ? `₹ ${Utils.getFormattedNumber(x)}`
            // : `-`
          } */}
        </Skeleton>
      ),
    },
    {
      title: "Credit (in ₹)",
      dataIndex: "value",
      width: "20%",
      align: "right",
      render: (x, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record.value < 0
            ? Utils.getFormattedNumber(Math.abs(record.value))
            : "0.00"}
        </Skeleton>
      ),
    },
  ];

  React.useEffect(() => {
    setSettings(companySettings?.setting?.tbSettings);
  }, [companySettings, shouldRefresh]);

  React.useEffect(() => {
    if (props.groupId) {
      setLedgerTabs([]);
      getGroupHierarchy(
        currentClient?.id!,
        dayjs(filterDate?.from, "DD/MM/YYYY").toISOString(),
        dayjs(filterDate?.to, "DD/MM/YYYY").endOf("day").toISOString(),
        props?.groupId
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, filterDate, filterDate, props?.groupId]);

  const getGroupHierarchy = async (
    clientId: string,
    fromDate: string = "",
    toDate: string = "",
    groupId?: string
  ) => {
    setLoading(true);
    await ReportsServiceNew.getGroupHierarchy(
      clientId,
      fromDate,
      toDate,
      groupId,
      GroupList_Type.HaveBalanceOnly
    )
      .then((res: any) => {
        let index = 0;
        // console.log("groupList......................", index);
        const dataSetInApi = (allgroupList: any[]) => {
          // console.log("allgroupList", allgroupList);
          return allgroupList.map((x: any) => {
            // console.log("index........................", index);
            const node: any = {
              key: (index += 1),
              id: x?.id,
              name: x?.name,
              value: x?.value,
            };
            if (x?.ledgerList?.length > 0 && x?.children?.length > 0) {
              const totalArray = [...x?.ledgerList, ...x?.children];
              node.children = dataSetInApi(totalArray);
            } else if (x?.ledgerList?.length > 0) {
              node.children = dataSetInApi(x?.ledgerList);
            } else if (x?.children?.length > 0) {
              node.children = dataSetInApi(x?.children);
            }
            return node;
          });
        };
        const flattenedData = dataSetInApi(res?.groupList);
        setGroupTreeDataList(flattenedData);
        // console.log("treeData...............", flattenedData);

        setTotalAmount(
          Utils.getFormattedNumber(res?.gTotalDebit + res?.gTotalCredit)
        );
        setLoading(false);
        // console.log("result", res);
      })
      .catch((err: any) => {
        // console.log("error", err);
        setLoading(false);
      });
  };

  const onExpand = (expanded: any, record: any) => {
    // console.log("expanded..................", record);
    // Update the list of expanded row keys
    setExpandedRowKeys(
      expanded
        ? [...expandedRowKeys, record.key]
        : expandedRowKeys.filter((key) => key !== record.key)
    );
  };

  const commonBar = () => (
    <Row
      justify="space-between"
      style={{
        // paddingTop: props?.partyId ? "10px" : "0px"
        // marginTop: 10,
        padding: "4px 0px 0px",
      }}
    >
      <Col>
        <Space>
          {/* <Col span={24}> */}
          <RangePicker
            allowClear={false}
            format="DD-MM-YYYY"
            style={{ width: "250px" }}
            defaultValue={
              // props?.filterFromDate && props?.filterToDate
              // ? [
              //     dayjs(props.filterFromDate, "DD/MM/YYYY"),
              //     dayjs(props.filterToDate, "DD/MM/YYYY"),
              //   ]
              // :
              [
                dayjs(filterDate?.from, "DD/MM/YYYY"),
                dayjs(filterDate?.to, "DD/MM/YYYY"),
              ]
            }
            onChange={(e: any) => {
              e === null
                ? setFilterDate({})
                : setFilterDate({
                    ...filterDate,
                    from: dayjs(e[0]).format("DD/MM/YYYY"),
                    to: dayjs(e[1]).format("DD/MM/YYYY"),
                  });
              // SetPageInv(1);
            }}
            presets={[
              {
                label: "Today",
                value: [dayjs().add(0, "d"), dayjs()],
              },
              {
                label: "Last 7 Days",
                value: [dayjs().add(-7, "d"), dayjs()],
              },
              {
                label: "Last 15 Days",
                value: [dayjs().add(-15, "d"), dayjs()],
              },
              {
                label: "This Month",
                value: [dayjs().startOf("month"), dayjs()],
              },
              {
                label: "Last Month",
                value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
              },
              {
                label: "Quarter 1",
                value: [
                  dayjs().month(3).startOf("month"),
                  dayjs().month(5).endOf("month"),
                ],
              },
              {
                label: "Quarter 2",
                value: [
                  dayjs().month(6).startOf("month"),
                  dayjs().month(8).endOf("month"),
                ],
              },
              {
                label: "Quarter 3",
                value: [
                  dayjs().month(9).startOf("month"),
                  dayjs().month(11).endOf("month"),
                ],
              },
              {
                label: "Quarter 4",
                value: [
                  dayjs().month(0).startOf("month"),
                  dayjs().month(2).endOf("month"),
                ],
              },
              {
                label: "This Year",
                //           dayjs().month(0).startOf("month"),
                // dayjs(),
                value: [dayjs().month(0).startOf("month"), dayjs()],
              },
              {
                label: "Last Year",
                value: [
                  dayjs().month(-12).startOf("month"),
                  dayjs().month(-1).endOf("month"),
                ],
              },
            ]}
          />
        </Space>
      </Col>

      <Col
        style={
          {
            // marginTop: width <= 768 ? 15 : "",
          }
        }
      >
        <Row>
          <Col>
            <Space></Space>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  const initialItems = [
    {
      label: `${props.groupName}`,
      key: `${props.groupId}`,
      closable: false,
      children: (
        <div style={{ width: "100%" }} className="m-auto">
          {commonBar()}
          <Table
            columns={columns}
            dataSource={
              loading
                ? (Array.from({ length: 15 }, (_, index) => ({
                    key: `loading-${index}`,
                  })) as InterfaceProfitandLoss[])
                : groupTreeDataList
            }
            scroll={{ y: 650 }}
            pagination={false}
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            expandedRowKeys={expandedRowKeys}
            onExpand={onExpand}
            footer={() => (
              <>
                <Row justify={"end"}>
                  <Col lg={12} className="textStart">
                    Total
                  </Col>
                  <Col
                    lg={12}
                    style={{
                      paddingLeft: "40px",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    ₹{totalAmount}
                  </Col>
                </Row>
              </>
            )}
          />
          {/* // scroll={trialBalanceList.length > 10 ? { x: 700, y: 620 } : { x: 600 }} */}
        </div>
      ),
    },
  ];
  // RemoveTabs//
  type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

  const remove = (targetKey: TargetKey) => {
    const newPanes = ledgerTabs?.filter((x: any) => x.key !== targetKey);
    setLedgerTabs(newPanes);
    if (newPanes.length && targetKey === activeKey) {
      setActiveKey(`${props.groupName}`);
    }
    // setMoreTabs(newPanes);
  };

  const updatedTabs = initialItems?.concat(
    ledgerTabs?.map((tab: any) => ({
      label: tab.label,
      key: tab.key,
      closable: true,
      children: (
        <>
          <VoucherDetailsByLedger
            ledgerId={tab.key}
            ledgerName={tab.label}
            filterFromDate={filterDate.from}
            filterToDate={filterDate.to}
          />
        </>
      ),
    }))
  );

  return (
    <>
      {ledgerTabs.length > 0 ? (
        <Tabs
          hideAdd
          type="editable-card"
          // className="tabFlexOverride"
          onEdit={remove}
          activeKey={activeKey}
          items={updatedTabs}
          onChange={(key) => setActiveKey(key)}
          tabBarExtraContent={
            <Button
              className="ms-10"
              onClick={() => {
                setLedgerTabs([]);
                //setTabData(undefined);
                setActiveKey("");
              }}
            >
              Close All
            </Button>
          }
        />
      ) : (
        <>
          {commonBar()}

          <Table
            columns={columns}
            dataSource={
              loading
                ? (Array.from({ length: 15 }, (_, index) => ({
                    key: `loading-${index}`,
                  })) as InterfaceProfitandLoss[])
                : groupTreeDataList
            }
            scroll={{ y: 650 }}
            pagination={false}
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            expandedRowKeys={expandedRowKeys}
            onExpand={onExpand}
            footer={() => (
              <>
                <Row justify={"end"}>
                  <Col lg={12} className="textStart">
                    Total
                  </Col>
                  <Col
                    lg={12}
                    style={{
                      paddingLeft: "40px",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    ₹{totalAmount}
                  </Col>
                </Row>
              </>
            )}
          />
        </>
      )}

      {/* customize setting modal */}
      {isModalOpen && (
        <ReportSettings
          isOpen={isModalOpen}
          setOpen={setIsModalOpen}
          type="PnL"
          value={settings}
          refresh={shouldRefresh}
          setRefresh={setShouldRefresh}
        />
      )}
    </>
  );
};

export default GroupDetailsTree;
