import React, { useMemo, useState } from "react";
import { Button, Card, Col, ConfigProvider, Row, theme } from "antd";
import templete from "../../Images/Templetinvoice.jpg";
// import templete2 from "../../Images/templet02.jpg";
import { EditOutlined, FileSearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import tempimg from "../../../images/templatepdf.jpg";
import {
  InvoiceTemplates,
  SettingsSections,
} from "../../Services/SettingService";

const SettingsTemplates: React.FC = () => {
  const { currentClient, updateCompanySettings, companySettings } = useClient();
  const navigate = useNavigate();
  const [click, SetClick] = React.useState<any>(false);
  const { token } = theme.useToken();
  let currentTemp = companySettings?.setting.defaultTemplate;
  // const [defaultSelectedStyle, setDefaultSelectedStyle] = useMemo({
  //   border: `1px `,
  //   boxShadow: `0px 0px 5px 1px ${token.colorPrimaryBorderHover}`,
  // });
  // let defaultSelectedStyle: any = useMemo(() => {
  //
  //   if (currentTemp === InvoiceTemplates.Template1) {
  //     return {
  //       border: `1px `,
  //       boxShadow: `0px 0px 5px 1px ${token.colorPrimaryBorderHover}`,
  //     };
  //   } else if (currentTemp == InvoiceTemplates.Template2) {
  //     return {
  //       border: `1px `,
  //       boxShadow: `0px 0px 5px 1px ${token.colorPrimaryBorderHover}`,
  //     };
  //   } else {
  //     return {};
  //   }
  // }, []);
  // console.log(click, "check");
  console.log(
    "defaultSelectedStyle",
    currentTemp,
    currentTemp === InvoiceTemplates.Template1,
    currentTemp === InvoiceTemplates.Template2
  );

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        // marginTop: "6px",
        marginTop: 35,
        // padding: "48px 0px 0px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <Row style={{ padding: "0px 0px 3px 0px" }}>
        {/* <Col
          lg={4}
          style={{
            background: "#F6F9FF",
            width: 225,
            height: "100%",
            borderRadius: 4,
            padding: "35px 20px 18px 18px",
            border: click === true ? "1px solid #2D81FF " : "1px solid #E5EBF8",
          }}
        >
          <div>
            <img
              alt="imgerror"
              src={tempimg}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",

              justifyContent: "space-between",
              paddingTop: "6px",
            }}
          >
            <Button
              type={click ? "primary" : "default"}
              size="small"
              onClick={() => SetClick(!click)}
            >
              {click ? "Default" : "Selected"}
            </Button>

            <FileSearchOutlined
              style={{ color: "gray", fontSize: "16px" }}
              onClick={() => window.alert("inprogress")}
            />

            <EditOutlined
              style={{ color: "gray", fontSize: "16px" }}
              onClick={() =>
                navigate(
                  `/accounts/clients/${currentClient?.id}/settings/templates/1`
                )
              }
            />
          </div>
        </Col> */}
        <Col
          lg={4}
          style={{
            background: "#F6F9FF",
            width: 225,
            height: "100%",
            borderRadius: 4,
            padding: "35px 20px 18px 18px",
            marginLeft: "15px",
            border: currentTemp === InvoiceTemplates.Template1 ? `1px ` : "0px",
            boxShadow:
              currentTemp === InvoiceTemplates.Template1
                ? `0px 0px 5px 1px ${token.colorPrimaryBorderHover}`
                : "none",
          }}
        >
          <div>
            <img
              alt="imgerror"
              src={tempimg}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "6px",
            }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() =>
                updateCompanySettings!(
                  SettingsSections.DefaultTemplate,
                  {
                    ...companySettings,
                    setting: {
                      ...companySettings?.setting!,
                      defaultTemplate: InvoiceTemplates.Template1,
                    },
                  },
                  { defaultTemplate: InvoiceTemplates.Template1 }
                )
              }
            >
              Default
            </Button>

            <FileSearchOutlined
              style={{ color: "gray", fontSize: "16px" }}
              onClick={() => window.alert("inprogress")}
            />

            <EditOutlined
              style={{ color: "gray", fontSize: "16px" }}
              onClick={() =>
                navigate(
                  `/accounts/clients/${
                    currentClient?.id
                  }/settings/templates/${0}`
                )
              }
            />
          </div>
        </Col>
        <Col
          lg={4}
          style={{
            background: "#F6F9FF",
            width: 225,
            height: "100%",
            borderRadius: 4,
            padding: "35px 20px 18px 18px",
            marginLeft: "15px",
            border: currentTemp === InvoiceTemplates.Template2 ? `1px ` : "0",
            boxShadow:
              currentTemp === InvoiceTemplates.Template2
                ? `0px 0px 5px 1px ${token.colorPrimaryBorderHover}`
                : "none",
          }}
        >
          <div>
            <img
              alt="imgerror"
              src={tempimg}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "6px",
            }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() =>
                updateCompanySettings!(
                  SettingsSections.DefaultTemplate,
                  {
                    ...companySettings,
                    setting: {
                      ...companySettings?.setting!,
                      defaultTemplate: InvoiceTemplates.Template2,
                    },
                  },
                  { defaultTemplate: InvoiceTemplates.Template2 }
                )
              }
            >
              Default
            </Button>

            <FileSearchOutlined
              style={{ color: "gray", fontSize: "16px" }}
              onClick={() => window.alert("inprogress")}
            />

            <EditOutlined
              style={{ color: "gray", fontSize: "16px" }}
              onClick={() =>
                navigate(
                  `/accounts/clients/${
                    currentClient?.id
                  }/settings/templates/${1}`
                )
              }
            />
          </div>
        </Col>
        {/* <Col
          lg={4}
          style={{
            background: "#F6F9FF",
            width: 225,
            height: "100%",
            borderRadius: 4,
            padding: "35px 20px 18px 18px",
            marginLeft: "15px",
          }}
        >
          <div>
            <img
              alt="imgerror"
              src={tempimg}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "6px",
            }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => window.alert("inprogress")}
            >
              Default
            </Button>

            <FileSearchOutlined
              style={{ color: "gray", fontSize: "16px" }}
              onClick={() => window.alert("inprogress")}
            />

            <EditOutlined
              style={{ color: "gray", fontSize: "16px" }}
              onClick={() =>
                navigate(
                  `/accounts/clients/${
                    currentClient?.id
                  }/settings/templates/${2}`
                )
              }
            />
          </div>
        </Col> */}
      </Row>
    </Card>
  );
};

export default SettingsTemplates;
