/* eslint-disable array-callback-return */
import React, { useEffect, useRef, useState } from "react";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import { useNavigate, useParams } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
// import { notificationContext } from "../../Common/PageRoute";
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Tooltip,
  Upload,
} from "antd";
import { useLocation } from "react-router-dom";

import { dayjs } from "../../../Utilities/dayjs";
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Utils } from "../../../Utilities/Utils";
import VoucherServices, {
  Party_Tax,
  Voucher_Type,
} from "../../Services/VoucherServices";
import noteContext from "../../Context/StyleContext";
import deleteImg from "../../../images/deletebutton.svg";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import { AddLedger } from "../Master/Ledger/AddLedger";
import TextArea from "antd/es/input/TextArea";
import ImportLedger from "../Settings/ImportLedger";
import TrialBalance from "../Reports/TrialBalance";
import EntryLevelKeys from "../../Common/EntryLevelKeys";
import DraftService from "../../Services/DraftService";
import { VoucherMode } from "../../Services/SalesService";
import DocumentPreview from "../../Common/DocumentPreview";
import settingslogo from "../../../images/settingslogo.png";
import SettingCustomizationIsDrawer from "../Settings/SettingCustomizationIsDrawer";
import { SettingsSections } from "../../Services/SettingService";

interface IBankIdAndBalnce {
  id: string;
  balance: number;
}

const AddJournalNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { journalId, draftId } = useParams();
  const { currentClient, companySettings } = useClient();
  const { windowWidth } = React.useContext(noteContext);
  const { openNotification } = React.useContext(notificationContext);
  // const [itemList, setItemList] = useState<any[]>([]);sadasdadsds
  const [form] = Form.useForm();
  //   const [accountLedgerOption, setAccountLedgerOption] = React.useState<any[]>(
  //     []
  //   );
  const [partyLedgerOption, setPartyLedgerOption] = React.useState<any[]>([]);
  const [selectedPartyOption, setSelectedPartyOption] = React.useState<any[]>(
    []
  );
  const [selectedBankBalance, setSelectedBankBalance] = React.useState<any[]>(
    []
  );
  const [editInvBankDetails, setEditInvBankDetails] = React.useState<any[]>([]);

  const [trigger, setTrigger] = React?.useState(false);
  const [settingTigger, setSettingTigger] = useState(false);
  const [openModel, setOpenModel] = React?.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [itemLength, setItemLength] = React.useState<number>(0);
  const [deleteItemList, setDeleteItemList] = React.useState<{
    name: any;
    index: number;
  }>({
    name: "",
    index: -1,
  });
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  //   const [currentBalance, setCurrentBalance] = React.useState<number>(0);
  const [accountIsParty, setAccountIsParty] = React.useState<Party_Tax>();
  const [partyRef, setPartyRef] = React.useState<any[]>([]);
  const [selectPartyRef, setSelectPartyRef] = React.useState<any[]>([[]]);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [totalPaymentIn, setTotalPaymentIn] = React.useState<number>(0);
  const [disabledAmountInputs, setDisabledAmountInputs] = React.useState(
    Array(0).fill(false)
  );

  const [allUpdatePartyData, setAllUpdatePartyData] = React.useState<any[]>([]);
  const [debitSubTotal, setDebitSubTotal] = React.useState<number>(0);
  const [creditSubTotal, setCreditSubTotal] = React.useState<number>(0);
  const [dateFiled, setDateFiled] = React.useState<any>();
  const [editDate, setEditDate] = useState<any>();

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [importModal, setImportModal] = React.useState<any>({
    type: "",
    param: {},
  });
  const [deleteUploadId, setDeleteUploadId] = React.useState<string[]>([]);
  const [previewImage, setPreviewImage] = React.useState<{
    isBaseConvert: boolean;
    path: any;
  }>({ isBaseConvert: false, path: "" });

  const [itmDropDownLoadinf, setItmDropDownLoading] = React.useState(false);

  console.log("importData", location.state?.importData, allUpdatePartyData);

  useEffect(() => {
    if (location?.state?.importData) {
      const formattedData = location.state?.importData?.map((item: any) => ({
        id: {
          value: item.ledgerId,
          title: item?.partyOrTax,
        },
        credit: item?.credit,
        debit: item?.debit,
        // partyOrTax: item?.partyOrTax,
      }));
      form.setFieldsValue({
        partyLedger: [
          ...formattedData,
          { amount: "0.00", credit: "0.00", debit: "0.00" },
        ],
      });

      const debitTotal = location.state?.importData?.reduce(
        (total: any, item: any) => total + item.debit,
        0
      );
      setDebitSubTotal(debitTotal);
      const creditTotal = location.state?.importData?.reduce(
        (total: any, item: any) => total + item.credit,
        0
      );
      setCreditSubTotal(creditTotal);
    }
  }, [location, form]);

  console.log("location.........", selectedBankBalance);
  // Date Api
  React.useEffect(() => {
    const getDate = async () => {
      if (currentClient?.id && !journalId) {
        await VoucherServices.getMaxVoucherDate(
          currentClient?.id!,
          Voucher_Type.Journal
        ).then((res) => {
          const givenDate = new Date(res?.result);
          const currentTime = new Date();

          // Set the time part of givenDate to match currentTime
          givenDate?.setHours(
            currentTime.getHours(),
            currentTime.getMinutes(),
            currentTime.getSeconds(),
            currentTime.getMilliseconds()
          );
          form.setFieldValue("date", dayjs(givenDate));

          setDateFiled(res.result);
        });
      }
    };
    getDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  const getVoucherTitle = companySettings?.setting?.vouchersSettings.find(
    (x: any) => x?.title === "JOURNAL"
  );

  const [settingControl, setSettingControl] = React.useState<{
    title: string;
  }>({
    title: getVoucherTitle?.settings?.basicSettings?.title,
  });

  useEffect(() => {
    setSettingControl({
      title: getVoucherTitle?.settings?.basicSettings?.title,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingTigger]);

  // ----- initial value -----
  React.useEffect(() => {
    if (currentClient?.id && !journalId && dateFiled) {
      setLoading(true);
      VoucherServices.getVoucherMaxNo(
        currentClient?.id!,
        Voucher_Type.Journal,
        dayjs(dateFiled).endOf("day").toISOString(),
        getVoucherTitle?.title!
      )
        .then((res) => {
          form.setFieldValue("number", res?.result);
          if (location?.state?.trialBalanceData) {
            // const trialBalanceData = location.state.trialBalanceData;
            const trialBalanceData = location?.state?.trialBalanceData?.map(
              (led: any) => {
                return {
                  id: led?.ledgerId,
                  debit: led?.debit,
                  credit: led?.credit,
                  title: led?.partyOrTax,
                };
              }
            );
            form?.setFieldsValue({
              partyLedger: trialBalanceData,
            });
            const debitTotal = trialBalanceData.reduce(
              (total: any, item: any) => total + item.debit,
              0
            );
            setDebitSubTotal(debitTotal);
            const creditTotal = trialBalanceData.reduce(
              (total: any, item: any) => total + item.credit,
              0
            );
            setCreditSubTotal(creditTotal);
          }
          setLoading(false);
        })
        .catch((err: any) => {
          openNotification("error", err.message);
          console.error(err.message);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, dateFiled, settingTigger]);

  // party & other payment select
  React.useEffect(() => {
    setLoading(true);
    setItmDropDownLoading(true);
    LedgerService.getLedgersByGroupName(
      currentClient?.id!,
      SearchLedgerByGroupsTypes.All
    ).then((res) => {
      if (res) {
        // const onlyBank = res.filter(
        //   (x: any) => x.partyOrTax === 3 || x.partyOrTax === 2
        // );
        // setPartyLedgerOption(onlyBank);
        setPartyLedgerOption(res);
        setLoading(false);
        setItmDropDownLoading(false);
      } else {
        setLoading(false);
        setItmDropDownLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // Post (save) jornal api
  const save = (isSaveAndNew?: boolean, isDraft?: boolean) => {
    form.validateFields().then(async (val: any) => {
      // console.log(
      //   "post",
      //   val,
      //   val.partyLedger.filter(
      //     (x: any) =>
      //       x?.id?.title === Party_Tax.BanknCash &&
      //       Utils.getNumber(x?.credit) > 0
      //   ),
      //   selectedBankBalance
      // );
      try {
        const allBankLedgerOnlyCreditAmount: any[] = val.partyLedger.filter(
          (x: any) =>
            x?.id?.title === Party_Tax.BanknCash &&
            Utils.getNumber(x?.credit) > 0
        );

        let bankBalanceLargeAmount: any = [];
        if (allBankLedgerOnlyCreditAmount.length > 0) {
          for (const index in allBankLedgerOnlyCreditAmount) {
            const bankLedgerBalance = selectedBankBalance.find(
              (x) => x?.id === allBankLedgerOnlyCreditAmount[index]?.id?.value
            )?.balance;
            if (
              Utils.getNumber(allBankLedgerOnlyCreditAmount[index]?.credit) >
              bankLedgerBalance
            ) {
              bankBalanceLargeAmount.push({
                name: allBankLedgerOnlyCreditAmount[index]?.id?.label,
                amount: bankLedgerBalance,
              });
              console.log("index", bankLedgerBalance);
            }
          }
        }

        if (bankBalanceLargeAmount.length > 0) {
          const formattedDetails = bankBalanceLargeAmount.map(
            (obj: any) =>
              `${obj?.name} -  Bank Amount: ₹ ${Utils.getFormattedNumber(
                obj?.amount
              )}`
          );
          alert(formattedDetails.join("\n"));
        } else {
          setSaveButtonLoading(true);

          const effectiveLedger: any[] = [];
          const partyLedger: any[] = [];
          val?.partyLedger?.map((x: any) => {
            if (parseFloat(x?.debit) > 0) {
              effectiveLedger.push({
                ...x,
                amount: x?.debit,
                id: x?.id?.value,
                partyOrTax: x?.id?.title,
                isDebitNature: true,
                ref:
                  x?.ref?.length > 0
                    ? x?.ref.map((x: any) => {
                        return {
                          ...x,
                          name: x?.name?.label ?? x?.name,
                          partyRef: x?.name?.value,
                        };
                      })
                    : null,
              });
            } else {
              partyLedger.push({
                ...x,
                amount: x?.credit,
                id: x?.id?.value,
                partyOrTax: x?.id?.title,
                isDebitNature: false,
                ref:
                  x?.ref?.length > 0
                    ? x?.ref.map((x: any) => {
                        return {
                          ...x,
                          name: x?.name?.label ?? x?.name,
                          partyRef: x?.name?.value,
                        };
                      })
                    : null,
              });
            }
          });

          let givenDate;
          if (journalId && dayjs(val?.date).isSame(dayjs(editDate), "day")) {
            givenDate = editDate;
          } else {
            givenDate = new Date(val.date);
            const currentTime = new Date();

            // Set the time part of givenDate to match currentTime
            givenDate?.setHours(
              currentTime.getHours(),
              currentTime.getMinutes(),
              currentTime.getSeconds(),
              currentTime.getMilliseconds()
            );
          }

          const data = {
            ...val,
            deleteUploadId: deleteUploadId,
            amount: totalPaymentIn,
            date: dayjs(givenDate).toISOString(),
            isLocked: false,
            isReconciled: false,
            isAudited: false,
            isActive: true,
            partyLedger: partyLedger,
            effectiveLedger: effectiveLedger,
            mode:
              location.state?.importData?.length > 0
                ? VoucherMode.Opening
                : VoucherMode.Normal,
          };

          const res = isDraft
            ? await DraftService.postVoucherDraft(
                currentClient?.id!,
                Voucher_Type.Journal,
                data,
                getVoucherTitle?.title!,
                draftId
              )
            : await VoucherServices.postVoucher(
                currentClient?.id!,
                Voucher_Type.Journal,
                data,
                getVoucherTitle?.title!,
                journalId
              );

          if (res?.status) {
            if (isDraft) {
              openNotification(
                "success",
                `Journal draft ${res.result?.name} ${
                  draftId ? "updated successfully." : "added successfully."
                }`
              );
            } else {
              openNotification(
                "success",
                `Journal ${
                  journalId ? "updated successfully." : "added successfully."
                }`
              );
              navigate(-1);
              setSaveButtonLoading(false);
            }
          } else if (res?.message) {
            openNotification("error", res?.message);
            setSaveButtonLoading(false);
          }
          // .catch((ex) => {
          //   setSaveButtonLoading(false);
          //   openNotification("error", ex);
          // });
        }
      } catch (ex) {
        console.error("Error", ex);
        setSaveButtonLoading(false);
        openNotification("error", ex);
      }
    });
  };
  EntryLevelKeys({
    save: () => save(),
    cancel: () => navigate(-1),
  });
  const onClickSelectPartyLedger = (
    value: string,
    index: number,
    partyOrTax: Party_Tax
  ) => {
    console.log("x", value, index, partyOrTax);
    setSelectedPartyOption((prevState) => {
      const updatedSelectedOptions = [...prevState];
      updatedSelectedOptions[index] = value;
      // console.log(updatedSelectedOptions);
      return updatedSelectedOptions;
    });
  };

  const partySelect: any = async (
    partyId: string,
    index: number,
    title: Party_Tax
  ) => {
    // if (!journalId) {
    setSelectPartyRef((prevDataArray) => {
      const newArray = [...prevDataArray];
      if (index < 0 || index >= newArray.length) {
        newArray.push([]);
      } else {
        newArray.splice(index, 1, []);
      }
      return newArray;
    });

    let onePartyUpdateData = allUpdatePartyData.find(
      (x) => x.id.value === partyId
    );
    console.log("onePartyUpdateData", onePartyUpdateData);

    if (title === Party_Tax.BanknCash) {
      const bankLedger = partyLedgerOption.find((x) => x.id === partyId);
      // console.log("bankBalance", bankLedger.balance);
      setSelectedBankBalance((x: any) => {
        const updatedSelectedOptions = [...x];
        updatedSelectedOptions[index] = {
          id: bankLedger.id,
          balance:
            bankLedger?.balance +
            Math.abs(Utils.getNumber(onePartyUpdateData?.credit)),
        };
        return updatedSelectedOptions;
      });
    }

    if (title === Party_Tax.party) {
      // update case if user delete reference & again add same reference
      if (journalId) {
        let onePartyUpdateData = allUpdatePartyData.find(
          (x) => x.id.value === partyId
        );

        console.log("onePartyUpdateData", onePartyUpdateData);

        if (onePartyUpdateData) {
          // yadi user ne pahle hi add kar raha & deleter kar ke again add
          form.setFieldValue(["partyLedger", index], {
            ...onePartyUpdateData,
          });

          setSelectPartyRef((prevState: any[]) => {
            const updatedSelectedOptions: any[] = [...prevState];
            updatedSelectedOptions[index] = [...(prevState[index] || [])];
            updatedSelectedOptions[index] = onePartyUpdateData?.ref?.map(
              (x: any) => x?.parentRef
            );
            return updatedSelectedOptions;
          });

          setPartyRef((prevDataArray) => {
            const newArray = [...prevDataArray];
            newArray[index] = onePartyUpdateData?.ref?.map((x: any) => {
              return {
                ...x,
                maxAmount: x?.value,
              };
            });
            return newArray;
          });
        }

        console.log("allUpdatePartyData", onePartyUpdateData);
      }

      await VoucherServices.getReferencesByParty(
        currentClient?.id!,
        partyId,
        Voucher_Type.Journal
      ).then((res: any) => {
        console.log("result", res.result?.length, index);
        if (res.result?.length > 0) {
          const data = res.result?.map((v: any, i: number) => {
            return {
              name: res.result[i]?.refNo,
              maxAmount: res.result[i]?.value,
              dueAmount: res.result[i]?.dueAmount,
              // id: res.result[i]?.id,
              parentRef: res.result[i]?.id,
              id: null,
              refType: 1,
            };
          });

          if (
            journalId &&
            allUpdatePartyData.find((x) => x.id?.value === partyId)
          ) {
            setPartyRef((prevDataArray) => {
              const prevArray = prevDataArray[index];
              // Filter out the common elements based on 'parentRefId'
              const filteredData = data.filter((newItem: any) => {
                return !prevArray.some(
                  (prevItem: any) => prevItem?.parentRef === newItem?.parentRef
                );
              });
              const updatedArray = [...prevArray, ...filteredData];
              console.log("newArray", updatedArray);
              const newArray = [...prevDataArray];
              newArray[index] = updatedArray;
              return newArray;
            });
          } else {
            // debugger;
            form.setFieldValue(["partyLedger", index, "ref"], [{}]);

            form.setFieldValue(["partyLedger", index, "ref", 0], {
              name: form.getFieldValue(["number"]),
              amount: 0,
              refType: 0,
            });

            form.setFieldValue(["partyLedger", index, "ref", 1], {});

            setPartyRef((prevDataArray) => {
              const newArray = [...prevDataArray];
              newArray[index] = data;
              return newArray;
            });
          }
        } else {
          form.setFieldValue(["partyLedger", index, "ref"], [{}]);
          form.setFieldValue(["partyLedger", index, "ref", 0], {
            name: form.getFieldValue(["number"]),
            amount: 0,
            refType: 0,
          });
          setPartyRef((prevDataArray) => {
            const newArray = [...prevDataArray];
            newArray[index] = [];
            return newArray;
          });
        }
      });
    } else {
      setPartyRef((prevDataArray) => {
        const newArray = [...prevDataArray];
        newArray[index] = [];
        return newArray;
      });
    }

    setDisabledAmountInputs((prev) => {
      const newDisabledInputs = [...prev];
      newDisabledInputs[index] = title === Party_Tax.party ? true : false;
      return newDisabledInputs;
    });
    // }
    // handleLedgerNetAmount(index);
  };

  console.log("data");

  //  -------- Edit payment Get API ---------
  React.useEffect(() => {
    if ((journalId || draftId) && partyLedgerOption.length > 0) {
      const editData = async (journalId: string) => {
        // setLoading(true);
        try {
          const res = draftId
            ? await DraftService.getByDraftId(currentClient?.id!, draftId)
            : await VoucherServices.getVoucharById(
                currentClient?.id!,
                journalId,
                Voucher_Type.Journal
              );
          if (res.result) {
            // console.log("result.............................", res.result);
            setTotalPaymentIn(Math.abs(res.result?.effectiveLedger[0]?.amount));
            // console.log("result", [
            //   ...res.result?.effectiveLedger,
            //   ...res.result?.partyLedger,
            // ]);

            const x = [
              ...res.result?.effectiveLedger,
              ...res.result?.partyLedger,
            ];
            console.log("x...........", x);

            // x?.map((x: any) => {
            //   let bankAccount = partyLedgerOption.find(
            //     (x: any) => x.id === x?.id
            //   );
            //   console.log(
            //     "Enter Details",
            //     // bankAccountList.find((x: any) => x.id === x?.id)
            //     Utils.getNumber(Math.abs(x?.amount)),
            //     bankAccount?.balance
            //   );
            //   // console.log("x........", x.date);

            //   setSelectedBankBalance((x: any) => [
            //     ...x,
            //     {
            //       id: bankAccount.id,
            //       balance:
            //         Utils.getNumber(Math.abs(x?.amount)) + bankAccount?.balance,
            //     },
            //   ]);
            // });

            const partyLedger = await Promise.all(
              x.map(async (v: any, partyIndex: number) => {
                // console.log("ref", v?.ref);

                let bankAccount = partyLedgerOption.find(
                  (y: any) => y.id === v?.id
                );
                console.log(
                  "Enter Details",
                  // bankAccountList.find((x: any) => x.id === x?.id)
                  v.amount,
                  Math.abs(v?.amount),
                  bankAccount?.balance,
                  Math.abs(bankAccount?.balance)
                );
                // console.log("x........", x.date);

                setSelectedBankBalance((x: any) => [
                  ...x,
                  {
                    id: v.id,
                    balance: Math.abs(v?.amount) + (bankAccount?.balance ?? 0),
                  },
                ]);

                // party ref set only
                if (v?.partyOrTax === Party_Tax.party) {
                  setSelectPartyRef((prevState: any[]) => {
                    const updatedSelectedOptions: any[] = [...prevState];
                    updatedSelectedOptions[partyIndex] = [
                      ...(prevState[partyIndex] || []),
                    ];
                    const pushNewElement = [null, ...v.ref]; // if refType not specified 0 (like new Ref type not avaliable)
                    updatedSelectedOptions[partyIndex] =
                      v?.ref[0]?.refType === 0
                        ? v?.ref?.map((x: any) => x.parentRef)
                        : pushNewElement?.map((x: any) => x?.parentRef);
                    // updatedSelectedOptions[partyIndex] =
                    //   v?.ref?.length > 0 &&
                    //   v?.ref?.map((x: any) => x.parentRef);
                    return updatedSelectedOptions;
                  });

                  setPartyRef((prevDataArray) => {
                    const newArray = [...prevDataArray];
                    newArray[partyIndex] =
                      v?.ref?.length > 0 &&
                      v?.ref.map((x: any, refIndex: Number) => {
                        return {
                          ...x,
                          maxAmount: x?.value,
                        };
                      });
                    return newArray;
                  });

                  // party New Ref.
                  await VoucherServices.getReferencesByParty(
                    currentClient?.id!,
                    v?.id,
                    Voucher_Type.Journal
                  ).then((res) => {
                    const data = res.result?.map((x: any, i: number) => {
                      return {
                        name: res.result[i]?.refNo,
                        maxAmount: res.result[i]?.value,
                        dueAmount: res.result[i]?.dueAmount,
                        // id: res.result[i]?.id,
                        parentRef: res.result[i]?.id,
                        id: null,
                        refType: 1,
                      };
                    });

                    setPartyRef((prevDataArray) => {
                      const prevArray = prevDataArray[partyIndex];
                      // Filter out the common elements based on 'id'
                      const filteredData = data?.filter((newItem: any) => {
                        return !prevArray?.some(
                          (prevItem: any) =>
                            prevItem?.parentRef === newItem?.parentRef
                        );
                      });
                      const updatedArray = [...prevArray, ...filteredData];
                      // console.log("newArray", updatedArray);
                      const newArray = [...prevDataArray];
                      newArray[partyIndex] = updatedArray;
                      return newArray;
                    });
                  });
                } else {
                  setPartyRef((prevDataArray) => {
                    const newArray = [...prevDataArray];
                    newArray[partyIndex] = [];
                    return newArray;
                  });
                  setSelectPartyRef((prevState: any[]) => {
                    const updatedSelectedOptions: any[] = [...prevState];
                    updatedSelectedOptions[partyIndex] = [];
                    return updatedSelectedOptions;
                  });
                }

                setDisabledAmountInputs((prev) => {
                  const newDisabledInputs = [...prev];
                  newDisabledInputs[partyIndex] =
                    v.partyOrTax === Party_Tax.party ? true : false;
                  // console.log(newDisabledInputs);
                  return newDisabledInputs;
                });
                setSelectedPartyOption((prevState) => {
                  const updatedSelectedOptions = [...prevState];
                  updatedSelectedOptions[partyIndex] = v?.id;
                  return updatedSelectedOptions;
                });

                return {
                  ...v,
                  // ref:
                  // v?.ref[0]?.refType === 0
                  //   ? [...v?.ref, {}]
                  //   : [
                  //       { refType: 0, name: res?.result?.number },
                  //       ...v?.ref,
                  //       {},
                  //     ],
                  ref:
                    v?.ref?.length > 0
                      ? v?.ref[0]?.refType === 0
                        ? [
                            ...v?.ref?.map((x: any) => {
                              return {
                                ...x,
                                maxAmount: Utils.getNumber(x?.value),
                                value: x?.value,
                                dueAmount: x?.dueAmount,
                              };
                            }),
                            {},
                          ]
                        : [
                            { refType: 0, name: res?.result?.number },
                            ...v?.ref?.map((x: any) => {
                              return {
                                ...x,
                                maxAmount: Utils.getNumber(x?.value),
                                value: x?.value,
                                dueAmount: x?.dueAmount,
                              };
                            }),
                            {},
                          ]
                      : [],
                  debit: v?.isDebitNature ? Math.abs(v?.amount) : "0.00",
                  credit: !v?.isDebitNature ? Math.abs(v?.amount) : "0.00",
                  id: {
                    value: v?.id,
                    label: v?.ledgerName,
                    title: v?.partyOrTax,
                  },
                };
              })
            );

            console.log("partyLedger", partyLedger[0].ref);
            if (partyLedger) {
              form.setFieldsValue({
                ...res.result,
                partyLedger: [...partyLedger, { debit: 0.0, credit: 0.0 }],
                date: dayjs(res.result?.date),
              });
            }
            setEditDate(res.result?.date);
            setDebitSubTotal(res.result?.amountSummary?.totalInvoiceAmount);
            setCreditSubTotal(res.result?.amountSummary?.totalInvoiceAmount);

            setAccountIsParty(res.result?.effectiveLedger[0]?.partyOrTax);
            setAllUpdatePartyData([...partyLedger]);

            setLoading(false);
          }
        } catch (err: any) {
          openNotification("error", err.message);
          console.error("error..", err.message);
          setLoading(false);
        }
      };

      editData(journalId! ?? draftId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [journalId, partyLedgerOption, draftId]);

  const handleLedgerNetAmount = (index: number) => {
    // console.log(
    //   "false.............",
    //   form.getFieldValue(["partyLedger", index])?.id?.title
    // );
    let partyLedgerlist = form.getFieldValue(["partyLedger"]);
    const currentPartyLedgerIndexData = partyLedgerlist[index];

    // console.log("currentPartyLedgerIndexData", currentPartyLedgerIndexData);

    form.setFieldValue(["partyLedger", index], {
      ...currentPartyLedgerIndexData,
      amount: currentPartyLedgerIndexData?.ref.reduce(
        (acc: number, curr: any) => {
          return (acc += Utils.getNumber(curr?.value));
        },
        0
      ),
    });

    handleAllLedgerValue();

    return 0;
  };

  const handleAllLedgerValue = () => {
    let partyLedgerlist = form.getFieldValue(["partyLedger"]);
    // console.log("partyLedgerlist", partyLedgerlist);

    const x = partyLedgerlist.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.amount));
    }, 0);
    // console.log("ccccccccccccccc.c.c.c.c.", x);
    setTotalPaymentIn(x);
  };

  const onChangeSetPartyRef = async (
    partyIndex: number,
    refIndex: number,
    partyRefId: string
  ) => {
    console.log("ref.....onChangeSetPartyRef.", selectPartyRef);
    const x = partyRef[partyIndex].find((x: any) => x.parentRef === partyRefId);
    // console.log("data........", x);

    if (journalId) {
      form.setFieldValue(["partyLedger", partyIndex, "ref", refIndex], {
        ...x,
        value: x.value ?? 0.0,
        dueAmount: x?.dueAmount,
      });
    } else {
      const partyIndexAmount =
        Utils.getNumber(
          form.getFieldValue(["partyLedger", partyIndex, "debit"])
        ) > 0
          ? Utils.getNumber(
              form.getFieldValue(["partyLedger", partyIndex, "debit"])
            )
          : Utils.getNumber(
              form.getFieldValue(["partyLedger", partyIndex, "credit"])
            );
      const remainingRefAmount = Utils.getNumber(
        form
          .getFieldValue(["partyLedger", partyIndex, "ref"])
          .slice(1)
          .filter((_: any, idx: number) => idx !== refIndex)
          .reduce((acc: number, curr: any) => {
            return (acc += Utils.getNumber(curr?.value));
          }, 0)
      );
      let dueAmount;
      if (partyIndexAmount - (remainingRefAmount + x.dueAmount) < 0) {
        dueAmount = partyIndexAmount - remainingRefAmount;
      } else {
        dueAmount = x.dueAmount;
      }
      console.log(
        "partyIndexAmount",
        partyIndexAmount,
        "remainingRefAmount",
        remainingRefAmount
      );

      form.setFieldValue(["partyLedger", partyIndex, "ref", refIndex], {
        ...x,
        value: dueAmount,
      });
    }

    setSelectPartyRef((prevState: any[]) => {
      const updatedSelectedOptions: any[] = [...prevState];
      console.log("setSelectPartyRef.....", updatedSelectedOptions);
      updatedSelectedOptions[partyIndex] = [...(prevState[partyIndex] || [])];
      updatedSelectedOptions[partyIndex][refIndex] = partyRefId;
      return updatedSelectedOptions;
    });

    onChangeRefAmountSet(x.dueAmount, partyIndex, false);
  };

  const onChangeRefAmountSet = (
    value: string,
    partyIndex: number,
    isParty: boolean,
    refIndex?: number
  ) => {
    console.log("ref..........", selectPartyRef, partyIndex, refIndex);
    if (refIndex) {
      setSelectPartyRef((prevDataArray) => {
        const newArray = [...prevDataArray];
        newArray[partyIndex] = [...(prevDataArray[partyIndex] || [])];
        newArray[partyIndex].splice(refIndex, 1);
        return newArray;
      });
    }

    if (isParty) {
      disabledAmountInputs[partyIndex] &&
        form.setFieldValue(
          ["partyLedger", partyIndex, "ref", 0, "value"],
          Utils.getNumber(value) -
            Utils.getNumber(
              form
                .getFieldValue(["partyLedger", partyIndex, "ref"])
                .slice(1)
                .reduce((acc: number, curr: any) => {
                  return (acc += Utils.getNumber(curr?.value));
                }, 0)
            )
        );
    } else {
      disabledAmountInputs[partyIndex] &&
        form.setFieldValue(
          ["partyLedger", partyIndex, "ref", 0, "value"],
          Utils.getNumber(
            Utils.getNumber(
              form.getFieldValue(["partyLedger", partyIndex, "debit"])
            ) > 0
              ? form.getFieldValue(["partyLedger", partyIndex, "debit"])
              : form.getFieldValue(["partyLedger", partyIndex, "credit"])
          ) -
            Utils.getNumber(
              form
                .getFieldValue(["partyLedger", partyIndex, "ref"])
                .slice(1)
                .reduce((acc: number, curr: any) => {
                  return (acc += Utils.getNumber(curr?.value));
                }, 0)
            )
        );
    }
  };

  const handleTotalChange = (itemList: any) => {
    // Calculate the total credit and update state
    const TotalCredit = itemList.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.credit));
    }, 0);
    setCreditSubTotal(TotalCredit);

    // Calculate the total credit and update state
    const TotalDebit = itemList.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.debit));
    }, 0);
    setDebitSubTotal(TotalDebit);
  };

  const onCreditBlur = (val: string, index: number) => {
    const itemList = form.getFieldValue(["partyLedger"]);
    const updatedItems = [...itemList];
    console.log("val", val);
    if (Utils.getNumber(val) !== 0) {
      updatedItems[index].debit = Utils.getFormattedNumber(0);
      updatedItems[index].credit = Utils.getFormattedNumber(
        Utils.getNumber(val)
      );
    } else
      updatedItems[index].credit = Utils.getFormattedNumber(
        Utils.getNumber(val)
      );
    form.setFieldValue("partyLedger", updatedItems);
    handleTotalChange(updatedItems);
  };

  const onDebitBlur = (val: string, index: number) => {
    const itemList = form.getFieldValue(["partyLedger"]);
    const updatedItems = [...itemList];
    console.log("val", val);
    if (Utils.getNumber(val) !== 0) {
      updatedItems[index].credit = Utils.getFormattedNumber(0);
      updatedItems[index].debit = Utils.getFormattedNumber(
        Utils.getNumber(val)
      );
    } else
      updatedItems[index].debit = Utils.getFormattedNumber(
        Utils.getNumber(val)
      );
    form.setFieldValue("partyLedger", updatedItems);
    handleTotalChange(updatedItems);
  };

  const debitRefs: any = useRef([]);
  const creditRefs: any = useRef([]);

  const updateTotals = () => {
    const currentFields = form.getFieldValue("partyLedger") || [];
    const nonNullFields = currentFields.filter(
      (item: any) => item !== null && item !== undefined
    );

    const totalDebit = nonNullFields.reduce(
      (sum: number, item: any) => sum + Utils.getNumber(item.debit),
      0
    );

    const totalCredit = nonNullFields.reduce(
      (sum: number, item: any) => sum + Utils.getNumber(item.credit),
      0
    );

    const remainingDebit = totalCredit - totalDebit;
    const remainingCredit = totalDebit - totalCredit;
    const lastIndex = currentFields.length - 2;

    const updatedFields = [...currentFields];

    if (updatedFields.length > 2) {
      if (remainingDebit > 0) {
        debitRefs.current[lastIndex].select();
        updatedFields[lastIndex] = {
          ...updatedFields[lastIndex],
          debit: remainingDebit,
          credit: "0.00",
        };
      } else if (remainingCredit > 0) {
        creditRefs.current[lastIndex].select();
        updatedFields[lastIndex] = {
          ...updatedFields[lastIndex],
          credit: remainingCredit,
          debit: "0.00",
        };
      } else {
        debitRefs.current[lastIndex].select();
        updatedFields[lastIndex] = {
          ...updatedFields[lastIndex],
          debit: "0.00",
          credit: "0.00",
        };
      }
      form.setFieldsValue({ partyLedger: updatedFields });
      handleTotalChange(updatedFields);
    } else {
      updatedFields[lastIndex] = {
        ...updatedFields[lastIndex],
        debit: "0.00",
        credit: "0.00",
      };
      form.setFieldsValue({ partyLedger: updatedFields });
      debitRefs.current[lastIndex].select();
    }
  };

  const focusOnvalue = (i: number) => {
    const currentFields = [...form.getFieldValue("partyLedger")];
    if (currentFields[i]?.debit > currentFields[i]?.credit) {
      debitRefs.current[i].select();
    } else if (currentFields[i]?.credit > currentFields[i]?.debit) {
      creditRefs.current[i].select();
    } else {
      debitRefs.current[i].select();
    }
  };
  const [addLedgerResponse, setAddLedgerResponse] = useState<any>(null);

  useEffect(() => {
    try {
      if (addLedgerResponse) {
        const exists = partyLedgerOption.some(
          (option: any) => option.id === addLedgerResponse.id
        );
        console.log("newAdd", addLedgerResponse, exists);

        if (exists) {
          let partyLedger = [...(form?.getFieldValue("partyLedger") || [])];
          partyLedger.pop();
          partyLedger.push({
            credit: 0.0,
            debit: 0.0,
            amount: 0,
            id: {
              label: addLedgerResponse?.ledgerName,
              value: addLedgerResponse.id,
              key: addLedgerResponse.id,
              title: addLedgerResponse.partyOrTax,
            },
          });
          partyLedger.push({
            credit: "0.00",
            debit: "0.00",
            amount: "0.00",
            id: {},
          });
          const index = partyLedger.findIndex(
            (ledgerArray) => ledgerArray?.id?.value === addLedgerResponse.id
          );
          form.setFieldValue("partyLedger", partyLedger);
          updateTotals();
          partySelect(
            addLedgerResponse.id,
            index,
            addLedgerResponse.ledgerName
          );
          onClickSelectPartyLedger(
            addLedgerResponse.id,
            index,
            addLedgerResponse.partyOrTax
          );
          setAddLedgerResponse(null);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partyLedgerOption]);

  console.log("dataa", form.getFieldValue(["partyLedger"]));
  return (
    <>
      <Card
        // size="small"
        // className="TableCard"
        style={{
          boxShadow: "0px 0px 10px 0px #96A1C84D",
          marginTop: 7,
          paddingTop: "5px",
        }}
        title={
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col className="fs-20 fw-200 themeColor">
              {settingControl.title}
            </Col>
            <Col>
              <img
                alt="settingslogo"
                src={settingslogo}
                style={{
                  paddingTop: "3px",
                  marginLeft: "10px",
                }}
                onClick={() =>
                  setOpenModel({
                    type: "settingCustomizationDrawer",
                    param: {},
                  })
                }
              />
            </Col>
          </Row>
        }
      >
        <Spin indicator={antIcon} spinning={loading}>
          <Form
            form={form}
            name="paymentIn"
            autoComplete="off"
            initialValues={{
              partyLedger: [{ amount: "0.00", credit: "0.00", debit: "0.00" }],
              date: dayjs(),
            }}
          >
            {/* <Row justify="space-between">
              <Col span={11}>
                <Row gutter={[14, 10]}>
                  <Col span={7}>
                    <span className=" fw-500">Date</span>
                    <Form.Item
                      name="date"
                      className="m-0"
                      rules={[
                        {
                          required: true,
                          message: "Select Date",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format="DD/MM/YYYY"
                        onChange={(val) => {
                          setDateFiled(dayjs(val).toISOString());
                        }}
                        // disabledDate={(current) =>
                        //   current && current > dayjs().endOf("day")
                        // }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <span className=" fw-500">Journal No.</span>
                    <Form.Item
                      name="number"
                      className="m-0"
                      rules={[
                        {
                          required: true,
                          message: "Please enter No.",
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row> */}

            <Row gutter={10}>
              <Col xl={12} lg={10} sm={24}>
                <Row gutter={10}>
                  <Col sm={12} xs={24}>
                    <span className=" fw-500">Date</span>
                    <Form.Item
                      name="date"
                      className="m-0"
                      rules={[
                        {
                          required: true,
                          message: "Select date",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format="DD/MM/YYYY"
                        autoFocus
                        onChange={(val) => {
                          setDateFiled(dayjs(val).toISOString());
                        }}
                        // disabledDate={(current) =>
                        //   current && current > dayjs().endOf("day")
                        // }
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12} xs={24}>
                    <span className=" fw-500">Journal No.</span>
                    <Form.Item
                      name="number"
                      className="m-0"
                      rules={[
                        {
                          required: true,
                          message: "Please enter No.",
                        },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div style={{ overflowY: "auto" }}>
              <table
                className="AddPageTabelDesign"
                style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
              >
                <thead>
                  <tr>
                    <th style={{ width: "5%" }} className="textCenter">
                      S.No.
                    </th>

                    <th
                      style={{
                        width: "55%",
                        borderLeft: "1px solid rgb(240, 245, 255)",
                      }}
                      className="textStart ps-6"
                    >
                      Name
                    </th>

                    <th style={{ width: "20%" }} className="textStart ps-6">
                      Debit
                    </th>

                    <th
                      colSpan={2}
                      style={{ width: "20%" }}
                      className="textStart ps-6"
                    >
                      Credit
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <Form.List name="partyLedger">
                    {(fields, { add, remove, move }) => {
                      return (
                        <>
                          {confirmation && (
                            <ConfirmationModal
                              open={confirmation}
                              // "${form.getFieldValue(["items"])}"
                              text={`Do you really want to delete this item "${form.getFieldValue(
                                [
                                  "partyLedger",
                                  deleteItemList?.name,
                                  "id",
                                  "label",
                                ]
                              )}" ?`}
                              onNo={() => setConfirmation(false)}
                              onYes={() => {
                                onDebitBlur("0.0", deleteItemList.index);
                                onCreditBlur("0.0", deleteItemList.index);
                                remove(deleteItemList.name);
                                handleAllLedgerValue();
                                setItemLength(fields.length - 2);
                                setConfirmation(false);
                                setSelectedBankBalance((x: any) => {
                                  const allBankAmount = [...x];
                                  allBankAmount.splice(deleteItemList.name, 1);
                                  return allBankAmount;
                                });
                                setPartyRef((prev) => {
                                  const allPrevArray = [...prev];
                                  // console.log(
                                  //   "allPrevArray----------",
                                  //   allPrevArray,
                                  //   deleteItemList.index
                                  // );
                                  allPrevArray.splice(deleteItemList.index, 1);
                                  return allPrevArray;
                                });

                                setSelectPartyRef((prev: any[]) => {
                                  const allPrevReference = [...prev];
                                  allPrevReference.splice(
                                    deleteItemList.index,
                                    1
                                  );
                                  return allPrevReference;
                                });

                                setDisabledAmountInputs((prev) => {
                                  const newDisabledInputs = [...prev];
                                  // console.log(
                                  //   "newDisabledInputs",
                                  //   newDisabledInputs
                                  // );
                                  newDisabledInputs.splice(
                                    deleteItemList.index,
                                    1
                                  );
                                  return newDisabledInputs;
                                });

                                setSelectedPartyOption((prev) => {
                                  const newDisabledInputs = [...prev];
                                  newDisabledInputs.splice(
                                    deleteItemList.index,
                                    1
                                  );
                                  return newDisabledInputs;
                                });
                              }}
                            />
                          )}
                          {fields.map(({ key, name, ...restField }, index1) => {
                            const isInputDisabled =
                              disabledAmountInputs[index1];
                            return (
                              <>
                                <tr key={key}>
                                  <td>
                                    {fields.length !== index1 + 1 && (
                                      <div className="ps-10">{index1 + 1}.</div>
                                    )}
                                  </td>

                                  <td>
                                    <Form.Item
                                      name={[name, "id"]}
                                      className="m-0"
                                      {...restField}
                                    >
                                      <Select
                                        labelInValue
                                        showSearch
                                        bordered={false}
                                        loading={itmDropDownLoadinf}
                                        variant="filled"
                                        placeholder="Search to items"
                                        className="variantFilledSelect"
                                        optionFilterProp="children"
                                        popupMatchSelectWidth={false}
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#FFFFFF",
                                        }}
                                        dropdownStyle={{ width: "250px" }}
                                        placement="bottomRight"
                                        // onSearch={(value: string) =>
                                        //   setSearchItem(value)
                                        // }

                                        options={partyLedgerOption
                                          .filter(
                                            (option) =>
                                              !selectedPartyOption.includes(
                                                option.id
                                              )
                                          )
                                          .map((option: any) => ({
                                            value: option?.id,
                                            label: option?.ledgerName.concat(
                                              option?.aliasName
                                                ? `${
                                                    " (" +
                                                    option?.aliasName +
                                                    ")"
                                                  }`
                                                : ""
                                            ),
                                            title: option?.partyOrTax,
                                          }))}
                                        filterOption={(input, option) =>
                                          (option?.label?.toString() ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        onChange={(e: any) => {
                                          // console.log(
                                          //   "form",
                                          //   form.getFieldsValue(),
                                          //   partyLedgerOption.filter(
                                          //     (x: any) =>
                                          //       x.partyOrTax ===
                                          //       Party_Tax.BanknCash
                                          //   )
                                          // );

                                          if (fields.length === index1 + 1) {
                                            add();
                                            updateTotals();
                                          } else {
                                            focusOnvalue(index1);
                                          }
                                          partySelect(e.value, index1, e.title);
                                          onClickSelectPartyLedger(
                                            e.value,
                                            index1,
                                            e.title
                                          );
                                        }}
                                        dropdownRender={(customerOptions) => (
                                          <>
                                            {customerOptions}
                                            <Divider
                                              style={{ margin: "5px 0px" }}
                                            />
                                            <Button
                                              style={{ width: "100%" }}
                                              icon={<PlusOutlined />}
                                              type="link"
                                              onClick={() => {
                                                setOpenModel({
                                                  type: "addLedger",
                                                  param: {
                                                    open: true,
                                                  },
                                                });
                                                // setEditParty({ id: "" });
                                              }}
                                            >
                                              New Ledger
                                            </Button>
                                          </>
                                        )}
                                      />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      className="m-0"
                                      {...restField}
                                      name={[name, "debit"]}
                                      // rules={[
                                      //   ({ getFieldValue }) => ({
                                      //     validator(_, value) {
                                      //       if (
                                      //         fields.length !== index1 + 1 &&
                                      //         isInputDisabled
                                      //       ) {
                                      //         if (
                                      //           Utils.getNumber(
                                      //             getFieldValue([
                                      //               "partyLedger",
                                      //               index1,
                                      //               "amount",
                                      //             ])
                                      //           ) !==
                                      //           Utils.getNumber(
                                      //             getFieldValue([
                                      //               "partyLedger",
                                      //               index1,
                                      //               "ref",
                                      //             ]).reduce(
                                      //               (acc: number, curr: any) => {
                                      //                 return (acc +=
                                      //                   Utils.getNumber(
                                      //                     curr?.value
                                      //                   ));
                                      //               },
                                      //               0
                                      //             )
                                      //           )
                                      //         ) {
                                      //           return Promise.reject(
                                      //             new Error(
                                      //               "Reference amount do not match!"
                                      //             )
                                      //           );
                                      //         } else {
                                      //           return Promise.resolve();
                                      //         }
                                      //       } else {
                                      //         return Promise.resolve();
                                      //       }
                                      //     },
                                      //   }),
                                      // ]}
                                    >
                                      <Input
                                        // disabled={isInputDisabled}
                                        // prefix="&#8377;"
                                        bordered={false}
                                        variant="filled"
                                        defaultValue="0.00"
                                        ref={(el) =>
                                          (debitRefs.current[index1] = el)
                                        }
                                        step={0.0}
                                        style={{ backgroundColor: "#FFFFFF" }}
                                        onBlur={(e) => {
                                          // handleAllLedgerValue();

                                          onDebitBlur(e.target.value, index1);
                                          Number(e.target.value) > 0 &&
                                            onChangeRefAmountSet(
                                              e.target.value,
                                              index1,
                                              true
                                            );

                                          // handelAmountChange(
                                          //   Utils.getNumber(e.target.value),
                                          //   index
                                          // );
                                          // getAllocatedAmount(Utils.getNumber(e.target.value));
                                        }}
                                        onChange={(e) => {
                                          onChangeRefAmountSet(
                                            e.target.value,
                                            index1,
                                            true
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                  </td>

                                  <td>
                                    <Form.Item
                                      className="m-0"
                                      {...restField}
                                      name={[name, "credit"]}
                                      // rules={[
                                      //   ({ getFieldValue }) => ({
                                      //     validator(_, value) {
                                      //       if (
                                      //         fields.length !== index1 + 1 &&
                                      //         isInputDisabled
                                      //       ) {
                                      //         if (
                                      //           Utils.getNumber(
                                      //             getFieldValue([
                                      //               "partyLedger",
                                      //               index1,
                                      //               "amount",
                                      //             ])
                                      //           ) !==
                                      //           Utils.getNumber(
                                      //             getFieldValue([
                                      //               "partyLedger",
                                      //               index1,
                                      //               "ref",
                                      //             ]).reduce(
                                      //               (acc: number, curr: any) => {
                                      //                 return (acc +=
                                      //                   Utils.getNumber(
                                      //                     curr?.value
                                      //                   ));
                                      //               },
                                      //               0
                                      //             )
                                      //           )
                                      //         ) {
                                      //           return Promise.reject(
                                      //             new Error(
                                      //               "Reference amount do not match!"
                                      //             )
                                      //           );
                                      //         } else {
                                      //           return Promise.resolve();
                                      //         }
                                      //       } else {
                                      //         return Promise.resolve();
                                      //       }
                                      //     },
                                      //   }),
                                      // ]}
                                    >
                                      <InputNumber
                                        defaultValue="0.00"
                                        // disabled={isInputDisabled}
                                        // prefix="&#8377;"
                                        ref={(el) =>
                                          (creditRefs.current[index1] = el)
                                        }
                                        bordered={false}
                                        variant="filled"
                                        step={0.01}
                                        style={{
                                          width: "100%",
                                          backgroundColor: "#FFFFFF",
                                        }}
                                        controls={false}
                                        onBlur={(e) => {
                                          // handleAllLedgerValue();
                                          onCreditBlur(e.target.value, index1);
                                          // handelAmountChange(
                                          //   Utils.getNumber(e.target.value),
                                          //   index
                                          // );
                                          // getAllocatedAmount(Utils.getNumber(e.target.value));
                                          Number(e.target.value) > 0 &&
                                            onChangeRefAmountSet(
                                              e.target.value,
                                              index1,
                                              true
                                            );
                                        }}
                                        onChange={(e: any) => {
                                          onChangeRefAmountSet(e, index1, true);
                                        }}
                                      />
                                    </Form.Item>
                                  </td>

                                  <td
                                    style={{
                                      width: 24,
                                      verticalAlign: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    {fields.length !== index1 + 1 && (
                                      <img
                                        className="cursor mx-1"
                                        onClick={() => {
                                          setDeleteItemList({
                                            name: name,
                                            index: index1,
                                          });
                                          setConfirmation(true);
                                        }}
                                        src={deleteImg}
                                        alt="Delete"
                                      />
                                    )}
                                  </td>
                                </tr>

                                {/* Nest Form.List */}
                                <tr
                                  style={{
                                    display: !isInputDisabled
                                      ? "none"
                                      : "contents",
                                  }}
                                >
                                  <td></td>
                                  <td>
                                    <table style={{ width: "100%" }}>
                                      <tbody>
                                        <Form.Item className="m-0" name="ref">
                                          <Form.List name={[name, "ref"]}>
                                            {(
                                              subFields,
                                              { add, remove, move }
                                            ) => {
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                  }}
                                                >
                                                  {subFields.map(
                                                    (subField, index) => {
                                                      const isInputDisabled1 =
                                                        subFields.length !==
                                                        index + 1
                                                          ? true
                                                          : false;
                                                      return (
                                                        <tr
                                                          // style={{
                                                          //   paddingLeft:
                                                          //     "105px",
                                                          // }}
                                                          key={index}
                                                        >
                                                          <td>
                                                            {index === 0 ? (
                                                              <Tooltip
                                                                title="New Reference"
                                                                placement="left"
                                                                color="#389e0d"
                                                              >
                                                                <Avatar
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    verticalAlign:
                                                                      "middle",
                                                                    color:
                                                                      "#389e0d",
                                                                    backgroundColor:
                                                                      "#f6ffed",
                                                                    borderColor:
                                                                      "#b7eb8f",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                  size="small"
                                                                >
                                                                  N
                                                                </Avatar>
                                                              </Tooltip>
                                                            ) : (
                                                              <Tooltip
                                                                title="Old Reference"
                                                                placement="left"
                                                                color="#08979C"
                                                              >
                                                                <Avatar
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    verticalAlign:
                                                                      "middle",
                                                                    color:
                                                                      "#08979C",
                                                                    backgroundColor:
                                                                      "#E6FFFB",
                                                                    borderColor:
                                                                      "rgb(127 231 235)",
                                                                    fontWeight:
                                                                      "bold",
                                                                  }}
                                                                  size="small"
                                                                >
                                                                  A
                                                                </Avatar>
                                                              </Tooltip>
                                                            )}
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "30%",
                                                            }}
                                                          >
                                                            <Form.Item
                                                              className="m-0"
                                                              name={[
                                                                subField.name,
                                                                "name",
                                                              ]}
                                                              rules={[
                                                                {
                                                                  required:
                                                                    index !==
                                                                      0 &&
                                                                    subFields.length !==
                                                                      index +
                                                                        1 &&
                                                                    form.getFieldValue(
                                                                      [
                                                                        "partyLedger",
                                                                        index1,
                                                                        "ref",
                                                                        index,
                                                                        "value",
                                                                      ]
                                                                    ) > 0
                                                                      ? true
                                                                      : false,
                                                                  message:
                                                                    "Select an invoice",
                                                                },
                                                              ]}
                                                            >
                                                              <Select
                                                                disabled={
                                                                  index === 0 &&
                                                                  true
                                                                }
                                                                bordered={false}
                                                                // variant="filled"
                                                                style={{
                                                                  backgroundColor:
                                                                    "#FFFFFF",
                                                                }}
                                                                variant="filled"
                                                                className="variantFilledSelect"
                                                                labelInValue
                                                                showSearch
                                                                optionFilterProp="children"
                                                                placeholder="Select account"
                                                                filterOption={(
                                                                  input,
                                                                  option
                                                                ) =>
                                                                  (
                                                                    option?.label?.toString() ??
                                                                    ""
                                                                  )
                                                                    .toLowerCase()
                                                                    .includes(
                                                                      input.toLowerCase()
                                                                    )
                                                                }
                                                                // showArrow={false}
                                                                notFoundContent={
                                                                  null
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  if (
                                                                    subFields.length ===
                                                                    index + 1
                                                                  )
                                                                    add();

                                                                  onChangeSetPartyRef(
                                                                    index1,
                                                                    index,
                                                                    e?.value
                                                                  );
                                                                }}
                                                                options={partyRef[
                                                                  index1
                                                                ]
                                                                  ?.filter(
                                                                    (
                                                                      option: any
                                                                    ) =>
                                                                      !selectPartyRef[
                                                                        index1
                                                                      ]?.includes(
                                                                        option.parentRef
                                                                      )
                                                                  )
                                                                  .map(
                                                                    (
                                                                      value: any
                                                                    ) => ({
                                                                      value:
                                                                        value?.parentRef,
                                                                      label:
                                                                        value?.name,
                                                                      // title: value?.balance,
                                                                    })
                                                                  )}
                                                              />
                                                            </Form.Item>
                                                          </td>
                                                          <td
                                                            style={{
                                                              width: "20%",
                                                            }}
                                                          >
                                                            <Form.Item
                                                              className="m-0"
                                                              name={[
                                                                subField.name,
                                                                "dueAmount",
                                                              ]}
                                                            >
                                                              <InputNumber
                                                                bordered={false}
                                                                // variant="filled"
                                                                step={0.01}
                                                                disabled
                                                                controls={false}
                                                                style={{
                                                                  width: "100%",
                                                                  backgroundColor:
                                                                    "#FFFFFF",
                                                                }}
                                                                placeholder="Amount"
                                                              />
                                                            </Form.Item>
                                                          </td>

                                                          <td
                                                            style={{
                                                              width: "40%",
                                                            }}
                                                          >
                                                            <Form.Item
                                                              className="m-0"
                                                              name={[
                                                                subField.name,
                                                                "value",
                                                              ]}
                                                              rules={[
                                                                {
                                                                  required:
                                                                    index !==
                                                                      0 &&
                                                                    form.getFieldValue(
                                                                      [
                                                                        "partyLedger",
                                                                        index1,
                                                                        "ref",
                                                                        index,
                                                                        "name",
                                                                      ]
                                                                    )
                                                                      ? true
                                                                      : false,
                                                                  message:
                                                                    "Enter amount",
                                                                },
                                                                {
                                                                  validator: (
                                                                    _,
                                                                    value
                                                                  ) => {
                                                                    if (
                                                                      value < 0
                                                                    ) {
                                                                      return Promise.reject(
                                                                        "Number must be non-negative"
                                                                      );
                                                                    }
                                                                    return Promise.resolve();
                                                                  },
                                                                },
                                                              ]}
                                                            >
                                                              <InputNumber
                                                                min={0}
                                                                step={0.01}
                                                                max={
                                                                  index !== 0
                                                                    ? journalId
                                                                      ? Math.round(
                                                                          (form.getFieldValue(
                                                                            [
                                                                              "partyLedger",
                                                                              index1,
                                                                              "ref",
                                                                              index,
                                                                              "dueAmount",
                                                                            ]
                                                                          ) +
                                                                            form.getFieldValue(
                                                                              [
                                                                                "partyLedger",
                                                                                index1,
                                                                                "ref",
                                                                                index,
                                                                                "maxAmount",
                                                                              ]
                                                                            )) *
                                                                            100
                                                                        ) / 100
                                                                      : Math.round(
                                                                          form.getFieldValue(
                                                                            [
                                                                              "partyLedger",
                                                                              index1,
                                                                              "ref",
                                                                              index,
                                                                              "dueAmount",
                                                                            ]
                                                                          ) *
                                                                            100
                                                                        ) / 100
                                                                    : Infinity
                                                                }
                                                                controls={false}
                                                                bordered={false}
                                                                variant="filled"
                                                                style={{
                                                                  width: "100%",
                                                                  backgroundColor:
                                                                    "#FFFFFF",
                                                                }}
                                                                placeholder="Amount"
                                                                onBlur={() => {
                                                                  if (
                                                                    subFields.length ===
                                                                      index +
                                                                        1 &&
                                                                    form.getFieldValue(
                                                                      [
                                                                        "partyLedger",
                                                                        index1,
                                                                        "ref",
                                                                        index,
                                                                        "value",
                                                                      ]
                                                                    ) > 0
                                                                  )
                                                                    add();

                                                                  // console.log(
                                                                  //   "onChage",
                                                                  //   index,
                                                                  //   form.getFieldValue(
                                                                  //     [
                                                                  //       "partyLedger",
                                                                  //       index1,
                                                                  //       "ref",
                                                                  //       index,
                                                                  //       "name",
                                                                  //     ]
                                                                  //   )
                                                                  // )
                                                                }}
                                                                onChange={(
                                                                  e: any
                                                                ) => {
                                                                  onChangeRefAmountSet(
                                                                    e,
                                                                    index1,
                                                                    false
                                                                  );

                                                                  if (
                                                                    Utils.getNumber(
                                                                      form.getFieldValue(
                                                                        [
                                                                          "partyLedger",
                                                                          index1,
                                                                          "amount",
                                                                        ]
                                                                      )
                                                                    ) !==
                                                                    Utils.getNumber(
                                                                      form
                                                                        .getFieldValue(
                                                                          [
                                                                            "partyLedger",
                                                                            index1,
                                                                            "ref",
                                                                          ]
                                                                        )
                                                                        .reduce(
                                                                          (
                                                                            acc: number,
                                                                            curr: any
                                                                          ) => {
                                                                            return (acc +=
                                                                              Utils.getNumber(
                                                                                curr?.value
                                                                              ));
                                                                          },
                                                                          0
                                                                        )
                                                                    )
                                                                  ) {
                                                                    console.log(
                                                                      "error"
                                                                    );
                                                                    form.validateFields(
                                                                      [
                                                                        "partyLedger",
                                                                        index1,
                                                                        "amount",
                                                                      ]
                                                                    );
                                                                    return Promise.resolve();
                                                                  }
                                                                }}
                                                                // onBlur={() =>
                                                                //   handleLedgerNetAmount(
                                                                //     index1
                                                                //   )subField
                                                                // }
                                                              />
                                                            </Form.Item>
                                                          </td>
                                                          <td
                                                          // style={{ width: "20%" }}
                                                          >
                                                            <td
                                                              style={{
                                                                width: 25,
                                                                border: "none",
                                                              }}
                                                            >
                                                              {index !== 0 &&
                                                                subFields.length !==
                                                                  index + 1 && (
                                                                  <CloseOutlined
                                                                    style={{
                                                                      color:
                                                                        "red",
                                                                      paddingLeft:
                                                                        "6px",
                                                                    }}
                                                                    onClick={() => {
                                                                      remove(
                                                                        subField.name
                                                                      );

                                                                      onChangeRefAmountSet(
                                                                        "0",
                                                                        index1,
                                                                        false,
                                                                        index
                                                                      );
                                                                    }}
                                                                  />
                                                                )}
                                                            </td>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              );
                                            }}
                                          </Form.List>
                                        </Form.Item>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </>
                            );
                          })}
                        </>
                      );
                    }}
                  </Form.List>
                </tbody>
                {/* {itemLength > 0 && (
                <tfoot>
                  <tr>
                    <th colSpan={5} className="textEnd pe-14">
                      Total
                    </th>
                    <th colSpan={2} className="textEnd pe-14">
                      {footerTotal.qty}
                    </th>
                    <th colSpan={2} className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.unitPrice)}
                    </th>
                    <th colSpan={2} className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.discount)}
                    </th>
                    {isGSTEnabled && (
                      <th colSpan={2} className="textEnd pe-12">
                        ₹ {Utils.getFormattedNumber(footerTotal.gst)}
                      </th>
                    )}
                    <th className="textEnd pe-12">
                      ₹ {Utils.getFormattedNumber(footerTotal.amount)}
                    </th>
                  </tr>
                </tfoot>
              )} */}
              </table>
            </div>

            <Row className="pt-10">
              <Col xl={6} lg={8} md={24} xs={24} className="pt-10">
                <Form.Item name="narration" className="m-0">
                  <TextArea placeholder="Write narration" rows={4} />
                </Form.Item>

                <Form.Item
                  name="attachments"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => {
                    return e.fileList;
                  }}
                  // className="m-0"
                  style={{ paddingTop: "10px", margin: "0px" }}
                >
                  <Upload
                    multiple
                    // accept={".png, .jpg, .jpeg, .pdf"}
                    // accept={".png, .jpg, .jpeg"}
                    beforeUpload={() => {
                      return false;
                    }}
                    onRemove={(e: any) => {
                      if ((draftId || journalId) && e?.id) {
                        setDeleteUploadId([...deleteUploadId, e?.id]);
                      }
                    }}
                    onPreview={(e: any) => {
                      console.log("preview", e);
                      if ((draftId || journalId) && e?.path) {
                        setPreviewImage({
                          isBaseConvert: false,
                          path: e,
                        });
                      } else if (e.type?.split("/")[0] === "image") {
                        setPreviewImage({
                          isBaseConvert: true,
                          path: e,
                        });
                      }
                    }}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ backgroundColor: "ButtonFace" }}
                    >
                      Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col xl={10} xs={0}></Col>

              <Col xl={8} lg={24} md={24} xs={24}>
                <Row>
                  <Col lg={8} md={8} xs={8}>
                    <span>Total Amount</span>
                  </Col>
                  <Col lg={8} md={8} xs={8} className="textEnd">
                    <span
                      style={{
                        color: "rgb(11, 48, 142)",
                        fontWeight: "400",
                        fontSize: "18px",
                      }}
                    >
                      &#8377;
                      {Utils.getFormattedNumber(debitSubTotal)}
                    </span>
                  </Col>
                  <Col lg={8} md={8} xs={8} className="textEnd">
                    <span
                      style={{
                        color: "rgb(11, 48, 142)",
                        fontWeight: "400",
                        fontSize: "18px",
                      }}
                    >
                      &#8377;
                      {Utils.getFormattedNumber(creditSubTotal)}
                    </span>
                  </Col>
                </Row>

                <Divider
                  style={{
                    borderBottom: "2px solid rgb(203, 203, 203)",
                  }}
                  className="my-5"
                />

                <Row justify="end" className="mt-10">
                  <Col>
                    <Button
                      onClick={() => {
                        // navigate(
                        //   `/accounts/clients/${currentClient?.id}/journals`
                        // )
                        if (
                          (debitSubTotal > 0 || creditSubTotal > 0) &&
                          !journalId
                        ) {
                          save(false, true);
                        }
                        navigate(-1);
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="ps-14">
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => save()}
                        loading={saveButtonLoading}
                        disabled={
                          debitSubTotal === creditSubTotal &&
                          debitSubTotal !== 0 &&
                          debitSubTotal !== 0
                            ? false
                            : true
                        }
                      >
                        {journalId ? "Update" : "Save"}
                      </Button>
                    </Form.Item>
                  </Col>

                  {/* <Col className="ps-14">
                  <Form.Item>
                    <Button
                      // loading={saveNewLoading}
                      // onClick={() => {
                      //   addDeliveryChallan(true);
                      // }}
                      type="primary"
                    >
                      {journalId ? "Update & New" : "Save & New"}
                    </Button>
                  </Form.Item>
                </Col> */}
                </Row>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>

      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={openModel?.param.open}
          id={""}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() => setTrigger(!trigger)}
          setAddLedgerResponse={setAddLedgerResponse}
        />
      )}
      {previewImage.path && (
        <DocumentPreview
          open={true}
          onCancel={() =>
            setPreviewImage({
              path: "",
              isBaseConvert: false,
              // uploadType: "",
            })
          }
          documentDetails={previewImage}
        />
      )}
      {openModel.type === "settingCustomizationDrawer" && (
        <SettingCustomizationIsDrawer
          isDrawer={true}
          settingsSections={SettingsSections.Journal}
          settingLabelName={getVoucherTitle?.title!}
          onCancel={() => setOpenModel({ type: "", param: {} })}
          trigger={() => setSettingTigger(!settingTigger)}
          editId={journalId ?? draftId}
        />
      )}
    </>
  );
};

export default AddJournalNew;
