import { SaveOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Row, Select, Space } from "antd";
import React from "react";
import { NameComponent } from "../../Common/CAPComponents";

const AddClientContacts = ({ open, setOpen }: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const designationOption = [
    { value: 1, label: "Owner" },
    { value: 2, label: "Director" },
    { value: 3, label: "Partner" },
    { value: 4, label: "Accountant" },
    { value: 5, label: "Manager" },
    { value: 6, label: "Staff" },
    { value: 7, label: "Representative" },
    { value: 8, label: "Other" },
  ];

  return (
    <>
      <Modal
        open={open}
        title="Add Contact"
        // onCancel={handleCancel}
        width={1000}
        footer={null}
        maskClosable={false}
        closable={false}
      >
        <div>
          <Form
            form={form}
            style={{ paddingTop: 25 }}
            name="basic"
            initialValues={{ remember: true }}
          >
            <Row style={{ marginBottom: 20 }}>
              <Col span={12}>
                <Row>
                  <Col span={5} style={{ textAlign: "end", paddingTop: "5px" }}>
                    Designation
                  </Col>
                  <Col span={18}>
                    <Form.Item
                      style={{ padding: "0 18px", marginBottom: 15 }}
                      name="type"
                    >
                      <Select
                        // defaultValue={clt?.clientType}
                        options={designationOption}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={5} style={{ textAlign: "end", paddingTop: "5px" }}>
                    Full Name
                  </Col>
                  <Col span={18}>
                    <Form.Item
                      name="name"
                      style={{ padding: "0 18px", marginBottom: 15 }}
                    >
                      <NameComponent
                        // value={form.getFieldValue("name")}
                        value={""}
                        FormItemName={["name"]}
                        innerLabel={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={5} style={{ textAlign: "end", paddingTop: "5px" }}>
                    Phone
                  </Col>
                  <Col span={18}>
                    <Form.Item
                      style={{ padding: "0 18px", marginBottom: 15 }}
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Please input phone number",
                        },
                        { min: 10, message: "Phone No. must be 10 digits long" },
                      ]}
                    >
                      <Input
                        placeholder="Phone"
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={5} style={{ textAlign: "end", paddingTop: "5px" }}>
                    Mobile
                  </Col>
                  <Col span={18}>
                    <Form.Item
                      style={{ padding: "0 18px", marginBottom: 15 }}
                      name="mobile"
                      rules={[
                        {
                          required: true,
                          message: "Please input Mobile!",
                        },
                        { min: 10, message: "Mobile no. must be 10 digits" },
                      ]}
                    >
                      <Input
                        placeholder="Mobile"
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col span={5} style={{ textAlign: "end", paddingTop: "5px" }}>
                    Email
                  </Col>
                  <Col span={18}>
                    <Form.Item
                      style={{ padding: "0 18px", marginBottom: 15 }}
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input Email!",
                        },
                        {
                          pattern: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                          message: "Please enter a valid email",
                        },
                      ]}
                    >
                      <Input placeholder="Email ID" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Form.Item name="registeredAddress" style={{ marginBottom: 0 }}>
                  <Input.Group>
                    <Row>
                      <Col
                        span={6}
                        style={{ textAlign: "end", paddingTop: "5px" }}
                      >
                        Building
                      </Col>
                      <Col span={18}>
                        <Form.Item
                          style={{ padding: "0 18px", marginBottom: 15 }}
                          name={["registeredAddress", "building"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input address",
                            },
                          ]}
                        >
                          <Input placeholder="Building" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        span={6}
                        style={{ textAlign: "end", paddingTop: "5px" }}
                      >
                        City/Town
                      </Col>
                      <Col span={18}>
                        <Form.Item
                          style={{ padding: "0 18px", marginBottom: 15 }}
                          name={["registeredAddress", "city"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input city/town",
                            },
                          ]}
                        >
                          <Input placeholder="City/Town" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        span={6}
                        style={{ textAlign: "end", paddingTop: "5px" }}
                      >
                        Pincode
                      </Col>
                      <Col span={18}>
                        <Form.Item
                          style={{ padding: "0 18px", marginBottom: 15 }}
                          name={["registeredAddress", "pincode"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input Pincode",
                            },
                            {
                              pattern: /^\d{4,6}\b/g,
                              message: "Please input a valid Pincode",
                            },
                          ]}
                        >
                          <Input placeholder="Pincode" maxLength={6} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        span={6}
                        style={{ textAlign: "end", paddingTop: "5px" }}
                      >
                        District
                      </Col>
                      <Col span={18}>
                        <Form.Item
                          style={{ padding: "0 18px", marginBottom: 15 }}
                          name={["registeredAddress", "district"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input district",
                            },
                          ]}
                        >
                          <Input placeholder="District" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col
                        span={6}
                        style={{ textAlign: "end", paddingTop: "5px" }}
                      >
                        State
                      </Col>
                      <Col span={18}>
                        <Form.Item
                          style={{ padding: "0 18px", marginBottom: 15 }}
                          name={["registeredAddress", "state"]}
                        >
                          <Select
                            showSearch
                            placeholder="Select State"
                            optionFilterProp="children"
                            // filterOption={(input, option) =>
                            //   (option?.label ?? "")
                            //     .toLowerCase()
                            //     .includes(input.toLowerCase())
                            // }
                            // options={statesOption.map((x: any) => ({
                            //   value: x.id,
                            //   label: x.name,
                            // }))}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Form.Item>
                {/* 
                <Row>
                  <Col span={6} style={{ textAlign: "end", paddingTop: "5px" }}>
                    PAN NO.
                  </Col>
                  <Col span={18}>
                    <Form.Item
                      style={{ padding: "0 18px", marginBottom: 15 }}
                      name="panNo"
                      rules={[
                        {
                          required: true,
                          message: "Please input PAN NO.!",
                        },
                        {
                          pattern: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
                          message: "Please enter a valid PAN Number",
                        },
                      ]}
                    >
                      <Input placeholder="PAN NO." />
                    </Form.Item>
                  </Col>
                </Row> */}

                <Row>
                  <Col span={6} style={{ textAlign: "end", paddingTop: "5px" }}>
                    Note
                  </Col>
                  <Col span={18}>
                    <Form.Item
                      style={{ padding: "0 18px", marginBottom: 15 }}
                      name="note"
                      rules={[
                        {
                          required: true,
                          message: "Please input note",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Note"
                        style={{ minHeight: 80 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="end">
              <Space>
                <Col>
                  <Button onClick={handleCancel}>Cancel</Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    // onClick={() => addClients()}
                    style={{ float: "right" }}
                  >
                    <SaveOutlined /> Save
                  </Button>
                </Col>
              </Space>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddClientContacts;
