import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  MenuProps,
  Row,
  Select,
  Tabs,
  Skeleton,
  Space,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import ReportsService from "../../Services/ReportsService";
import { useClient } from "../../Context/ClientContext";
import { dayjs } from "../../../Utilities/dayjs";
import {
  DownloadOutlined,
  ReloadOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { AccountShort, ISelectGroup } from "../../../Types/CommonTypes";
import { Utils } from "../../../Utilities/Utils";
import { ITrialBalanceSettings } from "../../Services/SettingService";
import { ReportSettings } from "../../Common/CAPComponents";
import { AccountDetailsList } from "./Ledger";
import { SyncOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import "../../Common/Dashboardlayout.css";
import { useTheme } from "../../Context/ThemeContext";

const TrialBalance = () => {
  const { RangePicker } = DatePicker;
  const { currentClient, companySettings } = useClient();
  const [tabData, setTabData] = useState<{ id: number; name: string }>();
  const [activeKey, setActiveKey] = useState("");
  const [isAccountGroup, setAccountGroup] = useState("none");
  const [changeTable, setChangeTable] = useState(false);
  const [moreTabs, setMoreTabs] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountsList, setAccountList] = useState<any[]>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [settings, setSettings] = React.useState<
    ITrialBalanceSettings | undefined
  >();
  const [filterDate, setFilterDate] = React.useState<any>({
    from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
    to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
  });

  type TargetKey = React.MouseEvent | React.KeyboardEvent | string;
  const remove = (targetKey: TargetKey) => {
    const newPanes = moreTabs.filter((pane: any) => pane.key !== targetKey);
    if (newPanes.length && targetKey === activeKey) {
      setActiveKey("Trial Balance");
    }
    setMoreTabs(newPanes);
  };

  useEffect(() => {
    setSettings(companySettings?.setting?.tbSettings);
  }, [companySettings, shouldRefresh]);

  useEffect(() => {
    ReportsService.getAccounts(currentClient?.id!).then((res) => {
      if (res?.result) {
        let acc: ISelectGroup[] = res?.result?.map((group: any) => ({
          label: `${group.typeName} (${group.groupName})`,
          options: group.accounts?.map((a: AccountShort) => ({
            label: a.name,
            value: a.id,
          })),
        }));
        setAccountList([...acc]);
      }
    });
  }, [currentClient?.id]);

  const items: MenuProps["items"] = [
    {
      label: <h3 style={{ textAlign: "center" }}>Custom Columns</h3>,
      key: "0",
    },
    {
      label: (
        <p
          style={{
            border: "1px solid lightgray",
            padding: "6px",
            width: "150px",
            paddingLeft: "10px",
          }}
        >
          <Checkbox style={{ paddingRight: "10px" }} />
          Code
        </p>
      ),
      key: "1",
    },
    {
      label: (
        <p
          style={{
            border: "1px solid lightgray",
            padding: "6px",
            paddingLeft: "10px",
          }}
        >
          <Checkbox style={{ paddingRight: "10px" }} /> Account Name
        </p>
      ),
      key: "2",
    },
    {
      label: (
        <p
          style={{
            border: "1px solid lightgray",
            padding: "6px",
            paddingLeft: "10px",
          }}
        >
          <Checkbox style={{ paddingRight: "10px" }} /> Account Type
        </p>
      ),
      key: "3",
    },
  ];

  const commandBar = () => (
    <>
      <Row>
        <Col lg={12}>
          <div className="themeColor fs-20 fw-600">
            {currentClient?.businessName}
          </div>
          <p style={{ color: "rgb(120, 120, 120)" }}>
            Trial Balance (
            {dayjs(filterDate.from, "DD/MM/YYYY").format("DD MMM YYYY")} to{" "}
            {dayjs(filterDate.to, "DD/MM/YYYY").format("DD MMM YYYY")})
          </p>
        </Col>
        <Col lg={12}>
          <Row justify={"end"} gutter={[8, 0]}>
            <Col>
              <RangePicker
                defaultValue={[
                  dayjs(filterDate?.from, "DD/MM/YYYY"),
                  dayjs(filterDate?.to, "DD/MM/YYYY"),
                ]}
                allowClear={false}
                format="DD/MM/YYYY"
                style={{ width: "220px", border: "1px solid #f1f3ff" }}
                onChange={(e: any) => {
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate!,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            <Col>
              <Button icon={<DownloadOutlined />}></Button>
            </Col>
            <Col>
              <Button
                onClick={() => setIsModalOpen(true)}
                icon={<SettingOutlined />}
              ></Button>
            </Col>
            <Col>
              <Button
                icon={<ReloadOutlined />}
                onClick={() => setShouldRefresh(!shouldRefresh)}
              ></Button>
            </Col>
          </Row>
        </Col>
        <Divider className="my-10" />
      </Row>
      <Row justify="end" gutter={[8, 0]} className="pb-10">
        <Col className="pt-5">Group by</Col>
        <Col>
          <Select
            defaultValue={isAccountGroup}
            style={{ width: 140 }}
            onChange={(val) => {
              setChangeTable(!changeTable);
              setAccountGroup(val);
            }}
            options={[
              { value: "none", label: "None" },
              { value: "accountGroup", label: "Account Group" },
            ]}
          />
        </Col>
      </Row>
    </>
  );

  //console.log("filterDate", filterDate);

  const initialItems = [
    {
      label: "Trial Balance",
      key: "Trial Balance",
      closable: false,
      children: (
        <div style={{ width: "100%" }} className="m-auto">
          {commandBar()}
          <TrialBalanceView
            clientId={currentClient?.id!}
            period={filterDate}
            setTabs={setTabData}
            items={moreTabs}
            setItems={setMoreTabs}
            setActive={setActiveKey}
            changeTable={changeTable}
            dropItem={items}
            refresh={shouldRefresh}
          />
        </div>
      ),
    },
  ];
  console.log(moreTabs, "test");

  const updatedItems = initialItems.concat(
    moreTabs.map((tab: any) => ({
      label: tab.label,
      key: tab.key,
      closable: true,
      children: (
        <AccountDetailsList
          clientId={currentClient?.id!}
          from={filterDate.from}
          to={filterDate.to}
          account={tab.account}
          accountList={accountsList}
          items={moreTabs}
          setItems={setMoreTabs}
          setTabs={setTabData}
          setActive={setActiveKey}
        />
      ),
    }))
  );

  return (
    <div className="px-16">
      {tabData?.id !== undefined && moreTabs.length > 0 ? (
        <Tabs
          hideAdd
          type="editable-card"
          className="tabFlexOverride"
          onEdit={remove}
          activeKey={activeKey}
          items={updatedItems}
          destroyInactiveTabPane={true}
          onChange={(key) => setActiveKey(key)}
          tabBarExtraContent={
            <Button
              className="ms-10"
              onClick={() => {
                setMoreTabs([]);
                setTabData(undefined);
                setActiveKey("");
              }}
            >
              Close All
            </Button>
          }
        />
      ) : (
        <div style={{ width: "100%" }} className="m-auto">
          {commandBar()}
          <TrialBalanceView
            clientId={currentClient?.id!}
            period={filterDate}
            setTabs={setTabData}
            items={moreTabs}
            setItems={setMoreTabs}
            setActive={setActiveKey}
            changeTable={changeTable}
            dropItem={items}
            refresh={shouldRefresh}
          />
        </div>
      )}

      {/* customize setting modal */}
      {isModalOpen && (
        <ReportSettings
          isOpen={isModalOpen}
          setOpen={setIsModalOpen}
          type="TB"
          value={settings}
          refresh={shouldRefresh}
          setRefresh={setShouldRefresh}
        />
      )}
    </div>
  );
};
export default TrialBalance;

interface DataType {
  id: number;
  key: React.Key;
  code: string;
  accountName: string;
  accountType: string;
  accountGroup: string;
  debit: number;
  credit: number;
  balance: number;
  monthItems: any;
}

interface ITrialBalanceView {
  period: {
    from: string;
    to: string;
  };
  clientId: string;
  setTabs: any;
  dropItem: MenuProps["items"];
  items?: any;
  setItems?: any;
  setActive: any;
  changeTable: boolean;
  refresh: boolean;
}

const TrialBalanceView = (props: ITrialBalanceView) => {
  const { themeData } = useTheme();
  const { companySettings } = useClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [isGroupColumn, setIsGroupColumn] = useState(false);
  const [trialBalanceList, setTrialBalanceList] = useState<any[]>([]);
  const [total, setTotal] = useState<{ credit: number; debit: number }>({
    credit: 0,
    debit: 0,
  });

  useEffect(() => {
    getTrialBalanceList(
      props.clientId!,
      props.period.from,
      props.period.to,
      companySettings ? companySettings?.setting?.tbSettings?.detailed : false,
      companySettings ? companySettings?.setting?.tbSettings?.monthly : false,
      0,
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clientId, props.period, props.refresh, companySettings]);

  const getTrialBalanceList = async (
    clientId: string,
    fromDate: string,
    toDate: string,
    isAccWithBalance: boolean,
    isMonthlyTb: boolean,
    years: number,
    isPdf: boolean
  ) => {
    setLoading(true);
    await ReportsService.getTrialBalance(
      clientId,
      fromDate,
      toDate,
      isAccWithBalance,
      isMonthlyTb,
      years,
      isPdf
    )
      .then((res: any) => {
        if (res) {
          if (res?.transactions?.length > 0) {
            let itmArr: any = [];
            res?.transactions.map((itm: any) => {
              if (companySettings?.setting?.tbSettings?.monthly) {
                return itm?.monthItems?.map((itm: any) => {
                  return itmArr.push(itm);
                });
              } else
                return itm?.items?.map((itm: any) => {
                  return itmArr.push(itm);
                });
            });
            const ItmArr = itmArr?.map((itm: any, index: number) => {
              console.log("itmmmm", itm);
              return {
                key: index,
                id: itm?.account?.id,
                code: itm?.account?.code,
                accountName: itm?.account?.name,
                accountType: itm?.account?.typeName,
                accountGroup: itm?.account?.groupName,
                debit: itm?.debit,
                credit: itm?.credit,
                balance: itm?.balance,
                monthItems: itm?.item,
              };
            });

            setTotal({ credit: res?.totalCredit, debit: res?.totalDebit });
            setTrialBalanceList(ItmArr);
          } else setTrialBalanceList([]);
          setLoading(false);
        } else setLoading(false);
      })
      .catch((ex: any) => {
        setLoading(false);
        console.log(ex);
      });
  };

  let columns: ColumnsType<DataType> = [
    {
      key: 1,
      title: "Code ",
      dataIndex: "code",
      width: "8%",
      fixed: companySettings?.setting?.tbSettings?.monthly ? "left" : false,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.code}
        </Skeleton>
      ),
    },
    {
      key: 2,
      title: "Account Name",
      dataIndex: "accountName",
      width: "18%",
      fixed: companySettings?.setting?.tbSettings?.monthly ? "left" : false,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.accountName}
        </Skeleton>
      ),
    },
    isGroupColumn === true
      ? {
          key: 3,
          title: (
            <Space>
              Account Group
              <SyncOutlined onClick={() => setIsGroupColumn(false)} />
            </Space>
          ),
          dataIndex: "accountGroup",
          width: "24%",
          fixed: companySettings?.setting?.tbSettings?.monthly ? "left" : false,
          render: (_, record) => (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              {record?.accountGroup}
            </Skeleton>
          ),
        }
      : {
          key: 3,
          title: (
            <Space>
              Account Type
              <SyncOutlined onClick={() => setIsGroupColumn(true)} />
            </Space>
          ),
          dataIndex: "accountType",
          width: "24%",
          fixed: companySettings?.setting?.tbSettings?.monthly ? "left" : false,
          render: (_, record) => (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              {record?.accountType}
            </Skeleton>
          ),
        },
    {
      key: 4,
      title: "Opening Balance",
      dataIndex: "balance",
      width: "14%",
      align: "right",
      render: (x, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          <div className="hoverLinkColor cursorPointer">
            {getSettingsAmount(
              false,
              x,
              companySettings?.setting?.tbSettings,
              true,
              false,
              0,
              0
            )}
          </div>
        </Skeleton>
      ),
    },
    {
      key: 5,
      title: "Debit",
      dataIndex: "debit",
      width: "10%",
      align: "right",
      render: (x, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          <div
            className="hoverLinkColor cursorPointer"
            onClick={() => {
              if (record?.id) {
                props.setTabs({ id: record?.id, name: record?.accountName });
                let index = props.items?.findIndex(
                  (itm: any) => itm.account?.id === record?.id
                );
                let newData = {
                  label: `${record?.accountName}`,
                  key: record?.accountName,
                  account: {
                    id: record?.id,
                    name: `${record?.accountName}-${record?.code}`,
                  },
                };
                if (index !== -1) {
                  props.items[index] = newData;
                  props.setItems(props.items);
                } else {
                  props.items.push(newData);
                  props.setItems(props.items);
                }
                props.setActive(record?.accountName);
              }
            }}
          >
            {getSettingsAmount(
              true,
              x,
              companySettings?.setting?.tbSettings,
              false,
              false,
              0,
              0
            )}
          </div>
        </Skeleton>
      ),
    },
    {
      key: 6,
      title: "Credit",
      dataIndex: "credit",
      width: "10%",
      align: "right",
      render: (x, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          <div
            className="hoverLinkColor cursorPointer"
            onClick={() => {
              if (record?.id) {
                props.setTabs({ id: record?.id, name: record?.accountName });
                let index = props.items?.findIndex(
                  (itm: any) => itm.account.id === record?.id
                );
                let newData = {
                  label: `${record?.accountName}`,
                  key: record?.accountName,
                  account: {
                    id: record?.id,
                    name: `${record?.accountName}-${record?.code}`,
                  },
                };
                if (index !== -1) {
                  props.items[index] = newData;
                  props.setItems(props.items);
                } else {
                  props.items.push(newData);
                  props.setItems(props.items);
                }
                props.setActive(record?.accountName);
              }
            }}
          >
            {getSettingsAmount(
              true,
              x,
              companySettings?.setting?.tbSettings,
              false,
              false,
              0,
              0
            )}
          </div>
        </Skeleton>
      ),
    },
    {
      key: 7,
      title: "Closing Balance",
      dataIndex: "closingBalance",
      width: "14%",
      align: "right",
      render: (x, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          <div className="hoverLinkColor cursorPointer">
            {getSettingsAmount(
              false,
              record?.balance,
              companySettings?.setting?.tbSettings,
              true,
              true,
              record?.debit,
              record?.credit
            )}
          </div>
        </Skeleton>
      ),
    },
    {
      // title: (
      //   <Dropdown menu={{props.dropItem}} trigger={["click"]}>
      //     <DownSquareOutlined />
      //   </Dropdown>
      // ),
      title: "xx",
      key: 8,
      dataIndex: "",
      width: "4%",
      align: "right",
    },
  ];

  if (
    !companySettings?.setting?.tbSettings?.detailed &&
    !companySettings?.setting?.tbSettings?.monthly
  ) {
    columns = columns.filter((itm) => itm?.key !== 4 && itm?.key !== 7);
  } else if (
    !companySettings?.setting?.tbSettings?.detailed &&
    companySettings?.setting?.tbSettings?.monthly
  ) {
    let noOfelement = columns?.filter(
      (itm: any) =>
        itm?.key > 8 ||
        itm?.key === 4 ||
        itm?.key === 5 ||
        itm?.key === 6 ||
        itm?.key === 7 ||
        itm?.key === 8
    )?.length;
    console.log("noOfelement", dayjs(props.period?.from, "DD/MM/YYYY"));
    columns?.splice(3, noOfelement);
    for (
      let m = dayjs(props.period.from, "DD/MM/YYYY"), i = 9, k = 3;
      m?.diff(dayjs(props.period.to, "DD/MM/YYYY")) < 0;
      m = m.add(1, "month"), i++, k++
    ) {
      columns?.splice(k, 0, {
        key: i,
        title: `${m.format("MMM YYYY")}`,
        dataIndex: `${m.format("MMM YYYY")}`,
        width: 85,
        render: (x, record) => (
          <div
            onClick={() =>
              console.log(record.monthItems, m.month() + 1, m?.year())
            }
          >
            {getSettingsAmount(
              false,
              record.monthItems?.find(
                (i: any) =>
                  i?.month?.number === m.month() + 1 &&
                  i?.month?.year === m?.year()
              )?.amount ?? 0,
              companySettings?.setting?.tbSettings,
              true,
              false,
              0,
              0
            )}
          </div>
        ),
      });
    }
  }

  return (
    <>
      <div>
        <Table
          columns={columns}
          // dataSource={trialBalanceList}
          dataSource={
            loading
              ? (Array.from({ length: 15 }, (_, index) => ({
                  key: `loading-${index}`,
                })) as DataType[])
              : trialBalanceList
          }
          scroll={
            companySettings?.setting?.tbSettings?.monthly
              ? { y: 600, x: 1470 }
              : { y: 600 }
          }
          expandable={
            props.changeTable
              ? {
                  expandedRowRender: (record: any) => (
                    <>
                      <p>{record?.accountGroup}</p>
                    </>
                  ),
                  rowExpandable: (record) => record.name !== "Not Expandable",
                }
              : {}
          }
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          pagination={false}
          footer={() => (
            <>
              <Row justify={"end"}>
                <Col lg={5}>Total</Col>
                <Col lg={6} className="textEnd">
                  {total.debit > 0
                    ? getSettingsAmount(
                        false,
                        total.debit,
                        companySettings?.setting?.tbSettings,
                        true,
                        false,
                        0,
                        0
                      )
                    : ""}
                </Col>
                <Col lg={5} className="textEnd">
                  {total.credit > 0
                    ? getSettingsAmount(
                        false,
                        total.credit,
                        companySettings?.setting?.tbSettings,
                        true,
                        false,
                        0,
                        0
                      )
                    : ""}
                </Col>
                <Col lg={1}></Col>
              </Row>
            </>
          )}
        />
      </div>
    </>
  );
};

export const getSettingsAmount = (
  isDebit: boolean,
  amount: number,
  settings: ITrialBalanceSettings | undefined,
  isOpeningBal: boolean,
  isCloseBal: boolean,
  debit: number,
  credit: number
) => {
  let newAmount;
  if (settings) {
    if (isDebit) {
      newAmount =
        amount > 0 && !settings?.hideDecimals && settings?.roundToMillion
          ? Math.round(amount / 1000000) === 0 && !settings?.showZeros
            ? `-`
            : `₹${Math.round(amount / 1000000)}`
          : amount > 0 && !settings?.hideDecimals && settings?.roundToThousand
          ? Math.round(amount / 1000) === 0 && !settings?.showZeros
            ? "-"
            : `₹${Math.round(amount / 1000)}`
          : amount > 0 && !settings?.hideDecimals
          ? `₹${Math.round(amount)}`
          : amount > 0 && settings?.hideDecimals && settings?.roundToMillion
          ? amount / 1000000 === 0 && !settings?.showZeros
            ? "-"
            : Utils.getFormattedCurrency(Math.floor(amount / 1000000))
          : amount > 0 && settings?.hideDecimals && settings?.roundToThousand
          ? amount / 1000 === 0 && !settings?.showZeros
            ? "-"
            : Utils.getFormattedCurrency(Math.floor(amount / 1000))
          : amount > 0 && settings?.hideDecimals
          ? Utils.getFormattedCurrency(amount)
          : settings?.showZeros && !settings?.hideDecimals
          ? `₹${Math.round(amount)}`
          : settings?.showZeros && settings?.hideDecimals
          ? Utils.getFormattedCurrency(0)
          : "-";
    } else if (isOpeningBal) {
      // let closingBalance = isCloseBal ? (debit > credit ? amount + (debit - credit) : amount + (credit - debit)) : amount ? amount : 0;
      let closingBalance = isCloseBal ? amount + (credit - debit) : amount ?? 0;
      if (closingBalance > 0) {
        if (!settings?.hideDecimals && settings?.roundToMillion) {
          newAmount = Math.round(Math.floor(closingBalance / 1000000));
          if (newAmount === 0 && !settings?.showZeros) {
            return `-`;
          } else {
            return Utils.getFormattedCurrency(newAmount);
          }
        } else if (!settings?.hideDecimals && settings?.roundToThousand) {
          newAmount = Math.round(Math.floor(closingBalance / 1000));
          if (newAmount === 0 && !settings?.showZeros) {
            return `-`;
          } else {
            return Utils.getFormattedCurrency(newAmount);
          }
        } else if (!settings?.hideDecimals) {
          return `₹${Math.round(closingBalance)}`;
        } else if (settings?.hideDecimals && settings?.roundToMillion) {
          newAmount = Math.floor(closingBalance / 1000000);
          if (newAmount === 0 && !settings?.showZeros) {
            return `-`;
          } else {
            return Utils.getFormattedCurrency(newAmount);
          }
        } else if (settings?.hideDecimals && settings?.roundToThousand) {
          newAmount = Math.floor(closingBalance / 1000);
          if (newAmount === 0 && !settings?.showZeros) {
            return "-";
          } else {
            return Utils.getFormattedCurrency(newAmount);
          }
        } else {
          return Utils.getFormattedCurrency(closingBalance);
        }
      } else if (closingBalance < 0) {
        if (!settings?.hideDecimals && settings?.roundToMillion) {
          newAmount = Math.round(
            Math.floor(Math.abs(closingBalance) / 1000000)
          );
          if (newAmount === 0 && !settings?.showZeros) {
            return "-";
          } else if (newAmount === 0 && settings?.showZeros) {
            return `₹0.00`;
          } else {
            if (settings?.showNegative) {
              let value = Utils.getFormattedCurrency(newAmount).replace(
                "₹",
                ""
              );
              let index = value.indexOf(".");
              let newValue = value?.substring(0, index);
              return `-₹${newValue}`;
            } else {
              let value = Utils.getFormattedCurrency(newAmount).replace(
                "₹",
                ""
              );
              let index = value.indexOf(".");
              let newValue = value?.substring(0, index);
              return `₹(${newValue})`;
            }
          }
        } else if (!settings?.hideDecimals && settings?.roundToThousand) {
          newAmount = Math.round(Math.floor(Math.abs(closingBalance) / 1000));
          if (newAmount === 0 && !settings?.showZeros) {
            return "-";
          } else if (newAmount === 0 && settings?.showZeros) {
            return `₹0.00`;
          } else {
            if (settings?.showNegative) {
              let value = Utils.getFormattedCurrency(newAmount).replace(
                "₹",
                ""
              );
              let index = value.indexOf(".");
              let newValue = value?.substring(0, index);
              return `-₹${newValue}`;
            } else {
              let value = Utils.getFormattedCurrency(newAmount).replace(
                "₹",
                ""
              );
              let index = value.indexOf(".");
              let newValue = value?.substring(0, index);
              return `₹(${newValue})`;
            }
          }
        } else if (!settings?.hideDecimals) {
          if (settings?.showNegative) {
            let value = Utils.getFormattedCurrency(
              Math.round(Math.abs(closingBalance))
            ).replace("₹", "");
            let index = value.indexOf(".");
            let newValue = value?.substring(0, index);
            return `-₹${newValue}`;
          } else {
            let value = Utils.getFormattedCurrency(
              Math.round(Math.abs(closingBalance))
            ).replace("₹", "");
            let index = value.indexOf(".");
            let newValue = value?.substring(0, index);
            return `₹(${newValue})`;
          }
        } else if (settings?.hideDecimals && settings?.roundToMillion) {
          newAmount = Math.floor(Math.abs(closingBalance) / 1000000);
          if (newAmount === 0 && !settings?.showZeros) {
            return "-";
          } else if (newAmount === 0 && settings?.showZeros) {
            return Utils.getFormattedCurrency(0);
          } else {
            if (settings?.showNegative) {
              return `-₹${Utils.getFormattedCurrency(newAmount).replace(
                "₹",
                ""
              )}`;
            } else {
              return `₹(${Utils.getFormattedCurrency(newAmount).replace(
                "₹",
                ""
              )})`;
            }
          }
        } else if (settings?.hideDecimals && settings?.roundToThousand) {
          newAmount = Math.floor(Math.abs(closingBalance) / 1000);
          if (newAmount === 0 && !settings?.showZeros) {
            return "-";
          } else if (newAmount === 0 && !settings?.showZeros) {
            return Utils.getFormattedCurrency(0);
          } else {
            if (settings?.showNegative) {
              return `-₹${Utils.getFormattedCurrency(newAmount).replace(
                "₹",
                ""
              )}`;
            } else {
              return `₹(${Utils.getFormattedCurrency(newAmount).replace(
                "₹",
                ""
              )})`;
            }
          }
        } else if (settings?.hideDecimals) {
          if (settings?.showNegative) {
            return `-₹${Utils.getFormattedCurrency(
              Math.abs(closingBalance)
            ).replace("₹", "")}`;
          } else {
            return `₹(${Utils.getFormattedCurrency(
              Math.abs(closingBalance)
            ).replace("₹", "")})`;
          }
        }
      } else {
        if (settings?.showZeros && !settings?.hideDecimals) {
          return `₹${Math.round(closingBalance)}`;
        } else if (settings?.showZeros && settings?.hideDecimals) {
          return Utils.getFormattedCurrency(0);
        } else {
          return "-";
        }
      }
    }
  }
  return newAmount;
};
