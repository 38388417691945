import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Spin,
  Table,
} from "antd";
import React from "react";
import UsersService from "../../Services/UsersService";
import { voucherType } from "../Reports/VoucherDetailsByLedger";
import { notification } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const VoucherSecurity = ({ userId }: any) => {
  const [form] = Form.useForm();
  const [data, setData] = React.useState<any>([]);
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    getUserDataById();
  }, [userId]);
  const getUserDataById = async () => {
    setLoading(true);
    let res = await UsersService?.getUserDataById(userId);
    if (res) {
      const voucherAccessList = res?.result?.accessSettings?.voucherAccessList;
      const formattedData = voucherAccessList?.map((item: any) => ({
        voucher_Type: voucherType[item?.voucher_Type],
        addOrEdit: item?.actions?.addOrEdit,
        delete: item?.actions?.delete,
        view: item?.actions?.view,
        print: item?.actions?.print,
        authorize: item?.actions?.authorize,
      }));

      form.setFieldsValue({
        items: formattedData,
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const saveBasicDetailsData = () => {
    setBtnLoading(true);
    form.validateFields().then(async (val: any) => {
      console.log("val", val);
      let data = val?.items.map((itm: any) => {
        let reco = {
          voucher_Type: voucherType.indexOf(itm?.voucher_Type),
          actions: {
            authorize: itm?.authorize,
            addOrEdit: itm?.addOrEdit,
            delete: itm?.delete,
            view: itm?.view,
            print: itm?.print,
          },
        };
        return reco;
      });

      let res = await UsersService?.UpdateUserDetailsData(
        userId,
        { AccessSettings: { VoucherAccessList: [...data] } },
        1
      );
      if (res?.status === true) {
        console.log("res", res);
        setBtnLoading(false);
        getUserDataById();
        notification.success({
          message: "Success",
          description: "User details updated successfully.",
        });
      } else {
        notification.error({
          message: "Error",
          description: res?.message,
        });
        setBtnLoading(false);
      }
    });
  };
  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <Divider orientation="left" orientationMargin={0}>
          Voucher Security
        </Divider>
        <Spin indicator={<LoadingOutlined />} spinning={loading}>
          <Form
            form={form}
            labelAlign="left"
            layout="vertical"
            requiredMark={false}
            initialValues={{
              items: [{}],
            }}
          >
            <table
              // border="1"
              // cellPadding="10"
              // cellSpacing="0"
              className="AddPageTabelDesign"
              style={{ width: "50%" }}
            >
              <thead>
                <tr>
                  <th>Voucher Type</th>
                  <th>Add Or Edit</th>
                  <th>Delete</th>
                  <th>Print</th>
                  <th>View</th>
                  <th>Authorize</th>
                </tr>
              </thead>
              <tbody>
                <Form.List name="items">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <tr key={key}>
                          <td>
                            <Form.Item
                              className="m-0"
                              {...restField}
                              name={[name, "voucher_Type"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing voucher type",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <Form.Item
                              className="m-0"
                              {...restField}
                              name={[name, "addOrEdit"]}
                              valuePropName="checked"
                              rules={[
                                {
                                  required: true,
                                  message: "Missing add/edit info",
                                },
                              ]}
                            >
                              <Checkbox />
                            </Form.Item>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <Form.Item
                              className="m-0"
                              {...restField}
                              name={[name, "delete"]}
                              valuePropName="checked"
                              rules={[
                                {
                                  required: true,
                                  message: "Missing delete info",
                                },
                              ]}
                            >
                              <Checkbox />
                            </Form.Item>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <Form.Item
                              className="m-0"
                              {...restField}
                              name={[name, "print"]}
                              valuePropName="checked"
                              rules={[
                                {
                                  required: true,
                                  message: "Missing print info",
                                },
                              ]}
                            >
                              <Checkbox />
                            </Form.Item>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <Form.Item
                              className="m-0"
                              {...restField}
                              name={[name, "view"]}
                              valuePropName="checked"
                              rules={[
                                {
                                  required: true,
                                  message: "Missing view info",
                                },
                              ]}
                            >
                              <Checkbox />
                            </Form.Item>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <Form.Item
                              className="m-0"
                              {...restField}
                              name={[name, "authorize"]}
                              valuePropName="checked"
                              rules={[
                                {
                                  required: true,
                                  message: "Missing view info",
                                },
                              ]}
                            >
                              <Checkbox />
                            </Form.Item>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </Form.List>
              </tbody>
            </table>
            <Row
              style={{ margin: "10px 10px", marginRight: "117px" }}
              justify={"center"}
            >
              <Button
                loading={btnLoading}
                type="primary"
                style={{ padding: "4px 30px" }}
                onClick={saveBasicDetailsData}
              >
                Update
              </Button>
            </Row>
          </Form>
        </Spin>
      </div>
    </>
  );
};

export default VoucherSecurity;
