import React, { useRef } from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Divider,
  Select,
  InputNumber,
  Spin,
  InputRef,
  TreeSelect,
} from "antd";
import ChartofAccountServicesNew, {
  GroupTypes,
} from "../../../Services/ChartofAccountServicesNew";
import { useClient } from "../../../Context/ClientContext";
import { notificationContext } from "../../../Common/PageRoute";
import { LoadingOutlined } from "@ant-design/icons";
import { useHotkeys } from "react-hotkeys-hook";
import { useKeyboardShortcuts } from "../../../Common/KeyboardShortcuts ";
import EntryLevelKeys from "../../../Common/EntryLevelKeys";
import { SearchLedgerByGroupsTypes } from "../../../Services/LedgerService";
import ReportsServiceNew, {
  GroupList_Type,
} from "../../../Services/ReportsServiceNew";
import { log } from "console";

const AddGroup: React.FC<{
  open: boolean;
  editData: any;
  onCancel: React.Dispatch<{}>;
  setTrigger: any;
  groupData: any[];
  type?: SearchLedgerByGroupsTypes;
  showName?: string;
  setAddGropVal?: any;
}> = (props) => {
  console.log("props", props.editData);
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const { currentClient } = useClient();
  const { openNotification } = React.useContext(notificationContext);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [parentGroupData, setParentGroupData] = React.useState<any[]>();
  // const [groupType, setGroupType] = React.useState<GroupTypes>(
  //   GroupTypes.Secondary
  // );
  const [loading, setLoading] = React.useState<boolean>(false);

  // ----- get group list api -----
  React.useEffect(() => {
    setLoading(true);
    const getListData = async () => {
      setLoading(true);
      await ReportsServiceNew.getGroupHierarchy(
        currentClient?.id!,
        undefined,
        undefined,
        "",
        GroupList_Type.All,
        props?.type
      )
        .then((res: any) => {
          let index = 0;
          const dataSetInApi = (allgroupList: any[]) => {
            console.log("allgroupList", allgroupList);
            return allgroupList.map((x: any) => {
              const node: any = {
                // key: (index += 1),
                // id: x?.id,
                parent: x?.parent,
                value: x?.id,
                title: x?.name,
              };
              if (x?.children?.length > 0) {
                node.children = dataSetInApi(x?.children);
              }
              return node;
            });
          };

          const flattenedData = dataSetInApi(res?.groupList);
          setParentGroupData(flattenedData);

          console.log("treeData...............", flattenedData);

          setLoading(false);
          // console.log("result", res);
        })
        .catch((err: any) => {
          // console.log("error", err);
          setParentGroupData([]);
          setLoading(false);
        });
    };
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setLoading(true);
    inputRef.current?.focus();
    if (props.editData?.id) {
      // setGroupType(props.editData?.group_Type);
      form.setFieldsValue(props.editData);
    }
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Post
  const saveGroup = () => {
    form.validateFields().then(async (value) => {
      setSaveButtonLoading(true);

      const parentGroup: any = props.groupData?.find(
        (x) => x?.id === value?.parent
      );

      console.log("parentGroup", parentGroup);

      let data = {
        ...value,
        group_Type: GroupTypes.Secondary,
        nature: parentGroup?.nature,
        statement: parentGroup?.statement,
        parent: value?.parent?.value,
        order: value?.order ?? 500,
        editable: true,
      };

      console.log("post Data....", data);

      await ChartofAccountServicesNew.postGroup(
        currentClient?.id!,
        data,
        props.editData?.id
      )
        .then((res: any) => {
          if (res.result) {
            // setProps1(true);
            props?.setAddGropVal(res?.result);
            props.setTrigger();
            openNotification(
              "success",
              `Group ${
                props?.editData?.id
                  ? "updated successfully."
                  : "added successfully."
              }`
            );
            props.onCancel({});
          } else if (res?.message) {
            openNotification("error", res?.message);
          }
          setSaveButtonLoading(false);
        })
        .catch((ex: any) => {
          console.error(ex);
          openNotification("error", ex.message);
          setSaveButtonLoading(false);
        });
    });
  };

  EntryLevelKeys({
    save: () => saveGroup(),
    cancel: () => props.onCancel({}),
  });

  const intialValue = {
    group_Type: GroupTypes.Secondary,
  };

  const customizeRequiredMark = (
    label: React.ReactNode,
    { required }: { required: boolean }
  ) => (
    <>
      {label}
      {/* {required ? (
        <Tag color="error">Required</Tag>
      ) : (
        <Tag color="warning">optional</Tag>
      )} */}
    </>
  );
  return (
    <React.Fragment>
      <Modal
        open={props.open}
        title={
          props.editData?.id
            ? `Update ${props?.showName ?? ""} Group`
            : `Add ${props?.showName ?? ""} Group`
        }
        maskClosable={false}
        width={500}
        style={{ top: 44 }}
        onCancel={() => props.onCancel({})}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancel({});
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => saveGroup()}
              style={{ width: "80px" }}
              loading={saveButtonLoading}
              className="modelSaveButtonMargin"
            >
              {props.editData?.id ? "Update" : "Save"}
            </Button>
          </div>
        }
      >
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          spinning={loading}
        >
          <Form
            form={form}
            name="Account"
            colon={false}
            initialValues={intialValue}
            scrollToFirstError
            autoComplete="off"
            labelAlign="left"
            labelWrap={true}
            labelCol={{ xs: 7, sm: 8 }}
            wrapperCol={{ xs: 16, sm: 16 }}
            requiredMark={true}
          >
            <Divider className="ca-model-css" />
            <Form.Item
              name="group_Name"
              label="Group Name"
              className="mb-14"
              rules={[
                {
                  required: true,
                  message:
                    "Group's name is required. Max length is 50. You can include alphanumeric characters & some special characters like '_', '(', ')', '&', '-' and '.' also.",
                  pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                  max: 50,
                },
              ]}
            >
              <Input autoFocus={true} ref={inputRef} />
            </Form.Item>
            <Form.Item
              name="aliasName"
              label="Alias/Short Name"
              className="mb-12"
              rules={[
                {
                  pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                  max: 20,
                },
              ]}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              name="group_Type"
              label="Type"
              className="mb-14"
              rules={[{ required: true, message: "Account Type is required" }]}
            >
              <Select
                options={[
                  { value: GroupTypes.Primary, label: "Primary" },
                  { value: GroupTypes.Secondary, label: "Secondary" },
                ]}
                onChange={(e) => {
                  // setGroupType(e);
                }}
              />
            </Form.Item> */}

            {/* {groupType === GroupTypes.Secondary ? ( */}
            <Form.Item
              name="parent"
              label="Parent Group"
              className="mb-14"
              rules={[{ required: true, message: "Parent group is required" }]}
            >
              {/* <Select
                placeholder="Select parent group"
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={props.groupData?.map((value: any) => ({
                  value: value?.id,
                  label: value?.group_Name.concat(
                    value?.aliasName ? `${" (" + value?.aliasName + ")"}` : ""
                  ),
                }))}
                showSearch
              /> */}

              <TreeSelect
                showSearch
                virtual={false}
                // loading={groupDropdownloading}
                style={{ width: "100%" }}
                labelInValue
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Please select"
                // allowClear
                treeDefaultExpandAll
                // onChange={handleChangeTree}
                treeData={parentGroupData}
                treeNodeLabelProp="title"
                fieldNames={{
                  label: "title",
                  value: "value",
                  children: "children",
                }}
                treeNodeFilterProp="title"
                // onPopupScroll={(e) => console.log("onPopupScroll", e)}
              />
            </Form.Item>
          </Form>
          <Divider className="divider-account" />
        </Spin>
      </Modal>
    </React.Fragment>
  );
};

export default AddGroup;
