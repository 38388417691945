import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  InputRef,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  TabsProps,
  Tag,
  Upload,
  UploadFile,
} from "antd";
import React from "react";
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useClient } from "../../Context/ClientContext";
import TextArea from "antd/es/input/TextArea";
import { notificationContext } from "../../Common/PageRoute";
import { Utils } from "../../../Utilities/Utils";
import { dayjs } from "../../../Utilities/dayjs";
import Items from "./Items";
import { AccountShort, ISelectGroup } from "../../../Types/CommonTypes";
import ItemsService, { DiscountSalesType } from "../../Services/ItemsService";
import UnitModel from "../ItemInventory/UnitModel";
import { CompanyGSTTypes } from "../../../Types/CommonConstants";
import { IGstShort, SettingsSections } from "../../Services/SettingService";
import { useHotkeys } from "react-hotkeys-hook";
import EntryLevelKeys from "../../Common/EntryLevelKeys";
import { log } from "@antv/g2plot/lib/utils";
import DocumetPreview from "../../Common/DocumentPreview";
import DocumentPreview from "../../Common/DocumentPreview";
import ItemCategoryService from "../../Services/ItemCategoryService";
import AddCategoryItemsModel from "../ItemInventory/Category/AddCategoryItemsModel";
import { percentToRs } from "../../Common/CAPComponents";

const AddItems: React.FC<{
  open: boolean;
  onCancel: any;
  id?: string;
  setTrigger?: any;
  setNewItem?: any;
  itemPage?: any;
  isServicesItem?: boolean;
}> = (props) => {
  const [form] = Form.useForm();
  const inputRef = React.useRef<InputRef>(null);
  const { currentClient, companySettings, updateCompanySettings } = useClient();
  const { openNotification } = React.useContext(notificationContext);
  const [props1, setProps1] = React.useState<any>(false);
  const [isGstEnabled, setIsGstEnabled] = React.useState<boolean>();
  const [defaultAccount, setDefaultAccount] = React.useState<any>();
  const [salesAccounts, setSalesAccounts] = React.useState<any[]>([]);
  const [modelLoading, setModelLoading] = React.useState<boolean>(false);
  const [checkServices, setCheckServices] = React.useState<boolean>(
    props?.isServicesItem ?? false
  );
  const [salesCheckbox, setSalesCheckbox] = React.useState<boolean>(true);
  const [purchaseCheckbox, setPurchaseCheckbox] = React.useState<any>(true);
  const [stockData, setStockData] = React.useState<any>({});
  const [purchaseAccounts, setPurchaseAccounts] = React.useState<any[]>([]);
  const [unitOptions, setUnitOptions] = React.useState<any[]>([]);
  const [searchUnit, setSearchUnit] = React.useState<string>(" ");
  const [openModel, setOpenModel] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [unitData, setUnitData] = React.useState<any>({});
  const [selectGSTId, setSelectGSTId] = React.useState<any>();
  const [addGstSetting, setAddGSTSetting] = React.useState<boolean>(false);
  const [deleteUploadId, setDeleteUploadId] = React.useState<string[]>([]);
  const [previewImage, setPreviewImage] = React.useState<{
    isBaseConvert: boolean;
    path: any;
    // uploadType: string;
  }>({ isBaseConvert: false, path: "" });
  const [itemCategoryList, setItemCategoryList] = React.useState<any[]>([]);
  const [discountType, setDiscountType] = React.useState<DiscountSalesType>(
    DiscountSalesType.Percentage
  );
  const [addCatVal, setAddCatVal] = React.useState<any>();
  const [addUnitVal, setAddUnitVal] = React.useState<any>();
  const gstOption = companySettings?.setting?.gstDetails?.gst ?? [];
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const [openCatModel, setOpenCatModel] = React.useState<boolean>(false);
  const [locationDetail, setLocationdetail] = React.useState<any[]>([]);
  const stockOnChange = () => {
    let qty = isNaN(form.getFieldValue(["openingStock", "quantity"]));
    let price = isNaN(form.getFieldValue(["openingStock", "price"]));
    !qty &&
      !price &&
      form.setFieldValue(
        ["openingStock", "totalAmount"],
        Utils.getFormattedNumber(
          form.getFieldValue(["openingStock", "quantity"]) *
            Utils.getNumber(form.getFieldValue(["openingStock", "price"]))
        )
      );
  };

  const totalAmountStock = () => {
    let totAmt = isNaN(form.getFieldValue(["openingStock", "totalAmount"]));
    let qty = isNaN(form.getFieldValue(["openingStock", "quantity"]));
    !totAmt &&
      !qty &&
      form.setFieldValue(
        ["openingStock", "price"],
        Utils.getFormattedNumber(
          Utils.getNumber(form.getFieldValue(["openingStock", "totalAmount"])) /
            form.getFieldValue(["openingStock", "quantity"])
        )
      );
  };

  console.log("checkServices", props);

  // ------- Initial or Edit item Api --------
  React.useEffect(() => {
    setModelLoading(true);
    inputRef.current?.focus();
    ItemsService.getItem(currentClient?.id!, props?.id)
      .then((res) => {
        if (res?.status) {
          console.log("res.result", res.result, props?.id);

          setIsGstEnabled(
            companySettings?.setting.gstDetails.scheme ===
              CompanyGSTTypes.Regular ||
              (props.id && res.result?.inventoryItem?.taxRate?.id !== 0
                ? res.result?.inventoryItem?.taxRate?.rate === 0 ||
                  res.result?.inventoryItem?.taxRate?.rate > 0
                : false)
              ? true
              : false
          );
          setDefaultAccount(res?.result?.defaultAccount);
          const inv = res?.result?.inventoryItem;
          inv?.isService && setCheckServices(true);
          props?.id && setStockData(inv?.openingStock);
          if (props?.id) {
            inv?.purchaseItem
              ? setPurchaseCheckbox(true)
              : setPurchaseCheckbox(false);

            inv?.salesItem ? setSalesCheckbox(true) : setSalesCheckbox(false);
          }

          const parsedDate = dayjs(
            dayjs(inv?.openingStock?.date).format("DD/MM/YYYY"),
            "DD/MM/YYYY"
          );
          setSelectGSTId({
            key: inv.taxRate?.name,
            value: inv.taxRate?.id,
            title: inv.taxRate?.rate,
            label: inv.taxRate?.name,
          });
          setDiscountType(
            props?.id
              ? inv?.salesItem?.discountSalesType
              : DiscountSalesType.Percentage
          );

          // {label: 'GST 1.5%', value: 12, key: 'GST 1.5%',

          form.setFieldsValue({
            ...inv,
            isService: props.id ? inv.isService : checkServices,
            isTaxable: props.id ? inv?.isTaxable : false,
            taxRate: props.id &&
              inv.taxRate?.id > 0 && {
                key: inv.taxRate?.name,
                value: inv.taxRate?.id,
                title: inv.taxRate?.rate,
                label: inv.taxRate?.name,
              },
            // : {
            //     key: gstOption && gstOption[0]?.name,
            //     value: gstOption && gstOption[0]?.id,
            //     title: gstOption && gstOption[0]?.rate,
            //     label: gstOption && gstOption[0]?.name,
            //   },
            unit: props?.id && { label: inv?.unit?.name, value: inv?.unit?.id },
            purchaseItem: {
              ...inv?.purchaseItem,
              price: Utils.getFormattedNumber(inv?.purchaseItem?.price),
            },
            salesItem: {
              ...inv?.salesItem,
              price: Utils.getFormattedNumber(inv?.salesItem?.price),
              discountSalesType: props?.id
                ? inv?.salesItem?.discountSalesType
                : DiscountSalesType.Percentage,
            },
            mrpDetails: {
              ...inv?.mrpDetails,
              price: Utils.getFormattedNumber(inv?.mrpDetails?.price),
            },
            wholesaleDetails: {
              ...inv?.wholesaleDetails,
              price: Utils.getFormattedNumber(inv?.wholesaleDetails?.price),
            },
            openingStock: {
              location: inv?.openingStock?.location,
              quantity: inv?.openingStock?.quantity
                ? inv?.openingStock?.quantity
                : 0,
              price: Utils.getFormattedNumber(inv?.openingStock?.price),
              totalAmount: Utils.getFormattedNumber(
                inv?.openingStock?.totalAmount
              ),
              date: parsedDate.isValid() ? parsedDate : null,
            },
          });

          if (res?.result?.salesAccounts) {
            let acc: ISelectGroup[] = res?.result?.salesAccounts?.map(
              (group: any) => ({
                label: group.groupName,
                options: group.accounts?.map((a: AccountShort) => ({
                  id: a.code,
                  label: a.name,
                  value: a.id,
                  title: group.group,
                })),
              })
            );
            setSalesAccounts([...acc]);
          }

          if (res?.result?.purchaseAccounts) {
            let acc: ISelectGroup[] = res?.result?.purchaseAccounts?.map(
              (group: any) => ({
                label: group.groupName,
                options: group.accounts?.map((a: AccountShort) => ({
                  id: a.code,
                  label: a.name,
                  value: a.id,
                  title: group.group,
                })),
              })
            );
            setPurchaseAccounts([...acc]);
          }

          setModelLoading(false);
        } else {
          setModelLoading(false);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setModelLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, props?.id]);

  //-------- Unit API----------//
  React.useEffect(() => {
    ItemsService.getUnitsList(
      currentClient?.id!,
      0,
      15,
      "",
      "",
      searchUnit
    ).then((res: any) => {
      setUnitOptions(res?.result?.items);
      // console.log(res, "unitdata");
    });
  }, [currentClient?.id, searchUnit]);

  // -------- Add or Edit Items Post API ---------
  const postItem = () => {
    form.validateFields().then(async (val: any) => {
      console.log("valll", val);

      if (salesCheckbox) {
        if (
          companySettings?.setting.gstDetails.scheme ===
            CompanyGSTTypes.Regular &&
          companySettings?.setting?.gstDetails?.gst?.length
            ? companySettings?.setting?.gstDetails?.gst.find(
                (x) => x.id === val?.taxRate?.value
              )
            : undefined
        ) {
        }
      }
      // if (
      //   companySettings?.setting.gstDetails.scheme ===
      //     CompanyGSTTypes.Regular &&
      //   companySettings?.setting?.gstDetails?.gst?.length
      //     ? companySettings?.setting?.gstDetails?.gst.find(
      //         (x) => x.id === val?.taxRate?.value
      //       )
      //     : ""
      // ) {
      // }

      setSaveButtonLoading(true);
      console.log("val", val);

      let data = {
        ...val,
        name: val.name.trim(),
        purchaseActive: purchaseCheckbox,
        salesActive: salesCheckbox,
        purchaseItem: purchaseCheckbox
          ? {
              ...val.purchaseItem,
              isActive: purchaseCheckbox,
              type: 1,
              price: Utils.getNumber(val?.purchaseItem?.price),
            }
          : null,
        salesItem: salesCheckbox
          ? {
              ...val.salesItem,
              isActive: salesCheckbox,
              type: 0,
              price: Utils.getNumber(val?.salesItem?.price),
            }
          : null,
        taxRate: {
          id: val?.taxRate?.value ?? undefined,
          name: val?.taxRate?.key ?? undefined,
          rate: val?.taxRate?.title ?? undefined,
        },
        unit: {
          id: val?.unit?.value,
          name: val?.unit?.label,
        },
        openingStock: !checkServices
          ? {
              ...val.openingStock,
              quantity: val.openingStock
                ? val.openingStock?.quantity
                : stockData?.quantity
                ? stockData?.quantity
                : 0,
              price: Utils.getNumber(
                val.openingStock ? val.openingStock?.price : stockData?.price
              ),
              totalAmount: Utils.getNumber(
                val.openingStock
                  ? val.openingStock?.totalAmount
                  : stockData?.totalAmount
              ),

              date: val.openingStock
                ? dayjs(val.openingStock?.date).toISOString()
                : dayjs(stockData?.date).toISOString(),

              location: val?.openingStock?.location?.value,
            }
          : null,
        deleteUploadId: deleteUploadId,
      };

      console.log("data", data);

      // const config = "multipart/form-data";
      // const formData = new FormData();

      // console.log("post data", data);
      //formData.values = data1;
      // val.attachments?.map((x: any) =>
      //   formData.append("file", x?.originFileObj)
      // );
      // const data = {
      //   ...data1,
      //   ...,
      // };

      // const x = {
      //   ...data1,
      //   ...val.attachments?.map((x: any) => formData.append("file", x)),
      // };

      // console.log("from data", formData);

      await ItemsService.PostItems(data, currentClient?.id!, props?.id)
        .then((res: any) => {
          if (res.result) {
            props.setNewItem &&
              props.setNewItem({ data: res?.result, isSaved: true });
            setProps1(true);
            props.setTrigger((x: any) => !x);
            props.onCancel();
            openNotification(
              "success",
              `Item ${
                props?.id ? "updated successfully." : "save successfully."
              }`
            );
            setSaveButtonLoading(false);
          } else if (res?.message) {
            openNotification("error", res?.message);
            setSaveButtonLoading(false);
          }
        })
        .catch((ex: any) => {
          console.error(ex);
          openNotification("error", ex.message);
          setSaveButtonLoading(false);
        });

      // add gst in client Setting
      if (addGstSetting && selectGSTId?.value) {
        const newGstDetail: IGstShort = {
          rate: selectGSTId?.title,
          name: selectGSTId?.label,
          id: selectGSTId?.value,
        };
        const updatedGstDetails = {
          gstDetails: {
            ...companySettings?.setting?.gstDetails!,
            gst:
              companySettings?.setting?.gstDetails?.gst!?.length > 0
                ? [...companySettings?.setting?.gstDetails?.gst!, newGstDetail]
                : [newGstDetail],
          },
        };

        console.log("updatedGstDetails", updatedGstDetails);

        await updateCompanySettings!(
          SettingsSections.GstDetails,
          {
            ...companySettings,
            setting: {
              ...companySettings?.setting!,
              gstDetails: updatedGstDetails?.gstDetails!,
            },
          },
          updatedGstDetails
        )
          .then((result: any) => {
            if (result?.status) {
              openNotification("success", "Setting updated successfully.");
              setSaveButtonLoading(false);
            } else {
              openNotification("error", "there is some error");
              setSaveButtonLoading(false);
            }
          })
          .catch((ex: any) => {
            setSaveButtonLoading(false);
            openNotification("error", ex.message);
          });
      }
    });
  };
  EntryLevelKeys({
    save: () => postItem(),
    cancel: () => props.onCancel(),
  });

  React.useEffect(() => {
    getLocationList();
  }, []);

  const getLocationList = async () => {
    let res = await ItemsService.getItemLocationsList(currentClient?.id);
    if (res?.result) {
      setLocationdetail(res?.result);
      console.log("data", res);
    }
  };

  React.useEffect(() => {
    if (addCatVal) {
      form.setFieldsValue({
        itemCatgoryId: {
          value: addCatVal.id,
          label: addCatVal.name,
        },
      });
    }
  }, [addCatVal, form]);

  React.useEffect(() => {
    if (addUnitVal) {
      form.setFieldsValue({
        unit: {
          value: addUnitVal.id,
          label: addUnitVal.name,
        },
      });
    }
  }, [addUnitVal, form]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Item",
      children: (
        <>
          {/* --------- For Purchases ---------*/}
          <Divider
            orientation="left"
            orientationMargin="0"
            style={{ marginTop: "4px", marginBottom: "4px" }}
          >
            <p style={{ fontWeight: 600 }}>
              Purchases Details
              <Checkbox
                style={{
                  // float: "left",
                  // padding: "6px 0px 12px 0px",
                  marginLeft: "10px",
                }}
                checked={purchaseCheckbox}
                onChange={(e: any) => {
                  if (salesCheckbox) {
                    setPurchaseCheckbox(e.target.checked);
                  }
                }}
              />
            </p>
          </Divider>
          <Row gutter={14}>
            <Col sm={8}>
              <Form.Item
                htmlFor="price"
                name={["purchaseItem", "price"]}
                label="Price"
                className="input-item"
                // labelCol={{ sm: 8 }}
                style={{ width: "100%" }}
              >
                <Input
                  disabled={!purchaseCheckbox}
                  addonAfter="INR"
                  onBlur={(e) => {
                    const allDetails = form.getFieldsValue();
                    form.setFieldsValue({
                      ...allDetails,
                      purchaseItem: {
                        ...allDetails?.purchaseItem,
                        price: Utils.getFormattedNumber(
                          e.target.value ? Utils.getNumber(e.target.value) : 0
                        ),
                      },
                    });
                  }}
                  onKeyPress={(e: any) => {
                    if (!/\d|\./.test(e.key)) e.preventDefault();
                    if (e.key === "." && e.target.value.includes("."))
                      e.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item
                htmlFor="description"
                label="Description"
                name={["purchaseItem", "description"]}
                className="input-item"
                // labelCol={{ sm: 8 }}
                style={{ width: "100%" }}
                layout="vertical"
              >
                <TextArea disabled={!purchaseCheckbox} />
              </Form.Item>
            </Col>
          </Row>

          {/* --------- For sales ---------*/}
          <Divider
            orientation="left"
            orientationMargin="0"
            style={{ marginTop: "4px", marginBottom: "4px" }}
          >
            <span style={{ fontWeight: 600 }}>
              Sales Details
              <Checkbox
                style={{
                  // float: "left",
                  padding: "6px 0px 12px 0px",
                  marginLeft: "10px",
                }}
                checked={salesCheckbox}
                onChange={(e: any) => {
                  if (purchaseCheckbox) {
                    setSalesCheckbox(e.target.checked);
                  }
                }}
              />
            </span>
          </Divider>
          {/* --------- MRP sales ---------*/}
          <Divider
            orientation="left"
            orientationMargin="0"
            style={{ marginTop: "4px", marginBottom: "4px" }}
          >
            <p style={{ fontWeight: 500, fontSize: "14px" }}>MRP Details</p>
          </Divider>

          <Row gutter={14} justify="space-between">
            <Col sm={8}>
              <Form.Item
                htmlFor="price"
                label="MRP"
                name={["mrpDetails", "price"]}
                className="input-item"
                style={{ width: "100%" }}
              >
                <Input
                  disabled={!salesCheckbox}
                  addonAfter="INR"
                  // precision={2}
                  onBlur={(e) => {
                    const allDetails = form.getFieldsValue();
                    form.setFieldsValue({
                      ...allDetails,
                      mrpDetails: {
                        ...allDetails?.mrpDetails,
                        price: Utils.getFormattedNumber(
                          e.target.value ? Utils.getNumber(e.target.value) : 0
                        ),
                      },
                    });
                    // if (form.getFieldValue(["mrpDetails", "disForSales"])) {
                    //   form.setFieldValue(
                    //     ["salesItem", "price"],
                    //     Utils.getFormattedNumber(
                    //       (Utils.getNumber(e.target.value) *
                    //         (100 -
                    //           form.getFieldValue([
                    //             "mrpDetails",
                    //             "disForSales",
                    //           ]))) /
                    //         100
                    //     )
                    //   );
                    // } else {
                    //   form.setFieldValue(
                    //     ["salesItem", "price"],
                    //     Utils.getFormattedNumber(
                    //       Utils.getNumber(e.target.value)
                    //     )
                    //   );
                    // }
                  }}
                  onChange={(e) => {
                    // Sales Discount & Wholesale Discount Befor Enter User
                    if (
                      form.getFieldValue(["mrpDetails", "disForSales"]) &&
                      form.getFieldValue(["mrpDetails", "disForWholeSale"])
                    ) {
                      form.setFieldValue(
                        ["salesItem", "price"],
                        Utils.getFormattedNumber(
                          (Utils.getNumber(e.target.value) *
                            (100 -
                              form.getFieldValue([
                                "mrpDetails",
                                "disForSales",
                              ]))) /
                            100
                        )
                      );
                      form.setFieldValue(
                        ["wholesaleDetails", "price"],
                        Utils.getFormattedNumber(
                          (Utils.getNumber(e.target.value) *
                            (100 -
                              form.getFieldValue([
                                "mrpDetails",
                                "disForWholeSale",
                              ]))) /
                            100
                        )
                      );
                    }
                    // Only Sales Discount Befor Enter
                    else if (
                      form.getFieldValue(["mrpDetails", "disForSales"])
                    ) {
                      form.setFieldValue(
                        ["salesItem", "price"],
                        Utils.getFormattedNumber(
                          (Utils.getNumber(e.target.value) *
                            (100 -
                              form.getFieldValue([
                                "mrpDetails",
                                "disForSales",
                              ]))) /
                            100
                        )
                      );
                    }
                    // user not discount enter
                    else {
                      form.setFieldValue(
                        ["salesItem", "price"],
                        Utils.getFormattedNumber(
                          Utils.getNumber(e.target.value)
                        )
                      );
                    }
                  }}
                  onKeyPress={(e: any) => {
                    if (!/\d|\./.test(e.key)) e.preventDefault();
                    if (e.key === "." && e.target.value.includes("."))
                      e.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item
                htmlFor="price"
                label="Dis. on MRP for Sale (%)"
                name={["mrpDetails", "disForSales"]}
                className="input-item"
                style={{ width: "100%" }}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  controls={false}
                  max={100}
                  // precision={2}
                  disabled={!salesCheckbox}
                  onKeyPress={(e: any) => {
                    if (!/\d|\./.test(e.key)) e.preventDefault();
                    if (e.key === "." && e.target.value.includes("."))
                      e.preventDefault();
                  }}
                  onChange={(e: any) => {
                    //  Mrp details before user enter
                    if (
                      Utils.getNumber(
                        form.getFieldValue(["mrpDetails", "price"])
                      )
                    ) {
                      form.setFieldValue(
                        ["salesItem", "price"],
                        Utils.getFormattedNumber(
                          Utils.getNumber(
                            form.getFieldValue(["mrpDetails", "price"])
                          ) *
                            ((100 - e) / 100)
                        )
                      );
                    }
                  }}
                  // percentToRs
                />
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item
                htmlFor="price"
                label="Dis. on MRP for Wholesale (%)"
                name={["mrpDetails", "disForWholeSale"]}
                className="input-item"
              >
                <InputNumber
                  style={{ width: "100%" }}
                  controls={false}
                  max={100}
                  precision={2}
                  disabled={!salesCheckbox}
                  onKeyPress={(e: any) => {
                    if (!/\d|\./.test(e.key)) e.preventDefault();
                    if (e.key === "." && e.target.value.includes("."))
                      e.preventDefault();
                  }}
                  onChange={(e: any) => {
                    console.log(
                      "data",
                      e,
                      form.getFieldValue(["mrpDetails", "price"])
                    );
                    //  Mrp details before user enter
                    if (e === null || e === undefined || e === 0) {
                      form.setFieldValue(["wholesaleDetails", "price"], "0.00");
                    } else if (
                      Utils.getNumber(
                        form.getFieldValue(["mrpDetails", "price"])
                      )
                    ) {
                      form.setFieldValue(
                        ["wholesaleDetails", "price"],
                        Utils.getFormattedNumber(
                          Utils.getNumber(
                            form.getFieldValue(["mrpDetails", "price"])
                          ) *
                            ((100 - e) / 100)
                        )
                      );
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Divider
            orientation="left"
            orientationMargin="0"
            style={{ marginTop: "4px", marginBottom: "4px" }}
          >
            <p style={{ fontWeight: 500, fontSize: "14px" }}>Sale</p>
          </Divider>

          <Row gutter={14}>
            <Col sm={8}>
              <Form.Item
                htmlFor="price"
                label="Price"
                name={["salesItem", "price"]}
                className="input-item"
                // style={{ width: "100%" }}
              >
                <Input
                  disabled={!salesCheckbox}
                  addonAfter="INR"
                  onBlur={(e) => {
                    const allDetails = form.getFieldsValue();
                    form.setFieldsValue({
                      ...allDetails,
                      salesItem: {
                        ...allDetails?.salesItem,
                        price: Utils.getFormattedNumber(
                          e.target.value ? Utils.getNumber(e.target.value) : 0
                        ),
                      },
                    });
                    if (
                      !Utils.getNumber(
                        form.getFieldValue(["mrpDetails", "price"])
                      ) &&
                      Utils.getNumber(
                        form.getFieldValue(["mrpDetails", "disForSales"])
                      )
                    ) {
                      form.setFieldValue(
                        ["mrpDetails", "price"],
                        Utils.getFormattedNumber(
                          (100 * Utils.getNumber(e.target.value)) /
                            (100 -
                              Utils.getNumber(
                                form.getFieldValue([
                                  "mrpDetails",
                                  "disForSales",
                                ])
                              ))
                        )
                      );
                    }
                  }}
                  onKeyPress={(e: any) => {
                    if (!/\d|\./.test(e.key)) e.preventDefault();
                    if (e.key === "." && e.target.value.includes("."))
                      e.preventDefault();
                  }}
                  onChange={(e: any) => {
                    //  Mrp Details & Discount Befor user Enter
                    if (
                      Utils.getNumber(
                        form.getFieldValue(["mrpDetails", "price"])
                      ) &&
                      form.getFieldValue(["mrpDetails", "disForSales"])
                    ) {
                      // Mrp price > Sales price se = Set Discount Amout
                      if (
                        Utils.getNumber(
                          form.getFieldValue(["mrpDetails", "price"])
                        ) >= Utils.getNumber(e.target.value)
                      ) {
                        // console.log(
                        //   "Mrp price > Sales price se = Set Discount Amout",
                        //   Utils.getNumber(
                        //     ((Utils.getNumber(
                        //       form.getFieldValue(["mrpDetails", "price"])
                        //     ) -
                        //       Utils.getNumber(e.target.value)) /
                        //       Utils.getNumber(
                        //         form.getFieldValue(["mrpDetails", "price"])
                        //       )) *
                        //       100
                        //   ),
                        // );
                        const mrpPrice = Utils.getNumber(
                          form.getFieldValue(["mrpDetails", "price"])
                        );
                        form.setFieldValue(
                          ["mrpDetails", "disForSales"],
                          Utils.getNumber(
                            ((mrpPrice - Utils.getNumber(e.target.value)) /
                              mrpPrice) *
                              100
                          )
                        );
                      }
                      // Mrp price < Sales price se = Set Discount Amout
                      else {
                        console.log(
                          "Data",
                          "Mrp price < Sales price se = Set Discount Amout"
                        );

                        form.setFieldValue(
                          ["mrpDetails", "price"],
                          Utils.getFormattedNumber(
                            (100 * Utils.getNumber(e.target.value)) /
                              (100 -
                                Utils.getNumber(
                                  form.getFieldValue([
                                    "mrpDetails",
                                    "disForSales",
                                  ])
                                ))
                          )
                        );
                      }
                    }
                    // only mrp price given user - Done
                    else if (
                      Utils.getNumber(
                        form.getFieldValue(["mrpDetails", "price"])
                      )
                    ) {
                      console.log(
                        "only mrp price given user",
                        form.getFieldValue(["mrpDetails", "price"])
                      );
                      const x = Utils.getNumber(e.target.value);
                      form.setFieldValue(
                        ["mrpDetails", "price"],
                        Utils.getFormattedNumber(x)
                      );
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item
                htmlFor="price"
                label="Dis. on Sales Price"
                name={["salesItem", "discountSalesPrice"]}
                className="input-item"
                rules={[
                  {
                    validator: (_, value) => {
                      if (
                        discountType === DiscountSalesType.Amount &&
                        Utils.getNumber(
                          form.getFieldValue(["salesItem", "price"])
                        ) <
                          Utils.getNumber(
                            form.getFieldValue([
                              "salesItem",
                              "discountSalesPrice",
                            ])
                          )
                      ) {
                        return Promise.reject("Cannot be more than Sale price");
                      } else if (
                        discountType === DiscountSalesType.Percentage &&
                        100 <
                          Utils.getNumber(
                            form.getFieldValue([
                              "salesItem",
                              "discountSalesPrice",
                            ])
                          )
                      ) {
                        return Promise.reject("Maximun discount 100%");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  style={{ width: "100%" }}
                  disabled={!salesCheckbox}
                  min={0}
                  precision={2}
                  onKeyPress={(e: any) => {
                    if (!/\d|\./.test(e.key)) e.preventDefault();
                    if (e.key === "." && e.target.value.includes("."))
                      e.preventDefault();
                  }}
                  max={
                    discountType === DiscountSalesType.Percentage
                      ? 100
                      : Utils.getNumber(
                          form.getFieldValue(["salesItem", "price"])
                        )
                  }
                />
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item
                htmlFor="price"
                label="Discount Type"
                name={["salesItem", "discountSalesType"]}
                className="input-item"
                style={{ width: "100%" }}
              >
                <Select
                  disabled={!salesCheckbox}
                  options={[
                    {
                      label: "Percentage",
                      value: DiscountSalesType.Percentage,
                    },
                    { label: "Amount", value: DiscountSalesType.Amount },
                  ]}
                  onChange={(e) => setDiscountType(e)}
                />
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item
                htmlFor="description"
                label="Description"
                name={["salesItem", "description"]}
                className="input-item"
              >
                <TextArea disabled={!salesCheckbox} />
              </Form.Item>
            </Col>
          </Row>

          {/* --------- Wholesale Price  ---------*/}
          <Divider
            orientation="left"
            orientationMargin="0"
            style={{ marginTop: "4px", marginBottom: "4px" }}
          >
            <p style={{ fontWeight: 500, fontSize: "14px" }}>Wholesale</p>
          </Divider>

          <Row gutter={14}>
            <Col sm={8}>
              <Form.Item
                htmlFor="price"
                label="Price"
                name={["wholesaleDetails", "price"]}
                className="input-item"
                style={{ width: "100%" }}
              >
                <Input
                  disabled={!salesCheckbox}
                  addonAfter="INR"
                  onBlur={(e) => {
                    const allDetails = form.getFieldsValue();
                    form.setFieldsValue({
                      ...allDetails,
                      wholesaleDetails: {
                        ...allDetails?.wholesaleDetails,
                        price: Utils.getFormattedNumber(
                          e.target.value ? Utils.getNumber(e.target.value) : 0
                        ),
                      },
                    });
                  }}
                  onKeyPress={(e: any) => {
                    if (!/\d|\./.test(e.key)) e.preventDefault();
                    if (e.key === "." && e.target.value.includes("."))
                      e.preventDefault();
                  }}
                  onChange={() => {}}
                />
              </Form.Item>
            </Col>

            <Col sm={8}>
              <Form.Item
                htmlFor="price"
                label="Minimum Qty."
                name={["wholesaleDetails", "minimumQty"]}
                className="input-item"
                rules={[
                  {
                    validator: (_, value) => {
                      if (
                        Utils.getNumber(
                          form.getFieldValue(["wholesaleDetails", "price"])
                        ) &&
                        !value
                      ) {
                        return Promise.reject(
                          "Wholesale minimum qty. is required"
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  controls={false}
                  min={0}
                  precision={2}
                  disabled={!salesCheckbox}
                  onKeyPress={(e: any) => {
                    if (!/\d|\./.test(e.key)) e.preventDefault();
                    if (e.key === "." && e.target.value.includes("."))
                      e.preventDefault();
                  }}
                  // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                />
              </Form.Item>
            </Col>
          </Row>
          <></>

          <Row justify="space-between">
            {/* --------- For Purchases ---------*/}
            {/* <Col sm={11}>
              <Row>
                <Col sm={8}></Col>
                <Col sm={2}>
                  <Checkbox
                    style={{
                      float: "left",
                      padding: "6px 0px 12px 0px",
                    }}
                    checked={purchaseCheckbox}
                    onChange={(e: any) => {
                      if (salesCheckbox) {
                        setPurchaseCheckbox(e.target.checked);
                      }
                    }}
                  />
                </Col>
                <Col sm={14} className="textStart">
                  <div className="pt-5"> For Purchases</div>
                </Col>
              </Row>
              <Form.Item
                htmlFor="price"
                name={["purchaseItem", "price"]}
                label="Purchase Price"
                className="input-item"
                labelCol={{ sm: 8 }}
                style={{ width: "100%" }}
              >
                <Input
                  disabled={!purchaseCheckbox}
                  addonAfter="INR"
                  onBlur={(e) => {
                    const allDetails = form.getFieldsValue();
                    form.setFieldsValue({
                      ...allDetails,
                      purchaseItem: {
                        ...allDetails?.purchaseItem,
                        price: Utils.getFormattedNumber(
                          e.target.value ? Utils.getNumber(e.target.value) : 0
                        ),
                      },
                    });
                  }}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                />
              </Form.Item>
              <Form.Item
                htmlFor="description"
                label="Description"
                name={["purchaseItem", "description"]}
                className="input-item"
                labelCol={{ sm: 8 }}
                style={{ width: "100%" }}
              >
                <TextArea disabled={!purchaseCheckbox} />
              </Form.Item>
            </Col> */}

            {/* --------- For sales ---------*/}

            {/* <Col sm={11}>
              <Row>
                <Col sm={8}></Col>
                <Col sm={2}>
                  <Checkbox
                    style={{
                      float: "left",
                      padding: "6px 0px 12px 0px",
                    }}
                    checked={salesCheckbox}
                    onChange={(e: any) => {
                      if (purchaseCheckbox) {
                        setSalesCheckbox(e.target.checked);
                      }
                    }}
                  />
                </Col>
                <Col sm={14} className="textStart">
                  <div className="pt-5"> For Sales</div>
                </Col>
              </Row>
              <Form.Item
                htmlFor="price"
                label="Sell Price"
                name={["salesItem", "price"]}
                className="input-item"
                labelCol={{ sm: 8 }}
                style={{ width: "100%" }}
              >
                <Input
                  disabled={!salesCheckbox}
                  addonAfter="INR"
                  onBlur={(e) => {
                    const allDetails = form.getFieldsValue();
                    form.setFieldsValue({
                      ...allDetails,
                      salesItem: {
                        ...allDetails?.salesItem,
                        price: Utils.getFormattedNumber(
                          e.target.value ? Utils.getNumber(e.target.value) : 0
                        ),
                      },
                    });
                  }}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                />
              </Form.Item>

            

              <Form.Item
                htmlFor="description"
                label="Description"
                name={["salesItem", "description"]}
                className="input-item"
                labelCol={{ sm: 8 }}
                style={{ width: "100%" }}
              >
                <TextArea disabled={!salesCheckbox} />
              </Form.Item>
            </Col> */}
          </Row>
        </>
      ),
    },
  ];

  // eslint-disable-next-line no-lone-blocks
  {
    !checkServices &&
      items.push({
        key: "2",
        label: "Stock",
        children: (
          <>
            <Row
              style={{
                justifyContent: "space-between",
                paddingTop: "10px",
              }}
            >
              <Col sm={11} xs={24}>
                <Form.Item
                  name={["openingStock", "quantity"]}
                  label="Quantity"
                  htmlFor="quantity"
                  className="input-item"
                >
                  <InputNumber
                    controls={false}
                    onChange={(e) => stockOnChange()}
                    formatter={(value: any) =>
                      Utils.getInputNumberFormat(value)
                    }
                    onKeyPress={(e: any) => {
                      if (!/\d|\./.test(e.key)) e.preventDefault();
                      if (e.key === "." && e.target.value.includes("."))
                        e.preventDefault();
                    }}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col sm={11} xs={24}>
                <Form.Item
                  htmlFor="date"
                  label="At of Date"
                  name={["openingStock", "date"]}
                  className="input-item"
                >
                  <DatePicker
                    disabledDate={(current) =>
                      current && current > dayjs().endOf("day")
                    }
                    style={{ width: "100%" }}
                    format="DD/MM/YYYY"
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col sm={11} xs={24}>
                <Form.Item
                  htmlFor="price"
                  label="At Price"
                  name={["openingStock", "price"]}
                  className="input-item"
                >
                  <Input
                    addonAfter="INR"
                    onChange={() => stockOnChange()}
                    onBlur={(e) => {
                      const allDetails = form.getFieldsValue();
                      form.setFieldsValue({
                        ...allDetails,
                        openingStock: {
                          ...allDetails?.openingStock,
                          price: Utils.getFormattedNumber(
                            e.target.value ? Utils.getNumber(e.target.value) : 0
                          ),
                        },
                      });
                    }}
                    onKeyPress={(e: any) => {
                      if (!/\d|\./.test(e.key)) e.preventDefault();
                      if (e.key === "." && e.target.value.includes("."))
                        e.preventDefault();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col sm={11} xs={24}>
                <Form.Item
                  htmlFor="location"
                  label="Location"
                  name={["openingStock", "location"]}
                  className="input-item"
                >
                  <Select
                    labelInValue
                    style={{ marginBottom: "3px" }}
                    // loading={locDropdownLoading}
                    // onChange={handleLocation}
                    placeholder="Select location"
                    options={locationDetail?.map((l: any) => ({
                      value: l.id,
                      label: l.itemLocationName,
                    }))}
                    // dropdownRender={(itemsOption) => (
                    //   <>
                    //     {itemsOption}
                    //     <Divider
                    //       style={{
                    //         margin: "5px 0px",
                    //       }}
                    //     />
                    //     <Button
                    //       style={{ width: "100%" }}
                    //       icon={<PlusOutlined />}
                    //       type="link"
                    //       onClick={() => setIsModelOpen(true)}
                    //     >
                    //       Add Location
                    //     </Button>
                    //   </>
                    // )}
                  />
                </Form.Item>
              </Col>
              <Col sm={11} xs={24}>
                <Form.Item
                  htmlFor="totalAmount"
                  name={["openingStock", "totalAmount"]}
                  label="Total Amount"
                  className="input-item"
                >
                  <Input
                    addonAfter="INR"
                    onChange={() => totalAmountStock()}
                    onBlur={(e) => {
                      const allDetails = form.getFieldsValue();
                      form.setFieldsValue({
                        ...allDetails,
                        openingStock: {
                          ...allDetails?.openingStock,
                          totalAmount: Utils.getFormattedNumber(
                            e.target.value ? Utils.getNumber(e.target.value) : 0
                          ),
                        },
                      });
                    }}
                    onKeyPress={(e: any) => {
                      if (!/\d|\./.test(e.key)) e.preventDefault();
                      if (e.key === "." && e.target.value.includes("."))
                        e.preventDefault();
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ margin: "0px" }} />
          </>
        ),
      });
  }

  console.log(companySettings?.setting.gstDetails.scheme);

  const handleGSTValidation = () => {
    if (companySettings?.setting.gstDetails.scheme === CompanyGSTTypes.Regular)
      if (gstOption.find((x) => x?.id === selectGSTId?.value)) {
        return <></>;
      } else if (selectGSTId?.value) {
        return (
          <Tag bordered={true} color="red" className="m-5">
            Selected GST Rate is not listed in Company's GST Settings hence the
            same will be added.
          </Tag>
        );
      }
  };

  React.useEffect(() => {
    if (
      companySettings?.setting?.gstDetails?.scheme ===
        CompanyGSTTypes.Regular &&
      gstOption?.find((x) => x?.id === selectGSTId?.value)
    ) {
      setAddGSTSetting(false);
    } else {
      setAddGSTSetting(true);
    }
  }, [companySettings, gstOption, selectGSTId]);

  // get api Item Category
  React.useEffect(() => {
    const getCategoryNameList = async () => {
      await ItemCategoryService.getCategoryList(currentClient?.id)
        .then((res) => {
          if (res?.result?.items?.length > 0) {
            setItemCategoryList(res?.result?.items);
          }
        })
        .catch((err) => {
          setItemCategoryList([]);
          console.error(err?.message);
        });
    };
    getCategoryNameList();
  }, [trigger]);

  return (
    <>
      <Drawer
        maskClosable={false}
        open={props.open}
        title={!props?.id ? "Add Item" : "Edit Item"}
        width={850}
        // onCancel={() => props.onCancel()}
        // closable={props.onCancel}
        closable={false}
        extra={
          <Space>
            <CloseOutlined onClick={() => props.onCancel()} />
          </Space>
        }
        // style={{ maxHeight: `calc(100% - 50px)`, overflowY: "auto" }}
        // centered
        //  <Space
        //     style={{
        //       display: "flex",
        //       justifyContent: "end",
        //       marginRight: 20,
        //     }}
        //   >
        //     <Button onClick={() => props.setOpenDrawer({})}>Cancel</Button>
        //     <Button
        //       onClick={() => saveLedger()}
        //       type="primary"
        //       loading={saveButtonLoading}
        //     >
        //       {props?.id === "" ? "Save" : "Update"}
        //     </Button>
        //   </Space>
        footer={
          <div style={{ marginTop: "0px" }}>
            <Space
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: 20,
              }}
            >
              {salesCheckbox ? handleGSTValidation() : <></>}
              <Button
                onClick={() => {
                  form.resetFields();
                  props.onCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => postItem()}
                loading={saveButtonLoading}
                className="modelSaveButtonMargin"
                // style={{marginLeft:"10px"}}
              >
                {!props?.id ? "Save" : "Update"}
              </Button>
            </Space>
          </div>
        }
        className="ledgerdrawerstyle"
      >
        {/* <Divider className="ca-model-css" /> */}
        <Spin indicator={antIcon} spinning={modelLoading}>
          <Form
            form={form}
            name="item"
            autoCapitalize="false"
            requiredMark={true}
            colon={false}
            labelAlign="left"
            // labelCol={{ xs: 7, sm: 8 }}
            // wrapperCol={{ xs: 16, sm: 16 }}
            // labelWrap={true}
            style={{ margin: "0px", padding: "0px" }}
            layout="vertical"
          >
            <Row gutter={14} style={{ justifyContent: "space-between" }}>
              <Col sm={8} xs={24}>
                <Form.Item
                  htmlFor="name"
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Item's name is required" },
                    {
                      pattern: /^[A-Za-z][ A-Za-z0-9_()&-.,]*$/,
                      message:
                        "Only alphanumeric with space and some special characters i.e. '_', '(', ')', '&', '-', '.' and ',' are allowed",
                    },
                    {
                      min: 3,
                      max: 50,
                      message:
                        "Length must be Minimum 3 or Maximum 80 characters long",
                    },
                  ]}
                  className="input-item"
                >
                  <Input ref={inputRef} autoFocus={true} />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  htmlFor="isService"
                  label="Type"
                  name="isService"
                  // valuePropName="checked"
                  className="inputBoxMb8 inputLabel33"
                >
                  <Radio.Group
                    disabled={
                      props?.isServicesItem || props?.isServicesItem === false
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setCheckServices(e.target.value);
                      const allDetails = form.getFieldsValue();
                      // checkServices
                      //   ? form.setFieldsValue({
                      //       ...allDetails,
                      //       purchaseItem: {
                      //         ...allDetails?.purchaseItem,
                      //         account: {
                      //           label:
                      //             defaultAccount?.purchaseAccount?.product
                      //               ?.name,
                      //           value:
                      //             defaultAccount?.purchaseAccount?.product?.id,
                      //         },
                      //       },
                      //       salesItem: {
                      //         ...allDetails?.salesItem,
                      //         account: {
                      //           label:
                      //             defaultAccount?.salesAccount?.product?.name,
                      //           value:
                      //             defaultAccount?.salesAccount?.product?.id,
                      //         },
                      //       },
                      //     })
                      //   : form.setFieldsValue({
                      //       ...allDetails,
                      //       purchaseItem: {
                      //         ...allDetails?.purchaseItem,
                      //         account: {
                      //           label:
                      //             defaultAccount?.purchaseAccount?.service
                      //               ?.name,
                      //           value:
                      //             defaultAccount?.purchaseAccount?.service?.id,
                      //         },
                      //       },
                      //       salesItem: {
                      //         ...allDetails?.salesItem,
                      //         account: {
                      //           label:
                      //             defaultAccount?.salesAccount?.service?.name,
                      //           value:
                      //             defaultAccount?.salesAccount?.service?.id,
                      //         },
                      //       },
                      //     });
                    }}
                  >
                    <Radio value={false}>Goods</Radio>
                    <Radio value={true}>Services</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  htmlFor="number"
                  label="Item Code"
                  name="number"
                  // className="input-item"
                  className="input-item"
                  rules={[{ required: true, message: "Item code is required" }]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              {isGstEnabled && (
                <Col sm={8} xs={24}>
                  {!checkServices ? (
                    <Form.Item
                      htmlFor="hsnCode"
                      name="hsnCode"
                      label="HSN Code"
                      className="input-item"
                      rules={[
                        { required: true, message: "HSN code is required" },
                        {
                          pattern: new RegExp(/^(?!0+$)\d{4,8}$/),
                          message:
                            "Enter valid HSN code with minimum 4 and maximum 8 digits",
                        },
                      ]}
                    >
                      <Input
                        maxLength={8}
                        // controls={false}
                        // addonAfter={<SearchOutlined />}
                        onChange={(e) => {
                          const newValue = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          form.setFieldValue("hsnCode", newValue);
                        }}
                        // onKeyPress={(e) =>
                        //   !/[A-Za-z\s]/.test(e.key) && e.preventDefault()
                        // }
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      htmlFor="sacCode"
                      name="sacCode"
                      label="SAC Code"
                      className="input-item"
                      rules={[
                        { required: true, message: "SAC code is required" },
                        {
                          pattern: new RegExp(/^(?!0+$)\d{4,6}$/),
                          message:
                            "Enter valid SAC code with minimum 4 and maximum 6 digits",
                        },
                      ]}
                    >
                      <Input
                        maxLength={6}
                        // controls={false}
                        onChange={(e) => {
                          const newValue = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          form.setFieldValue("sacCode", newValue);
                        }}
                      />
                    </Form.Item>
                  )}
                </Col>
              )}

              {isGstEnabled && (
                <Col sm={8} xs={24}>
                  <Form.Item
                    htmlFor="taxRate"
                    name="taxRate"
                    label="GST Rate"
                    className="input-item"
                    rules={[
                      {
                        required: true,
                        message: "GST rate is required",
                      },
                    ]}
                  >
                    <Select
                      allowClear
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      showSearch
                      labelInValue={true}
                      onChange={(e: any) => {
                        // console.log(e, "e");
                        setSelectGSTId(e);
                      }}
                      options={companySettings?.gsts?.map((gst: any) => ({
                        key: gst.name,
                        value: gst.id,
                        title: gst.rate,
                        label: gst.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col sm={8} xs={24}>
                <Form.Item
                  label="Unit"
                  name="unit"
                  rules={[{ required: true, message: "Select an unit" }]}
                  className="input-item"
                  // initialValue="S.elect Unit"
                >
                  <Select
                    showSearch
                    labelInValue
                    optionFilterProp="children"
                    placeholder="Search unit"
                    allowClear
                    filterOption={false}
                    notFoundContent={null}
                    onSearch={(value: string) => setSearchUnit(value)}
                    // onClick={() =>
                    //   setSearchUnit({ ...searchParty, search: "" })
                    // }
                    // showArrow={false}
                    // onSearch={(value: string) => setSearchCustomer(value)}
                    // onSelect={(value: any) => {
                    //   CustomerSelect(value);
                    // }}
                    options={[
                      ...unitOptions?.map((value: any) => ({
                        value: value?.id,
                        label: value?.name,
                      })),
                      {
                        value: "searchMore",
                        label: "Search for more...",
                        disabled: true,
                      },
                    ]}
                    dropdownRender={(unitOptions) => (
                      <>
                        {unitOptions}
                        <Divider style={{ margin: "5px 0px" }} />
                        <Button
                          icon={<PlusOutlined />}
                          type="link"
                          onClick={() => {
                            setOpenModel(true);
                          }}
                        >
                          New Unit
                        </Button>
                      </>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col sm={8} xs={24}>
                <Form.Item
                  label="Add Image"
                  name="attachments"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => {
                    return e.fileList;
                  }}
                  // rules={[
                  //   {
                  //     required:
                  //       data?.result?.ticketAttachments &&
                  //       data?.result?.ticketAttachments?.length > 0
                  //         ? false
                  //         : true,
                  //     message: "This field is required",
                  //   },
                  // ]}
                >
                  <Upload
                    multiple
                    maxCount={5}
                    accept={".png, .jpg, .jpeg, .pdf"}
                    // accept={".png, .jpg, .jpeg"}
                    beforeUpload={() => {
                      return false;
                    }}
                    onRemove={(e: any) => {
                      if (props?.id && e?.id) {
                        setDeleteUploadId([...deleteUploadId, e?.id]);
                      }
                    }}
                    onPreview={(e: any) => {
                      console.log("preview", e);
                      if (props?.id && e?.path) {
                        setPreviewImage({
                          isBaseConvert: false,
                          path: e,
                        });
                      } else if (e.type?.split("/")[0] === "image") {
                        setPreviewImage({
                          isBaseConvert: true,
                          path: e,
                        });
                      }
                    }}
                  >
                    <Button
                      icon={<UploadOutlined />}
                      style={{ backgroundColor: "ButtonFace" }}
                    />
                  </Upload>
                </Form.Item>
              </Col>

              {isGstEnabled && (
                <Col sm={8} xs={24}>
                  <Form.Item
                    htmlFor="isTaxable"
                    label="Tax Preference"
                    name="isTaxable"
                    // valuePropName="checked"
                    className="input-item"
                  >
                    <Radio.Group>
                      <Radio value={true}>Inclusive</Radio>
                      <Radio value={false}>Exclusive</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}
              <Col sm={8} xs={24}>
                <Form.Item
                  htmlFor="ItemCatgoryId"
                  name="itemCatgoryId"
                  label="Category"
                  className="input-item"
                >
                  <Select
                    allowClear
                    mode="multiple"
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    showSearch
                    onChange={(e: any) => {
                      console.log(e, "e");

                      // setSelectGSTId(e);
                    }}
                    options={itemCategoryList?.map((x: any) => ({
                      value: x?.id,
                      label: x?.categoryName,
                    }))}
                    dropdownRender={(unitOptions) => (
                      <>
                        {unitOptions}
                        <Divider style={{ margin: "5px 0px" }} />
                        <Button
                          icon={<PlusOutlined />}
                          type="link"
                          onClick={() => {
                            setOpenCatModel(true);
                          }}
                        >
                          Add Category
                        </Button>
                      </>
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ margin: "0px" }} />

            {/*  -------- Tab Item & Stock --------- */}
            <Tabs
              centered
              defaultActiveKey="1"
              size="small"
              className="ca-tab-addItem"
              items={items}
            />
          </Form>
          {openModel && (
            <UnitModel
              open={openModel}
              onCancel={() => setOpenModel(false)}
              setTrigger={setTrigger}
              data={unitData}
              setAddUnitVal={setAddUnitVal}
            />
          )}
          {previewImage.path && (
            <DocumentPreview
              open={true}
              onCancel={() =>
                setPreviewImage({
                  path: "",
                  isBaseConvert: false,
                  // uploadType: "",
                })
              }
              documentDetails={previewImage}
            />
          )}
          {/* {} */}
        </Spin>
      </Drawer>

      {props1 && <Items itemPage={props.itemPage} trigger={props1} />}
      {openCatModel && (
        <AddCategoryItemsModel
          open={true}
          onCancel={() => setOpenCatModel(false)}
          setTrigger={setTrigger}
          // editData={editCategoryData}
          setAddCatVal={setAddCatVal}
        />
      )}
    </>
  );
};

export default AddItems;

// import React from "react";
// import { Form, Input, Button, Upload } from "antd";
// import axios from "axios";
// const MyForm = () => {
//   const [form] = Form.useForm();
//   const onFinish = async (values) => {
//     const formData = new FormData();
//     // Loop through the form values and append them to FormData
//     Object.entries(values).forEach(([key, value]) => {
//       if (Array.isArray(value)) {
//         value.forEach((item) => formData.append(key, item)); // Append each item if it's an array
//       } else if (value && value.fileList) {
//         value.fileList.forEach((file) => {
//           formData.append(key, file.originFileObj); // Append each file if it's a file input
//         });
//       } else {
//         formData.append(key, value); // Append other values
//       }
//     });
//     try {
//       const response = await axios.post("YOUR_BACKEND_URL", formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       console.log("Success:", response.data);
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };
//   return (
//     <Form form={form} onFinish={onFinish}>
//       <Form.Item
//         name="name"
//         label="Name"
//         rules={[{ required: true, message: "Please input your name!" }]}
//       >
//         <Input />
//       </Form.Item>
//       <Form.Item
//         name="description"
//         label="Description"
//         rules={[{ required: true, message: "Please input a description!" }]}
//       >
//         <Input.TextArea />
//       </Form.Item>
//       <Form.Item
//         name="upload"
//         label="Upload"
//         valuePropName="fileList"
//         getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
//       >
//         <Upload name="files" beforeUpload={() => false}>
//           <Button>Click to Upload</Button>
//         </Upload>
//       </Form.Item>
//       <Form.Item>
//         <Button type="primary" htmlType="submit">
//           Submit
//         </Button>
//       </Form.Item>
//     </Form>
//   );
// };
// export default MyForm;
