import { Button, Modal, Space } from "antd";
import React from "react";
import "../Common/Acc_Common.css";
interface IConfirmationModal {
  open: boolean;
  onNo: any;
  onYes: any;
  text: string;
  title?: string;
  type?: "text" | "link" | "default" | "primary" | "dashed";
  loading?: boolean;
}

export const ConfirmationModal = ({
  open,
  onNo,
  onYes,
  text,
  title,
  type,
}: // loading,
IConfirmationModal) => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleKeyUp = (event: any) => {
    // console.log(event);
    if (event.key === "Enter" || event.key === "Delete") {
      setLoading(true);
      onYes();
      setLoading(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title={title ? title : "Confirmation"}
      cancelText="No"
      maskClosable={false}
      onCancel={() => onNo()}
      open={open}
      width={360}
      centered
      footer={
        <Space>
          <Button
            style={{ width: "80px" }}
            onClick={() => {
              onNo();
            }}
          >
            No
          </Button>
          <div className="ca-confirmationModal">
            <Button
              type={type ? type : "primary"}
              onClick={() => {
                setLoading(true);
                onYes();
                // setLoading(false);
              }}
              danger
              style={{
                border: "2px solid black",
                width: "80px",
              }}
              loading={loading}
            >
              Yes
            </Button>
          </div>
        </Space>
      }
    >
      <p style={{ color: "gray" }}>{text}</p>
    </Modal>
  );
};
