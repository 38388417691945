import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { LedgerStatus } from "./LedgerService";

export enum ItemInvStatus {
  All,
  Sales,
  Purchase,
  AdjustItem,
}

export enum InventoryType {
  Add = 0,
  Update = 1,
  Delete = 2,
  Active = 3,
}

export enum ItemType {
  All,
  SalesItems,
  PurchaseItems,
  SalesGoods,
  SalesService,
  PurchaseGoods,
  PurchaseService,
  AllItemGoods,
  AllItemServices,
}

export enum UnitTypes {
  Length,
  Weight,
  Capacity,
  Countables,
}

export enum FilterCategoryInItems {
  AvailableItems,
  NotAvailableItems,
}

export enum DiscountSalesType {
  Percentage,
  Amount,
}

class ItemsServices {
  route = "/v1/API/";

  getItem = (clientId: string, id?: string) =>
    ApiUtility.get(
      `${this.route}Acc_Item/${clientId}/Get${id ? `?id=${id}` : ""}`
    );

  getListItems = (
    clientId: string,
    start: number,
    length: any,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    status?: any,
    type?: ItemType,
    gstRate?: number[],
    itemInventory?: boolean,
    name?: string,
    number?: string,
    purchasePrice?: string,
    salesPrice?: string,
    itemType?: boolean[] | null,
    HSNCode?: string,
    isTaxable?: boolean[] | null
  ) =>
    ApiUtility.getResult(`${this.route}Acc_Item/${clientId}`, {
      start,
      length,
      sortCol,
      sortDir,
      search,
      type,
      status,
      gstRate,
      itemInventory,
      name,
      number,
      purchasePrice,
      salesPrice,
      itemType,
      HSNCode,
      isTaxable,
    });

  getItemDetails = (clientId: string, itemId: string) =>
    ApiUtility.getResult(
      `${this.route}Acc_Item/${clientId}/ItemIntDetails/${itemId}`
    );

  PostItems = (param: any, clientId: string, id?: string, config?: string) =>
    ApiUtility.postForm(
      `${this.route}Acc_Item/${id ? clientId + "/" + id : clientId}`,
      param
      // config
    );

  deleteItem = (clientId: string, itemId: string) =>
    ApiUtility.delete(`${this.route}Acc_Item/${clientId}/DeleteItem/${itemId}`);

  // "https://localhost:7142/V1/API/Acc_ItemInventory/6463611020350584c0d5c017/Get?itemId=6501a570643413cc49d1164c&start=0&length=15&status=0";

  // https: //localhost:7142/V1/API/Acc_ItemInventory/6463611020350584c0d5c017/Get?itemId=6465e265731bc731fb04ada8&start=0&length=15&type=1&type=2

  // "https://localhost:7142/V1/API/Acc_ItemInventory/6463611020350584c0d5c017/Get?itemId=651bc9472706aed188a399a7&start=0&length=15&from=01%2F09%2F2023&to=30%2F09%2F2023&type=1";

  getItemInventoryList = (
    clientId: string | undefined,
    itemId: string,
    start?: number,
    length?: number,
    from?: string,
    to?: string,
    sortCol?: string,
    sortDir?: string,
    type?: number[],
    partyId?: string[]
  ) =>
    ApiUtility.get(`/V1/API/Acc_ItemInventory/${clientId}/Get`, {
      itemId,
      start,
      length,
      from,
      to,
      sortCol,
      sortDir,
      type,
      partyId,
    });

  // https: //localhost:7142/V1/API/Acc_ItemInventory/64f96b34ef29cde5ee5e45fe?Type=0
  // /localhost:7142/V1/API/Acc_ItemInventory/64f96b34ef29cde5ee5e45fe/6509410c5f4e3d2158fbed7d?Type=0
  addItemInventory = (
    param: any,
    clientId: string | undefined,
    type?: InventoryType,
    id?: string
  ) =>
    ApiUtility.post(
      `/V1/API/Acc_ItemInventory/${
        id ? clientId + "/" + id : clientId
      }?Type=${type}`,
      param
    );

  // https://localhost:7142/V1/API/Acc_ItemInventory/64f96b34ef29cde5ee5e45fe/650941ff5f4e3d2158fbed80
  getItemInventory = (clientId: string | undefined, id: string) =>
    ApiUtility.get(`/V1/API/Acc_ItemInventory/${clientId}/${id}`);

  // Unit APi
  // https://localhost:7142/V1/API/Acc_Units/6463611020350584c0d5c017?start=0&length=150

  getUnitsList = (
    companyId: string | undefined,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string
  ) =>
    ApiUtility.get(`/V1/API/Acc_Units/${companyId}`, {
      start,
      length,
      sortCol,
      sortDir,
      search,
    });

  // https://localhost:7142/V1/API/Acc_Units/6463611020350584c0d5c017/Units
  // https: //localhost:7142/V1/API/Acc_Units/6463611020350584c0d5c017/Units/44
  postUnit = (param: any, clientId?: string, id?: number) =>
    ApiUtility.post(
      `/V1/API/Acc_Units/${
        id ? clientId + "/Units/" + id : clientId + "/Units"
      }`,
      param
    );

  getUnit = (clientId?: string, id?: number) =>
    ApiUtility.get(`/V1/API/Acc_Units/${clientId}`, id);

  // ItemGroup APi
  // https://localhost:7142/V1/API/Acc_ItemGroups/6463611020350584c0d5c017?start=0&length=150

  getItemGroupsList = (
    companyId: string | undefined,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    groupId?: string,
    groupSearch?: string,
    status?: LedgerStatus
  ) =>
    ApiUtility.get(`/V1/API/Acc_ItemGroups/${companyId}/GetList`, {
      search,
      sortCol,
      sortDir,
      groupId,
      groupSearch,
      status,
    });

  postItemGroup = (param: any, clientId?: string, itemGroupId?: string) =>
    ApiUtility.post(
      `/V1/API/Acc_ItemGroups/${
        itemGroupId ? clientId + "/" + itemGroupId : clientId
      }`,
      param
    );

  // ItemLocation APi
  // https://localhost:7142/V1/API/Acc_ItemLocations/6463611020350584c0d5c017?start=0&length=150

  getItemLocationsList = (
    companyId: string | undefined,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    status?: LedgerStatus
  ) =>
    ApiUtility.get(`/V1/API/Acc_ItemLocations/${companyId}/GetList`, {
      search,
      sortCol,
      sortDir,
      status,
    });

  postItemLocation = (param: any, clientId?: string, itemLocationId?: string) =>
    ApiUtility.post(
      `/V1/API/Acc_ItemLocations/${
        itemLocationId ? clientId + "/" + itemLocationId : clientId
      }`,
      param
    );

  deleteLocationById = (
    companyId: string | undefined,
    itemLocationId: string
  ) =>
    ApiUtility.delete(
      `/V1/API/Acc_ItemLocations/${companyId}/${itemLocationId}`
    );

  // https://localhost:7142/V1/API/Acc_Item/6749549bdb6d377cad9c59d6/GetCategoryToItemList/674efcfeac3640294815cd08

  getCategoryToItemList = (
    companyId: string | undefined,
    categoryId: string,
    isCategoryInItem: FilterCategoryInItems,
    start: number,
    length: number,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    status?: LedgerStatus
  ) =>
    ApiUtility.get(
      `${this.route}Acc_Item/${companyId}/GetCategoryToItemList/${categoryId}`,
      {
        isCategoryInItem,
        start,
        length,
        search,
        sortCol,
        sortDir,
        status,
      }
    );

  moveCategoryData = (
    companyId: string | undefined,
    categoryId: string,
    isCategoryInItem: FilterCategoryInItems,
    AddOrRemoveItemsId: string[]
  ) =>
    ApiUtility.post(
      `${this.route}Acc_Item/${companyId}/MoveToThisCategoryItems/${categoryId}?isCategoryInItem=${isCategoryInItem}`,
      {
        AddOrRemoveItemsId,
      }
    );
}

// eslint-disable-next-line new-parens
export default new ItemsServices();
