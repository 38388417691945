import React, { useContext, useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  Card,
  Popover,
  Tooltip,
  Skeleton,
} from "antd";
import {
  PlusOutlined,
  DownloadOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import ViewDebitNote from "./ViewDebitNote";
import ViewPurchaseInvoice from "./ViewPurchaseInvoice";
import PurchaseService from "../../Services/PurchaseService";
import { useNavigate } from "react-router-dom";
import { dayjs } from "../../../Utilities/dayjs";
import { Utils } from "../../../Utilities/Utils";
import { useTheme } from "../../Context/ThemeContext";
import { useClient } from "../../Context/ClientContext";
import { InvoiceStatus } from "../../Services/SalesService";
import { PurchaseInvoiceTypes } from "../../../Types/CommonConstants";
import { notificationContext } from "../../Common/PageRoute";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import search from "../../Images/search.svg";

interface IPurchaseDebitNoteListData {
  key: React.Key;
  number: { id: string; name: string };
  invoiceRef: { id: string; name: string };
  name: string;
  date: string;
  amount: string;
  supplierName: string;
}
const PurchaseCreditNotes: React.FC<{ partyId?: string }> = (props) => {
  const { themeData } = useTheme();
  const { RangePicker } = DatePicker;
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [debitNoteList, setDebitNoteList] = useState<any>([]);
  const [openPopArray, setOpenPopArray] = useState<boolean[]>([]);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [debitNoteId, setDebitNoteId] = React.useState<string>("");
  const [searchSupplier, setSearchSupplier] = React.useState<string>("");
  const [purchaseInvoiceId, setPurchaseInvoiceId] = React.useState<string>("");
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);
  const [filterDate, setFilterDate] = React.useState<any>({ from: "", to: "" });
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [invoiceStatus, setInvoiceStatus] = React.useState<number>(
    InvoiceStatus.Active
  );
  const [openPurchaseInvoice, setOpenPurchaseInvoice] =
    React.useState<boolean>(false);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [deleteDebitNote, setDeleteDebitNote] = React.useState<any>({
    id: "",
    isRev: false,
  });
  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns: ColumnsType<IPurchaseDebitNoteListData> = [
    {
      title: "Debit Note No.",
      dataIndex: "number",
      render: (_: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
          className="cursor"
        >
          <span
            style={{ color: "#1677FF", cursor: "pointer" }}
            onClick={() => {
              setIsOpen(true);
              setDebitNoteId(record?.number?.id);
            }}
          >
            {record?.number?.name}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Invoice No.",
      dataIndex: "invoiceRef",
      key: "invoiceNo",
      render: (_: any, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
          className="cursor"
        >
          <span
            style={{ color: "#1677FF", cursor: "pointer" }}
            onClick={() => {
              setOpenPurchaseInvoice(true);
              setPurchaseInvoiceId(record?.invoiceRef?.id);
            }}
          >
            {record?.invoiceRef?.name}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "right",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.amount}
        </Skeleton>
      ),
    },
    invoiceStatus !== InvoiceStatus.Deleted
      ? {
          title: "  ",
          dataIndex: "icon1",
          render: (_, record, index) => (
            <Skeleton
              active
              loading={loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Popover
                placement="bottomRight"
                content={ellipsisContent(record, index)}
                trigger="click"
                open={openPopArray[index]}
                onOpenChange={(newOpen) => handleOpenChange(index, newOpen)}
              >
                <EllipsisOutlined style={{ color: "#3D4998" }} />
              </Popover>
            </Skeleton>
          ),
        }
      : {},
  ];

  const handleOpenChange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenPopArray(updatedOpenPopArray);
  };

  const ellipsisContent = (record: any, index: number) => {
    return (
      <div>
        <Tooltip title="Delete temporarily">
          <Button
            style={{ width: 100 }}
            onClick={() => {
              setConfirmation(true);
              setDebitNoteId(record?.id);
              handleOpenChange(index, false);
            }}
          >
            Delete
          </Button>
        </Tooltip>
      </div>
    );
  };

  if (!props?.partyId) {
    columns.splice(1, 0, {
      title: "Supplier Name",
      dataIndex: "supplierName",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
          className="cursor"
        >
          {record?.supplierName}
        </Skeleton>
      ),
    });
  }

  React.useEffect(() => {
    if (deleteDebitNote?.id) {
      const DeleteCreditNote = async () => {
        setLoading(true);
        await PurchaseService.deletePurchaseInvoice(
          currentClient?.id!,
          deleteDebitNote.id,
          deleteDebitNote.isRev,
          true
        ).then((res) => {
          setLoading(false);
          if (res?.result) {
            setRefreshAtDelete(!refreshAtDelete);
            openNotification("success", `Entry deleted successfully `);
          }
        });
      };
      DeleteCreditNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDebitNote]);

  React.useEffect(() => {
    setLoading(true);
    if (currentClient?.id) {
      getDebitNoteList(
        currentClient?.id,
        invoiceStatus,
        PurchaseInvoiceTypes.PurchaseDebitNote,
        (page - 1) * pageSize,
        pageSize,
        filter.sortCol,
        filter.sortDir,
        searchSupplier,
        filterDate.from,
        filterDate.to,
        props?.partyId
      );
    } else if (currentClient?.id === null) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // props.trigger,
    // trigger,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    searchSupplier,
    filterDate.from,
    filterDate.to,
    invoiceStatus,
    refreshAtDelete,
    props?.partyId,
    // location.pathname,
  ]);

  const getDebitNoteList = async (
    clientId: string,
    status: InvoiceStatus,
    type: PurchaseInvoiceTypes,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    search?: string,
    from?: any,
    to?: any,
    partyId?: string
  ) => {
    await PurchaseService.getPurchaseInvoiceList(
      clientId,
      status,
      type,
      start,
      length,
      sortCol,
      sortDir,
      search,
      from,
      to,
      partyId
    )
      .then((res: any) => {
        if (res.items.length > 0) {
          setDebitNoteList(
            res?.items?.map((v: any, i: any) => {
              return {
                key: i,
                id: v?.id,
                date: v?.date,
                supplierName: v?.tPartner.name,
                number: { name: v?.number, id: v?.id },
                amount: Utils.getFormattedNumber(v?.totalInvoiceAmount),
                invoiceRef: {
                  id: v?.invoiceRef?.id,
                  name: v?.invoiceRef?.name,
                },
              };
            })
          );
          setTotalRecords(res?.totalRecords);
        } else if (res.items.length === 0) {
          setDebitNoteList([]);
          setTotalRecords(res?.totalRecords);
        }
        setLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setDebitNoteList([]);
      });
  };

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "Date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
  };

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        marginTop: "6px",
        padding: "48px 0px 38px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <Row
        justify="space-between"
        style={{
          // paddingTop: props.partyId ? "10px" : "0px"
          // marginTop: 10,
          padding: "0px 0px 3px 0px",
        }}
      >
        <Col>
          <Space size={width <= 768 ? 440 : 5}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                props?.partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/addDebitNote/${props?.partyId}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/addDebitNote`
                    );
              }}
            >
              Debit Note
            </Button>
            <Button type="default" icon={<DownloadOutlined />}>
              Export
            </Button>
          </Space>
        </Col>
        <Col
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          <Row gutter={width <= 768 ? 60 : 8}>
            <Col>
              <Input
                style={{ width: "200px", border: "1px solid #f1f3ff" }}
                placeholder="Search Supplier"
                allowClear
                onChange={(e: any) => {
                  setPage(1);
                  setSearchSupplier(e.target.value);
                }}
                suffix={
                  searchSupplier ? "" : <img alt="Search.." src={search} />
                }
              />
            </Col>
            <Col>
              <RangePicker
                allowClear={false}
                format="DD/MM/YYYY"
                style={{ width: "220px", border: "1px solid #f1f3ff" }}
                onChange={(e: any) => {
                  console.log(e);
                  e === null
                    ? setFilterDate({})
                    : setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: [dayjs().add(-7, "d"), dayjs()],
                  },
                  {
                    label: "Last 15 Days",
                    value: [dayjs().add(-15, "d"), dayjs()],
                  },
                  {
                    label: "This Month",
                    value: [dayjs().startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Month",
                    value: [dayjs().date(0).startOf("month"), dayjs().date(0)],
                  },
                  {
                    label: "Quarter 1",
                    value: [
                      dayjs().month(3).startOf("month"),
                      dayjs().month(5).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 2",
                    value: [
                      dayjs().month(6).startOf("month"),
                      dayjs().month(8).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 3",
                    value: [
                      dayjs().month(9).startOf("month"),
                      dayjs().month(11).endOf("month"),
                    ],
                  },
                  {
                    label: "Quarter 4",
                    value: [
                      dayjs().month(0).startOf("month"),
                      dayjs().month(2).endOf("month"),
                    ],
                  },
                  {
                    label: "This Year",
                    //           dayjs().month(0).startOf("month"),
                    // dayjs(),
                    value: [dayjs().month(0).startOf("month"), dayjs()],
                  },
                  {
                    label: "Last Year",
                    value: [
                      dayjs().month(-12).startOf("month"),
                      dayjs().month(-1).endOf("month"),
                    ],
                  },
                ]}
              />
            </Col>
            <Col>
              <Select
                clearIcon={true}
                value={invoiceStatus}
                style={{ width: 120 }}
                allowClear
                options={[
                  { value: InvoiceStatus.Active, label: "Active" },
                  { value: InvoiceStatus.Deleted, label: "Deleted" },
                ]}
                onSelect={(val) => {
                  setPage(1);
                  setInvoiceStatus(val);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as IPurchaseDebitNoteListData[])
            : debitNoteList
        }
        onChange={tableSort}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
      />
      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            setDeleteDebitNote({
              id: debitNoteId,
              isRev: invoiceStatus === InvoiceStatus.Deleted ? true : false,
            });
            setConfirmation(false);
          }}
          text={
            invoiceStatus === InvoiceStatus.Deleted
              ? `Are you sure you want to revive?`
              : `Are you sure you want to delete?`
          }
        />
      )}
      {open && (
        <ViewDebitNote
          open={open}
          onCancel={() => setIsOpen(false)}
          debitNoteId={debitNoteId}
        />
      )}
      {openPurchaseInvoice && (
        <ViewPurchaseInvoice
          open={openPurchaseInvoice}
          onCancel={() => setOpenPurchaseInvoice(false)}
          invoiceId={purchaseInvoiceId}
        />
      )}
    </Card>
  );
};
export default PurchaseCreditNotes;
