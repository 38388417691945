import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Spin,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { dayjs } from "../../../../Utilities/dayjs";
import TdsTcsServices, {
  Limit_Type,
  Transaction_Type,
} from "../../../Services/TdsTcsServices";
import {
  notificationContext,
  NotificationType,
} from "../../../Common/PageRoute";
import { LocalChunkSize } from "papaparse";
import { ITdsNature, TDSNature } from "../../Master/TDSNatures";

interface IPropsTcs {
  open: boolean;
  setOpen: (value: boolean) => void;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  editData?: any;
}

const AddTdsTaxRate = (props: IPropsTcs) => {
  // console.log("props: ", props);
  const [form] = Form.useForm();
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [type, setType] = useState<Transaction_Type>(Transaction_Type.Domestic);

  const openNotification = (type: NotificationType, message: string) => {
    api[type]({
      message: "",
      description: message,
      duration: 2,
      closeIcon: false,
      style: {
        width: 350,
      },
    });
  };

  useEffect(() => {
    if (props.editData?.id) {
      console.log("Props data: ", props.editData);
      const findData = TDSNature.find(
        (x: ITdsNature) => x.key === props.editData?.sectionKey
      );
      form.setFieldsValue({
        section: {
          label: `${findData?.section} - ${findData?.title}`,
          value: findData?.key,
          key: findData?.key,
          title: findData?.section,
        },

        natureOfPayment: props.editData?.natureOfPayment,
        limitType: props.editData?.limitType,
        rateDetail: props.editData?.rateDetail,
        transactionType: props.editData?.transactionType,
        effectiveFrom:
          props.editData?.effectiveFrom !== undefined &&
          props.editData?.effectiveFrom !== null &&
          props.editData?.effectiveFrom !== ""
            ? dayjs(
                dayjs(props.editData?.effectiveFrom).format("DD/MM/YYYY"),
                "DD/MM/YYYY"
              )
            : undefined,
        effectiveTo:
          props.editData?.effectiveTo !== undefined &&
          props.editData?.effectiveTo !== null &&
          props.editData?.effectiveTo !== ""
            ? dayjs(
                dayjs(props.editData?.effectiveTo).format("DD/MM/YYYY"),
                "DD/MM/YYYY"
              )
            : undefined,
        form: props.editData?.form,
      });
      setType(props.editData?.transactionType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editData?.id]);

  const saveTDS = () => {
    form.validateFields().then(async (value) => {
      try {
        setSaveButtonLoading(true);
        const postData = {
          ...value,
          sectionKey: value?.section.value,
          section: value?.section.title,
          effectiveFrom:
            value?.effectiveFrom !== undefined
              ? dayjs(
                  dayjs(value?.effectiveFrom).format("DD/MM/YYYY"),
                  "DD/MM/YYYY"
                )
              : null,
          effectiveTo:
            value?.effectiveTo !== undefined
              ? dayjs(
                  dayjs(value?.effectiveTo).format("DD/MM/YYYY"),
                  "DD/MM/YYYY"
                )
              : null,
        };

        console.log("data", value);

        const res = await TdsTcsServices.addTDS(postData, props.editData?.id);

        if (res.status) {
          openNotification("success", `TDS rate added successfully..`);
          setSaveButtonLoading(false);
          props.setOpen(false);
          props.setTrigger((x: boolean) => !x);
        } else {
          setSaveButtonLoading(false);
          // openNotification("error", res?.message);
          setSaveButtonLoading(false);
          res?.message && openNotification("error", res.message);
        }
      } catch (err: any) {
        console.log(err);
        // openNotification("error", err);
        setSaveButtonLoading(false);
        err && openNotification("error", err);
      }
    });
  };
  const setValue = (selSection: any) => {
    console.log("Section data: ", selSection);

    const findData = TDSNature.find(
      (x: ITdsNature) => x.key === selSection.key
    );

    if (findData) {
      if (props.editData?.section !== findData.section) {
        console.log(findData);
        form.setFieldsValue({
          section: selSection,
          natureOfPayment: findData?.title,
          limitType: findData?.term,
          rateDetail: {
            thresholdLimit: findData?.thresholdLimit,
            rates: {
              indHUFRate: findData.rates.indHUF,
              otherRate: findData.rates.others,
              ifPANNotRate: findData.rates.ifNotPAN,
            },
          },
          transactionType: findData?.type,
          form: findData?.form,
          effectiveFrom:
            findData?.effectiveFrom !== undefined &&
            findData?.effectiveFrom !== ""
              ? dayjs(
                  dayjs(findData?.effectiveFrom, "DD/MM/YYYY").format(
                    "DD/MM/YYYY"
                  ),
                  "DD/MM/YYYY"
                )
              : undefined,
          effectiveTo:
            findData?.effectiveTo !== undefined && findData?.effectiveTo !== ""
              ? dayjs(
                  dayjs(findData?.effectiveTo, "DD/MM/YYYY").format(
                    "DD/MM/YYYY"
                  ),
                  "DD/MM/YYYY"
                )
              : undefined,
        });
        setType(findData?.type);
      } else {
        form.setFieldsValue({
          section: props.editData?.section,
          natureOfPayment: props.editData?.natureOfPayment,
          limitType: props.editData?.limitType,
          rateDetail: props.editData?.rateDetail,
          transactionType: props.editData?.transactionType,
          form: props.editData?.form,
          effectiveFrom:
            props.editData?.effectiveFrom !== undefined &&
            props.editData?.effectiveFrom !== ""
              ? dayjs(
                  dayjs(props.editData?.effectiveFrom, "DD/MM/YYYY").format(
                    "DD/MM/YYYY"
                  ),
                  "DD/MM/YYYY"
                )
              : undefined,
          effectiveTo:
            props.editData?.effectiveTo !== undefined &&
            props.editData?.effectiveTo !== ""
              ? dayjs(
                  dayjs(props.editData?.effectiveTo, "DD/MM/YYYY").format(
                    "DD/MM/YYYY"
                  ),
                  "DD/MM/YYYY"
                )
              : undefined,
        });
        setType(props.editData?.transactionType);
      }
    }
  };

  return (
    <>
      <Modal
        maskClosable={false}
        title={props.editData?.id ? "Update TDS Rate" : "Add TDS Rate"}
        open={props.open}
        width={900}
        // centered
        onCancel={() => props.setOpen(false)}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => saveTDS()}
              style={{ width: "80px" }}
              loading={saveButtonLoading}
              className="modelSaveButtonMargin"
            >
              {props.editData?.id ? "Update" : "Save"}
            </Button>
          </div>
        }
      >
        {/* <Spin */}
        {/* // indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} */}
        {/* // spinning={loading} */}
        {/* > */}
        <Form
          form={form}
          // layout="vertical"
          labelWrap={true}
          autoComplete="off"
          colon={false}
          labelAlign="left"
          name="tscDetail"
          requiredMark={false}
          initialValues={{ limitType: Limit_Type.Yearly, transactionType: 0 }}
        >
          <Divider className="ca-model-css" />
          <Row gutter={20}>
            <Col lg={24}>
              <Form.Item
                className="mb-14"
                // className="inputBoxMb8"
                name="section"
                label="Section"
                labelCol={{ xs: 4, sm: 4 }}
                wrapperCol={{ xs: 20, sm: 20 }}
                rules={[{ required: true, message: "Section is required" }]}
              >
                {/* <Input /> */}
                <Select
                  labelInValue
                  style={{ width: "100%" }}
                  options={TDSNature?.map((x: any, index: number) => ({
                    label: `${x.section} - ${x.title}`,
                    value: x.key,
                    key: x.key,
                    title: x.section,
                  }))}
                  onChange={(e) => setValue(e)}
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item
                className="mb-14"
                name="natureOfPayment"
                label="Nature of Payment"
                labelCol={{ xs: 4, sm: 4 }}
                wrapperCol={{ xs: 20, sm: 20 }}
                rules={[
                  { required: true, message: "Nature of payment is required" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                className="mb-14"
                labelCol={{ xs: 8, sm: 8 }}
                wrapperCol={{ xs: 16, sm: 16 }}
                label="Effective From"
                name="effectiveFrom"
              >
                <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                className="mb-14"
                labelCol={{ xs: 8, sm: 8 }}
                wrapperCol={{ xs: 16, sm: 16 }}
                label="Effective To"
                name="effectiveTo"
              >
                <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                labelCol={{ xs: 8, sm: 8 }}
                wrapperCol={{ xs: 16, sm: 16 }}
                className="mb-14"
                label="Limit Type"
                name="limitType"
              >
                <Select
                  options={[
                    { label: "Yearly", value: Limit_Type.Yearly },
                    { label: "Monthly", value: Limit_Type.Monthly },
                    {
                      label: "Per Transaction",
                      value: Limit_Type.PerTransaction,
                    },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col lg={12}>
              <Form.Item
                className="mb-14"
                labelCol={{ xs: 8, sm: 8 }}
                wrapperCol={{ xs: 16, sm: 16 }}
                label="Transaction Type"
                name="transactionType"
              >
                <Select
                  options={[
                    { label: "Domestic", value: Transaction_Type.Domestic },
                    {
                      label: "International",
                      value: Transaction_Type.International,
                    },
                  ]}
                  onChange={(e) => setType(e)}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                className="mb-14"
                labelCol={{ xs: 8, sm: 8 }}
                wrapperCol={{ xs: 16, sm: 16 }}
                label="Form No."
                name="form"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {/* Rates Block */}
          <Row gutter={20}>
            <Divider
              orientation="left"
              orientationMargin="0"
              className="dividertext"
              style={{ marginTop: "11px", marginBottom: "11px" }}
            >
              {type === Transaction_Type.Domestic ? (
                <>
                  <p style={{ fontWeight: 600 }}>Domestic</p>
                </>
              ) : (
                <>
                  <p style={{ fontWeight: 600 }}>International</p>
                </>
              )}
            </Divider>
            <Col lg={12}>
              <Form.Item
                className="mb-14"
                label="Threshold Limit"
                labelCol={{ xs: 8, sm: 8 }}
                wrapperCol={{ xs: 16, sm: 16 }}
                name={["rateDetail", "thresholdLimit"]}
              >
                <InputNumber
                  min={0}
                  // max={100}
                  style={{ width: "100%" }}
                  controls={false}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                className="mb-14"
                labelCol={{ xs: 8, sm: 8 }}
                wrapperCol={{ xs: 16, sm: 16 }}
                label="Ind / HUF Rate"
                name={["rateDetail", "rates", "indHUFRate"]}
                rules={[
                  {
                    required: true,
                    message: "Ind / HUF rate is required",
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  controls={false}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                className="mb-14"
                labelCol={{ xs: 8, sm: 8 }}
                wrapperCol={{ xs: 16, sm: 16 }}
                label="Other Rate"
                name={["rateDetail", "rates", "otherRate"]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  controls={false}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                className="mb-14"
                labelCol={{ xs: 8, sm: 8 }}
                wrapperCol={{ xs: 16, sm: 16 }}
                label="Rate If PAN not available"
                name={["rateDetail", "rates", "ifPANNotRate"]}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  controls={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {/* </Spin> */}
        <Divider style={{ margin: "0px" }} />
      </Modal>
      {contextHolder}
    </>
  );
};

export default AddTdsTaxRate;
