import React from "react";
import { Button, Divider, Form, Input, Modal } from "antd";
import { useState } from "react";
import { notificationContext } from "../../../Common/PageRoute";

import ItemCategoryService from "../../../Services/ItemCategoryService";
import { useClient } from "../../../Context/ClientContext";

interface IPropsTcs {
  open: boolean;
  onCancel: (value: boolean) => void;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  editData?: any;
  setAddCatVal?: any;
}

const AddCategoryItemsModel = (props: IPropsTcs) => {
  const { currentClient } = useClient();
  const [form] = Form.useForm();
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const { openNotification } = React.useContext(notificationContext);

  React.useEffect(() => {
    if (props.editData?.id)
      form.setFieldsValue({ categoryName: props.editData.categoryName });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editData]);

  const setCategory = () => {
    form.validateFields().then(async (value) => {
      try {
        setSaveButtonLoading(true);
        const postData = {
          ...value,
          // effectiveDate: dayjs(
          //   dayjs(value?.effectiveDate).format("DD/MM/YYYY"),
          //   "DD/MM/YYYY"
          // ),
        };

        const res = await ItemCategoryService.postCategoryData(
          currentClient?.id,
          props?.editData?.id,
          value
        );
        console.log("result", res);

        if (res.status) {
          // debugger;
          props.setAddCatVal && props.setAddCatVal(res?.result);
          openNotification(
            "success",
            `${
              props?.editData?.id
                ? "Item Category updated successfully."
                : "Item Category added successfully."
            }`
          );
          setSaveButtonLoading(false);
          props.onCancel(false);
          props.setTrigger((x: boolean) => !x);
        } else {
          setSaveButtonLoading(false);
          console.error("error", res?.message);
          res?.message && openNotification("error", res.message);
        }
      } catch (err: any) {
        // openNotification("error", err);
        console.error("error", err);
        err && openNotification("error", err);
        setSaveButtonLoading(false);
      }
    });
  };

  return (
    <Form
      form={form}
      // layout="vertical"
      labelWrap={true}
      labelCol={{ xs: 24, sm: 7 }}
      wrapperCol={{ xs: 24, sm: 16 }}
      autoComplete="off"
      colon={false}
      labelAlign="left"
      name="tscDetail"
      requiredMark={true}
      // initialValues={intialValue}
    >
      <Modal
        maskClosable={false}
        title={
          props.editData?.id ? "Update Category Name" : "Add Category Name"
        }
        open={props.open}
        width={500}
        // centered
        // onOk={handleOk}
        onCancel={() => props.onCancel(false)}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancel(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => setCategory()}
              style={{ width: "80px" }}
              loading={saveButtonLoading}
              className="modelSaveButtonMargin"
            >
              {props.editData?.id ? "Update" : "Save"}
            </Button>
          </div>
        }
      >
        <Divider className="ca-model-css" />

        <Form.Item
          className="mb-14"
          // className="inputBoxMb8"
          name="categoryName"
          label="Category Name"
          rules={[{ required: true, message: "Category's name is required" }]}
        >
          <Input />
        </Form.Item>
        {/* </Spin> */}
        <Divider />
      </Modal>
    </Form>
  );
};

export default AddCategoryItemsModel;
