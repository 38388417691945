import { Tabs, TabsProps } from "antd";
import React from "react";
import "../../Common/Acc_Common.css";
import ProductsItem from "./GoodsItems";
import GoodsItems from "./GoodsItems";
import ServiceItems from "./ServiceItems";
import { useLocation } from "react-router-dom";
import UnitsItems from "./UnitsItems";
import CategoryItems from "./CategoryItems";
import { ItemType } from "../../Services/ItemsService";

const InventoryTabs = () => {
  const location = useLocation();

  console.log("location..........", location);

  const goodsProps = (itemType: string, type: string) => {
    debugger;
    if (itemType === "salesGoods")
      return { type: ItemType.SalesGoods, id: location.state.id };
    else if (itemType === "purchaseGoods")
      return { type: ItemType.PurchaseItems, id: location.state.id };
    else if (type === "sales") return { type: ItemType.SalesItems, id: null };
    else if (type === "purchase")
      return { type: ItemType.PurchaseItems, id: null };
    // else if (itemType === "AllItemGoods")
    //   return { type: ItemType.AllItemGoods, id: location.state.id };
    // else if (itemType === "AllItemServices")
    //   return { type: ItemType.AllItemServices, id: location.state.id };
  };

  const serviceProps = (itemType: string, type: string) => {
    if (itemType === "salesService")
      return { type: ItemType.SalesService, id: location.state.id };
    else if (itemType === "purchaseService")
      return { type: ItemType.PurchaseService, id: location.state.id };
    else if (type === "sales") return { type: ItemType.SalesService, id: null };
    else if (type === "purchase")
      return { type: ItemType.PurchaseService, id: null };
  };

  const items: TabsProps["items"] = [
    {
      key: "Goods",
      label: "Goods",
      children: (
        <GoodsItems
          // goodsItm={goodsProps(location.state?.itemType, location.state?.type)}
          goodsItm={{
            type: ItemType.AllItemGoods,
            id: location.state.id,
            isCurrentItem:
              location.state?.itemType === "AllItemGoods" ? true : false,
          }}
        />
      ),
    },
    {
      key: "Services",
      label: "Services",
      children: (
        <GoodsItems
          goodsItm={{
            type: ItemType.AllItemServices,
            id: location.state.id,
            isCurrentItem:
              location.state?.itemType === "AllItemServices" ? true : false,
          }}
          //
        />
      ),
    },
    // {
    //   key: "3",
    //   label: "Category",
    //   children: <CategoryItems />,
    // },
    // {
    //   key: "4",
    //   label: "Units",
    //   children: <UnitsItems />,
    // },
  ];

  return (
    <div>
      <Tabs
        className="ca-tab-inventory"
        size="small"
        defaultActiveKey={
          location.state?.itemType === "salesGoods" ||
          location.state?.itemType === "purchaseGoods" ||
          location.state?.itemType === "AllItemGoods"
            ? "Goods"
            : "Services"
        }
        items={items}
      />
    </div>
  );
};

export default InventoryTabs;
