import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import React from "react";
import TermsAndConditionsService from "../../Services/TermsAndConditionsService";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";
import { Voucher_Type } from "../../Services/VoucherServices";
import SunEditor from "suneditor-react";
import { LoadingOutlined } from "@ant-design/icons";
import { SettingsSections } from "../../Services/SettingService";
interface Modelprops {
  onCancel: any;
  isModelOpen: boolean;
  id?: any;
  voucher?: Voucher_Type | undefined;
  setRefresh: any;
  voucherTitle?: string;
}
const AddTermsAndCondition = ({
  isModelOpen,
  onCancel,
  id,
  voucher,
  setRefresh,
  voucherTitle,
}: Modelprops) => {
  const [form] = Form.useForm();
  const { currentClient, companySettings } = useClient();
  const { openNotification } = React.useContext(notificationContext);
  const [voucherOption, setVoucherOption] = React.useState<{
    type: Voucher_Type;
    titleName: string;
  }>({ type: Voucher_Type.All, titleName: "" });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  const [termsAndCond, setTermsAndCond] = React.useState<any>();

  const addTermsndCondData = () => {
    form.validateFields().then(async (val) => {

      console.log("val", val);
      let obj = {
        ...val,
        voucherTitle: val.voucherType.value,
        voucherType: Number(val.voucherType.title),
      };
      setBtnLoading(true);
      let res = await TermsAndConditionsService?.saveData(
        currentClient?.id,
        id,
        voucherOption.type ?? voucher,
        voucherOption.titleName,
        obj
      );
      if (res) {
        setBtnLoading(false);
        setRefresh((x: boolean) => !x);
        onCancel(false);
        openNotification(
          "success",
          `Term & Condition ${id ? "updated successfully." : "added successfully."
          }`
        );
        onCancel(false);
      } else {
        setBtnLoading(false);
        openNotification("error", "error");
      }
    });
  };

  React.useEffect(() => {
    if (id) {
      const getDatabyId = async () => {
        setLoading(true);
        let res = await TermsAndConditionsService?.getTermsAndConditionbyId(
          currentClient?.id,
          id,
          voucher,
          voucherTitle
        );
        // console.log("response", res);

        // if (res?.result?.termNConditions !== undefined) {
        //   setTermsAndCond(res?.result?.termNConditions);
        // } else {
        //   form?.setFieldsValue({
        //     termNConditions: res?.result?.termNConditions,
        //   });
        // }

        if (res) {
          setTermsAndCond(res?.result?.termNConditions);
          setVoucherOption({
            type: Number(res?.result?.voucherType),
            titleName: res?.result?.voucherTitle,
          });
          form?.setFieldsValue({
            templateName: res?.result?.templateName,
            termNConditions: res?.result?.termNConditions,
            voucherType: {
              value: res?.result?.voucherTitle,
              label: res?.result?.voucherTitle,
              title: `${res?.result?.voucherType}`,
            },
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      };
      getDatabyId();
    }
  }, []);

  const handleSunEditorChange = (content: any) => {
    form.setFieldsValue({
      termNConditions: content,
    });
  };
  return (
    <>
      <Modal
        width={1000}
        open={isModelOpen}
        onCancel={() => onCancel(false)}
        title="Add Terms & Conditions"
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => onCancel(false)}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button
              loading={btnLoading}
              type="primary"
              onClick={() => addTermsndCondData()}
            >
              {id ? "Edit" : "Save"}
            </Button>
          </div>
        }
      >
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 20 }} />}
          spinning={loading}
        >
          <Form form={form} labelAlign="left" requiredMark={false}>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="templateName"
                  label="Template Name"
                  labelCol={{ span: 8 }}
                  rules={[
                    { required: true, message: "Template's name is required" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="voucherType"
                  label="Voucher Template"
                  labelCol={{ span: 8 }}
                  labelAlign="right"
                  rules={[
                    { required: true, message: "Voucher type is required." },
                  ]}
                >
                  <Select
                    showSearch
                    labelInValue
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    placeholder="Select voucher template..."
                    options={companySettings?.setting?.vouchersSettings
                      ?.filter(
                        (x) =>
                          x.settingType === SettingsSections.Invoice ||
                          x.settingType === SettingsSections.PInvoice ||
                          x.settingType === SettingsSections.CrNote ||
                          x.settingType === SettingsSections.DrNote
                      )
                      ?.map((x: any, index: number) => ({
                        value: x?.title,
                        label: x?.title,
                        title:
                          x?.settingType === SettingsSections.Invoice
                            ? `${Voucher_Type.SalesVoucher}`
                            : x?.settingType === SettingsSections.PInvoice
                              ? `${Voucher_Type.PurchaseVoucher}`
                              : x.settingType === SettingsSections.CrNote
                                ? `${Voucher_Type.CreditNote}`
                                : `${Voucher_Type.DebitNote}`,
                        key: index,
                      }))}
                    onChange={(x: any) => {
                      setVoucherOption({
                        type: Number(x.title),
                        titleName: x.value,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="termNConditions" label="Terms and Conditions" rules={[
              { required: true, message: "Term and condition is required." },
            ]}>
              <SunEditor
                setContents={termsAndCond}
                setOptions={{
                  buttonList: [
                    ["undo", "redo"],
                    ["font", "fontSize"],
                    ["bold", "underline", "italic", "strike"],
                    ["fontColor", "hiliteColor"],
                    ["align", "list", "lineHeight"],
                    ["outdent", "indent"],
                    ["removeFormat"],
                  ],
                  defaultTag: "div",
                  showPathLabel: false,
                }}
                height="150px"
                onChange={handleSunEditorChange}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default AddTermsAndCondition;
