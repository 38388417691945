import React, { useContext } from "react";
import { Button, Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import "../../../CapsitechAccountsComponents/Common/Acc_Common.css";
import "../../Pages/Parties/Party.css";
import "../../Services/SettingService";
import TextArea from "antd/es/input/TextArea";
import logicon from "../../Images/CapsitechAccounts.svg";
import { Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import { dayjs } from "../../../Utilities/dayjs";
import {
  InvoiceTemplates,
  ITemplateSettings,
  SettingsSections,
} from "../../Services/SettingService";
import { notificationContext } from "../../Common/PageRoute";
import Template2 from "./Template2";
import Template1 from "./Template1";
import Template3 from "./Template3";

// export enum fontoptions {
//   Arial,
//   Calibri,
//   Cambria,
//   Georgia,
//   Myriad,
//   Thoma,
// }
export const fontSizeOptions = [
  { label: "8pt", value: 0 },
  { label: "9pt", value: 1 },
  { label: "10pt", value: 2 },
  { label: "11pt", value: 3 },
];

export const headingfontsize = [
  { label: "8pt", value: 0 },
  { label: "9pt", value: 1 },
  { label: "10pt", value: 2 },
  { label: "11pt", value: 3 },
];

export const taxSubtotals: any = [
  { label: "Tax rates", value: 0 },
  { label: "Tax rates over 0%", value: 1 },
  { label: "A single tax subtotal", value: 2 },
];

const EditInvoiceTemplate: React.FC = () => {
  const { Text } = Typography;
  const { openNotification } = useContext(notificationContext);
  const navigate = useNavigate();
  let { type }: any = useParams();
  const { currentClient, companySettings, updateCompanySettings } = useClient();
  const [margin, setMargin] = React.useState<number>(0);
  const typeAsInt = parseInt(type);
  console.log("type =>>", type);

  // const { type } = useParams();
  const [form] = Form.useForm();

  const [isChanged, setIsChanged] = React.useState<ITemplateSettings>({
    type: InvoiceTemplates.Template1,
    showTaxNo: true,
    showTaxColumn: true,
    taxInclusive: true,
    name: "",
    paperSize: "",
    measureIn: "",
    topMargin: 0,
    bottomMargin: 0,
    addressPadding: 0,
    font: "",
    fontSize: 0,
    headingFontSize: 0,
    taxSubtotals: 0,

    contactDetails: "",
    showUnitPriceAndQty: true,
    showPaymentAdviceCutAway: true,
    showRegisteredAddress: true,
    hideDisc: true,
    showContactAcNo: true,
    showColumnHeading: true,
    showLogo: true,
    logoAlign: "",
    showCompanyAddress: true,
    showGSTIN: true,
    showPan: true,
    showEmail: true,
    showPhone: true,
    showWebsite: true,
    showShipping: true,
    showEBillNo: true,
    showEInvoiceNo: true,
    showItemCode: true,
    showUnit: true,
    showHSN: true,
    showTaxableValue: true,
    showBankDetail: true,
    showDispatchDetail: true,
    showQRorUPI: true,
    specialNotes: "",
    psCreditCard: {
      name: "",
      id: 0,
    },
    psPayPal: {
      name: "",
      id: 0,
    },
    psBankPayments: {
      name: "",
      id: 0,
    },
  });

  React.useEffect(() => {
    if (typeAsInt || typeAsInt === 0) {
      const allTempleteData = companySettings?.setting;

      const templeteData = allTempleteData?.templateSettings?.find(
        (x) => x?.type === typeAsInt
      );
      // debugger;
      if (templeteData) {
        form.setFieldsValue(templeteData);
        setIsChanged(templeteData);
      } else {
        console.error("Not found templeteData");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySettings]);

  const onsubmit = () => {
    form
      .validateFields()
      .then(async (values: any) => {
        const data = { type: typeAsInt, ...values };
        console.log("fromData", data, values);

        const postData: any = {
          templateSettings: companySettings?.setting?.templateSettings?.map(
            (t) => {
              if (t.type === ((typeAsInt ?? 0) as InvoiceTemplates)) t = data;
              return t;
            }
          ),
          // defaultTemplate: typeAsInt ?? 0,
        };

        console.log("values", postData);
        if (postData) {
          await updateCompanySettings!(
            SettingsSections.Template,
            {
              ...companySettings,
              setting: {
                ...companySettings?.setting!,
                templateSettings: postData?.templateSettings,
                // defaultTemplate: postData?.defaultTemplate,
              },
            },
            postData
          ).then((result: any) => {
            if (result?.status) {
              openNotification("success", "Setting updated successfully.");
              //  setSaveButtonLoading(false);
            } else {
              //  setSaveButtonLoading(false);
              openNotification("error", result.message);
            }
          });
        }
        // updateCompanySettings!(
        //   SettingsSections.Template,
        //   {
        //     ...companySettings,
        //     setting: {
        //       ...companySettings?.setting!,
        //       template: data?.dueOn,
        //     },
        //   },
        //   data
        // );
      })
      .catch((error: any) => {
        console.log(error, "error-------");
      });
  };

  const handleCheckboxChange = (align: any) => {
    setIsChanged((prevState) => ({
      ...prevState,
      logoAlign: prevState.logoAlign === align ? "left" : align,
    }));
  };

  return (
    <>
      <div style={{ backgroundColor: "#f7f8faff" }}>
        <Row>
          <Col lg={11} md={10}>
            <Card
              style={{
                border: "1.5px solid #eef2fe",
                height: "96%",
                overflowY: "hidden",
              }}
              className="templatesec"
            >
              <Row
                style={{
                  fontWeight: 500,
                  color: "#0b308e",
                  alignItems: "center",
                }}
                justify={"space-between"}
              >
                <Col
                  style={{
                    borderBottom: "1px solid rgb(210, 208, 206)",
                    padding: "10px 0",
                  }}
                >
                  <span>Email Template</span>
                </Col>
                <Col>
                  <Button type="primary">
                    <RedoOutlined style={{ fontSize: "16px" }} />
                    Reset
                  </Button>
                </Col>
              </Row>
              <Form
                form={form}
                name="templateform"
                requiredMark={false}
                colon={false}
                labelAlign="left"
                size="small"
                labelCol={{ span: 11 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
              >
                <Row style={{ padding: "15px 0px" }}>
                  <Col lg={12}>
                    <Form.Item label="Name" name="name" className="inputBoxMb8">
                      <Input style={{ width: "147px" }} disabled />
                    </Form.Item>
                    <Form.Item
                      label="Paper Size"
                      name="paperSize"
                      className="inputBoxMb8"
                    >
                      <Select
                        style={{ width: "147px" }}
                        options={[{ value: "A4", label: "A4" }]}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Measure in"
                      name="measureIn"
                      className="inputBoxMb8"
                    >
                      <Select
                        style={{ width: "147px" }}
                        options={[
                          {
                            value: "cm",
                            label: "cm",
                          },
                          {
                            value: "inch",
                            label: "inch",
                          },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Top margin"
                      name="topMargin"
                      className="inputBoxMb8"
                    >
                      <Input
                        style={{ width: "147px" }}
                        onChange={(e: any) => {
                          setIsChanged({
                            ...isChanged,
                            topMargin: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Bottom margin"
                      name="bottomMargin"
                      className="inputBoxMb8"
                    >
                      <Input
                        style={{ width: "147px" }}
                        onChange={(e: any) => {
                          setIsChanged({
                            ...isChanged,
                            bottomMargin: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                    {/* <Form.Item
                      label="Address padding"
                      name="addressPadding"
                      className="inputBoxMb8"
                    >
                      <Input style={{ width: "147px" }} />
                    </Form.Item> */}
                    <Form.Item label="Font" name="font" className="inputBoxMb8">
                      <Select
                        style={{ width: "147px" }}
                        defaultValue={isChanged.font}
                        options={[
                          { value: "Arial", label: "Arial" },
                          { value: "Calibri", label: "Calibri" },
                          { value: "Cambria", label: "Cambria" },
                          { value: "Georgia", label: "Georgia" },
                          { value: "Myriad", label: "Myriad-Pro" },
                          { value: "Tahoma", label: "Tahoma" },
                        ]}
                        onChange={(e: any) => {
                          setIsChanged({
                            ...isChanged,
                            font: e,
                          });
                          //console.log(isChanged.font);
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Font size"
                      name="fontSize"
                      className="inputBoxMb8"
                    >
                      <Select
                        style={{ width: "147px" }}
                        options={fontSizeOptions}
                        onChange={(e: any) => {
                          setIsChanged({
                            ...isChanged,
                            fontSize: e,
                          });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Heading font size"
                      name="headingFontSize"
                      className="inputBoxMb8"
                    >
                      <Select
                        style={{ width: "147px" }}
                        options={headingfontsize}
                        onChange={(e) => {
                          setIsChanged({
                            ...isChanged,
                            headingFontSize: e,
                          });
                        }}
                      />
                    </Form.Item>
                    <Row style={{ paddingTop: "8px", paddingBottom: "10px" }}>
                      <span style={{ color: "gray", paddingBottom: "8px" }}>
                        Show tax subtotals by
                      </span>
                      <Form.Item name="taxSubtotals" className="inputBoxMb8">
                        <Select
                          style={{ width: "272px" }}
                          options={taxSubtotals}
                          onChange={(e) => {
                            setIsChanged({
                              ...isChanged,
                              taxSubtotals: e,
                            });
                          }}
                        />
                      </Form.Item>
                    </Row>

                    <span>
                      Enter any additional details (to be displayed in notes)
                    </span>
                    <Form.Item className="inputBoxMb8" name={"specialNotes"}>
                      <div style={{ width: "280px" }}>
                        <TextArea
                          rows={4}
                          id="additionaldetails"
                          onChange={(e: any) => {
                            setIsChanged({
                              ...isChanged,
                              specialNotes: e.target.value,
                            });
                            //console.log(additionaldocomentdetails, "details");
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Col>

                  <Col lg={11} style={{ marginLeft: "13px" }}>
                    <Form.Item
                      label="Show Logo"
                      className="inputBoxMb8"
                      name="showLogo"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showLogo}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showLogo: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showLogo: e.target.checked,
                              logoAlign: "left",
                            });
                          }
                          // console.log(isChanged, "logoimg");
                        }}
                      />
                    </Form.Item>
                    {/* <Form.Item
                      label="Logo alignment"
                      className="inputBoxMb8"
                      name="logoAlign"
                      valuePropName="checked"
                    >
                      <Row justify="space-between">
                        <Col>
                          <Checkbox
                            checked={
                              isChanged.showLogo &&
                              isChanged.logoAlign === "left"
                            }
                            disabled={!isChanged.showLogo}
                            onChange={() => handleCheckboxChange("left")}
                          >
                            Left
                          </Checkbox>
                        </Col>
                        <Col>
                          <Checkbox
                            checked={
                              isChanged.showLogo &&
                              isChanged.logoAlign === "right"
                            }
                            disabled={!isChanged.showLogo}
                            onChange={() => handleCheckboxChange("right")}
                          >
                            Right
                          </Checkbox>
                        </Col>
                        <Col>
                          <Checkbox
                            checked={
                              isChanged.showLogo &&
                              isChanged.logoAlign === "center"
                            }
                            disabled={!isChanged.showLogo}
                            onChange={() => handleCheckboxChange("center")}
                          >
                            Center
                          </Checkbox>
                        </Col>
                      </Row>
                    </Form.Item> */}
                    <Form.Item
                      label="Company Address"
                      name="showCompanyAddress"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showCompanyAddress}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showCompanyAddress: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showCompanyAddress: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="GSTIN"
                      name="showGSTIN"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showGSTIN}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showGSTIN: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showGSTIN: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Pan Card"
                      name="showPan"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showPan}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showPan: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showPan: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Email"
                      name="showEmail"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showEmail}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showEmail: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showEmail: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Mobile"
                      name="showPhone"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showPhone}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showPhone: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showPhone: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Website"
                      name="showWebsite"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showWebsite}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showWebsite: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showWebsite: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Shipping address"
                      name="showShipping"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showShipping}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showShipping: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showShipping: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="E-Bill No."
                      name="showEBillNo"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showEBillNo}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showEBillNo: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showEBillNo: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="E-Invoice No."
                      name="showEInvoiceNo"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showEInvoiceNo}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showEInvoiceNo: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showEInvoiceNo: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    {/* <Form.Item
                      label="Item Code"
                      name="showItemCode"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showItemCode}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showItemCode: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showItemCode: false,
                            });
                          }
                        }}
                      />
                    </Form.Item> */}

                    <Form.Item
                      label="Unit"
                      name="showUnit"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showUnit}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showUnit: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showUnit: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="HSC/SAC"
                      name="showHSN"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showHSN}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showHSN: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showHSN: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Taxable Value"
                      name="showTaxableValue"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showTaxableValue}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showTaxableValue: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showTaxableValue: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Bank Details"
                      name="showBankDetail"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showBankDetail}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showBankDetail: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showBankDetail: false,
                            });
                          }
                          console.log(isChanged.showBankDetail, "ok");
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Show QR or UPI"
                      name="showQRorUPI"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showQRorUPI}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showQRorUPI: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showQRorUPI: false,
                            });
                          }
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Dispatch Detail"
                      name="showDispatchDetail"
                      className="inputBoxMb8"
                      valuePropName="checked"
                    >
                      <Checkbox
                        defaultChecked={isChanged.showDispatchDetail}
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            setIsChanged({
                              ...isChanged,
                              showDispatchDetail: e.target.checked,
                            });
                          } else {
                            setIsChanged({
                              ...isChanged,
                              showDispatchDetail: false,
                            });
                          }
                          console.log(isChanged.showDispatchDetail, "ok");
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  style={{
                    padding: "10px 0",
                    borderTop: "1.5px solid rgb(210, 208, 206)",
                  }}
                  justify={"end"}
                >
                  <Col className="ps-14">
                    <Button
                      type="default"
                      size="middle"
                      onClick={() => {
                        navigate(
                          `/accounts/clients/${currentClient?.id}/settings/templates`
                        );
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col className="ps-14">
                    <Button
                      type="primary"
                      size="middle"
                      onClick={() => onsubmit()}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>

          {/* Templete 1 GST Format */}
          <Col lg={13} md={10}>
            {/* Template 1 */}
            {type == 0 && (
              <Template1 currentClient={currentClient} isChanged={isChanged} />
            )}
            {/* Template 2 */}
            {type == 1 && (
              <Template2 currentClient={currentClient} isChanged={isChanged} />
            )}
            {type == 2 && (
              <Template3 currentClient={currentClient} isChanged={isChanged} />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EditInvoiceTemplate;
