import { LoadingOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Tooltip,
} from "antd";
import React, { FC, Fragment, useContext, useState } from "react";
import "../../Common/AddPageCommon.css";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import { Utils } from "../../../Utilities/Utils";
import ViewPaymentIn from "./ViewPaymentIn";
import VoucherServices, {
  Party_Tax,
  ReconciledFilter,
  RefType,
  Voucher_Type,
  VoucherStatus_New,
} from "../../Services/VoucherServices";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import deleteImg from "../../../images/deletebutton.svg";
import { notificationContext } from "../../Common/PageRoute";
import { useLocation, useNavigate } from "react-router-dom";
import { LocalChunkSize } from "papaparse";
import { useLocale } from "antd/es/locale";
import { couldStartTrivia } from "typescript";
import { log } from "console";

interface IProps {
  open: boolean;
  onCancel: any;
  invoiceId: string | undefined;
  voucherType: Voucher_Type;
  currentInvoiceDetails: any;
  newPartyRecepitDetails?: any;
  oldPartyRecepitDetails?: any;
  newSalesInvoice?: boolean;
  setSaveAndNew?: any;
  setUpdateAndNew?: any;
  editInvBankDetails?: any[];
  settingControl?: { showReceipt: boolean; voucherTitle: string };
  voucherStatus?: VoucherStatus_New;
  editIsCashVoucher?: boolean;
  updateInPartyId?: string;
}

const AddSalesInvReferenceModel: FC<{
  open: boolean;
  onCancel: any;
  invoiceId: string | undefined;
  voucherType: Voucher_Type;
  currentInvoiceDetails: any;
  newPartyRecepitDetails?: any;
  oldPartyRecepitDetails?: any;
  newSalesInvoice?: boolean;
  setSaveAndNew?: any;
  setUpdateAndNew?: any;
  editInvBankDetails?: any[];
  settingControl?: { showReceipt: boolean; voucherTitle: string };
  voucherStatus?: VoucherStatus_New;
  // editIsCashVoucher?: boolean;
  updateInPartyId?: string;
}> = (props: IProps) => {
  console.log(
    "props...........",
    props
    // typeof props?.oldPartyRecepitDetails[0]?.value
  );

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const { currentClient, companySettings } = useClient();
  // const [loading, setLoading] = useState<boolean>(false);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const { openNotification } = useContext(notificationContext);

  // const [partyRecepitDetails, setPartyRecepitDetails] = useState<any>([]);
  const [selectedPartyOption, setSelectedPartyOption] = React.useState<any[]>(
    props.invoiceId ? [undefined] : []
  );
  const [updatePartyRefOption, setUpdatePartyRefOption] = React.useState<any[]>(
    []
  );
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [totalAmount, setTotalAmount] = React.useState<number>(
    props.invoiceId
      ? 0
      : props.currentInvoiceDetails?.amountSummary?.totalInvoiceAmount -
          props.currentInvoiceDetails?.bankAmount
    // -(props.currentInvoiceDetails?.amountSummary.tDSAmt ?? 0)
  );
  const [openModel, setOpenModel] = useState<any>({
    type: "",
    id: "",
  });
  const [deleteItemList, setDeleteItemList] = useState<number>(-1);
  const [saveInvoiceReferences, setSaveInvoiceReferences] =
    React.useState<any>();
  const [deletePaymentDetails, setDeletePaymentDetails] = React.useState<any[]>(
    []
  );
  const [isDeletePaymentApi, setIsDeletePaymentApi] =
    React.useState<boolean>(false);
  const [triggerDeletePaymentApi, setTriggerDeletePaymentApi] =
    React.useState<boolean>(false);
  // const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  // delete value set user edit time delete one payment method
  React.useEffect(() => {
    // Cash Only (Party Not Available) This time not delete payment method
    if (!props?.currentInvoiceDetails?.isCashVoucher || props.updateInPartyId) {
      const findDeletePaymentVouchar = props?.editInvBankDetails?.filter(
        (x: any) =>
          !props.currentInvoiceDetails?.bankDts?.some(
            (y: any) => y?.editId === x?.editId && y.amount > 0
          )
      );
      console.log("findDeletePaymentVouchar", findDeletePaymentVouchar);

      if (
        findDeletePaymentVouchar != null &&
        findDeletePaymentVouchar?.length > 0
      ) {
        setDeletePaymentDetails(findDeletePaymentVouchar);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRemovoLedger = (value: string, index: number) => {
    console.log("..........value", value);
    setSelectedPartyOption((prevState) => {
      const updatedSelectedOptions = [...prevState];
      updatedSelectedOptions[index] = value;
      return updatedSelectedOptions;
    });
  };

  // update Invoice
  React.useEffect(() => {
    if (props?.newPartyRecepitDetails) {
      props?.oldPartyRecepitDetails?.map((v: any, i: number) => {
        console.log("value", i, selectedPartyOption);
        if (v.refType) {
          setSelectedPartyOption((selectedPartyOption) => [
            ...selectedPartyOption,
            v?.id?.title,
          ]);

          setUpdatePartyRefOption((updatePartyRefOption) => [
            ...updatePartyRefOption,
            {
              maxAmount: v?.dueAmount + v?.value,
              dueAmount: v?.dueAmount,
              value: v?.value,
              refType: v?.refType,
              //  matchId: v?.id?.title, // parentRef Id Jo Updta
              id: v?.id?.value,
              refNo: v?.id?.label,
              title: v?.id?.title,
            },
          ]);
        }
      });

      props?.newPartyRecepitDetails.map((v: any, i: number) => {
        const find = props?.oldPartyRecepitDetails?.find(
          (x: any) => x?.id?.title === v?.id
        );
        if (!find) {
          // console.log("find......", v);
          setUpdatePartyRefOption((updatePartyRefOption) => [
            ...updatePartyRefOption,
            {
              dueAmount: v?.dueAmount,
              value: v?.value,
              refType: 1,
              //  matchId: v?.id,
              id: v?.id,
              title: v?.id,
              refNo: v?.refNo,
              maxAmount: v?.dueAmount,
            },
          ]);
        }
      });

      // form.setFieldValue(
      //   "ref",
      //   props.newPartyRecepitDetails.map((v: any) => {
      //     return {
      //       ...v,
      //       id: {
      //         label: v?.name,
      //         value: v?.id,
      //       },
      //     };
      //   })
      // );
    }
    const x = form.getFieldValue("ref");
    const totalAlloctedAmount = x.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.value));
    }, 0);
    setTotalAmount(totalAlloctedAmount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.newPartyRecepitDetails]);

  const selectReceiptReference: any = (value: any, index: number) => {
    console.log("value11.............", value, index);

    if (props.invoiceId) {
      const currentSelectLedger = updatePartyRefOption?.find(
        (x: any) => x.title === value
      );
      console.log("currentSelectLedger", currentSelectLedger);

      if (currentSelectLedger) {
        form.setFieldValue(["ref", index], {
          id: {
            value: currentSelectLedger?.id,
            label: currentSelectLedger?.refNo,
            title: currentSelectLedger?.title,
          },
          dueAmount: Utils.getFormattedNumber(currentSelectLedger?.dueAmount),
          value: Utils.getFormattedNumber(currentSelectLedger?.value),
        });
      }
    } else {
      const currentSelectLedger = props?.newPartyRecepitDetails?.find(
        (x: any) => x.id === value
      );
      if (currentSelectLedger) {
        const totalInvoiceAmount =
          props.currentInvoiceDetails?.amountSummary?.totalInvoiceAmount -
          props.currentInvoiceDetails?.bankAmount;
        // -  (props.currentInvoiceDetails?.amountSummary.tDSAmt ?? 0);
        const remainingRefAmount = Utils.getNumber(
          form
            .getFieldValue(["ref"])
            .filter((_: any, idx: number) => idx !== index)
            .slice(1)
            .reduce((acc: number, curr: any) => {
              return (acc += Utils.getNumber(curr?.value));
            }, 0)
        );
        let dueAmount;
        if (
          totalInvoiceAmount -
            (remainingRefAmount + currentSelectLedger?.dueAmount) <
          0
        ) {
          dueAmount = totalInvoiceAmount - remainingRefAmount;
        } else {
          dueAmount = currentSelectLedger?.dueAmount;
        }
        // const currentData = form.getFieldValue(["freightInsLedgers", index]);
        form.setFieldValue(["ref", index], {
          id: {
            value: currentSelectLedger?.id,
            label: currentSelectLedger?.refNo,
          },
          dueAmount: Utils.getFormattedNumber(currentSelectLedger?.dueAmount),
          value: dueAmount,
        });
      }
    }

    newRefAmountSet();
    // setLedgerLength(index + 1);
  };

  // console.log(
  //   "saveInvoiceReferences1",
  //   props.currentInvoiceDetails?.amountSummary?.totalInvoiceAmount,
  //   totalAmount,
  //   props.currentInvoiceDetails?.bankAmount
  // );

  console.log(
    "data....",
    props?.currentInvoiceDetails?.bankDts?.length === 0,
    props?.invoiceId,
    props?.currentInvoiceDetails?.isCashVoucher,
    props.currentInvoiceDetails?.partyLedger[0]?.partyOrTax !== Party_Tax.party,
    deletePaymentDetails.length > 0
  );

  // ------- Post api in invoice --------
  const postSalesInvoice = async () => {
    // debugger;
    // console.log("post value.........", props.currentInvoiceDetails);
    try {
      setSaveButtonLoading(true);
      await form
        .validateFields()
        .then(async (val: any) => {
          if (
            props.currentInvoiceDetails?.amountSummary?.totalInvoiceAmount ===
            totalAmount +
              props?.currentInvoiceDetails?.bankAmount +
              props.currentInvoiceDetails?.amountSummary?.tDSAmt
          ) {
            // console.log("final post", val?.ref);

            // const Items = props.currentInvoiceDetails?.items?.map(
            //   (itm: any) => {
            //     return {
            //       ...itm,
            //       amount: {
            //         ...itm?.amount,
            //         unit: {
            //           id: itm.amount?.unit?.value,
            //           name: itm.amount?.unit?.label,
            //         },
            //         grossAmount:
            //           Utils.getNumber(itm?.amount?.price) * itm?.amount?.qty,
            //         tax: {
            //           id: itm?.amount?.tax?.value,
            //           rate: itm?.amount?.tax?.key,
            //           name: itm?.amount?.tax?.label,
            //         },
            //       },
            //     };
            //   }
            // );

            const postData = {
              ...props.currentInvoiceDetails,
              // items: Items,
              partyLedger:
                props?.currentInvoiceDetails?.isCashVoucher &&
                props.currentInvoiceDetails?.partyLedger[0]?.partyOrTax !==
                  Party_Tax.party
                  ? props.currentInvoiceDetails?.partyLedger
                  : new Array({
                      ...props.currentInvoiceDetails?.partyLedger[0],
                      ref: !props.invoiceId
                        ? val?.ref?.length > 0
                          ? val?.ref.map((x: any, i: number) => {
                              if (i === 0) {
                                return {
                                  id: null,
                                  name: x?.id,
                                  refType: RefType.newRef,
                                  value:
                                    x?.value +
                                    props.currentInvoiceDetails?.bankAmount,
                                  // date: x?.date,
                                };
                              } else {
                                return {
                                  ...x,
                                  id: null,
                                  parentRef: x?.id?.value,
                                  name: x?.id?.label,
                                  refType: RefType.AgnstRef,
                                };
                              }
                            })
                          : null
                        : val?.ref.map((x: any, i: number) => {
                            if (i === 0) {
                              return {
                                id: x?.id?.value,
                                name: x?.id?.label,
                                refType: RefType.newRef,
                                value:
                                  x?.value +
                                  props.currentInvoiceDetails?.bankAmount,
                                // date: x?.date,
                              };
                            } else {
                              return {
                                ...x,
                                id: x?.id?.value,
                                parentRef: x?.id?.title ?? x?.id?.value,
                                name: x?.id?.label,
                                refType: RefType.AgnstRef,
                              };
                            }
                          }),
                    }),
            };

            // const config = "multipart/form-data";
            // const formData = new FormData();

            // const x1 = props.currentInvoiceDetails?.attachments?.map((x: any) =>
            //   formData.append("file", x?.originFileObj)
            // );

            // const x = {
            //   postData,
            //   x1,
            // };
            // // formData.values = postData;
            // formData.append("model", postData);

            console.log(postData, "Post Data");
            await VoucherServices.postVoucher(
              currentClient?.id!,
              props?.voucherType,
              postData,
              // x1,
              props?.settingControl?.voucherTitle!,
              props?.invoiceId!
              // config
              // location?.state?.copy ? undefined : id
            )
              .then((res: any) => {
                if (res?.status) {
                  console.log("res....", res);
                  sessionStorage.removeItem(`${currentClient?.id} Sales`);
                  sessionStorage.removeItem("NotificationState");

                  openNotification(
                    "success",
                    ` ${
                      props?.voucherType === Voucher_Type.SalesVoucher
                        ? "Sales Invoice "
                        : props?.voucherType === Voucher_Type.PurchaseVoucher
                        ? "Purchase Bill "
                        : props?.voucherType === Voucher_Type.Expenses
                        ? "Expenses"
                        : "Income"
                    } ${res.result?.name} ${
                      props?.invoiceId
                        ? " updated successfully."
                        : " added successfully."
                    }`
                  );

                  if (props?.currentInvoiceDetails?.bankDts?.length > 0) {
                    setSaveInvoiceReferences(res.result?.references[0]);
                  } // first credit & user change to cash & not select party & deletePaymentDetails length > 0
                  else if (
                    props?.currentInvoiceDetails?.bankDts?.length === 0 &&
                    props?.invoiceId &&
                    props?.currentInvoiceDetails?.isCashVoucher &&
                    props.currentInvoiceDetails?.partyLedger[0]?.partyOrTax !==
                      Party_Tax.party &&
                    deletePaymentDetails.length > 0
                  ) {
                    setTriggerDeletePaymentApi(true);
                  } else {
                    onPostSuccess();
                  }

                  // setInvoiceTotal(0.0);
                  // setItemLength(0);
                } else if (res?.message) {
                  openNotification("error", res?.message);
                }
              })
              .catch((ex) => openNotification("error", ex));

            // setSaveNewLoading(false);
            // isSaveAndNew && seInitialRender(!initialRender);
          } else {
            openNotification(
              "error",
              "Allocated value must be equal to invoice value."
            );
          }
        })
        .catch((err: any) => {
          openNotification("error", err.message);
        });
    } catch (err) {
      console.error("error", err);
      openNotification("error", err);
    } finally {
      setSaveButtonLoading(false);
    }
  };

  // Post(add) payment api
  React.useEffect(() => {
    const paymentDelete = async () => {
      let index = 0;
      try {
        let postSucessData = {};
        for (const x of props.currentInvoiceDetails?.bankDts || []) {
          // console.log("date x.............", x, index);
          const data = {
            amountSummary: {
              totalInvoiceAmount: x?.amount,
              roundOff: { roundOffApplicable: false, roundOffAmount: 0 },
            },
            editId: x?.editId,
            instantPayment: true,
            amount: x?.amount,
            payRecReference: x?.payRecReference,
            effectiveLedger: [
              {
                id: x?.name?.value,
                amount: x?.amount,
                partyOrTax: x?.name?.title,
              },
            ],
            isReconciled: x?.editId
              ? x?.isReconciled
              : location.state?.ledgerId && !index
              ? ReconciledFilter.reconciled
              : ReconciledFilter.nonreconciled,
            bankImportEntryRef: x?.editId
              ? x.bankImportEntryRef
              : location.state?.ledgerId && !index
              ? location.state?.bankEntriesId
              : "",
            date: props.currentInvoiceDetails?.date,
            isLocked: false,
            isAudited: false,
            isActive: true,
            partyLedger: [
              {
                id: props.currentInvoiceDetails?.partyLedger[0]?.id,
                amount: x?.amount,
                partyOrTax: Party_Tax.party,
                value: x?.amount,
                ref: [
                  {
                    refType: RefType.AgnstRef,
                    value: x?.amount,
                    name: saveInvoiceReferences?.name,
                    id: x?.partyRefId,
                    parentRef: saveInvoiceReferences?.id,
                  },
                ],
              },
            ],
          };

          if (data.amount > 0 && data.effectiveLedger[0]?.id) {
            try {
              const res = await VoucherServices.postVoucher(
                currentClient?.id!,
                props?.voucherType === Voucher_Type.SalesVoucher ||
                  props?.voucherType === Voucher_Type.Income
                  ? Voucher_Type.Receipt
                  : Voucher_Type.Payment,
                data,
                props?.voucherType === Voucher_Type.SalesVoucher ||
                  props?.voucherType === Voucher_Type.Income
                  ? "RECEIPTS"
                  : "PAYMENTS",
                // props.
                data?.editId
              );
              if (res.result) {
                if (index === 0) {
                  // debugger
                  postSucessData = {
                    id: res.result?.id,
                    name: res.result?.name,
                    voucherType: res.result?.voucherType,
                  };
                  // setAfterSaveData({
                  //   id: res.result?.id,
                  //   name: res.result?.name,
                  //   voucherType: res.result?.voucherType,
                  // });
                }
                openNotification(
                  "success",
                  `Payment ${res.result?.name} ${
                    data?.editId
                      ? "updated successfully."
                      : "added successfully."
                  }`
                );
              } else if (res.message) {
                openNotification("error", res.message);
              }
              index++;
            } catch (ex: any) {
              openNotification("error", ex.message);
              console.log("Error for ID:", x?.id, ex);
            }
          }
        }
        if (deletePaymentDetails?.length > 0) {
          setIsDeletePaymentApi(true);
        } else {
          onPostSuccess(postSucessData);
        }
      } catch (ex: any) {
        openNotification("error", ex.message);
        console.log("Error for ID:", ex);
      } finally {
        setSaveButtonLoading(false);
      }
    };

    if (saveInvoiceReferences?.id) {
      setSaveButtonLoading(true);
      paymentDelete();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveInvoiceReferences]);

  // delete payment api
  React.useEffect(() => {
    // console.log("deleteCreditNote.id", deletePaymentIn?.id);
    const deletePaymentVoucher = async () => {
      try {
        setSaveButtonLoading(true);
        for (const x of deletePaymentDetails) {
          if (x?.editId) {
            await VoucherServices.deleteVoucher(currentClient?.id!, x?.editId)
              .then((res) => {
                if (res?.result) {
                  openNotification(
                    "success",
                    `Voucher ${x?.paymentNumber} deleted successfully.`
                  );
                }
              })
              .catch((err) => {
                openNotification("error", err.message);
              });
          }
        }
        onPostSuccess();
      } catch (err: any) {
        console.error("error", err);
      } finally {
        setSaveButtonLoading(false);
      }
    };

    if (isDeletePaymentApi || triggerDeletePaymentApi) {
      deletePaymentVoucher();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeletePaymentApi, triggerDeletePaymentApi]);

  const onPostSuccess = (afterSave?: any) => {
    console.log("location", afterSave);

    if (props.newSalesInvoice && props.invoiceId) {
      props.setUpdateAndNew();
      props.onCancel();
    } else if (props.newSalesInvoice) {
      props.setSaveAndNew();
      props.onCancel();
    } else {
      if (location.state?.ledgerId) {
        navigate(
          `/billing/clients/${currentClient?.id}/banking/bankTransactionsDetails/${location.state?.bankEntriesId}`,
          {
            state: {
              ledgerId: location.state?.ledgerId,
              bankImportIndex: location.state?.bankImportIndex,
              voucherRef: [afterSave],
            },
          }
        );
      } else {
        navigate(-1);
      }
    }
    // setSaveButtonLoading(false);
  };

  const totalAlloctedAmount = () => {
    const x = form.getFieldValue("ref");
    const totalAlloctedAmount = x.reduce((acc: number, curr: any) => {
      return (acc += Utils.getNumber(curr?.value));
    }, 0);
    setTotalAmount(totalAlloctedAmount);
  };

  const newRefAmountSet = () => {
    form.setFieldValue(
      ["ref", 0, "value"],
      Utils.getNumber(
        props?.currentInvoiceDetails.amountSummary.totalInvoiceAmount -
          props?.currentInvoiceDetails.bankAmount
      ) -
        Utils.getNumber(
          form
            .getFieldValue(["ref"])
            .slice(1)
            .reduce((acc: number, curr: any) => {
              return (acc += Utils.getNumber(curr?.value));
            }, 0)
        )
    );
    totalAlloctedAmount();
  };

  console.log("selectedPartyOption", selectedPartyOption);

  const maxValueFind = (index: number): number => {
    let x = 0;
    if (index !== 0) {
      if (props.invoiceId) {
        const currentSelectLedger = updatePartyRefOption?.find(
          (x: any) =>
            x.title === form.getFieldValue(["ref", index, "id"])?.title
        );
        // console.log(
        //   "maxValue",
        //   updatePartyRefOption,
        //   currentSelectLedger,
        //   form.getFieldValue(["ref", index, "id"])
        // );
        x = currentSelectLedger?.maxAmount;
      } else {
        const currentSelectLedger = props?.newPartyRecepitDetails?.find(
          (x: any) => x.id === form.getFieldValue(["ref", index, "id"])?.value
        );
        x = currentSelectLedger?.dueAmount;
      }
    } else {
      x = props.currentInvoiceDetails?.amountSummary?.totalInvoiceAmount;
    }
    return x;
  };

  return (
    <Fragment>
      <Modal
        open={props.open}
        width={600}
        style={{ maxHeight: `calc(100% - 50px)`, overflowY: "auto" }}
        maskClosable={false}
        onCancel={() => props.onCancel()}
        centered
        title={
          <Row>
            <Col sm={6} xs={12} style={{ fontSize: "17px" }}>
              <span>Allocate amount</span>
            </Col>
            {/* <Col sm={5} xs={8}>
              <Input defaultValue={props?.currentInvoiceDetails?.number} />
            </Col> */}
          </Row>
        }
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => postSalesInvoice()}
              loading={saveButtonLoading}
              className="modelSaveButtonMargin"
            >
              {props?.invoiceId ? "Update" : "Save"}
            </Button>
          </div>
        }
      >
        <Divider style={{ margin: "0" }} />
        {/* <Spin indicator={antIcon} spinning={loading}> */}
        <Form
          name="Account"
          form={form}
          autoComplete="off"
          colon={false}
          labelAlign="left"
          // update 2 condition 1 refType new + old & 2 refType old
          initialValues={{
            ref: props?.invoiceId
              ? props?.oldPartyRecepitDetails[0]?.refType === 0 // new ref value not found
                ? [
                    {
                      ...props?.oldPartyRecepitDetails[0],
                      dueAmount: props?.oldPartyRecepitDetails[0].dueAmount,
                      // value:
                      //   props?.oldPartyRecepitDetails[0]?.value -
                      //   props.currentInvoiceDetails?.bankAmount,
                      value:
                        Utils.getNumber(
                          props?.currentInvoiceDetails?.amountSummary
                            ?.totalInvoiceAmount -
                            props?.currentInvoiceDetails?.bankAmount -
                            props.currentInvoiceDetails?.amountSummary?.tDSAmt
                        ) -
                        Utils.getNumber(
                          props.oldPartyRecepitDetails
                            ?.slice(1)
                            ?.reduce((acc: number, curr: any) => {
                              return (acc += Utils.getNumber(curr?.value));
                            }, 0)
                        ),
                    },
                    ...props?.oldPartyRecepitDetails.slice(1),
                    {},
                  ]
                : props?.voucherStatus === VoucherStatus_New.Deleted // Delete Voucher Active
                ? [
                    {
                      id: {
                        value: null,
                        label: props?.currentInvoiceDetails?.number,
                      },
                      dueAmount: 0.0,
                      value:
                        Utils.getNumber(
                          props?.currentInvoiceDetails?.amountSummary
                            ?.totalInvoiceAmount -
                            props?.currentInvoiceDetails?.bankAmount -
                            props.currentInvoiceDetails?.amountSummary?.tDSAmt
                        ) -
                        Utils.getNumber(
                          props.oldPartyRecepitDetails
                            ?.slice(1)
                            ?.reduce((acc: number, curr: any) => {
                              return (acc += Utils.getNumber(curr?.value));
                            }, 0)
                        ),
                    },
                    {},
                  ]
                : props?.currentInvoiceDetails?.isCashVoucher // Cash Only
                ? [
                    {
                      id: {
                        value: null,
                        label: props?.currentInvoiceDetails?.number,
                      },
                      dueAmount: 0.0,
                      value: 0.0,
                    },
                  ]
                : [
                    {
                      id: {
                        value: null,
                        label: props?.currentInvoiceDetails?.number,
                      },
                      dueAmount: 0.0,
                      value:
                        Utils.getNumber(
                          props?.currentInvoiceDetails?.amountSummary
                            ?.totalInvoiceAmount -
                            props?.currentInvoiceDetails?.bankAmount -
                            props.currentInvoiceDetails?.amountSummary?.tDSAmt
                        ) -
                        Utils.getNumber(
                          props.oldPartyRecepitDetails
                            ?.slice(1)
                            ?.reduce((acc: number, curr: any) => {
                              return (acc += Utils.getNumber(curr?.value));
                            }, 0)
                        ),
                      // value:
                      //   0.0 -
                      //   props.currentInvoiceDetails?.bankAmount -
                      //   props.currentInvoiceDetails?.amountSummary?.tDSAmt,
                    },
                    ...props?.oldPartyRecepitDetails,
                    {},
                  ]
              : props?.newPartyRecepitDetails?.length > 0
              ? [
                  {
                    id: props?.currentInvoiceDetails?.number,
                    dueAmount: 0.0,
                    value:
                      props.currentInvoiceDetails?.amountSummary
                        ?.totalInvoiceAmount -
                      props.currentInvoiceDetails?.bankAmount -
                      props.currentInvoiceDetails?.amountSummary?.tDSAmt,
                    // -
                    // (props.currentInvoiceDetails?.amountSummary.tDSAmt ?? 0),
                  },
                  {},
                ]
              : [
                  {
                    id: props?.currentInvoiceDetails?.number,
                    dueAmount: 0.0,
                    value:
                      props.currentInvoiceDetails?.amountSummary
                        ?.totalInvoiceAmount -
                      props.currentInvoiceDetails?.bankAmount -
                      props.currentInvoiceDetails?.amountSummary?.tDSAmt,
                    // -
                    // (props.currentInvoiceDetails?.amountSummary.tDSAmt ?? 0),
                  },
                ],
          }}
          labelCol={{ xs: 7, sm: 10 }}
          wrapperCol={{ xs: 16, sm: 14 }}
          labelWrap={true}
        >
          <div style={{ overflowY: "auto" }}>
            <table
              className="AddPageTabelDesign"
              // style={{ fontSize: windowWidth <= 1024 ? "10.3px" : "" }}
            >
              <thead>
                <tr>
                  {/* <th style={{ width: "15%" }} className="textCenter">
                    S.No.
                  </th> */}
                  <th
                    style={{
                      width: "20%",
                      // borderLeft: "1px solid rgb(240, 245, 255)",
                    }}
                    className="textStart"
                  >
                    Type
                  </th>
                  <th
                    style={{
                      width: "20%",
                      // borderLeft: "1px solid rgb(240, 245, 255)",
                    }}
                    className="textStart"
                  >
                    Reference No.
                  </th>
                  {/* <th style={{ width: "20%" }} className="textStart ps-6">
                      Date
                    </th> */}
                  <th style={{ width: "30%" }} className="textStart">
                    Pending Amount
                  </th>
                  <th style={{ width: "20%" }} className="textStart">
                    Allocated Amount
                  </th>
                  <th style={{ width: "10%" }} className="textStart"></th>
                </tr>
              </thead>

              <tbody>
                <Form.List name="ref">
                  {(fields, { add, remove, move }) => {
                    return (
                      <>
                        {confirmation && (
                          <ConfirmationModal
                            open={confirmation}
                            text={`Do you really want to delete this item  ?`}
                            onNo={() => setConfirmation(false)}
                            onYes={() => {
                              console.log(deleteItemList);
                              remove(deleteItemList);
                              setConfirmation(false);
                              // handleAllLedgerValue();
                              // setItemLength(fields.length - 2);
                              setSelectedPartyOption((prev) => {
                                const newDisabledInputs = [...prev];
                                newDisabledInputs.splice(deleteItemList, 1);
                                return newDisabledInputs;
                              });
                              newRefAmountSet();
                            }}
                          />
                        )}
                        {fields.map(({ key, name, ...restField }, index) => {
                          return (
                            <>
                              <tr key={key}>
                                {/* <td>
                                    {fields.length !== index1 + 1 && (
                                      <div className="ps-10">{index1 + 1}.</div>
                                    )}
                                  </td> */}
                                <td style={{ width: 25 }}>
                                  {index === 0 ? (
                                    <Tooltip
                                      title="New Reference"
                                      placement="left"
                                      color="#389e0d"
                                    >
                                      <Avatar
                                        style={{
                                          cursor: "pointer",
                                          verticalAlign: "middle",
                                          color: "#389e0d",
                                          backgroundColor: "#f6ffed",
                                          borderColor: "#b7eb8f",
                                          fontWeight: "bold",
                                        }}
                                        size="small"
                                      >
                                        N
                                      </Avatar>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title="Old Reference"
                                      placement="left"
                                      color="#08979C"
                                    >
                                      <Avatar
                                        style={{
                                          cursor: "pointer",
                                          verticalAlign: "middle",
                                          color: "#08979C",
                                          backgroundColor: "#E6FFFB",
                                          borderColor: "rgb(127 231 235)",
                                          fontWeight: "bold",
                                        }}
                                        size="small"
                                      >
                                        A
                                      </Avatar>
                                    </Tooltip>
                                  )}
                                </td>

                                <td>
                                  <Form.Item
                                    name={[name, "id"]}
                                    className="m-0"
                                    {...restField}
                                  >
                                    {index !== 0 ? (
                                      <Select
                                        labelInValue
                                        showSearch
                                        placeholder="Search for receipt"
                                        optionFilterProp="children"
                                        filterOption={false}
                                        popupMatchSelectWidth={false}
                                        style={{ width: "173px" }}
                                        // dropdownStyle={{ width: "250px" }}
                                        placement="bottomRight"
                                        // onSearch={(value: string) =>
                                        //   setSearchItem(value)
                                        // }

                                        // onClick={() => {
                                        //   setSearchItem("");
                                        //   setNewItem({
                                        //     data: {},
                                        //     isSaved: false,
                                        //   });
                                        // }
                                        options={
                                          props.invoiceId
                                            ? updatePartyRefOption
                                                ?.filter(
                                                  (option: any) =>
                                                    !selectedPartyOption.includes(
                                                      option.title
                                                    )
                                                )
                                                .map((option: any) => ({
                                                  value: option?.id,
                                                  label: option?.refNo,
                                                  title: option?.title,
                                                }))
                                            : props.newPartyRecepitDetails
                                                ?.filter(
                                                  (option: any) =>
                                                    !selectedPartyOption.includes(
                                                      option.id
                                                    )
                                                )
                                                .map((option: any) => ({
                                                  value: option?.id,
                                                  label: option?.refNo,
                                                  title: option?.title,
                                                }))
                                        }
                                        onChange={(e: any) => {
                                          if (
                                            fields.length ===
                                            index + 1
                                            // ||
                                            // (props?.invoiceId &&
                                            //   fields.length !==
                                            //     props.newPartyRecepitDetails
                                            //       .length +
                                            //       1)
                                          )
                                            add();

                                          props.invoiceId
                                            ? selectReceiptReference(
                                                e.title,
                                                index
                                              )
                                            : selectReceiptReference(
                                                e.value,
                                                index
                                              );

                                          props.invoiceId
                                            ? getRemovoLedger(e.title, index)
                                            : getRemovoLedger(e.value, index);
                                        }}
                                      />
                                    ) : (
                                      <Select
                                        disabled
                                        labelInValue
                                        style={{ width: "173px" }}
                                      />
                                    )}
                                  </Form.Item>
                                </td>
                                {/* 
                                  <td>
                                    <Form.Item
                                      className="m-0"
                                      {...restField}
                                      name={[name, "date"]}
                                    >
                                      <DatePicker
                                        disabled
                                        allowClear={false}
                                        format="DD/MM/YYYY"
                                        style={{ width: "100px" }}
                                        suffixIcon={<></>}
                                      />
                                    </Form.Item>
                                  </td> */}

                                <td>
                                  <Form.Item
                                    className="m-0"
                                    {...restField}
                                    name={[name, "dueAmount"]}
                                  >
                                    <InputNumber
                                      disabled={true}
                                      style={{ width: "150px" }}
                                      className="inputNumberTextEnd"
                                    />
                                  </Form.Item>
                                </td>

                                <td>
                                  <Form.Item
                                    className="m-0"
                                    {...restField}
                                    name={[name, "value"]}
                                    rules={[
                                      {
                                        validator: (_, value) => {
                                          if (value < 0) {
                                            return Promise.reject(
                                              "Amount is non-negative"
                                            );
                                          }
                                          return Promise.resolve();
                                        },
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      onChange={() => {
                                        newRefAmountSet();
                                      }}
                                      min={0}
                                      step={0.01}
                                      // max={
                                      //   index !== 0
                                      //     ? props.invoiceId
                                      //       ? Math.round(
                                      //         (form.getFieldValue([
                                      //           "ref",
                                      //           index,
                                      //           "dueAmount",
                                      //         ]) +
                                      //           form.getFieldValue([
                                      //             "ref",
                                      //             index,
                                      //             "value",
                                      //           ])) *
                                      //         100
                                      //       ) / 100
                                      //       : Math.round(
                                      //         form.getFieldValue([
                                      //           "ref",
                                      //           index,
                                      //           "dueAmount",
                                      //         ]) * 100
                                      //       ) / 100
                                      //     : props.currentInvoiceDetails
                                      //       ?.amountSummary
                                      //       ?.totalInvoiceAmount
                                      // }

                                      max={maxValueFind(index)}
                                      controls={false}
                                      style={{ width: "150px" }}
                                      className="inputNumberTextEnd"
                                    />
                                  </Form.Item>
                                </td>

                                <td style={{ width: 25 }}>
                                  {index !== 0 &&
                                    fields.length !== index + 1 && (
                                      <img
                                        className="cursor mx-1"
                                        onClick={() => {
                                          setDeleteItemList(name);
                                          setConfirmation(true);
                                        }}
                                        src={deleteImg}
                                        alt="Delete"
                                      />
                                    )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </>
                    );
                  }}
                </Form.List>
              </tbody>
            </table>

            <Row
              className="dashedBorderTop pt-10"
              style={{
                display: "flex",
                justifyContent: "end",
                paddingRight: "15px",
              }}
            >
              <Col lg={12} md={24}>
                {(props?.settingControl?.showReceipt ||
                  props.currentInvoiceDetails?.bankAmount > 0) && (
                  <>
                    <Row style={{ paddingRight: "12px" }}>
                      <Col lg={13}>
                        <span>
                          {props?.voucherType === Voucher_Type.SalesVoucher ||
                          props?.voucherType === Voucher_Type.Income
                            ? "Received"
                            : "Paid"}
                        </span>
                      </Col>

                      <Col lg={11} className="textEnd">
                        <span>
                          ₹ {""}
                          {Utils.getFormattedNumber(
                            props.currentInvoiceDetails?.bankAmount
                          )}
                        </span>
                      </Col>

                      {/* </> */}

                      <Col lg={13}>
                        <span>Total allocated</span>
                      </Col>
                      <Col lg={11} className="textEnd">
                        <span>₹ {Utils.getFormattedNumber(totalAmount)}</span>
                      </Col>
                    </Row>
                    <Divider className="my-5 grayBg" />
                  </>
                )}
                {props?.voucherType === Voucher_Type.PurchaseVoucher ||
                  props?.voucherType === Voucher_Type.Expenses ||
                  (props?.voucherType === Voucher_Type.Income && (
                    <>
                      {" "}
                      <Row>
                        <Col lg={13}>
                          <span>TDS</span>
                        </Col>
                        <Col lg={11} className="textEnd">
                          <span>
                            ₹{" "}
                            {Utils.getFormattedNumber(
                              props.currentInvoiceDetails?.amountSummary?.tDSAmt
                            )}
                          </span>
                        </Col>
                      </Row>
                      <Divider className="my-5 grayBg" />
                    </>
                  ))}
                <Row>
                  <Col lg={13} style={{ fontWeight: 500, fontSize: "15px" }}>
                    <span>Total Invoice Value</span>
                  </Col>

                  <Col
                    lg={11}
                    style={{ fontWeight: 500, fontSize: "15px" }}
                    className="textEnd"
                  >
                    <span>
                      ₹ {""}
                      {Utils.getFormattedNumber(
                        props.currentInvoiceDetails?.amountSummary
                          ?.totalInvoiceAmount
                      )}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider style={{ margin: "0" }} />
          </div>
        </Form>
        {/* </Spin> */}
      </Modal>

      {openModel.type === "viewPaymentIn" && (
        <ViewPaymentIn
          open={openModel.type === "viewPaymentIn"}
          onCancel={() => setOpenModel({ type: "", id: "" })}
          paymentId={openModel?.id}
        />
      )}
    </Fragment>
  );
};

export default AddSalesInvReferenceModel;
