import { Card, Col, Collapse, Row, Skeleton } from "antd";
import {
  DashboardChart,
  CardDivider,
  SalesCard,
} from "../../Common/DashboardChart";
import QuotationTable from "./QuotationTable";
import { useClient } from "../../Context/ClientContext";
import plusImg from "../../Images/plus.svg";
import equalImg from "../../Images/equal.svg";
import React from "react";
import { Utils } from "../../../Utilities/Utils";
import currency1 from "../../../images/currency1.svg";
import currency2 from "../../../images/currency2.svg";
import currency3 from "../../../images/currency3.svg";
const Quotation: React.FC = () => {
  const [estimateList, setEstimateList] = React.useState<any>([]);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { activeKey } = useClient();
  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const option = {
    tooltip: {
      trigger: "item",
      formatter: "{b}: {c} ({d}%)",
    },
    color: ["#eef2fe", "#fac858", "#91cc75", "#FF4D4F"],
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["45%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
        },
        labelLine: {
          show: false,
        },
        data: [

          {
            value: "",
            name: "Estimate Raised",
          },
          {
            value: Utils.getFormattedNumber(
              (estimateList?.totalAccepted?.count / estimateList?.quotationRaised) *
              100
            ),
            name: `Accepted (${Utils.getFormattedNumber(
              (estimateList?.totalAccepted?.count / estimateList?.quotationRaised) *
              100
            )}%)`,
          },
          {
            value: Utils.getFormattedNumber(
              (estimateList?.totalPending?.count / estimateList?.quotationRaised) *
              100
            ),
            name: `Pending (${Utils.getFormattedNumber(
              (estimateList?.totalPending?.count /
                estimateList?.quotationRaised) *
              100
            )}%)`,
          },
        ],
      },
    ],
  };

  console.log(estimateList, "estimate amount");
  return (
    <div>
      <div
        style={{
          // paddingTop: "20px",
          // paddingBottom: "20px",
          // borderRadius: "10px",
          marginTop: 35,
          // padding: "10px",
          // marginLeft: "-8px",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px 0px #96A1C84D",
          backgroundColor: "white",
          padding: activeKey ? "38px 0px 38px" : "38px 0px 38px",
        }}
      >
        <Collapse className="customcssColl" activeKey={activeKey} ghost>
          <Collapse.Panel
            className="customcss"
            showArrow={false}
            key={"1"}
            header={<></>}
          >
            <Row justify={"space-evenly"}>
              <Col lg={5} xl={5} md={11} xs={24}>
                <div>
                  <Skeleton loading={loading}>
                    <DashboardChart
                      title={"Accepted"}
                      paymentstatus2={"Paiddddd"}
                      paymentstatus={"Outstanding"}
                      amount={Utils.getInputNumberFormat(
                        Math.abs(estimateList?.totalAccepted || "0.00")
                      )}
                      option={
                        <img
                          alt="currency"
                          src={currency1}
                          style={{ marginTop: "8px" }}
                        />
                      }
                      pageName="SalesOrder"
                    />
                  </Skeleton>
                </div>
              </Col>
              {width <= 768 ? (
                <Col md={1} style={{ flex: "none" }}></Col>
              ) : (
                <Col>
                  <img
                    className="Resp-signs"
                    alt="currency"
                    src={plusImg}
                    style={{
                      marginTop: "60px",
                    }}
                  />
                </Col>
              )}
              <Col lg={5} xl={5} md={12} xs={24}>
                {" "}
                <div>
                  <Skeleton loading={loading}>
                    <CardDivider
                      title={"Pending"}
                      paymentstatus2={"Overdue"}
                      amount={Utils.getFormattedNumber(
                        Math.round(estimateList?.totalPending || "0.00"),
                        0
                      )}
                      paymentstatus={"Due"}
                      option={
                        <img
                          alt="₹"
                          src={currency2}
                          style={{ marginTop: "8px" }}
                        />
                      }
                      pageName="SalesOrder"
                    />
                  </Skeleton>{" "}
                </div>
              </Col>
              {width <= 768 ? (
                ""
              ) : (
                <Col>
                  <img
                    alt="₹"
                    className="Resp-signs"
                    src={plusImg}
                    style={{
                      marginTop: "60px",
                    }}
                  />
                </Col>
              )}
              <Col lg={5} xl={5} md={11} xs={24}>
                <div>
                  <Skeleton loading={loading}>
                    <DashboardChart
                      title={"Rejected"}
                      paymentstatus2={"Overdue"}
                      //   amount={estimateList?.quotationRaised}
                      amount={Utils.getFormattedNumber(
                        Math.round(estimateList?.totalRejected || "0.00"),
                        0
                      )}
                      paymentstatus={"Due"}
                      option={
                        <img
                          alt="₹"
                          src={currency3}
                          style={{ marginTop: "8px" }}
                        />
                      }
                      pageName="SalesOrder"
                    />
                  </Skeleton>
                </div>
              </Col>
              {width <= 768 ? (
                <Col md={1} style={{ flex: "none" }}></Col>
              ) : (
                <Col>
                  <img
                    className="Resp-signs"
                    alt="+"
                    src={equalImg}
                    style={{
                      marginTop: "60px",
                    }}
                  />
                </Col>
              )}
              <Col lg={5} xl={5} md={12} xs={24}>
                <Skeleton loading={loading}>
                  <SalesCard
                    title={"Total Amount"}
                    paymentstatus2={"Overdue"}
                    amount={Utils.getFormattedNumber(
                      Math.round(estimateList?.totalAmount || "0.00"),
                      0
                    )}
                    paymentstatus={"Due"}
                    option={option}
                  />
                </Skeleton>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </div>
      <Card
        size="small"
        className="TableCard"
        style={{
          width: "100%",
          height: "100%",
          minHeight: "60vh",
          // marginLeft: "16px",
          marginTop: "15px",
          // paddingTop:"25px",
          border: "1px solid #eef2fe",
          boxShadow: "0px 0px 10px 0px #96A1C84D",
        }}
      >
        <Row>
          <Col lg={24}>
            <QuotationTable
              loading={loading}
              setLoading={setLoading}
              estimateList={estimateList}
              setEstimateList={setEstimateList}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};
export default Quotation;
